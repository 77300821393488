// src/arbitrum/index.ts
import { SupportedChains as SupportedChains3 } from "@midas-capital/types";

// deployments/arbitrum.json
var arbitrum_default = {
  name: "arbitrum",
  chainId: "42161",
  contracts: {
    AddressesProvider: {
      address: "0xe693a13526Eb4cff15EbeC54779Ea640E2F36a9f"
    },
    AddressesProvider_Implementation: {
      address: "0x79AAb023F3cdCf5a8314E88bfb9EE88ecd3e12e7"
    },
    AddressesProvider_Proxy: {
      address: "0xe693a13526Eb4cff15EbeC54779Ea640E2F36a9f"
    },
    BalancerLpTokenLiquidator: {
      address: "0x6e54b2c5b1f4c9e559B7Dc72b4d02eEaAC44aa13"
    },
    BalancerSwapLiquidator: {
      address: "0x8BE6118918898894b72F889320b1f702068838C4"
    },
    CErc20Delegate: {
      address: "0xa7BA1a64a43D48f5C5001dBd7A95bCfC62FF0Cee"
    },
    CErc20PluginDelegate: {
      address: "0x26B63BE8aD948522702E5e3fb8A85116e4023274"
    },
    CErc20PluginRewardsDelegate: {
      address: "0x8123AaA3676eE37461A43006E13549C46fc7784c"
    },
    CEtherDelegate: {
      address: "0xB813269354382f8897dd133AB7aDdcc3D4dE9C3E"
    },
    CTokenFirstExtension: {
      address: "0x3d402363963478e6E0e1f201ADa552Adda8051C2"
    },
    ChainlinkPriceOracleV2: {
      address: "0x55C0f57cf6c1CAe4CC57D5F6334408f63C0d5bbC"
    },
    Comptroller: {
      address: "0xa65b3e229BD57AeA24D6Eeb822c5f26Ea4Ce9A8d"
    },
    ComptrollerFirstExtension: {
      address: "0x3f74Ce8432D8F433953Ff0FC16A57aea7ff946C3"
    },
    CurveLpTokenLiquidatorNoRegistry: {
      address: "0x2a3720945D9416f6583770E402f2129c77479878"
    },
    CurveLpTokenPriceOracleNoRegistry: {
      address: "0x606C2ED9a07b9b4C1D6d0b0df79100AF982d2476"
    },
    CurveLpTokenPriceOracleNoRegistry_Implementation: {
      address: "0xE82C29AC5f6dd9363a36ef78a6881134c4664011"
    },
    CurveLpTokenPriceOracleNoRegistry_Proxy: {
      address: "0x606C2ED9a07b9b4C1D6d0b0df79100AF982d2476"
    },
    CurveSwapLiquidator: {
      address: "0x9531D47dAb90eEd97B74e5c2Eb3A4baca48De014"
    },
    DefaultProxyAdmin: {
      address: "0xb6C070BFDFbbc081F2da688d3e0527A5B5EfCB0C"
    },
    FixedNativePriceOracle: {
      address: "0xFC43A2A797f731dad53D6BC4Fe9300d68F480203"
    },
    FuseFeeDistributor: {
      address: "0xA21eB35869C4aafeB55a931Db7F56267a59dB620"
    },
    FuseFeeDistributor_Implementation: {
      address: "0x6fa87A8FcCD054fa8e18f0855038f67B89a43154"
    },
    FuseFeeDistributor_Proxy: {
      address: "0xA21eB35869C4aafeB55a931Db7F56267a59dB620"
    },
    FuseFlywheelLensRouter: {
      address: "0xC9d0E2752716c0A2577db71402b78a9d335B1310"
    },
    FusePoolDirectory: {
      address: "0x68e8f59eA33FbccA716f56f89F47e53C73d47830"
    },
    FusePoolDirectory_Implementation: {
      address: "0xCbEf1456cEB963F928BB2D2544aA5177c2cB7B99"
    },
    FusePoolDirectory_Proxy: {
      address: "0x68e8f59eA33FbccA716f56f89F47e53C73d47830"
    },
    FusePoolLens: {
      address: "0x062CEc1fa0F54cccd513c5b8aa86cBead5d1e55d"
    },
    FusePoolLensSecondary: {
      address: "0x7b6F27bF7cE60DA6e398ec348a915D8A81bC2b3A"
    },
    FuseSafeLiquidator: {
      address: "0xf053FC4634B8683a583E962ebb2C7C419B6Eb7Aa"
    },
    FuseSafeLiquidator_Implementation: {
      address: "0x0D71831F4f819057A606656D5eF2BEe0bF491f29"
    },
    FuseSafeLiquidator_Proxy: {
      address: "0xf053FC4634B8683a583E962ebb2C7C419B6Eb7Aa"
    },
    JumpRateModel: {
      address: "0x0B3CB189Db3193C4F0d2911b196BC416621F97E1"
    },
    LeveredPositionFactory: {
      address: "0x723f7E28c2F5041C7e9f5b46B8b92bF4B40750e6"
    },
    LeveredPositionFactoryExtension: {
      address: "0xd566C2C4B7D58F63aA8A5C577a39E1De343466d2"
    },
    LeveredPositionsLens: {
      address: "0x8a571d80CaB8bf2101dbA71F4FeaC2216b507403"
    },
    LeveredPositionsLens_Implementation: {
      address: "0x1014Cb7eCFade62956648E7fC44f25243ab1823e"
    },
    LeveredPositionsLens_Proxy: {
      address: "0x8a571d80CaB8bf2101dbA71F4FeaC2216b507403"
    },
    LiquidatorsRegistry: {
      address: "0x085a8522D81e1cab60547613b2626db6A94A64c4"
    },
    LiquidatorsRegistryExtension: {
      address: "0xa1E8f53Af27263a15E20C58476D261e2c27C6985"
    },
    LooplessFlywheelBooster: {
      address: "0xB307C43f80FD6BB30624916eCc64d85550E9A8d9"
    },
    MasterPriceOracle: {
      address: "0xd4D0cA503E8befAbE4b75aAC36675Bc1cFA533D1"
    },
    MasterPriceOracle_Implementation: {
      address: "0x559F595fc39C03eB10B7B10308d31B10763f0c6C"
    },
    MasterPriceOracle_Proxy: {
      address: "0xd4D0cA503E8befAbE4b75aAC36675Bc1cFA533D1"
    },
    MidasFlywheelLensRouter: {
      address: "0xFe5aF5765A7cCD1538E4ee4B501BC7fe93ec8EBa"
    },
    OptimizedVaultsRegistry: {
      address: "0xCBA6171Ab5557CaadC993489CbC58B77a2b0f3Ed"
    },
    OptimizedVaultsRegistry_Implementation: {
      address: "0xf488dEC2669257D57318986f9FFa691484169CD7"
    },
    OptimizedVaultsRegistry_Proxy: {
      address: "0xCBA6171Ab5557CaadC993489CbC58B77a2b0f3Ed"
    },
    Quoter: {
      address: "0x69BaFbf39e09cCB3138d7Ed0BCB1655823F7D511"
    },
    SaddleLpPriceOracle: {
      address: "0xEB63CE86Caf06CbB6387F9822c13c629B31b6064"
    },
    SaddleLpPriceOracle_Implementation: {
      address: "0x48c54C108E065211D170da1957c9503fc1F554fa"
    },
    SaddleLpPriceOracle_Proxy: {
      address: "0xEB63CE86Caf06CbB6387F9822c13c629B31b6064"
    },
    SaddleLpTokenLiquidator: {
      address: "0x604805B587C939042120D2e22398f299547A130c"
    },
    SimplePriceOracle: {
      address: "0x7ef2A6a6aF8ee090da3a24ea947b3717c4D16D94"
    },
    SimplePriceOracle_Implementation: {
      address: "0xa486D81B950022E52A111980B0B08C05fbA594E0"
    },
    SimplePriceOracle_Proxy: {
      address: "0x7ef2A6a6aF8ee090da3a24ea947b3717c4D16D94"
    },
    UniswapLpTokenLiquidator: {
      address: "0x00C0681a5F335bE0077cf9b959d025C6b5b0A264"
    },
    UniswapLpTokenPriceOracle: {
      address: "0xE10C6F1Ec7d1C8664aB6473847c09c5109486a38"
    },
    UniswapV3LiquidatorFunder: {
      address: "0x46600C411D16C335D46675C09D578558d42fD1E7"
    },
    UniswapV3PriceOracle: {
      address: "0x6684b0A4B8363A43fbF4f6D022436B4554F8B4B2"
    },
    UniswapV3PriceOracle_Implementation: {
      address: "0x19a99b280cA067f0211ABdC8053e70c172b8A96a"
    },
    UniswapV3PriceOracle_Proxy: {
      address: "0x6684b0A4B8363A43fbF4f6D022436B4554F8B4B2"
    },
    WhitePaperInterestRateModel: {
      address: "0xEC7f3Cc73fF1B5b928105a81f57c676FD4004080"
    }
  }
};

// src/arbitrum/addresses.ts
import { assetSymbols as assetSymbols2, underlying } from "@midas-capital/types";
import { ethers as ethers2 } from "ethers";

// src/common/addresses.ts
import { ethers } from "ethers";
var UNISWAP_V3_ADDRESSES = {
  FACTORY: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
  PAIR_INIT_HASH: ethers.utils.hexlify("0xe34f199b19b2b4f47f68442619d555527d244f78a3297ea89325f843f87b8b54"),
  QUOTER_V2: "0x61fFE014bA17989E743c5F6cB21bF9697530B21e"
};

// src/arbitrum/assets.ts
import { assetSymbols, OracleTypes, SupportedChains as SupportedChains2 } from "@midas-capital/types";

// src/common/docs.ts
import { SupportedChains } from "@midas-capital/types";
var defaultDocs = (blockExplorerUrl, tokenAddress) => {
  return `
  <p><b>How to acquire this token</b><p/><br />
  <p>Check out the token tracker for this asset in the <a href="${blockExplorerUrl}/token/${tokenAddress}" target="_blank" style="color: #BCAC83;">Official Block Explorer</a>, where you can access the token's site as well as market information.</p>`;
};
var wrappedAssetDocs = (chainId) => {
  const wrapAddress = {
    [SupportedChains.bsc]: {
      swapName: "PancakeSwap",
      swapAddress: "https://pancakeswap.finance/swap?outputCurrency=0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
    },
    [SupportedChains.polygon]: {
      swapName: "SushiSwap",
      swapAddress: "https://app.sushi.com/swap?tokens=MATIC&tokens=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270&chainId=137"
    },
    [SupportedChains.moonbeam]: {
      swapName: "StellaSwap",
      swapAddress: "https://app.stellaswap.com/exchange/swap"
    },
    [SupportedChains.arbitrum]: {
      swapName: "SushiSwap",
      swapAddress: "https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=0x82aF49447D8a07e3bd95BD0d56f35241523fBab1&chainId=42161"
    },
    [SupportedChains.ganache]: {},
    [SupportedChains.evmos]: {},
    [SupportedChains.neon_devnet]: {
      swapName: "MoraSwap",
      swapAddress: "https://moraswap.com/exchange/swap"
    },
    [SupportedChains.chapel]: {},
    [SupportedChains.fantom]: {
      swapName: "SpookySwap",
      swapAddress: "https://spooky.fi/#/swap"
    },
    [SupportedChains.lineagoerli]: {
      swapName: "Uniswap",
      swapAddress: "https://swap.goerli.linea.build/#/swap"
    },
    [SupportedChains.ethereum]: {
      swapName: "Uniswap",
      swapAddress: "https://app.uniswap.org/#/swap?outputCurrency=0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
    }
  }[chainId];
  return `
  <p><b>How to acquire the wrapped token: </b><p/><br />
  Head over to the <a href="${wrapAddress.swapAddress}" target="_blank" style="color: #BCAC83;">${wrapAddress.swapName} Exchange</a> and swap your native token for the wrapped token.</p>
  `;
};
var ellipsisDocs = (poolAddress, poolName, tokenAddress) => {
  return `
  <p><b>How to acquire this token</b><p/><br />
  <p> 1. Head over to the <a href="https://ellipsis.finance/pool/${poolAddress}" target="_blank" style="color: #BCAC83;">${poolName} Ellipsis Finance Pool</a> and click on "Add Liquidity".</p><br />
  <p> 2. You can then supply any of the underlying assets, and upon adding liquidity.</p>
  <p> You will get back the <a href="https://bscscan.com/token/${tokenAddress}" target="_blank" style="color: #BCAC83;">${poolName} pool LP tokens</a>.</p><br />
  <p> 3. Come back here and hit "MAX" to deposit them all in this pool.</p>
  `;
};
var ankrCertificateDocs = (variant, chain) => {
  return `
  <p><b>How to acquire this token</b><p/><br />
  <p>Head over to <a href="https://www.ankr.com/staking/stake/${chain.toLowerCase()}/?token=${variant}" target="_blank" style="color: #BCAC83;">Ankr ${chain} Staking</a>, where you can acquire ${variant} by depositing ${chain}</p>
  `;
};
var clayStackDocs = (tokenName, chain) => {
  return `
  <p><b>How to acquire this token</b><p/><br />
  <p>Head over to <a href="https://app.claystack.com/stake/${chain.toLowerCase()}" target="_blank" style="color: #BCAC83;">ClayStack ${chain} Staking</a>, where you can acquire cs${tokenName} by depositing ${tokenName}</p>
  `;
};
var stkBNBDocs = () => {
  return `
  <p><b>How to acquire this token</b><p/><br />
  <p><code>stkBNB</code> is the liquid staked representative for BNB issued by pSTAKE. You can liquid stake your BNB  <a href="https://bnb.pstake.finance/" target="_blank" style="color: #BCAC83;">here</a>.
  `;
};
var StaderXDocs = (chainName, token) => {
  const baseTokenName = token.slice(0, -1);
  return `
  <p><b>How to acquire this token</b><p/><br />
  <p>Head over to <a href="https://${chainName}.staderlabs.com/liquid-staking/${token.toLowerCase()}/" target="_blank" style="color: #BCAC83;">Stader Lab's ${baseTokenName} Staking</a>, where you can acquire ${token} by depositing ${baseTokenName}</p>
  `;
};
var pancakeSwapDocs = (token0, token1, poolName, tokenAddress) => {
  return `
  <p><b>How to acquire this token</b><p/><br />
  <p> 1. Head to <a href="https://pancakeswap.finance/add/${token0}/${token1}" target="_blank" style="color: #BCAC83;">Pancakeswap</a>.</p><br />
  <p> 2. Ensure that the tokens are correct, and tap "Add Liquidity".</p>
  <p><b>NOTE:</b> You might have to convert between tokens and/or have to approve Pancakeswap to spend them. Finally, click on supply.</p>
  <p>You will get back <a href="https://bscscan.com/token/${tokenAddress}" target="_blank" style="color: #BCAC83;">Pancakeswap ${poolName} LP tokens</a> in your wallet.</p><br />
  <p> 3. Come back here and hit "MAX" to deposit them all in this pool.</p>
  `;
};
var apeSwapDocs = (token0, token1, poolName, tokenAddress) => {
  return `
  <p><b>How to acquire this token</b><p/><br />
  <p> 1. Head to <a href="https://apeswap.finance/add-liquidity/${token0}/${token1}" target="_blank" style="color: #BCAC83;">Apeswap</a>.</p><br />
  <p> 2. Ensure that the tokens are correct, and tap "Add Liquidity".</p>
  <p><b>NOTE:</b> You might have to convert between tokens and/or have to approve Pancakeswap to spend them. Finally, click on supply.</p>
  <p>You will get back <a href="https://bscscan.com/token/${tokenAddress}" target="_blank" style="color: #BCAC83;">Apeswap ${poolName} LP tokens</a> in your wallet.</p><br />
  <p> 3. Come back here and hit "MAX" to deposit them all in this pool.</p>
  `;
};
var quickSwapDocs = (token0, token1, poolName, tokenAddress) => {
  return `
  <p><b>How to acquire this token</b><p/><br />
  <p> 1. Head to <a href="https://quickswap.exchange/#/pools" target="_blank" style="color: #BCAC83;">Quickswap</a>.</p><br />
  <p> 2. Input the token addresses -- token 1: ${token0} and  token 2: ${token1}".</p>
  <p><b>NOTE:</b> You might have to convert between tokens and/or have to approve QuickSwap to spend them.</p><br />
  <p> 3. Finally, click on supply.</p>
  <p>You will get back <a href="https://polygonscan.com/token/${tokenAddress}" target="_blank" style="color: #BCAC83;">Quickswap ${poolName} LP tokens</a> in your wallet.</p><br />
  <p> 4. Come back here and hit "MAX" to deposit them all in this pool.</p>
  `;
};
var beamSwapDocs = (token0, token1, poolName, tokenAddress) => {
  return `
  <p><b>How to acquire this token</b><p/><br />
  <p> 1. Head to <a href="https://app.beamswap.io/exchange/add/${token0}/${token1}" target="_blank" style="color: #BCAC83;">BeamSwap</a> and supply the desired liquidity pairs.</p>
  <p><b>NOTE:</b> You might have to convert between tokens and/or have to approve BeamSwap to spend them.</p><br />
  <p> 2. You will get back <a href="https://moonbeam.moonscan.com/token/${tokenAddress}" target="_blank" style="color: #BCAC83;">BeamSwap ${poolName} LP tokens</a> in your wallet.</p><br />
  <p> 3. Come back here and hit "MAX" to deposit them all in this pool. </p>
  `;
};
var stellaSwapDocs = (token0, token1, poolName, tokenAddress) => {
  return `
  <p><b>How to acquire this token</b><p/><br />
  <p> 1. Head to <a href="https://app.stellaswap.com/exchange/add/${token0}/${token1}" target="_blank" style="color: #BCAC83;">StellaSwap</a> and supply the desired liquidity pairs.</p>
  <p><b>NOTE:</b> You might have to convert between tokens and/or have to approve StellaSwap to spend them.</p><br />
  <p> 2. You will get back <a href="https://moonbeam.moonscan.com/token/${tokenAddress}" target="_blank" style="color: #BCAC83;">StellaSwap ${poolName} LP tokens</a> in your wallet.</p><br />
  <p> 3. Come back here and hit "MAX" to deposit them all in this pool. </p>
  `;
};
var stellaSwapStableDocs = (poolAddress, poolName, tokenAddress) => {
  return `
  <p><b>How to acquire this token</b><p/><br />
  <p> 1. Head to <a href="https://app.stellaswap.com/exchange/pool/stable/add/${poolAddress}" target="_blank" style="color: #BCAC83;">StellaSwap</a> and supply the desired liquidity pairs.</p>
  <p><b>NOTE:</b> You might have to convert between tokens and/or have to approve StellaSwap to spend them.</p><br />
  <p> 2. You will get back <a href="https://moonbeam.moonscan.com/token/${tokenAddress}" target="_blank" style="color: #BCAC83;">StellaSwap ${poolName} LP tokens</a> in your wallet.</p><br />
  <p> 3. Come back here and hit "MAX" to deposit them all in this pool. </p>
  `;
};
var curveFinancePolygonDocs = (poolNumber, poolName, tokenAddress, isFactoryOrCrypto = false) => {
  let poolPath;
  if (isFactoryOrCrypto === false) {
    poolPath = "/";
  } else if (isFactoryOrCrypto === true) {
    poolPath = "/factory/";
  } else {
    poolPath = "/${isFactoryOrCrypto}/";
  }
  return `
  <p><b>How to acquire this token</b><p/><br />
  <p> 1. Head over to the <a href="https://polygon.curve.fi${poolPath}${poolNumber}/deposit" target="_blank" style="color: #BCAC83;"> Curve ${poolName} Pool</a>.</p><br />
  <p> 2. You can then supply any of the underlying assets, and upon adding liquidity.</p>
  <p>You will get back the <a href="https://polygonscan.com/token/${tokenAddress}" target="_blank" style="color: #BCAC83;"> Curve ${poolName} LP tokens</a>.</p><br />
  <p> 3. Come back back here and hit "MAX" to deposit them all in this pool.</p>
  `;
};
var balancerDocs = (chain, poolAddress, poolName, tokenAddress) => {
  return `
  <p><b>How to acquire this token</b><p/><br />
  <p> 1. Head over to the <a href="https://${chain}.balancer.fi/#/pool/${poolAddress}" target="_blank" style="color: #BCAC83;"> Balancer ${poolName} Pool</a>.</p><br />
  <p> 2. You can then supply any of the underlying assets, and upon adding liquidity.</p>
  <p>You will get back the <a href="https://polygonscan.com/token/${tokenAddress}" target="_blank" style="color: #BCAC83;"> Balancer ${poolName} LP tokens</a>.</p><br />
  <p> 3. Come back back here and hit "MAX" to deposit them all in this pool.</p>
  `;
};
var beethovenXDocs = (poolAddress, poolName, tokenAddress) => {
  return `
  <p><b>How to acquire this token</b><p/><br />
  <p> 1. Head over to the <a href="https://beets.fi/pool/${poolAddress}" target="_blank" style="color: #BCAC83;"> Balancer ${poolName} Pool</a>.</p><br />
  <p> 2. You can then supply any of the underlying assets, and upon adding liquidity.</p>
  <p>You will get back the <a href="https://polygonscan.com/token/${tokenAddress}" target="_blank" style="color: #BCAC83;"> Balancer ${poolName} LP tokens</a>.</p><br />
  <p> 3. Come back back here and hit "MAX" to deposit them all in this pool.</p>
  `;
};
var curveFinanceArbitrumDocs = (poolName, tokenAddress) => {
  return `
  <p><b>How to acquire this token</b><p/><br />
  <p> 1. Head over to the <a href="https://arbitrum.curve.fi/${poolName}/deposit" target="_blank" style="color: #BCAC83;"> Curve ${poolName} Pool</a>.</p><br />
  <p> 2. You can then supply any of the underlying assets, and upon adding liquidity.</p>
  <p>You will get back the <a href="https://arbiscan.io/token/${tokenAddress}" target="_blank" style="color: #BCAC83;"> Curve ${poolName} LP tokens</a>.</p><br />
  <p> 3. Come back back here and hit "MAX" to deposit them all in this pool.</p>
  `;
};
var curveFinanceFantomDocs = (poolName, tokenAddress) => {
  return `
  <p><b>How to acquire this token</b><p/><br />
  <p> 1. Head over to the <a href="https://curve.fi/#/fantom/pools/${poolName}/deposit" target="_blank" style="color: #BCAC83;"> Curve ${poolName} Pool</a>.</p><br />
  <p> 2. You can then supply any of the underlying assets, and upon adding liquidity.</p>
  <p>You will get back the <a href="https://ftmscan.com/token/${tokenAddress}" target="_blank" style="color: #BCAC83;"> Curve ${poolName} LP tokens</a>.</p><br />
  <p> 3. Come back back here and hit "MAX" to deposit them all in this pool.</p>
  `;
};
var saddleFinanceDocs = (poolName, tokenAddress) => {
  return `
  <p><b>How to acquire this token</b><p/><br />
  <p> 1. Head over to the <a href="https://saddle.exchange/#/pools/${poolName}/deposit" target="_blank" style="color: #BCAC83;"> Saddle ${poolName} Pool</a>.</p><br />
  <p> 2. You can then supply any of the underlying assets, and upon adding liquidity.</p>
  <p>You will get back the <a href="https://arbiscan.io/token/${tokenAddress}" target="_blank" style="color: #BCAC83;"> Saddle ${poolName} LP tokens</a>.</p><br />
  <p> 3. Come back back here and hit "MAX" to deposit them all in this pool.</p>
  `;
};
var curveFinanceMoonbeamDocs = (poolNumber, poolName, tokenAddress, isFactory = false) => {
  return `
  <p><b>How to acquire this token</b><p/><br />
  <p> 1. Head over to the <a href="https://moonbeam.curve.fi${isFactory ? "/factory/" : "/"}${poolNumber}/deposit" target="_blank" style="color: #BCAC83;"> Curve ${poolName} Pool</a>.</p><br />
  <p> 2. You can then supply any of the underlying assets, and upon adding liquidity.</p>
  <p>You will get back the <a href="https://moonbeam.moonscan.io/token/${tokenAddress}" target="_blank" style="color: #BCAC83;"> Curve ${poolName} LP tokens</a>.</p><br />
  <p> 3. Come back back here and hit "MAX" to deposit them all in this pool.</p>
  `;
};
var jarvisDocs = (v) => {
  return `
  <p><b>How to acquire this token</b><p/><br />
  <p>You can acquire this asset on the <a href="https://${v}-app.jarvis.exchange/" target="_blank" style="color: #BCAC83;">Jarvis Network</a> website.</p>`;
};
var tangibleDocsUsdr = (action) => {
  return `
  <p><b>How to acquire this token</b><p/><br />
  <p>You can acquire this asset on the <a href="https://www.tangible.store/realusd?action=${action}" target="_blank" style="color: #BCAC83;">Tangible</a> website.</p>`;
};
var oneInchDocs = (url) => `
<p><b>How to acquire this token</b><p/><br />
<p>You can acquire this asset for example via the <a href="${url}" target="_blank" style="color: #BCAC83;">1inch decentralized exchange</a>.</p>
`;
var arrakisDocs = (networkName, chainId, vaultAddress) => {
  return `
  <p><b>How to acquire this Arrakis Vault token</b><p/><br /><p> 1. Make sure you are connected to ${networkName} Network on your browser wallet.</p><br />
  <p> 2. Head to the <a href="https://beta.arrakis.finance/vaults/${chainId}/${vaultAddress}/add" target="_blank" style="color: #BCAC83;">Arrakis Finance Vault</a> and deposit the desired amount of token pairs.</p>
  <p><b>NOTE:</b> You might have to convert between tokens and/or have to approve Arrakis to spend them. </p><br />
  <p> 3. Click on "Deposit".</p>
  <p>This will credit your wallet with the Arrakis Vault Tokens.</p><br />
  <p> 4. Come back back here and hit "MAX" to deposit them all in this pool.</p>
  `;
};
var wombatDocs = (pool, tokenName) => {
  return `
  <p><b>How to acquire this token</b></p><br />
  <p>You can acquire this asset on the <a href="https://app.wombat.exchange/pool?pool=${pool}&token=${tokenName}&action=DEPOSIT"> Wombat Exchange </a> website</p>`;
};
var thenaDocs = (poolAddress) => {
  return `
  <p><b>How to acquire this token</b><p/><br /><p> 1. Make sure you are connected to BNB Network on your browser wallet.</p><br />
  <p> 2. Head to the <a href="https://thena.fi/liquidity/manage/${poolAddress.toLowerCase()}" target="_blank" style="color: #BCAC83;"> Thena.fi Exchange </a> and deposit the desired amount of token pairs.</p>
  <p><b>NOTE:</b> You might have to convert between tokens and/or have to approve Thena to spend them. </p><br />
  <p> 3. Click on "Add Liquidty".</p>
  <p>This will credit your wallet with the Thena LP Tokens.</p><br />
  <p> 4. Come back back here and hit "MAX" to deposit them all in this pool.</p><br />
  <p> <b>NOTE</b>: do not stake the LPs on Thena, Midas will do that for you! </p>
  `;
};
var pearlDocs = (poolAddress) => {
  return `
  <p><b>How to acquire this token</b><p/><br /><p> 1. Make sure you are connected to Polygon Network on your browser wallet.</p><br />
  <p> 2. Head to the <a href="https://www.pearl.exchange/liquidity/manage/${poolAddress.toLowerCase()}" target="_blank" style="color: #BCAC83;"> Pearl Exchange </a> and deposit the desired amount of token pairs.</p>
  <p><b>NOTE:</b> You might have to convert between tokens and/or have to approve Thena to spend them. </p><br />
  <p> 3. Click on "Add Liquidty".</p>
  <p>This will credit your wallet with the Pearl LP Tokens.</p><br />
  <p> 4. Come back back here and hit "MAX" to deposit them all in this pool.</p><br />
  <p> <b>NOTE</b>: do not stake the LPs on Peal, Midas will do that for you! </p>
  `;
};
var thenaDocsV2 = (token0, token1, poolAddress, strategy) => {
  return `
  <p><b>How to acquire this token</b><p/><br /><p> 1. Make sure you are connected to BNB Network on your browser wallet.</p><br />
  <p> 2. Head to the <a href="https://thena.fi/liquidity/fusion?currency0=${token0}&currency1=${token1}&strategy=${strategy}" target="_blank" style="color: #BCAC83;"> Thena.fi Exchange </a> and deposit the desired amount of token pairs.</p>
  <p><b>NOTE:</b> You might have to convert between tokens and/or have to approve Thena to spend them. </p><br />
  <p> 3. Click on "Add Liquidty".</p>
  <p>This will credit your wallet with the Thena LP Tokens (${poolAddress}).</p><br />
  <p> 4. Come back back here and hit "MAX" to deposit them all in this pool.</p><br />
  <p> <b>NOTE</b>: <p> - Do not stake the LPs on Thena, Midas will do that for you! </p>  <p> - Make sure to use "AUTOMATIC" range for stable LPs </p> </p>
  `;
};
var lidoFinanceDocs = (chainName, baseToken, returnToken) => {
  return `
  <p><b>How to acquire this token</b><p/><br />
  <p>You can get <code>${returnToken}</code> by staking your <code>${baseToken}</code> on <a href="https://${chainName}.lido.fi/" target="_blank" style="color: #BCAC83; cursor="pointer">Lido on ${chainName.charAt(0).toUpperCase() + chainName.slice(1)}</a></p>`;
};
var sommFinanceMainnetDocs = (strategyName, returnToken, supplyTokens) => {
  return `
  <p><b>How to acquire this token</b><p/><br />
  <p>You can get <code>${returnToken}</code> by supplying any of your on the <code>${supplyTokens.join(
    ", "
  )}</code> on <a href="https://app.sommelier.finance/strategies/${strategyName}" target="_blank" style="color: #BCAC83; cursor="pointer">Sommelier strategy</a></p>`;
};

// src/arbitrum/assets.ts
var WBTC = "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f";
var USDC = "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8";
var BAL = "0x040d1EdC9569d4Bab2D15287Dc5A4F10F56a56B8";
var BNB = "0x20865e63B111B2649ef829EC220536c82C58ad7B";
var CRV = "0x11cDb42B0EB46D95f990BeDD4695A6e3fA034978";
var DAI = "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1";
var WETH = "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1";
var FRAX = "0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F";
var FXS = "0x9d2F299715D94d8A7E6F5eaa8E654E8c74a988A7";
var LINK = "0xf97f4df75117a78c1A5a0DBb814Af92458539FB4";
var DPX = "0x6C2C06790b3E3E3c38e12Ee22F8183b37a13EE55";
var GOHM = "0x8D9bA570D6cb60C7e3e0F31343Efe75AB8E65FB1";
var OHM = "0xf0cb2dc0db5e6c66B9a70Ac27B06b878da017028";
var wstETH = "0x5979D7b546E38E414F7E9822514be443A4800529";
var MAGIC = "0x539bdE0d7Dbd336b79148AA742883198BBF60342";
var GMX = "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a";
var USDs = "0xD74f5255D557944cf7Dd0E45FF521520002D5748";
var SUSHI = "0xd4d42F0b6DEF4CE0383636770eF773390d85c61A";
var USDT = "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9";
var TWOPOOL = "0x7f90122BF0700F9E7e1F688fe926940E8839F353";
var saddleFraxBP = "0x896935B02D3cBEb152192774e4F1991bb1D2ED3f";
var saddleFraxUsdsBP = "0x1e491122f3C096392b40a4EA27aa1a29360d38a1";
var saddleFraxUsdtBP = "0x166680852ae9Dec3d63374c5eBf89E974448BFE9";
var assets = [
  {
    symbol: assetSymbols.BAL,
    underlying: BAL,
    name: "Balancer",
    decimals: 18,
    oracle: OracleTypes.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://arbiscan.io", BAL)
  },
  {
    symbol: assetSymbols.BNB,
    underlying: BNB,
    name: "Binance Network",
    decimals: 18,
    oracle: OracleTypes.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://arbiscan.io", BNB)
  },
  {
    symbol: assetSymbols.CRV,
    underlying: CRV,
    name: "Curve DAO Token",
    decimals: 18,
    oracle: OracleTypes.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://arbiscan.io", CRV)
  },
  {
    symbol: assetSymbols.DAI,
    underlying: DAI,
    name: "Dai Stablecoin",
    decimals: 18,
    oracle: OracleTypes.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://arbiscan.io", DAI)
  },
  {
    symbol: assetSymbols.WETH,
    underlying: WETH,
    name: "Wrapped Ether",
    decimals: 18,
    oracle: OracleTypes.ChainlinkPriceOracleV2,
    extraDocs: wrappedAssetDocs(SupportedChains2.arbitrum)
  },
  {
    symbol: assetSymbols.FRAX,
    underlying: FRAX,
    name: "Frax",
    decimals: 18,
    oracle: OracleTypes.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://arbiscan.io", FRAX)
  },
  {
    symbol: assetSymbols.FXS,
    underlying: FXS,
    name: "Frax Share",
    decimals: 18,
    oracle: OracleTypes.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://arbiscan.io", FXS)
  },
  {
    symbol: assetSymbols.LINK,
    underlying: LINK,
    name: "ChainLink Token",
    decimals: 18,
    oracle: OracleTypes.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://arbiscan.io", LINK)
  },
  {
    symbol: assetSymbols.SUSHI,
    underlying: SUSHI,
    name: "SushiToken",
    decimals: 18,
    oracle: OracleTypes.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://arbiscan.io", SUSHI)
  },
  {
    symbol: assetSymbols.USDC,
    underlying: USDC,
    name: "USD Coin (Arb1)",
    decimals: 6,
    oracle: OracleTypes.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://arbiscan.io", USDC)
  },
  {
    symbol: assetSymbols.USDT,
    underlying: USDT,
    name: "Tether USD",
    decimals: 6,
    oracle: OracleTypes.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://arbiscan.io", USDT)
  },
  {
    symbol: assetSymbols.USDs,
    underlying: USDs,
    name: "Sperax USD",
    decimals: 18,
    oracle: OracleTypes.UniswapV3PriceOracle,
    extraDocs: defaultDocs("https://arbiscan.io", USDs)
  },
  // Awaiting price feeds
  // {
  //   symbol: assetSymbols.USX,
  //   underlying: USX,
  //   name: "dForce USD",
  //   decimals: 18,
  //   oracle: OracleTypes.UniswapV3PriceOracle,
  //   extraDocs: defaultDocs("https://arbiscan.io", USX),
  // },
  // {
  //   symbol: assetSymbols.asUSD,
  //   underlying: alUSD,
  //   name: "Alchemix USD",
  //   decimals: 18,
  //   oracle: OracleTypes.UniswapV3PriceOracle,
  //   extraDocs: defaultDocs("https://arbiscan.io", alUSD),
  // },
  {
    symbol: assetSymbols.WBTC,
    underlying: WBTC,
    name: "Wrapped BTC",
    decimals: 8,
    oracle: OracleTypes.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://arbiscan.io", WBTC)
  },
  {
    symbol: assetSymbols.GOHM,
    underlying: GOHM,
    name: "Governance OHM",
    decimals: 18,
    oracle: OracleTypes.UniswapTwapPriceOracleV2,
    extraDocs: defaultDocs("https://arbiscan.io", GOHM),
    disabled: true
  },
  {
    symbol: assetSymbols.DPX,
    underlying: DPX,
    name: "Dopex Governance Token",
    decimals: 18,
    oracle: OracleTypes.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://arbiscan.io", DPX)
  },
  {
    symbol: assetSymbols.MAGIC,
    underlying: MAGIC,
    name: "MAGIC",
    decimals: 18,
    oracle: OracleTypes.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://arbiscan.io", MAGIC)
  },
  {
    symbol: assetSymbols.GMX,
    underlying: GMX,
    name: "GMX",
    decimals: 18,
    oracle: OracleTypes.UniswapV3PriceOracle,
    extraDocs: defaultDocs("https://arbiscan.io", GMX),
    disabled: true
  },
  {
    symbol: assetSymbols.OHM,
    underlying: OHM,
    name: "Olympus",
    decimals: 9,
    oracle: OracleTypes.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://arbiscan.io", OHM)
  },
  {
    symbol: assetSymbols.wstETH,
    underlying: wstETH,
    name: "Lido Wrapped Staked Ether",
    decimals: 18,
    oracle: OracleTypes.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://arbiscan.io", wstETH)
  },
  // Curve LP tokens
  {
    symbol: assetSymbols["2pool"],
    underlying: TWOPOOL,
    name: "Curve.fi USDC/USDT",
    decimals: 18,
    oracle: OracleTypes.CurveLpTokenPriceOracleNoRegistry,
    extraDocs: curveFinanceArbitrumDocs("2pool", TWOPOOL)
  },
  // Saddle LP tokens
  {
    symbol: assetSymbols["saddleFraxBP"],
    underlying: saddleFraxBP,
    name: "Saddle Frax/USDC",
    decimals: 18,
    oracle: OracleTypes.SaddleLpPriceOracle,
    extraDocs: saddleFinanceDocs("FRAX-USDC-BP", saddleFraxBP)
  },
  {
    symbol: assetSymbols["saddleFraxUsdsBP"],
    underlying: saddleFraxUsdsBP,
    name: "Saddle Frax/USDS",
    decimals: 18,
    oracle: OracleTypes.SaddleLpPriceOracle,
    extraDocs: saddleFinanceDocs("FRAXBP-USDs", saddleFraxUsdsBP),
    disabled: true
  },
  {
    symbol: assetSymbols["saddleFraxUsdtBP"],
    underlying: saddleFraxUsdtBP,
    name: "Saddle Frax/USDT",
    decimals: 18,
    oracle: OracleTypes.SaddleLpPriceOracle,
    extraDocs: saddleFinanceDocs("FRAXBP-USDT", saddleFraxUsdtBP)
  }
];
var assets_default = assets;

// src/arbitrum/addresses.ts
var chainAddresses = {
  W_TOKEN: underlying(assets, assetSymbols2.WETH),
  W_TOKEN_USD_CHAINLINK_PRICE_FEED: "0x639Fe6ab55C921f74e7fac1ee960C0B6293ba612",
  UNISWAP_V2_ROUTER: "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
  UNISWAP_V2_FACTORY: "0xc35DADB65012eC5796536bD9864eD8773aBc74C4",
  UNISWAP_V3_ROUTER: "0xE592427A0AEce92De3Edee1F18E0157C05861564",
  PAIR_INIT_HASH: ethers2.utils.hexlify("0xe18a34eb0e04b04f7a0ac29a6e80748dca96319b42c54d679cb821dca90c6303"),
  STABLE_TOKEN: underlying(assets, assetSymbols2.USDC),
  W_BTC_TOKEN: underlying(assets, assetSymbols2.WBTC),
  UNISWAP_V3: UNISWAP_V3_ADDRESSES
};
var addresses_default = chainAddresses;

// src/arbitrum/fundingStrategies.ts
var fundingStrategies = {};
var fundingStrategies_default = fundingStrategies;

// src/arbitrum/irms.ts
import { IrmTypes } from "@midas-capital/types";
var baseIrms = [IrmTypes.WhitePaperInterestRateModel, IrmTypes.JumpRateModel];
var irms = [...baseIrms];
var irms_default = irms;

// src/arbitrum/liquidation.ts
import { assetSymbols as assetSymbols3, LiquidationStrategy, underlying as underlying2 } from "@midas-capital/types";
import { BigNumber, constants } from "ethers";
var liquidationDefaults = {
  DEFAULT_ROUTER: addresses_default.UNISWAP_V2_ROUTER,
  ASSET_SPECIFIC_ROUTER: {},
  SUPPORTED_OUTPUT_CURRENCIES: [constants.AddressZero, underlying2(assets, assetSymbols3.WETH)],
  SUPPORTED_INPUT_CURRENCIES: [constants.AddressZero, underlying2(assets, assetSymbols3.WETH)],
  LIQUIDATION_STRATEGY: LiquidationStrategy.UNISWAP,
  MINIMUM_PROFIT_NATIVE: BigNumber.from(0),
  LIQUIDATION_INTERVAL_SECONDS: 20,
  jarvisPools: [],
  balancerPools: [
    {
      poolAddress: "0x89dc7e71e362faF88D92288fE2311D25c6a1B5E0",
      underlyingTokens: [underlying2(assets, assetSymbols3.WETH), underlying2(assets, assetSymbols3.OHM)]
    }
  ]
};
var liquidation_default = liquidationDefaults;

// src/arbitrum/oracles.ts
import { OracleTypes as OracleTypes2 } from "@midas-capital/types";
var baseOracles = [OracleTypes2.FixedNativePriceOracle, OracleTypes2.MasterPriceOracle, OracleTypes2.SimplePriceOracle];
var oracles = [
  ...baseOracles,
  OracleTypes2.ChainlinkPriceOracleV2,
  OracleTypes2.CurveLpTokenPriceOracleNoRegistry,
  OracleTypes2.UniswapLpTokenPriceOracle,
  OracleTypes2.UniswapTwapPriceOracleV2,
  OracleTypes2.SaddleLpPriceOracle
];
var oracles_default = oracles;

// src/arbitrum/params.ts
import { assetSymbols as assetSymbols4, underlying as underlying3 } from "@midas-capital/types";
import { BigNumber as BigNumber2 } from "ethers";
var specificParams = {
  blocksPerYear: BigNumber2.from((4 * 24 * 365 * 60).toString()),
  cgId: "ethereum",
  metadata: {
    chainIdHex: "0xA4B1",
    name: "Arbitrum One",
    shortName: "Arbitrum",
    uniswapV3Fees: {
      [underlying3(assets_default, assetSymbols4.USDC)]: {
        [underlying3(assets_default, assetSymbols4.GMX)]: 3e3
      }
    },
    img: "https://d1912tcoux65lj.cloudfront.net/network/arbitrum.jpg",
    blockExplorerUrls: { default: { name: "arbiscan", url: "https://arbiscan.io" } },
    rpcUrls: {
      default: { http: ["https://rpc.ankr.com/arbitrum"] },
      public: { http: ["https://rpc.ankr.com/arbitrum"] }
    },
    nativeCurrency: {
      symbol: "ETH",
      name: "ETH"
    },
    wrappedNativeCurrency: {
      symbol: assetSymbols4.WETH,
      address: addresses_default.W_TOKEN,
      name: "WETH",
      decimals: 18,
      color: "#7A88A1",
      overlayTextColor: "#fff",
      logoURL: "https://d1912tcoux65lj.cloudfront.net/network/arbitrum.jpg"
    }
  }
};
var params_default = specificParams;

// src/arbitrum/plugins.ts
var deployedPlugins = {};
var plugins_default = deployedPlugins;

// src/arbitrum/redemptionStrategies.ts
import { assetSymbols as assetSymbols5, RedemptionStrategyContract, underlying as underlying4 } from "@midas-capital/types";
var redemptionStrategies = {
  [underlying4(assets, assetSymbols5.saddleFraxBP)]: [RedemptionStrategyContract.SaddleLpTokenLiquidator, USDC],
  [underlying4(assets, assetSymbols5.saddleFraxUsdsBP)]: [
    RedemptionStrategyContract.SaddleLpTokenLiquidator,
    underlying4(assets, assetSymbols5.saddleFraxBP)
  ],
  [underlying4(assets, assetSymbols5.saddleFraxUsdtBP)]: [
    RedemptionStrategyContract.SaddleLpTokenLiquidator,
    underlying4(assets, assetSymbols5.saddleFraxBP)
  ],
  [underlying4(assets, assetSymbols5.OHM)]: [
    RedemptionStrategyContract.BalancerSwapLiquidator,
    underlying4(assets, assetSymbols5.WETH)
  ]
};
var redemptionStrategies_default = redemptionStrategies;

// src/arbitrum/index.ts
var chainConfig = {
  chainId: SupportedChains3.arbitrum,
  chainAddresses: addresses_default,
  assets: assets_default,
  irms: irms_default,
  liquidationDefaults: liquidation_default,
  oracles: oracles_default,
  specificParams: params_default,
  deployedPlugins: plugins_default,
  redemptionStrategies: redemptionStrategies_default,
  fundingStrategies: fundingStrategies_default,
  chainDeployments: arbitrum_default.contracts
};
var arbitrum_default2 = chainConfig;

// src/bsc/index.ts
import { SupportedChains as SupportedChains5 } from "@midas-capital/types";

// deployments/bsc.json
var bsc_default = {
  name: "bsc",
  chainId: "56",
  contracts: {
    AddressesProvider: {
      address: "0x01c97299b37E66c03419bC4Db24074a89FB36e6d"
    },
    AddressesProvider_Implementation: {
      address: "0xad3920247d59443FDb39c7622b3770C02F8D9E2a"
    },
    AddressesProvider_Proxy: {
      address: "0x01c97299b37E66c03419bC4Db24074a89FB36e6d"
    },
    AdjustableAnkrBNBIrm: {
      address: "0x99D6226Cee7322f21b50D64C9B176E29cd432900"
    },
    AdjustableJumpRateModel_JARVIS_jBRL: {
      address: "0x5aD28814ae64efb07eED63305659Af68d46D32f2"
    },
    AdjustableJumpRateModel_PSTAKE_WBNB: {
      address: "0x8Aa6DD595F4924FE751B7130AC70E8EbC8688CCe"
    },
    AdjustableJumpRateModel_STADER_WBNB: {
      address: "0xFBE3CaB915Ed7B6CC35510E5e73Bb468Cd7208e0"
    },
    AdjustableJumpRateModel_TRANSFERO_BRZ: {
      address: "0xFABc93626D6B91C4e9250B2fcdCf4Bf761aa5a45"
    },
    AdjustableJumpRateModel_TRANSFERO_BTCB_ETH_MAI_WBNB: {
      address: "0x109fa5BC7665c56CD145A0590A3b82A9E4bd3e1E"
    },
    AlgebraPriceOracle: {
      address: "0xA4C55B159824470a710e5131Faec37969b2F32Ab"
    },
    AlgebraPriceOracle_Implementation: {
      address: "0xcaa6Dcbf94b307edF46Ef3bb5F364486B8e32C55"
    },
    AlgebraPriceOracle_Proxy: {
      address: "0xA4C55B159824470a710e5131Faec37969b2F32Ab"
    },
    AlgebraSwapLiquidator: {
      address: "0x836a8E408FDBd6D0EF73790372084788662Ab82B"
    },
    AlpacaERC4626_BETH: {
      address: "0xCBE401B8874A1C30163740f5f45156088Eb21481"
    },
    AlpacaERC4626_BTCB: {
      address: "0xe9c47E742a2fD2b2a1cef2231BE7BDE16C82A0FB"
    },
    AlpacaERC4626_BUSD: {
      address: "0x9223EcAD6F7E73f73Ee7f9e74D48d9f9050A1954"
    },
    AlpacaERC4626_ETH: {
      address: "0x7c7967EFf04E89B3033F5BB01D82e35929c6eC33"
    },
    AlpacaERC4626_TUSD: {
      address: "0x8fC8565BAB740597C0fE985cb739eB48bdb1fD74"
    },
    AlpacaERC4626_USDC: {
      address: "0xb38E3FFC29B148B4f0b127ED721a4b58e91cD824"
    },
    AlpacaERC4626_USDT: {
      address: "0xC167A896a28599e9AB211E5573c6A5b1953E32a0"
    },
    AlpacaERC4626_WBNB: {
      address: "0x1B9Ce82Cf99D8181dA39c0572A81E042A21EE815"
    },
    AnkrBNBInterestRateModel: {
      address: "0x2dDa49e54e9EccaE1a1B7E493b787310483Dbb71"
    },
    AnkrCertificateTokenPriceOracle: {
      address: "0x1D5Db1b87bb5Ca37d0091c0c884C1F961524E5EA"
    },
    AnkrCertificateTokenPriceOracle_Implementation: {
      address: "0x8f0751eEcF23b2bC01f06284AfBDb70D7Ccc540e"
    },
    AnkrCertificateTokenPriceOracle_Proxy: {
      address: "0x1D5Db1b87bb5Ca37d0091c0c884C1F961524E5EA"
    },
    BNBxPriceOracle: {
      address: "0x23B1ff27D97eF804fC0F100af7Bc5911D07f79d3"
    },
    BNBxPriceOracle_Implementation: {
      address: "0x4daD43e24392Bd50BD1400A0b6ED3AD537F353F4"
    },
    BNBxPriceOracle_Proxy: {
      address: "0x23B1ff27D97eF804fC0F100af7Bc5911D07f79d3"
    },
    CErc20Delegate: {
      address: "0x3b7012C63073161466Bf59206969F2fD95b01781"
    },
    CErc20PluginDelegate: {
      address: "0x284039a61E0cEC53C8ae5b551e6bc85e45A66df4"
    },
    CErc20PluginRewardsDelegate: {
      address: "0xaAAEF4A15321c170B419eDF7f965288b06bfD6e3"
    },
    CEtherDelegate: {
      address: "0x4F4326189Adf2e2d46aE15A55499B7A5995f8A23"
    },
    CTokenFirstExtension: {
      address: "0x909090e13d03bD6442C51E8DfDbE1861bD866d40"
    },
    ChainlinkPriceOracleV2: {
      address: "0x66397a7BD91650e260a605F68F2d46F3350f2a1B"
    },
    Comptroller: {
      address: "0x745773fB12cBE330257831a83815870C444b9212"
    },
    ComptrollerFirstExtension: {
      address: "0xb57cf3507486abF0443ba782e2A1730CbE25bb9e"
    },
    CurveLpTokenLiquidatorNoRegistry: {
      address: "0x5392C43F5904977cC2F8CFf872A11Cf7939b8a12"
    },
    CurveLpTokenPriceOracleNoRegistry: {
      address: "0x4544d21EB5B368b3f8F98DcBd03f28aC0Cf6A0CA"
    },
    CurveLpTokenPriceOracleNoRegistry_Implementation: {
      address: "0xcF6b3c34D3758c05e46ebe0BD98A5fa4B1950EAB"
    },
    CurveLpTokenPriceOracleNoRegistry_Proxy: {
      address: "0x4544d21EB5B368b3f8F98DcBd03f28aC0Cf6A0CA"
    },
    CurveSwapLiquidator: {
      address: "0x3e5114109cdcD592dE30D33F36545a005c78c335"
    },
    CurveSwapLiquidatorFunder: {
      address: "0x5f304e4d2C17Bf51D459F5B9717CB96515ADA153"
    },
    CurveV2LpTokenPriceOracleNoRegistry: {
      address: "0x461fc4600c4e1B2e99886E9564a223E76C87e336"
    },
    CurveV2LpTokenPriceOracleNoRegistry_Implementation: {
      address: "0x9E327Dd23002c9EE5289cBBB7928fFaB2A6Bfe5f"
    },
    CurveV2LpTokenPriceOracleNoRegistry_Proxy: {
      address: "0x461fc4600c4e1B2e99886E9564a223E76C87e336"
    },
    DefaultProxyAdmin: {
      address: "0x0C64dEFA0125678C77dDa2287FDD6000699737Cc"
    },
    DiaPriceOracle: {
      address: "0x4682b914C351830e9e289C02ec312e453f017eE2"
    },
    DotDotLpERC4626_2brl_0xf0a2852958aD041a9Fb35c312605482Ca3Ec17ba: {
      address: "0x23bBcF59BF843cD55c4DA9bDB81429695C87f847"
    },
    DotDotLpERC4626_2brl_0xf0a2852958aD041a9Fb35c312605482Ca3Ec17ba_Implementation: {
      address: "0xdF07Fa746F82A7d5FC9307218C7a0ABe126431bc"
    },
    DotDotLpERC4626_2brl_0xf0a2852958aD041a9Fb35c312605482Ca3Ec17ba_Proxy: {
      address: "0x23bBcF59BF843cD55c4DA9bDB81429695C87f847"
    },
    DotDotLpERC4626_3EPS_0x6f9B6ccD027d1c6Ed09ee215B9Ca5B85a57C6eA1: {
      address: "0x628C6d2236fC1712D66Df5fbFf9041f7809C959C"
    },
    DotDotLpERC4626_3EPS_0x6f9B6ccD027d1c6Ed09ee215B9Ca5B85a57C6eA1_Implementation: {
      address: "0x487fF23F4f3E6B73aafcC23cE80dE8060c49ec1B"
    },
    DotDotLpERC4626_3EPS_0x6f9B6ccD027d1c6Ed09ee215B9Ca5B85a57C6eA1_Proxy: {
      address: "0x628C6d2236fC1712D66Df5fbFf9041f7809C959C"
    },
    DotDotLpERC4626_val3EPS_0xccc9BEF35C50A3545e01Ef72Cc957E0aec8B2e7C: {
      address: "0xe38A0F34DB15fCC47510cdB0519E149eC20c8806"
    },
    DotDotLpERC4626_val3EPS_0xccc9BEF35C50A3545e01Ef72Cc957E0aec8B2e7C_Implementation: {
      address: "0xf48fffd335f84655d07f0e69768c0Ff6627CC632"
    },
    DotDotLpERC4626_val3EPS_0xccc9BEF35C50A3545e01Ef72Cc957E0aec8B2e7C_Proxy: {
      address: "0xe38A0F34DB15fCC47510cdB0519E149eC20c8806"
    },
    DotDotLpERC4626_valdai3EPS_0x7479dd29b9256aB74c9bf84d6f9CE6e30014d248: {
      address: "0xc2Af1451dBFbf564FB32E57f275d419395F5BC92"
    },
    DotDotLpERC4626_valdai3EPS_0x7479dd29b9256aB74c9bf84d6f9CE6e30014d248_Implementation: {
      address: "0x6dE10cd134A2069ceC34514BBFAE8ADfD78F3f0E"
    },
    DotDotLpERC4626_valdai3EPS_0x7479dd29b9256aB74c9bf84d6f9CE6e30014d248_Proxy: {
      address: "0xc2Af1451dBFbf564FB32E57f275d419395F5BC92"
    },
    FixedNativePriceOracle: {
      address: "0x7518ff4adaF40090a6f857F42bEE06EF53A6AA73"
    },
    FlywheelStaticRewards_ANKR_Ankr_BNB_Pool: {
      address: "0x8Ad591EC60292B395376Fc4617859645b08EB185"
    },
    FlywheelStaticRewards_ANKR_Ankr_BNB_Pool2: {
      address: "0x990FD62a1F1b77F410E89d3BA499039D544c74C1"
    },
    FuseFeeDistributor: {
      address: "0xFc1f56C58286E7215701A773b61bFf2e18A177dE"
    },
    FuseFeeDistributor_Implementation: {
      address: "0x6e045afB6c9ca966F2b07eD669c78f8b76417D06"
    },
    FuseFeeDistributor_Proxy: {
      address: "0xFc1f56C58286E7215701A773b61bFf2e18A177dE"
    },
    FuseFlywheelDynamicRewardsPlugin_AUTOv2: {
      address: "0xEDF3265CbCf270B37AD33A20DE6b2A2d8BEcE266"
    },
    FuseFlywheelDynamicRewardsPlugin_DDD: {
      address: "0x351249E8eCf7e9fBc21F59783eA571EBB65f6805"
    },
    FuseFlywheelDynamicRewardsPlugin_EPX: {
      address: "0x55a9b3140E1eeEEB505478cbB6c1823AD1fcbcC0"
    },
    FuseFlywheelDynamicRewards_AUTOv2: {
      address: "0xe7E366C0c8d21a7195BAedaBd41bE37c173E0913"
    },
    FuseFlywheelDynamicRewards_DDD: {
      address: "0x0429cBBdc856366857a60c5F3424A4b477621761"
    },
    FuseFlywheelDynamicRewards_EPX: {
      address: "0xf06bb51fB6bfA6B46D8641d5bCeA60bd9454Cf83"
    },
    FuseFlywheelLensRouter: {
      address: "0xbC58155360A097A544276a8bF96f3fc468e49dd2"
    },
    FuseFlywheelLensRouter_Implementation: {
      address: "0x70FaF0FBa62CC230B84999814505F84c0E0cfDcd"
    },
    FuseFlywheelLensRouter_Proxy: {
      address: "0x9Ae3C01b225459dBF7108b1e8386633ecc745036"
    },
    FusePoolDirectory: {
      address: "0x295d7347606F4bd810C8296bb8d75D657001fcf7"
    },
    FusePoolDirectory_Implementation: {
      address: "0x3998B88995B1cbbF0b2f735a187F68CEF73c3dda"
    },
    FusePoolDirectory_Proxy: {
      address: "0x295d7347606F4bd810C8296bb8d75D657001fcf7"
    },
    FusePoolLens: {
      address: "0x2d3c31E38d3aD71571b1406639003A6648481628"
    },
    FusePoolLensSecondary: {
      address: "0x221308c6ff1FF6A1AEc72B4674Cc62309448246d"
    },
    FusePoolLensSecondary_Implementation: {
      address: "0xbC4eeEE763a0B348080bABF3E6a0cE6d648DdC81"
    },
    FusePoolLensSecondary_Proxy: {
      address: "0x4B68509296e33e47d6A09D76F75500920cc1a006"
    },
    FusePoolLens_Implementation: {
      address: "0x0982443440007f82C71bC3AFEBb96642bfEe6Cb5"
    },
    FusePoolLens_Proxy: {
      address: "0xFed496831E71794D746A35Ad8feef57885e6CdCB"
    },
    FuseSafeLiquidator: {
      address: "0xc9C3D317E89f4390A564D56180bBB1842CF3c99C"
    },
    FuseSafeLiquidator_Implementation: {
      address: "0x2C28cB502AE51f14491b2A649Fb498803266DB5a"
    },
    FuseSafeLiquidator_Proxy: {
      address: "0xc9C3D317E89f4390A564D56180bBB1842CF3c99C"
    },
    GammaLpTokenLiquidator: {
      address: "0x4807c55D5cde8864AF17B3f88a3FcE4Fd653Ed13"
    },
    GammaPoolPriceOracle: {
      address: "0x97c4Ff71D0bcF1EaAFF7709aD24Dc387d2BC5df7"
    },
    GammaPoolPriceOracle_Implementation: {
      address: "0xa0876927a590a7322A9c67E451cfc069c1734B77"
    },
    GammaPoolPriceOracle_Proxy: {
      address: "0x97c4Ff71D0bcF1EaAFF7709aD24Dc387d2BC5df7"
    },
    HelioERC4626_HAY_0xAB7d4760E2c54c0Da1efEC7C358F171d72e14153: {
      address: "0x369ddC01E8feF7350Eb740f4a32647E8640F0A17"
    },
    HelioERC4626_HAY_0xAB7d4760E2c54c0Da1efEC7C358F171d72e14153_Implementation: {
      address: "0x40f3704C5b04f015BDE6c2b0222b7e884Ee5b0F5"
    },
    HelioERC4626_HAY_0xAB7d4760E2c54c0Da1efEC7C358F171d72e14153_Proxy: {
      address: "0x369ddC01E8feF7350Eb740f4a32647E8640F0A17"
    },
    JarvisLiquidatorFunder: {
      address: "0x8867366c631127b0CE25e2619D6ed4A849f4563C"
    },
    JumpRateModel: {
      address: "0x8A163f41414c005c3f551f2179bC9Ad8dcadE1C6"
    },
    JumpRateModel_JARVIS_002_004_4_08: {
      address: "0xD924786281760368dd5857bB048CF7CB20a115C9"
    },
    LeveredPositionFactory: {
      address: "0x27b9D43312cE3eC5066d7530578922708104F8Af"
    },
    LeveredPositionFactoryExtension: {
      address: "0xE3624dE620d8F280d3d46A04e769f6Deb3a757C0"
    },
    LeveredPositionsLens: {
      address: "0xbFac919fEF89D2e3EB41c82C304E3d9183C67A75"
    },
    LeveredPositionsLens_Implementation: {
      address: "0xeEEBDb66e6DC61649E19A0fABC6bE804c0eF0430"
    },
    LeveredPositionsLens_Proxy: {
      address: "0xbFac919fEF89D2e3EB41c82C304E3d9183C67A75"
    },
    LiquidatorsRegistry: {
      address: "0xc2e30386EC3Dc8d42FC907DD03e9aeB3D5d2dFb6"
    },
    LiquidatorsRegistryExtension: {
      address: "0x4331156f5A56da09aC47eb006383D9c2e91Cb19A"
    },
    LooplessFlywheelBooster: {
      address: "0xb77ce603c7b894B9eFe9c0697D5B724dDdb9EaC6"
    },
    MasterPriceOracle: {
      address: "0xB641c21124546e1c979b4C1EbF13aB00D43Ee8eA"
    },
    MasterPriceOracle_Implementation: {
      address: "0xe2B7D4d7C5DcF8086b4f0dAefcfb03cb6227e2a0"
    },
    MasterPriceOracle_Proxy: {
      address: "0xB641c21124546e1c979b4C1EbF13aB00D43Ee8eA"
    },
    MidasFlywheelLensRouter: {
      address: "0x5b7f3D1615e5306F5C5c587e96Db31751041C1A7"
    },
    MidasFlywheel_ANKR_Ankr_BNB_Pool: {
      address: "0x4ab7cAEfb78D6EA8Bc62cAa6F9788ca873869dA1"
    },
    MidasFlywheel_ANKR_Ankr_BNB_Pool_Implementation: {
      address: "0x41dF5341e0F44C225b9cfaa723787A29DBe6b5c4"
    },
    MidasFlywheel_ANKR_Ankr_BNB_Pool_Proxy: {
      address: "0x4ab7cAEfb78D6EA8Bc62cAa6F9788ca873869dA1"
    },
    MidasFlywheel_AUTOv2: {
      address: "0xEfF15E27eea8bA87C93D972145730165366A935E"
    },
    MidasFlywheel_AUTOv2_Implementation: {
      address: "0x052f960e99FD9d139b8b81948EB60faC59aFfbA5"
    },
    MidasFlywheel_AUTOv2_Proxy: {
      address: "0xEfF15E27eea8bA87C93D972145730165366A935E"
    },
    MidasFlywheel_DDD: {
      address: "0xAbBbAc3F07d33F7dC4dfD5EdB1E7Bf56041abBa5"
    },
    MidasFlywheel_DDD_Implementation: {
      address: "0xb0562b226468FaF602B928EaDfD6E1695470a546"
    },
    MidasFlywheel_DDD_Proxy: {
      address: "0xAbBbAc3F07d33F7dC4dfD5EdB1E7Bf56041abBa5"
    },
    MidasFlywheel_EPX: {
      address: "0xF2E46295c684C541d618243558a0af17fb4a6862"
    },
    MidasFlywheel_EPX_Implementation: {
      address: "0x0614E7AD514afB81451a3f01597aB19e72C3b1cF"
    },
    MidasFlywheel_EPX_Proxy: {
      address: "0xF2E46295c684C541d618243558a0af17fb4a6862"
    },
    MidasFlywheel_THE: {
      address: "0x9f21e2bE2dD52083A5DA90a2BEe817d9F8228A74"
    },
    MidasFlywheel_THE_Implementation: {
      address: "0xF01057bEb023b3ACC8D6D7D43a15dE5D8570f19b"
    },
    MidasFlywheel_THE_Proxy: {
      address: "0x9f21e2bE2dD52083A5DA90a2BEe817d9F8228A74"
    },
    OptimizedVaultsRegistry: {
      address: "0xFbD283c802233d56Ba45F4E86d2C500d5B88006a"
    },
    OptimizedVaultsRegistry_Implementation: {
      address: "0x6FEE86968fE32106B9355d3Cb267c3d687633396"
    },
    OptimizedVaultsRegistry_Proxy: {
      address: "0xFbD283c802233d56Ba45F4E86d2C500d5B88006a"
    },
    ReplacingFlywheelDynamicRewards_AUTOv2: {
      address: "0xD5b4395736457104624587038961e967CdEAe937"
    },
    ReplacingFlywheelDynamicRewards_DDD: {
      address: "0x81A22ba0eE44453fF4021f504945A10eD42bFE09"
    },
    ReplacingFlywheelDynamicRewards_EPX: {
      address: "0xc534e0E748d7143736E5E19b77F5dec6623389A0"
    },
    ReplacingFlywheelDynamicRewards_THE: {
      address: "0x74475506d18fa05d38ad628330d3eb34283a7ec8"
    },
    SimplePriceOracle: {
      address: "0x5425F12dD3Fbc16deB3c6693d85B52b77fb59eaE"
    },
    SimplePriceOracle_Implementation: {
      address: "0xDa7B1135cFc6267ce2f6c18366e76905F9418e97"
    },
    SimplePriceOracle_Proxy: {
      address: "0x5425F12dD3Fbc16deB3c6693d85B52b77fb59eaE"
    },
    SolidlyLpTokenLiquidator: {
      address: "0xAC790560a78c16c8e6E4c195D1BA3B493d9DF2A7"
    },
    SolidlyLpTokenPriceOracle: {
      address: "0xf12BF362779D3F728644DC33a5D594F0E57a85F2"
    },
    SolidlyPriceOracle: {
      address: "0xf78A8fbBD89Bb01bA273565eb80f6D8425F63f1d"
    },
    SolidlyPriceOracle_Implementation: {
      address: "0xc873c16F109cF10fEB20F31FCed1EB8De819B41B"
    },
    SolidlyPriceOracle_Proxy: {
      address: "0xf78A8fbBD89Bb01bA273565eb80f6D8425F63f1d"
    },
    SolidlySwapLiquidator: {
      address: "0x970A429f8641Be49Ac5e6a3b7c9FF7eE25456Fae"
    },
    StkBNBPriceOracle: {
      address: "0x910162FA2612c44dd363645CeC9aaDE578B251cE"
    },
    StkBNBPriceOracle_Implementation: {
      address: "0x196ecDfC9E824Df7dcB148d1254E3E8071183CCA"
    },
    StkBNBPriceOracle_Proxy: {
      address: "0x910162FA2612c44dd363645CeC9aaDE578B251cE"
    },
    "ThenaLpERC4626_aANKRBNB-ANKR-N_0x0f3590D7010f3D5AD5d618BD2c223eb394bB5432": {
      address: "0x7A5D376F717732C65d5ac63Fd05F73a287324B05"
    },
    "ThenaLpERC4626_aANKRBNB-ANKR-N_0x0f3590D7010f3D5AD5d618BD2c223eb394bB5432_Implementation": {
      address: "0x037F744F44B4Fd801176eaF32ea486130a9b9Bfa"
    },
    "ThenaLpERC4626_aANKRBNB-ANKR-N_0x0f3590D7010f3D5AD5d618BD2c223eb394bB5432_Proxy": {
      address: "0x7A5D376F717732C65d5ac63Fd05F73a287324B05"
    },
    "ThenaLpERC4626_aANKRBNB-ANKR-W_0x6F55B262cA509e112cb27b15402EF0c8cd330F0b": {
      address: "0xDbC22fE3F4aAE46bA4509b4e13b200E87375b5ab"
    },
    "ThenaLpERC4626_aANKRBNB-ANKR-W_0x6F55B262cA509e112cb27b15402EF0c8cd330F0b_Implementation": {
      address: "0x3299Bf866b37d2b630713C3Ce20E573a9A87cB0E"
    },
    "ThenaLpERC4626_aANKRBNB-ANKR-W_0x6F55B262cA509e112cb27b15402EF0c8cd330F0b_Proxy": {
      address: "0xDbC22fE3F4aAE46bA4509b4e13b200E87375b5ab"
    },
    "ThenaLpERC4626_aANKRBNB-RDNT-N_0xA443a78906B534069a703BC5E186994CA4F90aCC": {
      address: "0xE706134f898320dc1b1772362566bA6646C569c1"
    },
    "ThenaLpERC4626_aANKRBNB-RDNT-N_0xA443a78906B534069a703BC5E186994CA4F90aCC_Implementation": {
      address: "0x268448fd5ab592780Fafd14992bc78A0eC1f71b6"
    },
    "ThenaLpERC4626_aANKRBNB-RDNT-N_0xA443a78906B534069a703BC5E186994CA4F90aCC_Proxy": {
      address: "0xE706134f898320dc1b1772362566bA6646C569c1"
    },
    "ThenaLpERC4626_aANKRBNB-RDNT-W_0x525fE855718680DE71F3FE761F893a0b763500E4": {
      address: "0x2841f61C4D0Dd34010a3E75241c1C80AFc99D202"
    },
    "ThenaLpERC4626_aANKRBNB-RDNT-W_0x525fE855718680DE71F3FE761F893a0b763500E4_Implementation": {
      address: "0xdf347478dd9533ae4500584984677C9De8A89D73"
    },
    "ThenaLpERC4626_aANKRBNB-RDNT-W_0x525fE855718680DE71F3FE761F893a0b763500E4_Proxy": {
      address: "0x2841f61C4D0Dd34010a3E75241c1C80AFc99D202"
    },
    "ThenaLpERC4626_aANKRBNB-THE-N_0x336378365892Ae2C706BB97cd9f23b339951ab01": {
      address: "0x5bEb4BD2164D286CdE9c672910c548dF643a2Caa"
    },
    "ThenaLpERC4626_aANKRBNB-THE-N_0x336378365892Ae2C706BB97cd9f23b339951ab01_Implementation": {
      address: "0x77ACC9d42f6D93e3420B9023f2495A847D5c66E8"
    },
    "ThenaLpERC4626_aANKRBNB-THE-N_0x336378365892Ae2C706BB97cd9f23b339951ab01_Proxy": {
      address: "0x5bEb4BD2164D286CdE9c672910c548dF643a2Caa"
    },
    "ThenaLpERC4626_aANKRBNB-THE-W_0x0B2ed08ba628bAF7287536A452C3060826422a13": {
      address: "0xbe79A4B9Cb6892ae0d383038C6e7b25fF79FBc14"
    },
    "ThenaLpERC4626_aANKRBNB-THE-W_0x0B2ed08ba628bAF7287536A452C3060826422a13_Implementation": {
      address: "0x8bbA9F1e806B25097Ee605C91B252c7c7A876D3F"
    },
    "ThenaLpERC4626_aANKRBNB-THE-W_0x0B2ed08ba628bAF7287536A452C3060826422a13_Proxy": {
      address: "0xbe79A4B9Cb6892ae0d383038C6e7b25fF79FBc14"
    },
    "ThenaLpERC4626_sAMM-HAY-BUSD_0xF8527Dc5611B589CbB365aCACaac0d1DC70b25cB": {
      address: "0x02706A482fc9f6B20238157B56763391a45bE60E"
    },
    "ThenaLpERC4626_sAMM-HAY-BUSD_0xF8527Dc5611B589CbB365aCACaac0d1DC70b25cB_Implementation": {
      address: "0xC3F159eB41b75BF79bbC099AFB63ED0c74d6aEC5"
    },
    "ThenaLpERC4626_sAMM-HAY-BUSD_0xF8527Dc5611B589CbB365aCACaac0d1DC70b25cB_Proxy": {
      address: "0x02706A482fc9f6B20238157B56763391a45bE60E"
    },
    "ThenaLpERC4626_vAMM-ANKR-HAY_0xbc65FE441545E9e8f97E50F70526B7E8963826bc": {
      address: "0x52156377Dbe031706cfAb4e759cA102e58A19953"
    },
    "ThenaLpERC4626_vAMM-ANKR-HAY_0xbc65FE441545E9e8f97E50F70526B7E8963826bc_Implementation": {
      address: "0xc7c100192fE5330d1767eBA5a8ACCd149E1AE230"
    },
    "ThenaLpERC4626_vAMM-ANKR-HAY_0xbc65FE441545E9e8f97E50F70526B7E8963826bc_Proxy": {
      address: "0x52156377Dbe031706cfAb4e759cA102e58A19953"
    },
    "ThenaLpERC4626_vAMM-ANKR-ankrBNB_0x71693C84486B37096192c9942852f542543639Bf": {
      address: "0x0c0e8ED68a72c31E657Ce5F03A615FB96753C9b5"
    },
    "ThenaLpERC4626_vAMM-ANKR-ankrBNB_0x71693C84486B37096192c9942852f542543639Bf_Implementation": {
      address: "0x8Ed68aec909477c6Fae8e479A19C69C0fece09ab"
    },
    "ThenaLpERC4626_vAMM-ANKR-ankrBNB_0x71693C84486B37096192c9942852f542543639Bf_Proxy": {
      address: "0x0c0e8ED68a72c31E657Ce5F03A615FB96753C9b5"
    },
    "ThenaLpERC4626_vAMM-HAY-ankrBNB_0x04b6895d7AD8b10a1a13C749159226249a3b8515": {
      address: "0xE141ce7507656f9b2c45d06d649961Eb84e19f82"
    },
    "ThenaLpERC4626_vAMM-HAY-ankrBNB_0x04b6895d7AD8b10a1a13C749159226249a3b8515_Implementation": {
      address: "0x0Bfc9B10Aa220481Fb0Cb93fF004880684b4ed12"
    },
    "ThenaLpERC4626_vAMM-HAY-ankrBNB_0x04b6895d7AD8b10a1a13C749159226249a3b8515_Proxy": {
      address: "0xE141ce7507656f9b2c45d06d649961Eb84e19f82"
    },
    UniswapLpTokenLiquidator: {
      address: "0x99f7AC1B8De47627dE263DEa2E2F92d7fB50E45d"
    },
    UniswapLpTokenPriceOracle: {
      address: "0x1023bc3990C45E39Ba8B74D158bE391CA04a18b1"
    },
    UniswapTwapPriceOracleV2: {
      address: "0xb1F031c1f8B86a227Dea22E0611a091E74D1fc08"
    },
    UniswapTwapPriceOracleV2Factory: {
      address: "0x22A1bD96CAbd0A82ff311c4C8a378eA243c58D9F"
    },
    UniswapTwapPriceOracleV2Resolver: {
      address: "0xAD42dEf8FD3A1E1124B5643Ba6d1efEa62F924B4"
    },
    UniswapTwapPriceOracleV2Root: {
      address: "0x83937d6388E4E208f77728F153d90602DE189eab"
    },
    UniswapV2LiquidatorFunder: {
      address: "0x0CE4eddf5799171B3969e0BEAd13a160d1480e31"
    },
    WhitePaperInterestRateModel: {
      address: "0x25a655F5f2bCeB4bFdD5C012Be865Cc2a9c7B4ED"
    },
    WombatLpTokenLiquidator: {
      address: "0x2A52Fa0B6D69269Ffca8A0a51266EfF9F84322Bf"
    },
    WombatLpTokenPriceOracle: {
      address: "0x5e875C4b10e605D5DD7a788750A914643f8Bdd34"
    },
    XBombLiquidatorFunder: {
      address: "0xE422FA2c89339ed8Fc306C25AB6bEcA490E45795"
    }
  }
};

// src/bsc/addresses.ts
import { ethers as ethers3 } from "ethers";

// src/bsc/assets.ts
import { assetOriginalSymbols, assetSymbols as assetSymbols6, OracleTypes as OracleTypes3, SupportedChains as SupportedChains4 } from "@midas-capital/types";
var WBNB = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
var BNB2 = "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE";
var BUSD = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
var BTCB = "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c";
var HAY = "0x0782b6d8c4551B9760e74c0545a9bCD90bdc41E5";
var ankrBNB = "0x52F24a5e03aee338Da5fd9Df68D2b6FAe1178827";
var DAI2 = "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3";
var ETH = "0x2170Ed0880ac9A755fd29B2688956BD959F933F8";
var BETH = "0x250632378E573c6Be1AC2f97Fcdf00515d0Aa91B";
var CAKE = "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82";
var AUTO = "0xa184088a740c695E156F91f5cC086a06bb78b827";
var BIFI = "0xCa3F508B8e4Dd382eE878A314789373D80A5190A";
var ALPACA = "0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F";
var USDC2 = "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d";
var USDT2 = "0x55d398326f99059fF775485246999027B3197955";
var TUSD = "0x14016E85a25aeb13065688cAFB43044C2ef86784";
var MAI = "0x3F56e0c36d275367b8C502090EDF38289b3dEa0d";
var FRAX2 = "0x29cED01C447166958605519F10DcF8b0255fB379";
var threeEPS = "0xaF4dE8E872131AE328Ce21D909C74705d3Aaf452";
var maiThreeEPS = "0x80D00D2c8d920a9253c3D65BA901250a55011b37";
var twoBRL = "0x1B6E11c5DB9B15DE87714eA9934a6c52371CfEA9";
var threeBRL = "0x27b5Fc5333246F63280dA8e3e533512EfA747c13";
var val3EPS = "0x5b5bD8913D766D005859CE002533D4838B0Ebbb5";
var valdai3EPS = "0x8087a94FFE6bcF08DC4b4EBB3d28B4Ed75a792aC";
var epsBUSD_jCHF = "0x5887cEa5e2bb7dD36F0C06Da47A8Df918c289A29";
var BOMB = "0x522348779DCb2911539e76A1042aA922F9C47Ee3";
var xBOMB = "0xAf16cB45B8149DA403AF41C63AbFEBFbcd16264b";
var THE = "0xF4C8E32EaDEC4BFe97E0F595AdD0f4450a863a11";
var RDNT = "0xf7DE7E8A6bd59ED41a4b5fe50278b3B7f31384dF";
var stkBNB_WBNB = "0xaA2527ff1893e0D40d4a454623d362B79E8bb7F1";
var stkBNB = "0xc2E9d07F66A89c44062459A47a0D2Dc038E4fb16";
var asBNBx_WBNB = "0xB88F211EC9ecfc2931Ae1DE53ea28Da76B9Ed37A";
var asANKR_ankrBNB = "0x653D51dbB4CC8B9Bcd884BB0c0795b4BE672AA4c";
var epsBNBx_BNB = "0x5c73804FeDd39f3388E03F4aa1fE06a1C0e60c8e";
var BNBx = "0x1bdd3Cf7F79cfB8EdbB955f20ad99211551BA275";
var jBRL = "0x316622977073BBC3dF32E7d2A9B3c77596a0a603";
var jCHF = "0x7c869b5A294b1314E985283d01C702B62224a05f";
var jEUR = "0x23b8683Ff98F9E4781552DFE6f12Aa32814924e8";
var jGBP = "0x048E9b1ddF9EBbb224812372280e94Ccac443f9e";
var jMXN = "0x47b19Af93d0bC33805269Af02B5CA953Aa145127";
var BRZ = "0x71be881e9C5d4465B3FfF61e89c6f3651E69B5bb";
var BRZw = "0x5b1a9850f55d9282a7C4Bf23A2a21B050e3Beb2f";
var BTCB_BOMB = "0x84392649eb0bC1c1532F2180E58Bae4E1dAbd8D6";
var WBNB_BUSD = "0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16";
var WBNB_DAI = "0xc7c3cCCE4FA25700fD5574DA7E200ae28BBd36A3";
var WBNB_USDC = "0xd99c7F6C65857AC913a8f880A4cb84032AB2FC5b";
var WBNB_USDT = "0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE";
var WBNB_ETH = "0x74E4716E431f45807DCF19f284c7aA99F18a4fbc";
var BUSD_USDT = "0x7EFaEf62fDdCCa950418312c6C91Aef321375A00";
var BUSD_BTCB = "0xF45cd219aEF8618A92BAa7aD848364a158a24F33";
var USDC_BUSD = "0x2354ef4DF11afacb85a5C7f98B624072ECcddbB1";
var USDC_ETH = "0xEa26B78255Df2bBC31C1eBf60010D78670185bD0";
var CAKE_WBNB = "0x0eD7e52944161450477ee417DE9Cd3a859b14fD0";
var BTCB_ETH = "0xD171B26E4484402de70e3Ea256bE5A2630d7e88D";
var ANKR_ankrBNB = "0x8028AC1195B6469de22929C4f329f96B06d65F25";
var EPX = "0xAf41054C1487b0e5E2B9250C0332eCBCe6CE9d71";
var DDD = "0x84c97300a190676a19D1E13115629A11f8482Bd1";
var pSTAKE = "0x4C882ec256823eE773B25b414d36F92ef58a7c0C";
var SD = "0x3BC5AC0dFdC871B365d159f728dd1B9A0B5481E8";
var ANKR = "0xf307910A4c7bbc79691fD374889b36d8531B08e3";
var WOMBATLP_WBNB = "0x74f019A5C4eD2C2950Ce16FaD7Af838549092c5b";
var solidlyStableAMM_jBRL_BRZ = "0xA0695f78AF837F570bcc50f53e58Cda300798B65";
var solidlyVolatileAMM_ANKR_ankrBNB = "0x7ef540f672Cd643B79D2488344944499F7518b1f";
var solidlyVolatileAMM_ANKR_HAY = "0xa2C2C08AFEFD3CC69B9aab797dEe5a1886388783";
var solidlyStableAMM_HAY_BUSD = "0x93B32a8dfE10e9196403dd111974E325219aec24";
var solidlyVolatileAMM_HAY_ankrBNB = "0xC6dB38F34DA75393E9aac841c08104348997D509";
var solidlyStableAMM_stkBNB_WBNB = "0x2B3510f57365aA17bFF8E6360EA67C136175dC6D";
var solidlyGammaStable_stkBNB_WBNB = "0x86b481fCe116DCd01fBeBb963f1358bcc466668C";
var solidlyGammaNarrow_BTCB_WBNB = "0xD3C480EC7a47596fF8D63396227d1F7dC728A7f0";
var solidlyGammaNarrow_ETH_WBNB = "0x10bf6e7B28b1cfFb1c047D7F815953931e5Ee947";
var solidlyGammaNarrow_ANKR_ankrBNB = "0x3f8f3caefF393B1994a9968E835Fd38eCba6C1be";
var solidlyGammaWide_ANKR_ankrBNB = "0x31257f40e65585cC45fDABEb12002C25bC95eE80";
var solidlyGammaNarrow_RDNT_ankrBNB = "0x137B283858b82Ed466823e81b7f791a04f7f6f46";
var solidlyGammaWide_RDNT_ankrBNB = "0x62034F60A8516a1b424c79c2e9a8c214D6Cb97e8";
var solidlyGammaNarrow_THE_ankrBNB = "0xD2F1045B4E5Ba91EE725E8BF50740617a92E4a5f";
var solidlyGammaWide_THE_ankrBNB = "0x23d13bc67bD086B8beFf6393eBF863d6a8C7DCDc";
var assets2 = [
  {
    symbol: assetSymbols6.BNB,
    underlying: BNB2,
    name: "Binance Network Token",
    decimals: 18,
    oracle: OracleTypes3.FixedNativePriceOracle,
    extraDocs: defaultDocs("https://bscscan.com", BNB2),
    disabled: true
  },
  {
    symbol: assetSymbols6.WBNB,
    underlying: WBNB,
    name: "Wrapped Binance Network Token",
    decimals: 18,
    oracle: OracleTypes3.FixedNativePriceOracle,
    extraDocs: wrappedAssetDocs(SupportedChains4.bsc)
  },
  {
    symbol: assetSymbols6.BUSD,
    underlying: BUSD,
    name: "Binance USD",
    decimals: 18,
    oracle: OracleTypes3.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://bscscan.com", BUSD)
  },
  {
    symbol: assetSymbols6.BTCB,
    underlying: BTCB,
    name: "Binance BTC",
    decimals: 18,
    oracle: OracleTypes3.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://bscscan.com", BTCB)
  },
  {
    symbol: assetSymbols6.DAI,
    underlying: DAI2,
    name: "Binance DAI",
    decimals: 18,
    oracle: OracleTypes3.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://bscscan.com", DAI2)
  },
  {
    symbol: assetSymbols6.ETH,
    underlying: ETH,
    name: "Binance ETH",
    decimals: 18,
    oracle: OracleTypes3.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://bscscan.com", ETH)
  },
  // CZ
  {
    symbol: assetSymbols6.BETH,
    underlying: BETH,
    name: "Binance Beacon ETH",
    decimals: 18,
    oracle: OracleTypes3.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://bscscan.com", BETH),
    disabled: true
  },
  {
    symbol: assetSymbols6.CAKE,
    underlying: CAKE,
    name: "PancakeSwap Token",
    decimals: 18,
    oracle: OracleTypes3.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://bscscan.com", CAKE)
  },
  //
  {
    symbol: assetSymbols6.AUTO,
    underlying: AUTO,
    name: "AUTOv2",
    decimals: 18,
    oracle: OracleTypes3.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://bscscan.com", AUTO),
    disabled: true
  },
  {
    symbol: assetSymbols6.BIFI,
    underlying: BIFI,
    name: "beefy.finance",
    oracle: OracleTypes3.ChainlinkPriceOracleV2,
    decimals: 18,
    extraDocs: defaultDocs("https://bscscan.com", BIFI),
    disabled: true
  },
  {
    symbol: assetSymbols6.FRAX,
    underlying: FRAX2,
    name: "Frax",
    oracle: OracleTypes3.ChainlinkPriceOracleV2,
    decimals: 18,
    extraDocs: defaultDocs("https://bscscan.com", FRAX2)
  },
  {
    symbol: assetSymbols6.ALPACA,
    underlying: ALPACA,
    name: "AlpacaToken",
    decimals: 18,
    oracle: OracleTypes3.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://bscscan.com", ALPACA),
    disabled: true
  },
  {
    symbol: assetSymbols6.THE,
    underlying: THE,
    name: "THENA",
    decimals: 18,
    oracle: OracleTypes3.AlgebraPriceOracle,
    extraDocs: defaultDocs("https://bscscan.com", THE)
  },
  {
    symbol: assetSymbols6.RDNT,
    underlying: RDNT,
    name: "Radiant Capital",
    decimals: 18,
    oracle: OracleTypes3.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://bscscan.com", RDNT)
  },
  // stables
  {
    symbol: assetSymbols6.USDC,
    underlying: USDC2,
    name: "Binance-Peg USD Coin",
    decimals: 18,
    oracle: OracleTypes3.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://bscscan.com", USDC2)
  },
  {
    symbol: assetSymbols6.USDT,
    underlying: USDT2,
    name: "Binance-Peg BSC-USD",
    decimals: 18,
    oracle: OracleTypes3.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://bscscan.com", USDT2)
  },
  {
    symbol: assetSymbols6.TUSD,
    underlying: TUSD,
    name: "Wrapped TrueUSD",
    decimals: 18,
    oracle: OracleTypes3.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://bscscan.com", TUSD),
    disabled: true
  },
  {
    symbol: assetSymbols6.MAI,
    underlying: MAI,
    name: "Mai Stablecoin",
    decimals: 18,
    oracle: OracleTypes3.DiaPriceOracle,
    extraDocs: defaultDocs("https://bscscan.com", MAI)
  },
  {
    symbol: assetSymbols6.HAY,
    underlying: HAY,
    name: "HAY",
    decimals: 18,
    oracle: OracleTypes3.UniswapTwapPriceOracleV2,
    extraDocs: defaultDocs("https://bscscan.com", HAY)
  },
  // Ellipsis
  {
    symbol: assetSymbols6["3EPS"],
    underlying: threeEPS,
    name: "Ellipsis.finance 3EPS (BUSD/USDC/USDT)",
    decimals: 18,
    oracle: OracleTypes3.CurveLpTokenPriceOracleNoRegistry,
    extraDocs: ellipsisDocs("0x160CAed03795365F3A589f10C379FfA7d75d4E76", "3EPS", threeEPS)
  },
  {
    symbol: assetSymbols6.mai3EPS,
    underlying: maiThreeEPS,
    name: "Ellipsis.finance MAI 3EPS (MAI/BUSD/USDC/USDT)",
    decimals: 18,
    oracle: OracleTypes3.CurveLpTokenPriceOracleNoRegistry,
    extraDocs: ellipsisDocs("0x68354c6E8Bbd020F9dE81EAf57ea5424ba9ef322", "mai3EPS", maiThreeEPS)
  },
  {
    symbol: assetSymbols6.val3EPS,
    underlying: val3EPS,
    name: "Ellipsis.finance val3EPS (BUSD/USDC/USDT)",
    decimals: 18,
    oracle: OracleTypes3.CurveLpTokenPriceOracleNoRegistry,
    extraDocs: ellipsisDocs("0x19EC9e3F7B21dd27598E7ad5aAe7dC0Db00A806d", "val3EPS", val3EPS)
  },
  {
    symbol: assetSymbols6.valdai3EPS,
    underlying: valdai3EPS,
    name: "Ellipsis.finance valdai3EPS (DAI, val3EPS)",
    decimals: 18,
    oracle: OracleTypes3.CurveLpTokenPriceOracleNoRegistry,
    extraDocs: ellipsisDocs("0x245e8bb5427822FB8fd6cE062d8dd853FbcfABF5", "valdai3EPS", valdai3EPS)
  },
  {
    symbol: assetSymbols6["2brl"],
    underlying: twoBRL,
    name: "Ellipsis.finance 2BRL (BRZ, jBRL)",
    decimals: 18,
    oracle: OracleTypes3.CurveLpTokenPriceOracleNoRegistry,
    extraDocs: ellipsisDocs("0xad51e40D8f255dba1Ad08501D6B1a6ACb7C188f3", "2brl", twoBRL)
  },
  {
    symbol: assetSymbols6["3brl"],
    underlying: threeBRL,
    name: "Ellipsis.finance 3BRL (jBRL, BRZ, BRZ (Wormhole))",
    decimals: 18,
    oracle: OracleTypes3.CurveLpTokenPriceOracleNoRegistry,
    extraDocs: ellipsisDocs("0x43719DfFf12B04C71F7A589cdc7F54a01da07D7a", "3brl", threeBRL)
  },
  {
    symbol: assetSymbols6["JCHF-BUSD"],
    underlying: epsBUSD_jCHF,
    name: "Ellipsis.finance JCHF-BUSD",
    decimals: 18,
    oracle: OracleTypes3.CurveV2LpTokenPriceOracleNoRegistry,
    extraDocs: ellipsisDocs("0xBcA6E25937B0F7E0FD8130076b6B218F595E32e2", "eps BUSD jCHF", epsBUSD_jCHF)
  },
  // Bomb
  {
    symbol: assetSymbols6.BOMB,
    underlying: BOMB,
    name: "BOMB",
    decimals: 18,
    oracle: OracleTypes3.UniswapTwapPriceOracleV2,
    extraDocs: defaultDocs("https://bscscan.com", BOMB),
    disabled: true
  },
  {
    symbol: assetSymbols6.xBOMB,
    underlying: xBOMB,
    name: "xBOMB",
    decimals: 18,
    disabled: true
  },
  {
    symbol: assetSymbols6.ankrBNB,
    underlying: ankrBNB,
    name: "Ankr Staked BNB ",
    decimals: 18,
    oracle: OracleTypes3.AnkrCertificateTokenPriceOracle,
    extraDocs: ankrCertificateDocs("ankrBNB", "BNB")
  },
  {
    symbol: assetSymbols6.stkBNB,
    underlying: stkBNB,
    name: "Staked BNB (Persistance)",
    decimals: 18,
    oracle: OracleTypes3.StkBNBPriceOracle,
    extraDocs: stkBNBDocs()
  },
  {
    symbol: assetSymbols6.BNBx,
    underlying: BNBx,
    name: "Liquid Staked BNB (Stader)",
    decimals: 18,
    oracle: OracleTypes3.ChainlinkPriceOracleV2,
    extraDocs: StaderXDocs("bnbchain", "BNBx")
  },
  {
    symbol: assetSymbols6["epsBNBx-BNB"],
    underlying: epsBNBx_BNB,
    name: "Ellipsis.finance epsBNBx (BNBx/BNB)",
    decimals: 18,
    oracle: OracleTypes3.CurveLpTokenPriceOracleNoRegistry,
    extraDocs: ellipsisDocs("0xFD4afeAc39DA03a05f61844095A75c4fB7D766DA", "BNBx/BNB", epsBNBx_BNB)
  },
  {
    symbol: assetSymbols6["asBNBx-WBNB"],
    underlying: asBNBx_WBNB,
    name: "BNBx-BNB ApeSwap LP",
    decimals: 18,
    oracle: OracleTypes3.UniswapLpTokenPriceOracle,
    extraDocs: apeSwapDocs(WBNB, BNBx, "BNBx-WBNB", asBNBx_WBNB)
  },
  {
    symbol: assetSymbols6["BTCB-BOMB"],
    underlying: BTCB_BOMB,
    name: "BOMB-BTC PCS LP",
    decimals: 18,
    oracle: OracleTypes3.UniswapLpTokenPriceOracle,
    extraDocs: pancakeSwapDocs(BTCB, BOMB, "BOMB-BTC", BTCB_BOMB)
  },
  {
    symbol: assetSymbols6["stkBNB-WBNB"],
    underlying: stkBNB_WBNB,
    name: "stkBNB-WBNB PCS LP",
    decimals: 18,
    oracle: OracleTypes3.UniswapLpTokenPriceOracle,
    extraDocs: pancakeSwapDocs(WBNB, stkBNB, "stkBNB-WBNB", stkBNB_WBNB)
  },
  {
    symbol: assetSymbols6["ANKR-ankrBNB"],
    underlying: ANKR_ankrBNB,
    name: "ANKR-ankrBNB PCS LP",
    decimals: 18,
    oracle: OracleTypes3.UniswapLpTokenPriceOracle,
    extraDocs: pancakeSwapDocs(ANKR, ankrBNB, "ANKR-ankrBNB", ANKR_ankrBNB)
  },
  {
    symbol: assetSymbols6["asANKR-ankrBNB"],
    underlying: asANKR_ankrBNB,
    name: "ANKR-ankrBNB ApeSwap LP",
    decimals: 18,
    oracle: OracleTypes3.UniswapLpTokenPriceOracle,
    extraDocs: apeSwapDocs(ANKR, ankrBNB, "ANKR-ankrBNB", asANKR_ankrBNB)
  },
  // Jarvis
  {
    symbol: assetSymbols6.jBRL,
    underlying: jBRL,
    name: "Jarvis Synthetic Brazilian Real",
    decimals: 18,
    oracle: OracleTypes3.ChainlinkPriceOracleV2,
    extraDocs: jarvisDocs("v1")
  },
  {
    symbol: assetSymbols6.JCHF,
    underlying: jCHF,
    name: "Jarvis Synthetic Swiss Franc",
    decimals: 18,
    oracle: OracleTypes3.ChainlinkPriceOracleV2,
    extraDocs: jarvisDocs("v1")
  },
  {
    symbol: assetSymbols6.JEUR,
    underlying: jEUR,
    name: "Jarvis Synthetic Euro",
    decimals: 18,
    oracle: OracleTypes3.ChainlinkPriceOracleV2,
    extraDocs: jarvisDocs("v1")
  },
  {
    symbol: assetSymbols6.JGBP,
    underlying: jGBP,
    name: "Jarvis Synthetic British Pound",
    decimals: 18,
    oracle: OracleTypes3.ChainlinkPriceOracleV2,
    extraDocs: jarvisDocs("v1")
  },
  {
    symbol: assetSymbols6.JMXN,
    underlying: jMXN,
    name: "Jarvis Synthetic Mexican Peso",
    decimals: 18,
    oracle: OracleTypes3.ChainlinkPriceOracleV2,
    extraDocs: jarvisDocs("v1")
  },
  {
    symbol: assetSymbols6.BRZ,
    underlying: BRZ,
    name: "BRZ Token",
    decimals: 4,
    oracle: OracleTypes3.ChainlinkPriceOracleV2,
    extraDocs: `<p><b>How to acquire this token</b><p/><br />
    <p>You can acquire BRZ tokens at <a href="https://www.brztoken.io" target="_blank" style="color: #BCAC83; cursor="pointer">https://www.brztoken.io</> or other centralised exchanges</p>`
  },
  {
    symbol: assetSymbols6.BRZw,
    underlying: BRZw,
    name: "BRZ Token (Wormhole)",
    decimals: 4,
    oracle: OracleTypes3.ChainlinkPriceOracleV2,
    extraDocs: `<p><b>How to acquire this token</b><p/><br />
    <p>This is the Wormhole-bridged version of BRZ. To get it, you can bridge BRZ from Solana to BSC using the <a href="https://www.portalbridge.com/#/transfer" target="_blank" style="color: #BCAC83; cursor="pointer">Official Bridge</></p>`
  },
  {
    symbol: assetSymbols6["WBNB-BUSD"],
    underlying: WBNB_BUSD,
    name: "WBNB-BUSD PCS LP",
    decimals: 18,
    oracle: OracleTypes3.UniswapLpTokenPriceOracle,
    extraDocs: pancakeSwapDocs(WBNB, BUSD, "WBNB-BUSD", WBNB_BUSD)
  },
  {
    symbol: assetSymbols6["WBNB-DAI"],
    underlying: WBNB_DAI,
    name: "WBNB-DAI PCS LP",
    decimals: 18,
    oracle: OracleTypes3.UniswapLpTokenPriceOracle,
    extraDocs: pancakeSwapDocs(WBNB, DAI2, "WBNB-DAI", WBNB_DAI)
  },
  {
    symbol: assetSymbols6["WBNB-USDC"],
    underlying: WBNB_USDC,
    name: "WBNB-USDC PCS LP",
    decimals: 18,
    oracle: OracleTypes3.UniswapLpTokenPriceOracle,
    extraDocs: pancakeSwapDocs(WBNB, USDC2, "WBNB-USDC", WBNB_USDC)
  },
  {
    symbol: assetSymbols6["WBNB-USDT"],
    underlying: WBNB_USDT,
    name: "WBNB-USDT PCS LP",
    decimals: 18,
    oracle: OracleTypes3.UniswapLpTokenPriceOracle,
    extraDocs: pancakeSwapDocs(WBNB, USDT2, "WBNB-USDT", WBNB_USDT)
  },
  {
    symbol: assetSymbols6["WBNB-ETH"],
    underlying: WBNB_ETH,
    name: "WBNB-ETH PCS LP",
    decimals: 18,
    oracle: OracleTypes3.UniswapLpTokenPriceOracle,
    extraDocs: pancakeSwapDocs(WBNB, ETH, "WBNB-ETH", WBNB_ETH)
  },
  {
    symbol: assetSymbols6["BUSD-USDT"],
    underlying: BUSD_USDT,
    name: "BUSD-USDT PCS LP",
    decimals: 18,
    oracle: OracleTypes3.UniswapLpTokenPriceOracle,
    extraDocs: pancakeSwapDocs(BUSD, USDT2, "BUSD-USDT", BUSD_USDT)
  },
  {
    symbol: assetSymbols6["BUSD-BTCB"],
    underlying: BUSD_BTCB,
    name: "BUSD-BTCB PCS LP",
    decimals: 18,
    oracle: OracleTypes3.UniswapLpTokenPriceOracle,
    extraDocs: pancakeSwapDocs(BUSD, BTCB, "BUSD-BTCB", BUSD_BTCB)
  },
  {
    symbol: assetSymbols6["USDC-BUSD"],
    underlying: USDC_BUSD,
    name: "USDC-BUSD PCS LP",
    decimals: 18,
    oracle: OracleTypes3.UniswapLpTokenPriceOracle,
    extraDocs: pancakeSwapDocs(USDC2, BUSD, "USDC-BUSD", USDC_BUSD)
  },
  {
    symbol: assetSymbols6["USDC-ETH"],
    underlying: USDC_ETH,
    name: "USDC-ETH PCS LP",
    decimals: 18,
    oracle: OracleTypes3.UniswapLpTokenPriceOracle,
    extraDocs: pancakeSwapDocs(USDC2, ETH, "USDC-ETH", USDC_ETH)
  },
  {
    symbol: assetSymbols6["CAKE-WBNB"],
    underlying: CAKE_WBNB,
    name: "CAKE-WBNB PCS LP",
    decimals: 18,
    oracle: OracleTypes3.UniswapLpTokenPriceOracle,
    extraDocs: pancakeSwapDocs(CAKE, WBNB, "CAKE-WBNB", CAKE_WBNB)
  },
  {
    symbol: assetSymbols6["BTCB-ETH"],
    underlying: BTCB_ETH,
    name: "BTCB-ETH PCS LP",
    decimals: 18,
    oracle: OracleTypes3.UniswapLpTokenPriceOracle,
    extraDocs: pancakeSwapDocs(BTCB, ETH, "BTCB-ETH", BTCB_ETH)
  },
  {
    symbol: assetSymbols6.EPX,
    underlying: EPX,
    name: "Ellipsis X",
    decimals: 18,
    oracle: OracleTypes3.UniswapTwapPriceOracleV2,
    extraDocs: defaultDocs("https://bscscan.com", EPX),
    disabled: true
  },
  {
    symbol: assetSymbols6.DDD,
    underlying: DDD,
    name: "DotDot",
    decimals: 18,
    oracle: OracleTypes3.UniswapTwapPriceOracleV2,
    extraDocs: defaultDocs("https://bscscan.com", DDD),
    disabled: true
  },
  {
    symbol: assetSymbols6.pSTAKE,
    underlying: pSTAKE,
    name: "pSTAKE",
    decimals: 18,
    oracle: OracleTypes3.UniswapTwapPriceOracleV2,
    extraDocs: defaultDocs("https://bscscan.com", pSTAKE),
    disabled: true
  },
  {
    symbol: assetSymbols6.SD,
    underlying: SD,
    name: "SD",
    decimals: 18,
    oracle: OracleTypes3.UniswapTwapPriceOracleV2,
    extraDocs: defaultDocs("https://bscscan.com", SD),
    disabled: true
  },
  {
    symbol: assetSymbols6.ANKR,
    underlying: ANKR,
    name: "Ankr",
    decimals: 18,
    oracle: OracleTypes3.UniswapTwapPriceOracleV2,
    extraDocs: defaultDocs("https://bscscan.com", ANKR)
  },
  {
    symbol: assetSymbols6["WOMBATLP-WBNB"],
    underlying: WOMBATLP_WBNB,
    name: "WombatLp WBNB",
    decimals: 18,
    oracle: OracleTypes3.WombatLpTokenPriceOracle,
    extraDocs: wombatDocs("BNB", "WBNB")
  },
  // Thena
  {
    symbol: assetSymbols6["sAMM-jBRL/BRZ"],
    underlying: solidlyStableAMM_jBRL_BRZ,
    name: "Stable V1 AMM - jBRL/BRZ",
    decimals: 18,
    oracle: OracleTypes3.SolidlyLpTokenPriceOracle,
    extraDocs: thenaDocs(solidlyStableAMM_jBRL_BRZ),
    originalSymbol: assetOriginalSymbols["sAMM-jBRL/BRZ"]
  },
  {
    symbol: assetSymbols6["vAMM-ANKR/ankrBNB"],
    underlying: solidlyVolatileAMM_ANKR_ankrBNB,
    name: "Volatile V1 AMM - ANKR/ankrBNB",
    decimals: 18,
    oracle: OracleTypes3.SolidlyLpTokenPriceOracle,
    extraDocs: thenaDocs(solidlyVolatileAMM_ANKR_ankrBNB),
    originalSymbol: assetOriginalSymbols["vAMM-ANKR/ankrBNB"]
  },
  {
    symbol: assetSymbols6["vAMM-ANKR/HAY"],
    underlying: solidlyVolatileAMM_ANKR_HAY,
    name: "Volatile V1 AMM - ANKR/HAY",
    decimals: 18,
    oracle: OracleTypes3.SolidlyLpTokenPriceOracle,
    extraDocs: thenaDocs(solidlyVolatileAMM_ANKR_HAY),
    originalSymbol: assetOriginalSymbols["vAMM-ANKR/HAY"]
  },
  {
    symbol: assetSymbols6["vAMM-HAY/ankrBNB"],
    underlying: solidlyVolatileAMM_HAY_ankrBNB,
    name: "Volatile V1 AMM - HAY/ankrBNB",
    decimals: 18,
    oracle: OracleTypes3.SolidlyLpTokenPriceOracle,
    extraDocs: thenaDocs(solidlyVolatileAMM_HAY_ankrBNB),
    originalSymbol: assetOriginalSymbols["vAMM-HAY/ankrBNB"]
  },
  {
    symbol: assetSymbols6["sAMM-HAY/BUSD"],
    underlying: solidlyStableAMM_HAY_BUSD,
    name: "Stable V1 AMM - HAY/BUSD",
    decimals: 18,
    oracle: OracleTypes3.SolidlyLpTokenPriceOracle,
    extraDocs: thenaDocs(solidlyStableAMM_HAY_BUSD),
    originalSymbol: assetOriginalSymbols["sAMM-HAY/BUSD"]
  },
  {
    symbol: assetSymbols6["sAMM-stkBNB/WBNB"],
    underlying: solidlyStableAMM_stkBNB_WBNB,
    name: "Stable V1 AMM - stkBNB/WBNB",
    decimals: 18,
    oracle: OracleTypes3.SolidlyLpTokenPriceOracle,
    extraDocs: thenaDocs(solidlyStableAMM_stkBNB_WBNB),
    originalSymbol: assetOriginalSymbols["sAMM-stkBNB/WBNB"]
  },
  {
    symbol: assetSymbols6.aWBNB_STKBNB,
    underlying: solidlyGammaStable_stkBNB_WBNB,
    name: "Fusion V2 AMM - Stable stkBNB/WBNB",
    decimals: 18,
    oracle: OracleTypes3.GammaPoolPriceOracle,
    extraDocs: thenaDocsV2(WBNB, stkBNB, solidlyGammaStable_stkBNB_WBNB, "GAMMA_STABLE")
  },
  {
    symbol: assetSymbols6.aWBNB_BTCB,
    underlying: solidlyGammaNarrow_BTCB_WBNB,
    name: "Fusion V2 AMM - Stable BTCB/WBNB",
    decimals: 18,
    oracle: OracleTypes3.GammaPoolPriceOracle,
    extraDocs: thenaDocsV2(BTCB, WBNB, solidlyGammaNarrow_BTCB_WBNB, "GAMMA_NARROW")
  },
  {
    symbol: assetSymbols6.aWBNB_ETH,
    underlying: solidlyGammaNarrow_ETH_WBNB,
    name: "Fusion V2 AMM - Stable ETH/WBNB",
    decimals: 18,
    oracle: OracleTypes3.GammaPoolPriceOracle,
    extraDocs: thenaDocsV2(ETH, WBNB, solidlyGammaNarrow_ETH_WBNB, "GAMMA_NARROW")
  },
  {
    symbol: assetSymbols6.aANKRBNB_ANKR_N,
    underlying: solidlyGammaNarrow_ANKR_ankrBNB,
    name: "Fusion V2 AMM - Narrow ANKR/ankrBNB",
    decimals: 18,
    oracle: OracleTypes3.GammaPoolPriceOracle,
    extraDocs: thenaDocsV2(ANKR, ankrBNB, solidlyGammaNarrow_ANKR_ankrBNB, "GAMMA_NARROW")
  },
  {
    symbol: assetSymbols6.aANKRBNB_ANKR_W,
    underlying: solidlyGammaWide_ANKR_ankrBNB,
    name: "Fusion V2 AMM - Wide ANKR/ankrBNB",
    decimals: 18,
    oracle: OracleTypes3.GammaPoolPriceOracle,
    extraDocs: thenaDocsV2(ANKR, ankrBNB, solidlyGammaWide_ANKR_ankrBNB, "GAMMA_WIDE")
  },
  {
    symbol: assetSymbols6.aANKRBNB_RDNT_W,
    underlying: solidlyGammaWide_RDNT_ankrBNB,
    name: "Fusion V2 AMM - Wide RDNT/ankrBNB",
    decimals: 18,
    oracle: OracleTypes3.GammaPoolPriceOracle,
    extraDocs: thenaDocsV2(RDNT, ankrBNB, solidlyGammaWide_RDNT_ankrBNB, "GAMMA_WIDE")
  },
  {
    symbol: assetSymbols6.aANKRBNB_RDNT_N,
    underlying: solidlyGammaNarrow_RDNT_ankrBNB,
    name: "Fusion V2 AMM - Narrow RDNT/ankrBNB",
    decimals: 18,
    oracle: OracleTypes3.GammaPoolPriceOracle,
    extraDocs: thenaDocsV2(RDNT, ankrBNB, solidlyGammaNarrow_RDNT_ankrBNB, "GAMMA_NARROW")
  },
  {
    symbol: assetSymbols6.aANKRBNB_THE_W,
    underlying: solidlyGammaWide_THE_ankrBNB,
    name: "Fusion V2 AMM - Wide THE/ankrBNB",
    decimals: 18,
    oracle: OracleTypes3.GammaPoolPriceOracle,
    extraDocs: thenaDocsV2(THE, ankrBNB, solidlyGammaWide_THE_ankrBNB, "GAMMA_WIDE")
  },
  {
    symbol: assetSymbols6.aANKRBNB_THE_N,
    underlying: solidlyGammaNarrow_THE_ankrBNB,
    name: "Fusion V2 AMM - Narrow THE/ankrBNB",
    decimals: 18,
    oracle: OracleTypes3.GammaPoolPriceOracle,
    extraDocs: thenaDocsV2(THE, ankrBNB, solidlyGammaNarrow_THE_ankrBNB, "GAMMA_NARROW")
  }
];
var assets_default2 = assets2;

// src/bsc/addresses.ts
var chainAddresses2 = {
  W_TOKEN: WBNB,
  W_TOKEN_USD_CHAINLINK_PRICE_FEED: "0x5f4ec3df9cbd43714fe2740f5e3616155c5b8419",
  UNISWAP_V2_ROUTER: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
  UNISWAP_V2_FACTORY: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73",
  PAIR_INIT_HASH: ethers3.utils.hexlify("0x00fb7f630766e6a796048ea87d01acd3068e8ff67d078148a3fa3f4a84f69bd5"),
  STABLE_TOKEN: BUSD,
  W_BTC_TOKEN: BTCB,
  ALGEBRA_SWAP_ROUTER: "0x327Dd3208f0bCF590A66110aCB6e5e6941A4EfA0",
  SOLIDLY_SWAP_ROUTER: "0xd4ae6eCA985340Dd434D38F470aCCce4DC78D109"
};
var addresses_default2 = chainAddresses2;

// src/bsc/fundingStrategies.ts
import { assetSymbols as assetSymbols7, FundingStrategyContract, underlying as underlying5 } from "@midas-capital/types";
var fundingStrategies2 = {
  // TODO: extract to predefined chained paths
  // jarvis
  [underlying5(assets_default2, assetSymbols7.jBRL)]: [
    FundingStrategyContract.JarvisLiquidatorFunder,
    underlying5(assets_default2, assetSymbols7.BUSD)
  ],
  // BOMB
  [underlying5(assets_default2, assetSymbols7.xBOMB)]: [
    FundingStrategyContract.XBombLiquidatorFunder,
    underlying5(assets_default2, assetSymbols7.BOMB)
  ],
  [underlying5(assets_default2, assetSymbols7.MAI)]: [
    FundingStrategyContract.CurveSwapLiquidatorFunder,
    underlying5(assets_default2, assetSymbols7.val3EPS)
  ],
  [underlying5(assets_default2, assetSymbols7.BRZ)]: [
    FundingStrategyContract.CurveSwapLiquidatorFunder,
    underlying5(assets_default2, assetSymbols7.jBRL)
  ],
  [underlying5(assets_default2, assetSymbols7.val3EPS)]: [
    FundingStrategyContract.CurveSwapLiquidatorFunder,
    underlying5(assets_default2, assetSymbols7.BUSD)
  ],
  [underlying5(assets_default2, assetSymbols7.JCHF)]: [
    FundingStrategyContract.CurveSwapLiquidatorFunder,
    underlying5(assets_default2, assetSymbols7.BUSD)
  ]
};
var fundingStrategies_default2 = fundingStrategies2;

// src/bsc/irms.ts
import { IrmTypes as IrmTypes2 } from "@midas-capital/types";
var baseIrms2 = [IrmTypes2.WhitePaperInterestRateModel, IrmTypes2.JumpRateModel];
var irms2 = [
  ...baseIrms2,
  IrmTypes2.AnkrBNBInterestRateModel,
  IrmTypes2.AdjustableAnkrBNBIrm,
  IrmTypes2.AdjustableJumpRateModel_PSTAKE_WBNB,
  IrmTypes2.AdjustableJumpRateModel_TRANSFERO_BRZ,
  IrmTypes2.AdjustableJumpRateModel_TRANSFERO_BTCB_ETH_MAI_WBNB,
  IrmTypes2.AdjustableJumpRateModel_STADER_WBNB,
  IrmTypes2.AdjustableJumpRateModel_JARVIS_jBRL
];
var irms_default2 = irms2;

// src/bsc/liquidation.ts
import { assetSymbols as assetSymbols8, LiquidationStrategy as LiquidationStrategy2, underlying as underlying6 } from "@midas-capital/types";
import { BigNumber as BigNumber3, constants as constants2 } from "ethers";
var liquidationDefaults2 = {
  DEFAULT_ROUTER: addresses_default2.UNISWAP_V2_ROUTER,
  ASSET_SPECIFIC_ROUTER: {
    [underlying6(assets_default2, assetSymbols8["asBNBx-WBNB"])]: "0xcF0feBd3f17CEf5b47b0cD257aCf6025c5BFf3b7",
    [underlying6(assets_default2, assetSymbols8["vAMM-HAY/ankrBNB"])]: "0xd4ae6eCA985340Dd434D38F470aCCce4DC78D109",
    // Thena Solidly Router
    [underlying6(assets_default2, assetSymbols8["sAMM-HAY/BUSD"])]: "0xd4ae6eCA985340Dd434D38F470aCCce4DC78D109",
    [underlying6(assets_default2, assetSymbols8["vAMM-ANKR/HAY"])]: "0xd4ae6eCA985340Dd434D38F470aCCce4DC78D109",
    [underlying6(assets_default2, assetSymbols8["vAMM-ANKR/ankrBNB"])]: "0xd4ae6eCA985340Dd434D38F470aCCce4DC78D109",
    [underlying6(assets_default2, assetSymbols8["sAMM-jBRL/BRZ"])]: "0xd4ae6eCA985340Dd434D38F470aCCce4DC78D109"
  },
  SUPPORTED_OUTPUT_CURRENCIES: [constants2.AddressZero, ankrBNB, WBNB, HAY, BUSD],
  SUPPORTED_INPUT_CURRENCIES: [constants2.AddressZero, WBNB],
  LIQUIDATION_STRATEGY: LiquidationStrategy2.UNISWAP,
  MINIMUM_PROFIT_NATIVE: BigNumber3.from(0),
  LIQUIDATION_INTERVAL_SECONDS: 60,
  jarvisPools: [
    {
      expirationTime: 40 * 60,
      liquidityPoolAddress: "0x0fD8170Dc284CD558325029f6AEc1538c7d99f49",
      syntheticToken: underlying6(assets_default2, assetSymbols8.jBRL),
      collateralToken: underlying6(assets_default2, assetSymbols8.BUSD)
    }
  ],
  balancerPools: []
};
var liquidation_default2 = liquidationDefaults2;

// src/bsc/oracles.ts
import { OracleTypes as OracleTypes4 } from "@midas-capital/types";
var baseOracles2 = [OracleTypes4.FixedNativePriceOracle, OracleTypes4.MasterPriceOracle, OracleTypes4.SimplePriceOracle];
var oracles2 = [
  ...baseOracles2,
  OracleTypes4.ChainlinkPriceOracleV2,
  OracleTypes4.CurveLpTokenPriceOracleNoRegistry,
  OracleTypes4.CurveV2LpTokenPriceOracleNoRegistry,
  OracleTypes4.UniswapLpTokenPriceOracle,
  OracleTypes4.UniswapTwapPriceOracleV2,
  OracleTypes4.StkBNBPriceOracle,
  OracleTypes4.AnkrCertificateTokenPriceOracle,
  OracleTypes4.DiaPriceOracle,
  OracleTypes4.SolidlyLpTokenPriceOracle
];
var oracles_default2 = oracles2;

// src/bsc/params.ts
import { BigNumber as BigNumber4 } from "ethers";
var specificParams2 = {
  blocksPerYear: BigNumber4.from((20 * 24 * 365 * 60).toString()),
  cgId: "binancecoin",
  metadata: {
    chainIdHex: "0x38",
    name: "Binance Smart Chain",
    shortName: "BNB",
    img: "https://d1912tcoux65lj.cloudfront.net/network/bsc.jpg",
    blockExplorerUrls: { default: { name: "BnbScan", url: "https://bscscan.com" } },
    rpcUrls: {
      default: { http: ["https://bsc-dataseed.binance.org/"] },
      public: { http: ["https://bsc-dataseed.binance.org/"] }
    },
    nativeCurrency: {
      symbol: "BNB",
      name: "BNB"
    },
    wrappedNativeCurrency: {
      symbol: "WBNB",
      address: WBNB,
      name: "BNB",
      decimals: 18,
      color: "#627EEA",
      overlayTextColor: "#fff",
      logoURL: "https://d1912tcoux65lj.cloudfront.net/network/bsc.jpg"
    }
  }
};
var params_default2 = specificParams2;

// src/bsc/plugins.ts
import { assetSymbols as assetSymbols9, Strategy, underlying as underlying7 } from "@midas-capital/types";
var deployedPlugins2 = {
  // DotDotLpERC4626_2brl_0xf0a2852958aD041a9Fb35c312605482Ca3Ec17ba.json
  "0x23bBcF59BF843cD55c4DA9bDB81429695C87f847": {
    market: "0xf0a2852958aD041a9Fb35c312605482Ca3Ec17ba",
    name: "2brl DotDotLpERC4626",
    strategy: Strategy.DotDot,
    apyDocsUrl: "https://dotdot.finance/#/stake",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/binance-smart-chain-bsc/jarvis-jfiat-pool",
    underlying: underlying7(assets_default2, assetSymbols9["2brl"]),
    // 2BRL
    otherParams: [
      "0xAbBbAc3F07d33F7dC4dfD5EdB1E7Bf56041abBa5",
      // _dddFlywheel
      "0xF2E46295c684C541d618243558a0af17fb4a6862",
      // _epxFlywheel
      "0x8189F0afdBf8fE6a9e13c69bA35528ac6abeB1af",
      // lpDepositor
      "0xf0a2852958aD041a9Fb35c312605482Ca3Ec17ba",
      // _rewardsDestination
      [underlying7(assets_default2, assetSymbols9.DDD), underlying7(assets_default2, assetSymbols9.EPX)]
      // _rewardTokens
    ]
  },
  // No plugin deployment file stored
  "0xBE0cCFA6B09eB1f3C0c62D406aE00F528e20594b": {
    market: "0x383158Db17719d2Cf1Ce10Ccb9a6Dd7cC1f54EF3",
    name: "3brl DotDotLpERC4626",
    strategy: Strategy.DotDot,
    apyDocsUrl: "https://dotdot.finance/#/stake",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/binance-smart-chain-bsc/jarvis-jfiat-pool",
    underlying: underlying7(assets_default2, assetSymbols9["3brl"]),
    // 2BRL
    otherParams: [
      "0xD146adB6B07c7a31174FFC8B001dCa7AAF8Ff9E0",
      // _dddFlywheel
      "0x89293CeaE1822CE4d5510d3Dd8248F6552FB60F4",
      // _epxFlywheel
      "0x8189F0afdBf8fE6a9e13c69bA35528ac6abeB1af",
      // lpDepositor
      "0x383158Db17719d2Cf1Ce10Ccb9a6Dd7cC1f54EF3",
      // _rewardsDestination
      [underlying7(assets_default2, assetSymbols9.DDD), underlying7(assets_default2, assetSymbols9.EPX)]
      // _rewardTokens
    ]
  },
  // DotDotLpERC4626_val3EPS_0xccc9BEF35C50A3545e01Ef72Cc957E0aec8B2e7C.json
  "0xe38A0F34DB15fCC47510cdB0519E149eC20c8806": {
    market: "0xccc9BEF35C50A3545e01Ef72Cc957E0aec8B2e7C",
    name: "val3EPS DotDotLpERC4626",
    strategy: Strategy.DotDot,
    apyDocsUrl: "https://dotdot.finance/#/stake",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/binance-smart-chain-bsc/ellipsis-x-dotdot",
    underlying: underlying7(assets_default2, assetSymbols9.val3EPS),
    otherParams: [
      "0xD146adB6B07c7a31174FFC8B001dCa7AAF8Ff9E0",
      // _dddFlywheel
      "0x89293CeaE1822CE4d5510d3Dd8248F6552FB60F4",
      // _epxFlywheel
      "0x8189F0afdBf8fE6a9e13c69bA35528ac6abeB1af",
      // lpDepositor
      "0xccc9BEF35C50A3545e01Ef72Cc957E0aec8B2e7C",
      // _rewardsDestination
      [underlying7(assets_default2, assetSymbols9.DDD), underlying7(assets_default2, assetSymbols9.EPX)]
      // _rewardTokens
    ]
  },
  // DotDotLpERC4626_valdai3EPS_0x7479dd29b9256aB74c9bf84d6f9CE6e30014d248.json
  "0xc2Af1451dBFbf564FB32E57f275d419395F5BC92": {
    market: "0x7479dd29b9256aB74c9bf84d6f9CE6e30014d248",
    name: "valdai3EPS DotDotLpERC4626",
    strategy: Strategy.DotDot,
    apyDocsUrl: "https://dotdot.finance/#/stake",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/binance-smart-chain-bsc/ellipsis-x-dotdot",
    underlying: underlying7(assets_default2, assetSymbols9.valdai3EPS),
    otherParams: [
      "0xD146adB6B07c7a31174FFC8B001dCa7AAF8Ff9E0",
      // _dddFlywheel
      "0x89293CeaE1822CE4d5510d3Dd8248F6552FB60F4",
      // _epxFlywheel
      "0x8189F0afdBf8fE6a9e13c69bA35528ac6abeB1af",
      // lpDepositor
      "0x7479dd29b9256aB74c9bf84d6f9CE6e30014d248",
      // _rewardsDestination
      [underlying7(assets_default2, assetSymbols9.DDD), underlying7(assets_default2, assetSymbols9.EPX)]
      // _rewardTokens
    ]
  },
  // DotDotLpERC4626_3EPS_0x6f9B6ccD027d1c6Ed09ee215B9Ca5B85a57C6eA1
  "0x628C6d2236fC1712D66Df5fbFf9041f7809C959C": {
    market: "0x6f9B6ccD027d1c6Ed09ee215B9Ca5B85a57C6eA1",
    name: "3EPS DotDotLpERC4626",
    strategy: Strategy.DotDot,
    apyDocsUrl: "https://dotdot.finance/#/stake",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/binance-smart-chain-bsc/ellipsis-x-dotdot",
    underlying: underlying7(assets_default2, assetSymbols9["3EPS"]),
    otherParams: [
      "0xD146adB6B07c7a31174FFC8B001dCa7AAF8Ff9E0",
      // _dddFlywheel
      "0x89293CeaE1822CE4d5510d3Dd8248F6552FB60F4",
      // _epxFlywheel
      "0x8189F0afdBf8fE6a9e13c69bA35528ac6abeB1af",
      // lpDepositor
      "0x6f9B6ccD027d1c6Ed09ee215B9Ca5B85a57C6eA1",
      // _rewardsDestination
      [underlying7(assets_default2, assetSymbols9.DDD), underlying7(assets_default2, assetSymbols9.EPX)]
    ]
  },
  // BeefyERC4626_jCHF-BUSD_0x1F0452D6a8bb9EAbC53Fa6809Fa0a060Dd531267.json
  "0x29b2aB4102d7aF1CDCF9c84D29D18dC2cFf11f1A": {
    market: "0x1F0452D6a8bb9EAbC53Fa6809Fa0a060Dd531267",
    name: "Beefy JCHF-BUSD Vault",
    strategy: Strategy.Beefy,
    apyDocsUrl: "https://app.beefy.finance/vault/ellipsis-busd-jchf",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/binance-smart-chain-bsc/jarvis-jfiat-pool",
    underlying: underlying7(assets_default2, assetSymbols9["3EPS"]),
    otherParams: ["0x80ACf5C89A284C4b6Fdbc851Ba9844D29d4c6BEd", "0"],
    // Beefy Vault, withdraw fee
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/beefy.png"
  },
  // BeefyERC4626_stkBNB-WBNB_0x906Ab4476221ADc91Dc112c25081A374E0bd29C0.json
  "0xcfB267a90974a172c38Af238b1010672DE4479Ad": {
    market: "0x906Ab4476221ADc91Dc112c25081A374E0bd29C0",
    name: "Beefy stkBNB-WBNB Vault",
    strategy: Strategy.Beefy,
    apyDocsUrl: "https://app.beefy.finance/vault/cakev2-wbnb-stkbnb",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/binance-smart-chain-bsc/pstake-pool",
    underlying: underlying7(assets_default2, assetSymbols9["stkBNB-WBNB"]),
    otherParams: ["0xd23ef71883a98c55Eb7ED67ED61fABF554aDEd21", "0"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/beefy.png"
  },
  // BeefyERC4626_3brl_0x8CE7E5A358bbb7dc49EB2bf796830Ce76A4AA63a.json
  "0xCeB429c710D523d8243833018852Bbad2CEA9Bb4": {
    market: "0x8CE7E5A358bbb7dc49EB2bf796830Ce76A4AA63a",
    name: "Beefy 3brl Vault",
    strategy: Strategy.Beefy,
    apyDocsUrl: "https://app.beefy.finance/vault/ellipsis-3brl",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/binance-smart-chain-bsc/transfero-stables-pool",
    underlying: underlying7(assets_default2, assetSymbols9["3brl"]),
    otherParams: ["0xF46E3e2eb855baE2A32eD941f0c03b1D9b7b96a0", "0"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/beefy.png"
  },
  // BeefyERC4626_epsBNBx-BNB_0x373E0F759828c891EF837b6457a0E7584107dbAb.json
  "0x643fd5AB2485dF7D9Ad43C4c210AbEc8Ae7e44D8": {
    market: "0x373E0F759828c891EF837b6457a0E7584107dbAb",
    name: "Beefy EPS BNBx/BNB  Vault",
    strategy: Strategy.Beefy,
    apyDocsUrl: "https://app.beefy.finance/vault/ellipsis-bnb-bnbx-crypto",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/binance-smart-chain-bsc/stader-bnbx-pool",
    underlying: underlying7(assets_default2, assetSymbols9["epsBNBx-BNB"]),
    otherParams: ["0x56ec6031dC969CfA035F6D65e65D8595d5660fB4", "0"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/beefy.png"
  },
  // BeefyERC4626_ApeSwap BNBx-WBNB LP_0xF0baaE2dc101e6Ff1439Ed3C9f27b30715d1E6AA.json
  "0x0b4444F3FB85264427397Fede0f94704aa3828b9": {
    market: "0xF0baaE2dc101e6Ff1439Ed3C9f27b30715d1E6AA",
    name: "Beefy ApeSwap BNBx/BNB  Vault",
    strategy: Strategy.Beefy,
    apyDocsUrl: "https://app.beefy.finance/vault/banana-bnbx-wbnb",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/binance-smart-chain-bsc/stader-bnbx-pool",
    underlying: underlying7(assets_default2, assetSymbols9["asBNBx-WBNB"]),
    otherParams: ["0xC46DcDe0d91f674C04a61Bb30A52C6B45b95F317", "0"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/beefy.png"
  },
  // BeefyERC4626_2brl_0xfB760f395E945Fd7D4AA1B22334CB1e4E6F0D19F.json
  "0xBCAc816440f7ef66Fea896b307352b86a83F94E8": {
    market: "0xfB760f395E945Fd7D4AA1B22334CB1e4E6F0D19F",
    name: "Beefy EPS 2brl  Vault",
    strategy: Strategy.Beefy,
    apyDocsUrl: "https://app.beefy.finance/vault/ellipsis-2brl",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/binance-smart-chain-bsc/transfero-stables-pool",
    underlying: underlying7(assets_default2, assetSymbols9["2brl"]),
    otherParams: ["0xf867cD98F3762D899F5F26FF5Dd62A5C566A3E0C", "0"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/beefy.png"
  },
  // BeefyERC4626_ApeSwap%20BNBx-WBNB%20LP_0xA057E4f8Ff4a9102F3ee2FF614b9cBC0286F2287.json
  "0xac99ced1a1310fB04618d4801888120ccDD7B87B": {
    market: "0xA057E4f8Ff4a9102F3ee2FF614b9cBC0286F2287",
    name: "Beefy BNBx-BNB LP Vault",
    strategy: Strategy.Beefy,
    apyDocsUrl: "https://app.beefy.finance/vault/banana-bnbx-wbnb",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/binance-smart-chain-bsc/jarvis-jfiat-pool",
    underlying: underlying7(assets_default2, assetSymbols9["asBNBx-WBNB"]),
    otherParams: ["0xC46DcDe0d91f674C04a61Bb30A52C6B45b95F317", "0"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/beefy.png"
  },
  // BeefyERC4626_epsBNBx-BNB_0xD96643Ba2Bf96e73509C4bb73c0cb259dAf34de1.json
  "0xA331FaA3Bb84A70466c801E9b14523d8f15f328E": {
    market: "0xD96643Ba2Bf96e73509C4bb73c0cb259dAf34de1",
    name: "Beefy eps BNBx-BNB LP Vault",
    strategy: Strategy.Beefy,
    apyDocsUrl: "https://app.beefy.finance/vault/ellipsis-bnb-bnbx-crypto",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/binance-smart-chain-bsc/jarvis-jfiat-pool",
    underlying: underlying7(assets_default2, assetSymbols9["epsBNBx-BNB"]),
    otherParams: ["0x56ec6031dC969CfA035F6D65e65D8595d5660fB4", "0"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/beefy.png"
  },
  // BeefyERC4626_valdai3EPS_0xBEE206C085f228674a2273F8A33ceaD9e34c3d48.json
  "0x7B77E7713FB2950326B0dE483852da0e1d975d4C": {
    market: "0xBEE206C085f228674a2273F8A33ceaD9e34c3d48",
    name: "Beefy valdai 3EPS LP Vault",
    strategy: Strategy.Beefy,
    apyDocsUrl: "https://app.beefy.finance/vault/ellipsis-valdai3eps",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/binance-smart-chain-bsc/jarvis-jfiat-pool",
    underlying: underlying7(assets_default2, assetSymbols9.valdai3EPS),
    otherParams: ["0x047d08f4eFB9a0BC166447A12326d39A5167138f", "0"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/beefy.png"
  },
  // BeefyERC4626_mai3EPS_0xcB710DD270fa9d57edFadD141BAF3efC0f776716.json
  "0xDE1A82D80082e6b6E9cbe70002857716A09EA18b": {
    market: "0xcB710DD270fa9d57edFadD141BAF3efC0f776716",
    name: "Beefy mai3EPS LP Vault",
    strategy: Strategy.Beefy,
    apyDocsUrl: "https://app.beefy.finance/vault/ellipsis-mai",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/binance-smart-chain-bsc/jarvis-jfiat-pool",
    underlying: underlying7(assets_default2, assetSymbols9.mai3EPS),
    otherParams: ["0xc1beA7B6b749D1f3A812F39afeB8795a97402dfF", "0"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/beefy.png"
  },
  // HelioERC4626_HAY_0xAB7d4760E2c54c0Da1efEC7C358F171d72e14153.json
  "0x369ddC01E8feF7350Eb740f4a32647E8640F0A17": {
    market: "0xAB7d4760E2c54c0Da1efEC7C358F171d72e14153",
    name: "HAY Staking Vault",
    strategy: Strategy.HelioHAY,
    apyDocsUrl: "https://helio.money/app/earn",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/binance-smart-chain-bsc/jarvis-jfiat-pool",
    underlying: underlying7(assets_default2, assetSymbols9.mai3EPS),
    otherParams: ["0x0a1Fd12F73432928C190CAF0810b3B767A59717e"],
    // JAR
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/helio.png"
  },
  // BeefyERC4626_sAMM-jBRL-BRZ_0x9C170d5c6264b04419a06492bf4FbC65ecd63f4D.json
  "0x33395bbe8fcA14368003f9aCE2Deb0Ba5103c670": {
    market: "0x9C170d5c6264b04419a06492bf4FbC65ecd63f4D",
    name: "Beefy sAMM jBRL-BRZ LP Vault",
    strategy: Strategy.Beefy,
    apyDocsUrl: "https://app.beefy.finance/vault/thena-jbrl-brz",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/binance-smart-chain-bsc/transfero-stables-pool",
    underlying: underlying7(assets_default2, assetSymbols9["sAMM-jBRL/BRZ"]),
    otherParams: ["0xb36fffD0174B2eC18D82d21BB2e24b132ecBA5b0", "0"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/beefy.png"
  },
  // ThenaLpERC4626_vAMM-HAY-ankrBNB_0x04b6895d7AD8b10a1a13C749159226249a3b8515
  "0xE141ce7507656f9b2c45d06d649961Eb84e19f82": {
    market: "0x04b6895d7AD8b10a1a13C749159226249a3b8515",
    name: "Thena vAMM-HAY-ankrBNB LP Vault",
    strategy: Strategy.ThenaERC4626,
    apyDocsUrl: `https://www.thena.fi/liquidity/manage/${underlying7(assets_default2, assetSymbols9["vAMM-HAY/ankrBNB"])}`,
    otherParams: [],
    underlying: underlying7(assets_default2, assetSymbols9["vAMM-HAY/ankrBNB"]),
    flywheel: "0x9f21e2bE2dD52083A5DA90a2BEe817d9F8228A74",
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/thena.png"
  },
  // ThenaLpERC4626_vAMM-ANKR-HAY_0xbc65FE441545E9e8f97E50F70526B7E8963826bc.json
  "0x52156377Dbe031706cfAb4e759cA102e58A19953": {
    market: "0xbc65FE441545E9e8f97E50F70526B7E8963826bc",
    name: "Thena vAMM-ANKR-HAY LP Vault",
    strategy: Strategy.ThenaERC4626,
    apyDocsUrl: `https://www.thena.fi/liquidity/manage/${underlying7(assets_default2, assetSymbols9["vAMM-ANKR/HAY"])}`,
    otherParams: [],
    underlying: underlying7(assets_default2, assetSymbols9["vAMM-ANKR/HAY"]),
    flywheel: "0x9f21e2bE2dD52083A5DA90a2BEe817d9F8228A74",
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/thena.png"
  },
  // ThenaLpERC4626_vAMM-ANKR-ankrBNB_0x71693C84486B37096192c9942852f542543639Bf.json
  "0x0c0e8ED68a72c31E657Ce5F03A615FB96753C9b5": {
    market: "0x71693C84486B37096192c9942852f542543639Bf",
    name: "Thena vAMM-ANKR-ankrBNB LP Vault",
    strategy: Strategy.ThenaERC4626,
    apyDocsUrl: `https://www.thena.fi/liquidity/manage/${underlying7(assets_default2, assetSymbols9["vAMM-ANKR/ankrBNB"])}`,
    otherParams: [],
    underlying: underlying7(assets_default2, assetSymbols9["vAMM-ANKR/ankrBNB"]),
    flywheel: "0x9f21e2bE2dD52083A5DA90a2BEe817d9F8228A74",
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/thena.png"
  },
  // ThenaLpERC4626_sAMM-HAY-BUSD_0xF8527Dc5611B589CbB365aCACaac0d1DC70b25cB.json
  "0x02706A482fc9f6B20238157B56763391a45bE60E": {
    market: "0xF8527Dc5611B589CbB365aCACaac0d1DC70b25cB",
    name: "Thena sAMM-HAY-BUSD LP Vault",
    strategy: Strategy.ThenaERC4626,
    apyDocsUrl: `https://www.thena.fi/liquidity/manage/${underlying7(assets_default2, assetSymbols9["sAMM-HAY/BUSD"])}`,
    otherParams: [],
    underlying: underlying7(assets_default2, assetSymbols9["sAMM-HAY/BUSD"]),
    flywheel: "0x9f21e2bE2dD52083A5DA90a2BEe817d9F8228A74",
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/thena.png"
  },
  // BeefyERC4626_sAMM-stkBNB-WBNB_0x17A610C49C8BaaACFb92681be1fcC62fdB17B2f8.json
  "0x3FCA4C8D345340Ef47a698f96e4813e8c4f6076C": {
    market: "0x17A610C49C8BaaACFb92681be1fcC62fdB17B2f8",
    name: "Beefy sAMM stkBNB-WBNB LP Vault",
    strategy: Strategy.Beefy,
    apyDocsUrl: "https://app.beefy.finance/vault/thena-wbnb-stkbnb",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/binance-smart-chain-bsc/pstake-pool",
    underlying: underlying7(assets_default2, assetSymbols9["sAMM-stkBNB/WBNB"]),
    otherParams: ["0xAd36A6a7fC6D6B2aeBB77B48A85a0B58D0f3AB28", "0"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/beefy.png"
  },
  // BeefyERC4626_aWBNB-STKBNB_0x1A8fF97a26B1ab7Eb17bD8E607B09F4f4382584B.json
  "0x7D6679bDbD5E23833F11B777Fd55275a104aD8e5": {
    market: "0x1A8fF97a26B1ab7Eb17bD8E607B09F4f4382584B",
    name: "Beefy aWBNB-stkBNB Gamma LP Vault",
    strategy: Strategy.Beefy,
    apyDocsUrl: "https://app.beefy.finance/vault/thena-gamma-bnb-stkbnb",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/binance-smart-chain-bsc/pstake-pool",
    underlying: underlying7(assets_default2, assetSymbols9["aWBNB_STKBNB"]),
    otherParams: ["0x33D0F945F77660F4A9e56949500265313f6D08fb", "0"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/beefy.png"
  },
  // BeefyERC4626_aWBNB-BTCB_0x3E03A4e68553311f1273529C9b2b9Fd756736cA6.json
  "0x3cDB6f8b8bDAEB684d917A3973D737220aE2292a": {
    market: "0x3E03A4e68553311f1273529C9b2b9Fd756736cA6",
    name: "Beefy aWBNB-BTCB Gamma Narrow LP Vault",
    strategy: Strategy.Beefy,
    apyDocsUrl: "https://app.beefy.finance/vault/thena-gamma-btcb-bnb",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/binance-smart-chain-bsc/pstake-pool",
    underlying: underlying7(assets_default2, assetSymbols9["aWBNB_BTCB"]),
    otherParams: ["0x960eE6caC56912D94cE5c83D7Fb0CD873b5c5099", "0"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/beefy.png"
  },
  // BeefyERC4626_aWBNB-ETH_0x274bA3f7bc996275D7cdEB6239B1B240FFE14198.json
  "0xFd25A63B7e387a79f9aa28272530DcEe5Efa2FBC": {
    market: "0x274bA3f7bc996275D7cdEB6239B1B240FFE14198",
    name: "Beefy aWBNB-ETH Gamma Narrow LP Vault",
    strategy: Strategy.Beefy,
    apyDocsUrl: "https://app.beefy.finance/vault/thena-gamma-eth-bnb",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/binance-smart-chain-bsc/pstake-pool",
    underlying: underlying7(assets_default2, assetSymbols9["aWBNB_ETH"]),
    otherParams: ["0xbCFcE18C5c33463316f93A067b1f50d75b00dc09", "0"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/beefy.png"
  },
  // ThenaLpERC4626_aANKRBNB-ANKR-W_0x6F55B262cA509e112cb27b15402EF0c8cd330F0b.json
  "0xDbC22fE3F4aAE46bA4509b4e13b200E87375b5ab": {
    market: "0x6F55B262cA509e112cb27b15402EF0c8cd330F0b",
    name: "Thena Gamma ankrBNB-ANKR Wide LP Vault",
    strategy: Strategy.ThenaERC4626,
    apyDocsUrl: `https://www.thena.fi/liquidity/manage/${underlying7(assets_default2, assetSymbols9.aANKRBNB_ANKR_W)}`,
    otherParams: [],
    underlying: underlying7(assets_default2, assetSymbols9.aANKRBNB_ANKR_W),
    flywheel: "0x9f21e2bE2dD52083A5DA90a2BEe817d9F8228A74",
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/thena.png"
  },
  // ThenaLpERC4626_aANKRBNB-ANKR-N_0x0f3590D7010f3D5AD5d618BD2c223eb394bB5432
  "0x7A5D376F717732C65d5ac63Fd05F73a287324B05": {
    market: "0x0f3590D7010f3D5AD5d618BD2c223eb394bB5432",
    name: "Thena Gamma ankrBNB-ANKR Narrow LP Vault",
    strategy: Strategy.ThenaERC4626,
    apyDocsUrl: `https://www.thena.fi/liquidity/manage/${underlying7(assets_default2, assetSymbols9.aANKRBNB_ANKR_N)}`,
    otherParams: [],
    underlying: underlying7(assets_default2, assetSymbols9.aANKRBNB_ANKR_N),
    flywheel: "0x9f21e2bE2dD52083A5DA90a2BEe817d9F8228A74",
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/thena.png"
  },
  // ThenaLpERC4626_aANKRBNB-RDNT-N_0xA443a78906B534069a703BC5E186994CA4F90aCC.json
  "0xE706134f898320dc1b1772362566bA6646C569c1": {
    market: "0xA443a78906B534069a703BC5E186994CA4F90aCC",
    name: "Thena Gamma ankrBNB-RDNT Narrow LP Vault",
    strategy: Strategy.ThenaERC4626,
    apyDocsUrl: `https://www.thena.fi/liquidity/manage/${underlying7(assets_default2, assetSymbols9.aANKRBNB_RDNT_N)}`,
    otherParams: [],
    underlying: underlying7(assets_default2, assetSymbols9.aANKRBNB_RDNT_N),
    flywheel: "0x9f21e2bE2dD52083A5DA90a2BEe817d9F8228A74",
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/thena.png"
  },
  // ThenaLpERC4626_aANKRBNB-RDNT-W_0x525fE855718680DE71F3FE761F893a0b763500E4.json
  "0x2841f61C4D0Dd34010a3E75241c1C80AFc99D202": {
    market: "0x525fE855718680DE71F3FE761F893a0b763500E4",
    name: "Thena Gamma ankrBNB-RDNT Wide LP Vault",
    strategy: Strategy.ThenaERC4626,
    apyDocsUrl: `https://www.thena.fi/liquidity/manage/${underlying7(assets_default2, assetSymbols9.aANKRBNB_RDNT_W)}`,
    otherParams: [],
    underlying: underlying7(assets_default2, assetSymbols9.aANKRBNB_RDNT_W),
    flywheel: "0x9f21e2bE2dD52083A5DA90a2BEe817d9F8228A74",
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/thena.png"
  },
  // ThenaLpERC4626_aANKRBNB-THE-N_0x336378365892Ae2C706BB97cd9f23b339951ab01.json
  "0x5bEb4BD2164D286CdE9c672910c548dF643a2Caa": {
    market: "0x336378365892Ae2C706BB97cd9f23b339951ab01",
    name: "Thena Gamma ankrBNB-THE Narrow LP Vault",
    strategy: Strategy.ThenaERC4626,
    apyDocsUrl: `https://www.thena.fi/liquidity/manage/${underlying7(assets_default2, assetSymbols9.aANKRBNB_THE_N)}`,
    otherParams: [],
    underlying: underlying7(assets_default2, assetSymbols9.aANKRBNB_THE_N),
    flywheel: "0x9f21e2bE2dD52083A5DA90a2BEe817d9F8228A74",
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/thena.png"
  },
  // ThenaLpERC4626_aANKRBNB-THE-W_0x0B2ed08ba628bAF7287536A452C3060826422a13.json
  "0xbe79A4B9Cb6892ae0d383038C6e7b25fF79FBc14": {
    market: "0x0B2ed08ba628bAF7287536A452C3060826422a13",
    name: "Thena Gamma ankrBNB-THE Wide LP Vault",
    strategy: Strategy.ThenaERC4626,
    apyDocsUrl: `https://www.thena.fi/liquidity/manage/${underlying7(assets_default2, assetSymbols9.aANKRBNB_THE_W)}`,
    otherParams: [],
    underlying: underlying7(assets_default2, assetSymbols9.aANKRBNB_THE_W),
    flywheel: "0x9f21e2bE2dD52083A5DA90a2BEe817d9F8228A74",
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/thena.png"
  }
};
var plugins_default2 = deployedPlugins2;

// src/bsc/redemptionStrategies.ts
import { assetSymbols as assetSymbols10, RedemptionStrategyContract as RedemptionStrategyContract2, underlying as underlying8 } from "@midas-capital/types";
var redemptionStrategies2 = {
  [underlying8(assets_default2, assetSymbols10["3EPS"])]: [
    RedemptionStrategyContract2.CurveLpTokenLiquidatorNoRegistry,
    underlying8(assets_default2, assetSymbols10.BUSD)
  ],
  [underlying8(assets_default2, assetSymbols10.mai3EPS)]: [
    RedemptionStrategyContract2.CurveLpTokenLiquidatorNoRegistry,
    underlying8(assets_default2, assetSymbols10.BUSD)
  ],
  [underlying8(assets_default2, assetSymbols10["2brl"])]: [
    RedemptionStrategyContract2.CurveLpTokenLiquidatorNoRegistry,
    underlying8(assets_default2, assetSymbols10.jBRL)
  ],
  [underlying8(assets_default2, assetSymbols10["3brl"])]: [
    RedemptionStrategyContract2.CurveLpTokenLiquidatorNoRegistry,
    underlying8(assets_default2, assetSymbols10.jBRL)
  ],
  [underlying8(assets_default2, assetSymbols10.val3EPS)]: [
    RedemptionStrategyContract2.CurveLpTokenLiquidatorNoRegistry,
    underlying8(assets_default2, assetSymbols10.BUSD)
  ],
  [underlying8(assets_default2, assetSymbols10.valdai3EPS)]: [
    RedemptionStrategyContract2.CurveLpTokenLiquidatorNoRegistry,
    underlying8(assets_default2, assetSymbols10.val3EPS)
  ],
  [underlying8(assets_default2, assetSymbols10["JCHF-BUSD"])]: [
    RedemptionStrategyContract2.CurveLpTokenLiquidatorNoRegistry,
    underlying8(assets_default2, assetSymbols10.BUSD)
  ],
  [underlying8(assets_default2, assetSymbols10["epsBNBx-BNB"])]: [
    RedemptionStrategyContract2.CurveLpTokenLiquidatorNoRegistry,
    underlying8(assets_default2, assetSymbols10.WBNB)
  ],
  [underlying8(assets_default2, assetSymbols10.MAI)]: [
    RedemptionStrategyContract2.CurveSwapLiquidator,
    underlying8(assets_default2, assetSymbols10.val3EPS)
  ],
  [underlying8(assets_default2, assetSymbols10.JCHF)]: [
    RedemptionStrategyContract2.CurveSwapLiquidator,
    underlying8(assets_default2, assetSymbols10.BUSD)
  ],
  [underlying8(assets_default2, assetSymbols10.BOMB)]: [
    RedemptionStrategyContract2.UniswapV2LiquidatorFunder,
    underlying8(assets_default2, assetSymbols10.BTCB)
  ],
  [underlying8(assets_default2, assetSymbols10.xBOMB)]: [
    RedemptionStrategyContract2.XBombLiquidatorFunder,
    underlying8(assets_default2, assetSymbols10.BOMB)
  ],
  [underlying8(assets_default2, assetSymbols10.jBRL)]: [
    RedemptionStrategyContract2.JarvisLiquidatorFunder,
    underlying8(assets_default2, assetSymbols10.BUSD)
  ],
  [underlying8(assets_default2, assetSymbols10["WBNB-BUSD"])]: [RedemptionStrategyContract2.UniswapLpTokenLiquidator, WBNB],
  [underlying8(assets_default2, assetSymbols10["WBNB-DAI"])]: [RedemptionStrategyContract2.UniswapLpTokenLiquidator, WBNB],
  [underlying8(assets_default2, assetSymbols10["WBNB-USDC"])]: [RedemptionStrategyContract2.UniswapLpTokenLiquidator, WBNB],
  [underlying8(assets_default2, assetSymbols10["WBNB-USDT"])]: [RedemptionStrategyContract2.UniswapLpTokenLiquidator, WBNB],
  [underlying8(assets_default2, assetSymbols10["WBNB-ETH"])]: [RedemptionStrategyContract2.UniswapLpTokenLiquidator, WBNB],
  [underlying8(assets_default2, assetSymbols10["BUSD-USDT"])]: [
    RedemptionStrategyContract2.UniswapLpTokenLiquidator,
    underlying8(assets_default2, assetSymbols10.BUSD)
  ],
  [underlying8(assets_default2, assetSymbols10["BUSD-BTCB"])]: [
    RedemptionStrategyContract2.UniswapLpTokenLiquidator,
    underlying8(assets_default2, assetSymbols10.BUSD)
  ],
  [underlying8(assets_default2, assetSymbols10["USDC-BUSD"])]: [
    RedemptionStrategyContract2.UniswapLpTokenLiquidator,
    underlying8(assets_default2, assetSymbols10.BUSD)
  ],
  [underlying8(assets_default2, assetSymbols10["USDC-ETH"])]: [
    RedemptionStrategyContract2.UniswapLpTokenLiquidator,
    underlying8(assets_default2, assetSymbols10.USDC)
  ],
  [underlying8(assets_default2, assetSymbols10["BTCB-BOMB"])]: [
    RedemptionStrategyContract2.UniswapLpTokenLiquidator,
    underlying8(assets_default2, assetSymbols10.BTCB)
  ],
  [underlying8(assets_default2, assetSymbols10["BTCB-ETH"])]: [
    RedemptionStrategyContract2.UniswapLpTokenLiquidator,
    underlying8(assets_default2, assetSymbols10.BTCB)
  ],
  [underlying8(assets_default2, assetSymbols10["CAKE-WBNB"])]: [RedemptionStrategyContract2.UniswapLpTokenLiquidator, WBNB],
  [underlying8(assets_default2, assetSymbols10["ANKR-ankrBNB"])]: [RedemptionStrategyContract2.UniswapLpTokenLiquidator, ankrBNB],
  [underlying8(assets_default2, assetSymbols10["stkBNB-WBNB"])]: [RedemptionStrategyContract2.UniswapLpTokenLiquidator, WBNB],
  [underlying8(assets_default2, assetSymbols10["asBNBx-WBNB"])]: [RedemptionStrategyContract2.UniswapLpTokenLiquidator, WBNB],
  [underlying8(assets_default2, assetSymbols10["sAMM-jBRL/BRZ"])]: [
    RedemptionStrategyContract2.SolidlyLpTokenLiquidator,
    underlying8(assets_default2, assetSymbols10.jBRL)
  ],
  [underlying8(assets_default2, assetSymbols10["vAMM-ANKR/ankrBNB"])]: [
    RedemptionStrategyContract2.SolidlyLpTokenLiquidator,
    underlying8(assets_default2, assetSymbols10.ankrBNB)
  ],
  [underlying8(assets_default2, assetSymbols10["vAMM-ANKR/HAY"])]: [
    RedemptionStrategyContract2.SolidlyLpTokenLiquidator,
    underlying8(assets_default2, assetSymbols10.HAY)
  ],
  [underlying8(assets_default2, assetSymbols10["sAMM-HAY/BUSD"])]: [
    RedemptionStrategyContract2.SolidlyLpTokenLiquidator,
    underlying8(assets_default2, assetSymbols10.BUSD)
  ],
  [underlying8(assets_default2, assetSymbols10["vAMM-HAY/ankrBNB"])]: [
    RedemptionStrategyContract2.SolidlyLpTokenLiquidator,
    underlying8(assets_default2, assetSymbols10.ankrBNB)
  ],
  [underlying8(assets_default2, assetSymbols10.BNBx)]: [RedemptionStrategyContract2.UniswapV2LiquidatorFunder, WBNB],
  [underlying8(assets_default2, assetSymbols10.ankrBNB)]: [RedemptionStrategyContract2.AlgebraSwapLiquidator, WBNB],
  [underlying8(assets_default2, assetSymbols10.HAY)]: [RedemptionStrategyContract2.SolidlySwapLiquidator, BUSD],
  [underlying8(assets_default2, assetSymbols10.aWBNB_STKBNB)]: [
    RedemptionStrategyContract2.GammaLpTokenLiquidator,
    underlying8(assets_default2, assetSymbols10.WBNB)
  ],
  [underlying8(assets_default2, assetSymbols10.aANKRBNB_ANKR_N)]: [
    RedemptionStrategyContract2.GammaLpTokenLiquidator,
    underlying8(assets_default2, assetSymbols10.ankrBNB)
  ],
  [underlying8(assets_default2, assetSymbols10.aANKRBNB_ANKR_W)]: [
    RedemptionStrategyContract2.GammaLpTokenLiquidator,
    underlying8(assets_default2, assetSymbols10.ankrBNB)
  ],
  [underlying8(assets_default2, assetSymbols10.aANKRBNB_RDNT_N)]: [
    RedemptionStrategyContract2.GammaLpTokenLiquidator,
    underlying8(assets_default2, assetSymbols10.ankrBNB)
  ],
  [underlying8(assets_default2, assetSymbols10.aANKRBNB_RDNT_W)]: [
    RedemptionStrategyContract2.GammaLpTokenLiquidator,
    underlying8(assets_default2, assetSymbols10.ankrBNB)
  ],
  [underlying8(assets_default2, assetSymbols10.aANKRBNB_THE_N)]: [
    RedemptionStrategyContract2.GammaLpTokenLiquidator,
    underlying8(assets_default2, assetSymbols10.ankrBNB)
  ],
  [underlying8(assets_default2, assetSymbols10.aANKRBNB_THE_W)]: [
    RedemptionStrategyContract2.GammaLpTokenLiquidator,
    underlying8(assets_default2, assetSymbols10.ankrBNB)
  ],
  [underlying8(assets_default2, assetSymbols10.RDNT)]: [
    RedemptionStrategyContract2.AlgebraSwapLiquidator,
    underlying8(assets_default2, assetSymbols10.ankrBNB)
  ]
};
var redemptionStrategies_default2 = redemptionStrategies2;

// src/bsc/index.ts
var chainConfig2 = {
  chainId: SupportedChains5.bsc,
  chainAddresses: addresses_default2,
  assets: assets_default2,
  irms: irms_default2,
  liquidationDefaults: liquidation_default2,
  oracles: oracles_default2,
  specificParams: params_default2,
  deployedPlugins: plugins_default2,
  redemptionStrategies: redemptionStrategies_default2,
  fundingStrategies: fundingStrategies_default2,
  chainDeployments: bsc_default.contracts
};
var bsc_default2 = chainConfig2;

// src/chapel/index.ts
import { SupportedChains as SupportedChains6 } from "@midas-capital/types";

// deployments/chapel.json
var chapel_default = {
  name: "chapel",
  chainId: "97",
  contracts: {
    AddressesProvider: {
      address: "0x38742363597fBaE312B0bdcC351fCc6107E9E27E"
    },
    AddressesProvider_Implementation: {
      address: "0x0173236042B3D1b2B19a185eDdf54B0BCc837345"
    },
    AddressesProvider_Proxy: {
      address: "0x38742363597fBaE312B0bdcC351fCc6107E9E27E"
    },
    CErc20Delegate: {
      address: "0x5b7f3D1615e5306F5C5c587e96Db31751041C1A7"
    },
    CErc20PluginDelegate: {
      address: "0x367CF6D26e071a27c5B2863706790bB97175171D"
    },
    CErc20PluginRewardsDelegate: {
      address: "0x8810eA647510DCd4e28ba7EE14bA13f793443E9E"
    },
    CErc20RewardsDelegate: {
      address: "0x99d485eF4425862D90C8301725DF670c4b8B71EC"
    },
    CEtherDelegate: {
      address: "0x909ED97ed24300CCe7af4533D74312b6fE0DA665"
    },
    CTokenFirstExtension: {
      address: "0x7C3966dfa9b347471cBcDA9AcBfF7ACfBcf08139"
    },
    ChainlinkPriceOracleV2: {
      address: "0x219f7a75925D6d0312d1B62cEE6a4eE6F1A85217"
    },
    ChapelBombERC20: {
      address: "0xe45589fBad3A1FB90F5b2A8A3E8958a8BAB5f768"
    },
    ChapelBombERC20_Implementation: {
      address: "0x54fc377a81D624bDB46e20c025EDa5E8379dced1"
    },
    ChapelBombERC20_Proxy: {
      address: "0xe45589fBad3A1FB90F5b2A8A3E8958a8BAB5f768"
    },
    ChapelRewardsContract: {
      address: "0x535CFc504C37C74948430392dC3E18d90d63e8e2"
    },
    ChapelRewardsERC20: {
      address: "0xf97e8F094c4428e6436b3bf86264D176A2606bC4"
    },
    ChapelRewardsERC20_Implementation: {
      address: "0xE024e3fF90fE820002c2Ff6CA7c2Fb7fA97BE289"
    },
    ChapelRewardsERC20_Proxy: {
      address: "0xf97e8F094c4428e6436b3bf86264D176A2606bC4"
    },
    ChapelRewardsFlywheel: {
      address: "0xed81328eb30fad8806e1E9B53FAaa085282cCb38"
    },
    ChapelRewardsFlywheel_Implementation: {
      address: "0xC8cfBfCE1D07b8017659AED821836a4Fafb4CF4E"
    },
    ChapelRewardsFlywheel_Proxy: {
      address: "0xed81328eb30fad8806e1E9B53FAaa085282cCb38"
    },
    CompoundMarketERC4626_0xc436c7848C6144cf04fa241ac8311864F8572ed3: {
      address: "0xF1264D7F0F88ae2EfE5f83Ce37E949e60d6a84d1"
    },
    CompoundMarketERC4626_0xc436c7848C6144cf04fa241ac8311864F8572ed3_Implementation: {
      address: "0xE40C30FB469edE8d7F188Ec49300d283f544C09A"
    },
    CompoundMarketERC4626_0xc436c7848C6144cf04fa241ac8311864F8572ed3_Proxy: {
      address: "0xF1264D7F0F88ae2EfE5f83Ce37E949e60d6a84d1"
    },
    CompoundMarketERC4626_0xddA148e5917A1c2DCfF98139aBBaa41636840830: {
      address: "0x239218130dCBe0298d7ec4d2F96f4696Fe118624"
    },
    CompoundMarketERC4626_0xddA148e5917A1c2DCfF98139aBBaa41636840830_Implementation: {
      address: "0x6684b0A4B8363A43fbF4f6D022436B4554F8B4B2"
    },
    CompoundMarketERC4626_0xddA148e5917A1c2DCfF98139aBBaa41636840830_Proxy: {
      address: "0x239218130dCBe0298d7ec4d2F96f4696Fe118624"
    },
    CompoundMarketERC4626_0xfa60851E76728eb31EFeA660937cD535C887fDbD: {
      address: "0x3379523a4ef49B19f411123053D455D5eC21C0aA"
    },
    CompoundMarketERC4626_0xfa60851E76728eb31EFeA660937cD535C887fDbD_Implementation: {
      address: "0xbbfBF2aa74c469282fFA087Dc6b15E30934f4A44"
    },
    CompoundMarketERC4626_0xfa60851E76728eb31EFeA660937cD535C887fDbD_Proxy: {
      address: "0x3379523a4ef49B19f411123053D455D5eC21C0aA"
    },
    Comptroller: {
      address: "0x4682b914C351830e9e289C02ec312e453f017eE2"
    },
    ComptrollerFirstExtension: {
      address: "0xeAc9AD4C7266E72D26336E9a0703f6c4Fee40d12"
    },
    DefaultProxyAdmin: {
      address: "0x0C64dEFA0125678C77dDa2287FDD6000699737Cc"
    },
    FixedNativePriceOracle: {
      address: "0x2d89ff22d1DA1b5330912c54E90FF81279D73E19"
    },
    FuseFeeDistributor: {
      address: "0xFc1f56C58286E7215701A773b61bFf2e18A177dE"
    },
    FuseFeeDistributor_Implementation: {
      address: "0xF18EB6556E1704d5D4641ce9b49290C804939c12"
    },
    FuseFeeDistributor_Proxy: {
      address: "0xFc1f56C58286E7215701A773b61bFf2e18A177dE"
    },
    FuseFlywheelLensRouter: {
      address: "0x970533836c4BF475EEEc0d49cDfde97051B35D89"
    },
    FuseFlywheelLensRouter_Implementation: {
      address: "0x70FaF0FBa62CC230B84999814505F84c0E0cfDcd"
    },
    FuseFlywheelLensRouter_Proxy: {
      address: "0x9Ae3C01b225459dBF7108b1e8386633ecc745036"
    },
    FusePoolDirectory: {
      address: "0x295d7347606F4bd810C8296bb8d75D657001fcf7"
    },
    FusePoolDirectory_Implementation: {
      address: "0xe57cbBb65a85016F308dEC0EBa02eA1430c41721"
    },
    FusePoolDirectory_Proxy: {
      address: "0x295d7347606F4bd810C8296bb8d75D657001fcf7"
    },
    FusePoolLens: {
      address: "0xD880d5D33221F3992E695f5C6bFBC558e9Ad31cF"
    },
    FusePoolLensSecondary: {
      address: "0x93c10cC75558c166E9b42412eC407B7012b98031"
    },
    FusePoolLensSecondary_Implementation: {
      address: "0xbC4eeEE763a0B348080bABF3E6a0cE6d648DdC81"
    },
    FusePoolLensSecondary_Proxy: {
      address: "0x4B68509296e33e47d6A09D76F75500920cc1a006"
    },
    FusePoolLens_Implementation: {
      address: "0x879481181804Db8c43FA7Ab21817c196F50Ac6C6"
    },
    FusePoolLens_Proxy: {
      address: "0xFed496831E71794D746A35Ad8feef57885e6CdCB"
    },
    FuseSafeLiquidator: {
      address: "0xc9C3D317E89f4390A564D56180bBB1842CF3c99C"
    },
    FuseSafeLiquidator_Implementation: {
      address: "0xeA197a889EBBe66544CAE881c222e708Ca1124Df"
    },
    FuseSafeLiquidator_Proxy: {
      address: "0xc9C3D317E89f4390A564D56180bBB1842CF3c99C"
    },
    JumpRateModel: {
      address: "0x212f88Ba5927b6cDcA498E0BA20541EF35864961"
    },
    LeveredPositionFactory: {
      address: "0xE55a7dd52D4e0577B1a8bbfeA7246d83b85A5B56"
    },
    LeveredPositionFactoryExtension: {
      address: "0xa63714AD142885858b3820BC1d33605A76FD9A95"
    },
    LeveredPositionsLens: {
      address: "0x1023e4f4cC27E59620c7D42c6fE72f509fe26a54"
    },
    LeveredPositionsLens_Implementation: {
      address: "0xfd4206644f5A8A6714583361bc2a3D970CB8F733"
    },
    LeveredPositionsLens_Proxy: {
      address: "0x1023e4f4cC27E59620c7D42c6fE72f509fe26a54"
    },
    LiquidatorsRegistry: {
      address: "0x074f1427cD25469F1051F51908751dA5409F3677"
    },
    LiquidatorsRegistryExtension: {
      address: "0x8CA26295eC319ee163221dd3b417592639FeF12c"
    },
    LooplessFlywheelBooster: {
      address: "0x8ac339fB1857cc2986941C50c4234EfC47F73424"
    },
    MasterPriceOracle: {
      address: "0x429041250873643235cb3788871447c6fF3205aA"
    },
    MasterPriceOracle_Implementation: {
      address: "0xdDCe51cdDB930ef019B086D9A9E0b0801e7c3970"
    },
    MasterPriceOracle_Proxy: {
      address: "0x429041250873643235cb3788871447c6fF3205aA"
    },
    MidasFlywheelLensRouter: {
      address: "0x9B4910cEAfb03056DddF9A1CB284C58f1d85d8A4"
    },
    MidasFlywheel_Implementation: {
      address: "0x7b08D19832eD5Ec09b4898eA928991ea7b145457"
    },
    MockERC4626_Mock_BUSD: {
      address: "0x865058A4aF2f70671C10333f21e2B3A8Ce9fB032"
    },
    OptimizedAPRVaultFirstExtension: {
      address: "0x677c9Eb810096C9F630AAd1Aaa6b1429C5e9FC44"
    },
    OptimizedAPRVaultSecondExtension: {
      address: "0xEe9Db38cbd8F7a32525C3ba7604FFF2327aac531"
    },
    OptimizedAPRVault_TBOMB_0xe45589fBad3A1FB90F5b2A8A3E8958a8BAB5f768: {
      address: "0x2dDa49e54e9EccaE1a1B7E493b787310483Dbb71"
    },
    OptimizedAPRVault_WBNB_0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd: {
      address: "0x6204027E412F7102E03d72C53a36B2C188d7048D"
    },
    OptimizedVaultsRegistry: {
      address: "0x353195Bdd4917e1Bdabc9809Dc3E8528b3421FF5"
    },
    OptimizedVaultsRegistry_Implementation: {
      address: "0x41dF5341e0F44C225b9cfaa723787A29DBe6b5c4"
    },
    OptimizedVaultsRegistry_Proxy: {
      address: "0x353195Bdd4917e1Bdabc9809Dc3E8528b3421FF5"
    },
    SimplePriceOracle: {
      address: "0xDdD3cf9407a12A6360281d244cb100ABe7fB561f"
    },
    SimplePriceOracle_Implementation: {
      address: "0xf585D1811d21414ad6f6adA0D8Dd20fb95B491cf"
    },
    SimplePriceOracle_Proxy: {
      address: "0xDdD3cf9407a12A6360281d244cb100ABe7fB561f"
    },
    TestingDAI: {
      address: "0x8870f7102F1DcB1c35b01af10f1baF1B00aD6805"
    },
    TestingStable: {
      address: "0x4f1885D25eF219D3D4Fa064809D6D4985FAb9A0b"
    },
    UniswapLpTokenLiquidator: {
      address: "0x3e9543F2B0b0DD72F8A85E8aBC4A8cDA3Eb5E14C"
    },
    UniswapLpTokenPriceOracle: {
      address: "0x457B2dc7CEd1956B6f97136cB70fc45F40865b1A"
    },
    UniswapTwapPriceOracleV2: {
      address: "0x2eb20C345272a33311FBe5943c8cc157088b8749"
    },
    UniswapTwapPriceOracleV2Factory: {
      address: "0x8F3586b2B350dbe1a0397811f424BdA9559A90E0"
    },
    UniswapTwapPriceOracleV2Resolver: {
      address: "0xe974F10a9B0533c7472a3d895DdBA5b9352D5bc5"
    },
    UniswapTwapPriceOracleV2Root: {
      address: "0xDF8d9323E1e8eB7C7d3282eF4f6B1454578aE394"
    },
    WhitePaperInterestRateModel: {
      address: "0xFe44E528caF2F391dEBA6fd5beaa5d530b6bf8A3"
    },
    WithdrawableFlywheelStaticRewards_TRT: {
      address: "0xF7a040Af0a2bd81Da65cC7692DCa3af23E64D982"
    },
    XBombLiquidatorFunder: {
      address: "0xC875a8D8E8a593953115131697a788faEAa37109"
    },
    XBombSwapTDai: {
      address: "0xd816eb4660615BBF080ddf425F28ea4AF30d04D5"
    },
    XBombSwapTUSD: {
      address: "0x161FbE0943Af4A39a50262026A81a243B635982d"
    }
  }
};

// src/chapel/addresses.ts
import { assetSymbols as assetSymbols12, underlying as underlying9 } from "@midas-capital/types";
import { ethers as ethers4 } from "ethers";

// src/chapel/assets.ts
import { assetSymbols as assetSymbols11, OracleTypes as OracleTypes5 } from "@midas-capital/types";
var assets3 = [
  {
    symbol: assetSymbols11.WBNB,
    underlying: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
    name: "Wrapped BNB ",
    decimals: 18,
    oracle: OracleTypes5.FixedNativePriceOracle
  },
  {
    symbol: assetSymbols11.BUSD,
    underlying: "0x78867BbEeF44f2326bF8DDd1941a4439382EF2A7",
    name: "Binance USD",
    decimals: 18,
    oracle: OracleTypes5.ChainlinkPriceOracleV2
  },
  {
    symbol: assetSymbols11.BTCB,
    underlying: "0x6ce8dA28E2f864420840cF74474eFf5fD80E65B8",
    name: "Binance BTC",
    decimals: 18,
    oracle: OracleTypes5.ChainlinkPriceOracleV2
  },
  {
    symbol: assetSymbols11.DAI,
    underlying: "0x8a9424745056Eb399FD19a0EC26A14316684e274",
    name: "Binance DAI",
    decimals: 18,
    oracle: OracleTypes5.ChainlinkPriceOracleV2
  },
  {
    symbol: assetSymbols11.ETH,
    underlying: "0x8BaBbB98678facC7342735486C851ABD7A0d17Ca",
    name: "Binance ETH",
    decimals: 18,
    oracle: OracleTypes5.ChainlinkPriceOracleV2
  },
  {
    symbol: assetSymbols11.USDT,
    underlying: "0x7ef95a0FEE0Dd31b22626fA2e10Ee6A223F8a684",
    name: "Tether USD",
    decimals: 18,
    oracle: OracleTypes5.ChainlinkPriceOracleV2
  },
  {
    symbol: assetSymbols11.SAFEMOON,
    underlying: "0xDAcbdeCc2992a63390d108e8507B98c7E2B5584a",
    name: "SafeMoon",
    decimals: 9,
    oracle: OracleTypes5.UniswapTwapPriceOracleV2
  },
  {
    symbol: assetSymbols11["WBNB-BUSD"],
    underlying: "0xe0e92035077c39594793e61802a350347c320cf2",
    name: "WBNB-BUSD PCS LP",
    decimals: 18,
    oracle: OracleTypes5.UniswapLpTokenPriceOracle
  },
  {
    symbol: assetSymbols11["WBNB-DAI"],
    underlying: "0xAE4C99935B1AA0e76900e86cD155BFA63aB77A2a",
    name: "WBNB-DAI PCS LP",
    decimals: 18,
    oracle: OracleTypes5.UniswapLpTokenPriceOracle
  },
  {
    symbol: assetSymbols11["BUSD-USDT"],
    underlying: "0x5126C1B8b4368c6F07292932451230Ba53a6eB7A",
    name: "WBNB-DAI PCS LP",
    decimals: 18,
    oracle: OracleTypes5.UniswapLpTokenPriceOracle
  },
  {
    symbol: assetSymbols11.BOMB,
    underlying: "0xe45589fBad3A1FB90F5b2A8A3E8958a8BAB5f768",
    name: "Testing Bomb",
    decimals: 18,
    oracle: OracleTypes5.SimplePriceOracle
  },
  {
    symbol: assetSymbols11.TDAI,
    underlying: "0x8870f7102F1DcB1c35b01af10f1baF1B00aD6805",
    name: "Testing DAI",
    decimals: 18,
    oracle: OracleTypes5.SimplePriceOracle
  },
  {
    symbol: assetSymbols11.TUSD,
    underlying: "0x4f1885D25eF219D3D4Fa064809D6D4985FAb9A0b",
    name: "Testing USD",
    decimals: 18,
    oracle: OracleTypes5.SimplePriceOracle
  }
];
var assets_default3 = assets3;

// src/chapel/addresses.ts
var chainAddresses3 = {
  W_TOKEN: underlying9(assets3, assetSymbols12.WBNB),
  W_TOKEN_USD_CHAINLINK_PRICE_FEED: "0x2514895c72f50D8bd4B4F9b1110F0D6bD2c97526",
  UNISWAP_V2_ROUTER: "0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3",
  UNISWAP_V2_FACTORY: "0xB7926C0430Afb07AA7DEfDE6DA862aE0Bde767bc",
  PAIR_INIT_HASH: ethers4.utils.hexlify("0xecba335299a6693cb2ebc4782e74669b84290b6378ea3a3873c7231a8d7d1074"),
  STABLE_TOKEN: underlying9(assets3, assetSymbols12.BUSD),
  W_BTC_TOKEN: underlying9(assets3, assetSymbols12.BTCB)
};
var addresses_default3 = chainAddresses3;

// src/chapel/fundingStrategies.ts
var fundingStrategies3 = {};
var fundingStrategies_default3 = fundingStrategies3;

// src/chapel/irms.ts
import { IrmTypes as IrmTypes3 } from "@midas-capital/types";
var baseIrms3 = [IrmTypes3.WhitePaperInterestRateModel, IrmTypes3.JumpRateModel];
var irms3 = [...baseIrms3];
var irms_default3 = irms3;

// src/chapel/liquidation.ts
import { assetSymbols as assetSymbols13, LiquidationStrategy as LiquidationStrategy3 } from "@midas-capital/types";
import { BigNumber as BigNumber5, constants as constants3 } from "ethers";
var liquidationDefaults3 = {
  DEFAULT_ROUTER: addresses_default3.UNISWAP_V2_ROUTER,
  ASSET_SPECIFIC_ROUTER: {},
  SUPPORTED_OUTPUT_CURRENCIES: [
    constants3.AddressZero,
    assets3.find((a) => a.symbol === assetSymbols13.WBNB).underlying
  ],
  SUPPORTED_INPUT_CURRENCIES: [
    constants3.AddressZero,
    assets3.find((a) => a.symbol === assetSymbols13.WBNB).underlying
  ],
  LIQUIDATION_STRATEGY: LiquidationStrategy3.UNISWAP,
  MINIMUM_PROFIT_NATIVE: BigNumber5.from(0),
  LIQUIDATION_INTERVAL_SECONDS: 60,
  jarvisPools: [],
  balancerPools: []
};
var liquidation_default3 = liquidationDefaults3;

// src/chapel/oracles.ts
import { OracleTypes as OracleTypes6 } from "@midas-capital/types";
var baseOracles3 = [OracleTypes6.FixedNativePriceOracle, OracleTypes6.MasterPriceOracle, OracleTypes6.SimplePriceOracle];
var oracles3 = [
  ...baseOracles3,
  OracleTypes6.ChainlinkPriceOracleV2,
  OracleTypes6.UniswapTwapPriceOracleV2
];
var oracles_default3 = oracles3;

// src/chapel/params.ts
import { assetSymbols as assetSymbols14 } from "@midas-capital/types";
import { BigNumber as BigNumber6 } from "ethers";
var specificParams3 = {
  blocksPerYear: BigNumber6.from((20 * 24 * 365 * 60).toString()),
  cgId: "binancecoin-chapel",
  metadata: {
    chainIdHex: "0x61",
    name: "BSC Testnet (Chapel)",
    shortName: "BSC Testnet",
    img: "https://d1912tcoux65lj.cloudfront.net/network/chapel.jpg",
    rpcUrls: {
      default: { http: ["https://data-seed-prebsc-1-s1.binance.org:8545/"] },
      public: { http: ["https://data-seed-prebsc-1-s1.binance.org:8545/"] }
    },
    blockExplorerUrls: { default: { name: "BscScan(Testnet)", url: "https://testnet.bscscan.com" } },
    nativeCurrency: {
      symbol: "BNB",
      name: "BSC"
    },
    wrappedNativeCurrency: {
      symbol: assetSymbols14.WBNB,
      address: addresses_default3.W_TOKEN,
      name: "BSC",
      decimals: 18,
      color: "#627EEA",
      overlayTextColor: "#fff",
      logoURL: "https://d1912tcoux65lj.cloudfront.net/network/chapel.jpg"
    },
    testnet: true
  }
};
var params_default3 = specificParams3;

// src/chapel/plugins.ts
var deployedPlugins3 = {};
var plugins_default3 = deployedPlugins3;

// src/chapel/redemptionStrategies.ts
import { assetSymbols as assetSymbols15, RedemptionStrategyContract as RedemptionStrategyContract3, underlying as underlying10 } from "@midas-capital/types";
var redemptionStrategies3 = {
  [underlying10(assets3, assetSymbols15["WBNB-BUSD"])]: [
    RedemptionStrategyContract3.UniswapLpTokenLiquidator,
    underlying10(assets3, assetSymbols15.WBNB)
  ],
  [underlying10(assets3, assetSymbols15.BOMB)]: [
    RedemptionStrategyContract3.XBombLiquidatorFunder,
    underlying10(assets3, assetSymbols15.TUSD)
  ],
  [underlying10(assets3, assetSymbols15.BOMB)]: [
    RedemptionStrategyContract3.XBombLiquidatorFunder,
    underlying10(assets3, assetSymbols15.TDAI)
  ],
  [underlying10(assets3, assetSymbols15.TUSD)]: [
    RedemptionStrategyContract3.XBombLiquidatorFunder,
    underlying10(assets3, assetSymbols15.BOMB)
  ],
  [underlying10(assets3, assetSymbols15.TDAI)]: [
    RedemptionStrategyContract3.XBombLiquidatorFunder,
    underlying10(assets3, assetSymbols15.BOMB)
  ]
};
var redemptionStrategies_default3 = redemptionStrategies3;

// src/chapel/index.ts
var chainConfig3 = {
  chainId: SupportedChains6.chapel,
  chainAddresses: addresses_default3,
  assets: assets_default3,
  irms: irms_default3,
  liquidationDefaults: liquidation_default3,
  oracles: oracles_default3,
  specificParams: params_default3,
  deployedPlugins: plugins_default3,
  redemptionStrategies: redemptionStrategies_default3,
  fundingStrategies: fundingStrategies_default3,
  chainDeployments: chapel_default.contracts
};
var chapel_default2 = chainConfig3;

// src/ethereum/index.ts
import { SupportedChains as SupportedChains8 } from "@midas-capital/types";

// deployments/ethereum.json
var ethereum_default = {
  name: "ethereum",
  chainId: "1",
  contracts: {
    AddressesProvider: {
      address: "0x863D4F7FA3618fb1D4A94F28B5b74a30c337b9C7"
    },
    AddressesProvider_Implementation: {
      address: "0x0e9AF6E9211d643fBF7413a65dB240f2cBfC5299"
    },
    AddressesProvider_Proxy: {
      address: "0x863D4F7FA3618fb1D4A94F28B5b74a30c337b9C7"
    },
    BalancerLpLinearPoolPriceOracle: {
      address: "0xdA2A0f6Bc88d5A023A04b8E59C36DE8CC2f828a4"
    },
    BalancerLpLinearPoolPriceOracle_Implementation: {
      address: "0x8A180Fd239E15652CE574e3601e43621FB9C0D94"
    },
    BalancerLpLinearPoolPriceOracle_Proxy: {
      address: "0xdA2A0f6Bc88d5A023A04b8E59C36DE8CC2f828a4"
    },
    BalancerLpStablePoolPriceOracle: {
      address: "0x923fcFd41e755DfECD8CA36a5e53AA05Ea1c357f"
    },
    BalancerLpStablePoolPriceOracle_Implementation: {
      address: "0xF1264D7F0F88ae2EfE5f83Ce37E949e60d6a84d1"
    },
    BalancerLpStablePoolPriceOracle_Proxy: {
      address: "0x923fcFd41e755DfECD8CA36a5e53AA05Ea1c357f"
    },
    BalancerLpTokenLiquidator: {
      address: "0x7A9Bd621067aC42327d93Ea9C3219E23BF4dB35A"
    },
    BalancerLpTokenPriceOracle: {
      address: "0x1F8e3bcD247674EcB7B6ef97771c2C98b5e2b5Fa"
    },
    BalancerLpTokenPriceOracle_Implementation: {
      address: "0xB5ea8d2913ccF26380a6690fA59C7631403c7d65"
    },
    BalancerLpTokenPriceOracle_Proxy: {
      address: "0x1F8e3bcD247674EcB7B6ef97771c2C98b5e2b5Fa"
    },
    BalancerSwapLiquidator: {
      address: "0x58d7840319ab12fFE1f4A43741F6ad30288DD3Ad"
    },
    CErc20Delegate: {
      address: "0xFfcD5BeC57EbF1B9AC513D1340c13a3A9d2a053e"
    },
    CErc20PluginDelegate: {
      address: "0x0b7c55eD8f7Bc25c20eA4FdFbfED79d4AeB9aCaE"
    },
    CErc20PluginRewardsDelegate: {
      address: "0x57F4dA24e95DCb26A58b45CecaCeaE858215e1C2"
    },
    CTokenFirstExtension: {
      address: "0x6c66030a8431C7e2973D63340F5fF95889dce5f9"
    },
    ChainlinkPriceOracleV2: {
      address: "0x199e1b4354604b8a6f8d1a8453d6db9cb4d705ed"
    },
    Comptroller: {
      address: "0x20c0D39eFDDf10cccB6CB0b2d4D16cc6B17CB7Fd"
    },
    ComptrollerFirstExtension: {
      address: "0x586250C13C174e9538eD07b4261264B0fa32C968"
    },
    CurveLpTokenLiquidatorNoRegistry: {
      address: "0xe8AbB29778D3f707CcbA10f43160B1bA3e471587"
    },
    CurveSwapLiquidator: {
      address: "0x1cCb35F33096a116D498f2745aef1303B5450363"
    },
    CurveSwapLiquidatorFunder: {
      address: "0x776FB8827bd7Fec1D589612Becc64720B7964c88"
    },
    CurveV2PriceOracle: {
      address: "0x0a6ad1A578A1E3bbd954168FF819B7C73674FfD0"
    },
    CurveV2PriceOracle_Implementation: {
      address: "0xEB63CE86Caf06CbB6387F9822c13c629B31b6064"
    },
    CurveV2PriceOracle_Proxy: {
      address: "0x0a6ad1A578A1E3bbd954168FF819B7C73674FfD0"
    },
    DefaultProxyAdmin: {
      address: "0xF68095f787CA0fA9362954afd04cE0E7063a1dC1"
    },
    DiaPriceOracle: {
      address: "0x968188FD4Bc26D120368Cec1410677B035848301"
    },
    ERC4626Liquidator: {
      address: "0x489B57744223e80Fbd5a7C4dD45F9fbd04333962"
    },
    ERC4626Oracle: {
      address: "0x97D767EDe0eEC19f8CB4f1c136939642740C40be"
    },
    ERC4626Oracle_Implementation: {
      address: "0x1DFB70941e7f2Df56af515012B3537a2239F637A"
    },
    ERC4626Oracle_Proxy: {
      address: "0x97D767EDe0eEC19f8CB4f1c136939642740C40be"
    },
    FixedNativePriceOracle: {
      address: "0x7Ac4e3a34Cb7f5260eA365Bef301dDcc691E7712"
    },
    FuseFeeDistributor: {
      address: "0xC033a75057C1102Bf54Df6DE75D2E9aBa8E745A2"
    },
    FuseFeeDistributor_Implementation: {
      address: "0x8E9f21C59A6E63e5F08a6688a02b55042158f0B5"
    },
    FuseFeeDistributor_Proxy: {
      address: "0xC033a75057C1102Bf54Df6DE75D2E9aBa8E745A2"
    },
    FusePoolDirectory: {
      address: "0x684873B5c1DA3AE5474BEE8C8e0Dd1d25ddedA09"
    },
    FusePoolDirectory_Implementation: {
      address: "0x840D8E074b5f5def94c9A1AE58c79a45f9a7CF7B"
    },
    FusePoolDirectory_Proxy: {
      address: "0x684873B5c1DA3AE5474BEE8C8e0Dd1d25ddedA09"
    },
    FusePoolLens: {
      address: "0xD7225110D8F419b0E8Ad0A536977965E62fB5769"
    },
    FusePoolLensSecondary: {
      address: "0xDdD3cf9407a12A6360281d244cb100ABe7fB561f"
    },
    FuseSafeLiquidator: {
      address: "0xF073bc39fC443d13F402D559cF115768Faff7a47"
    },
    FuseSafeLiquidator_Implementation: {
      address: "0x939321650F2a112d51B29d0445cd828749ac46f4"
    },
    FuseSafeLiquidator_Proxy: {
      address: "0xF073bc39fC443d13F402D559cF115768Faff7a47"
    },
    JumpRateModel: {
      address: "0x1Fe3e112fC2CbB0f45436F056f83019B138B3575"
    },
    LiquidatorsRegistry: {
      address: "0xD439f3fCCBBeB0eaa03E43538b72764684ee07Bd"
    },
    LiquidatorsRegistryExtension: {
      address: "0x66397a7BD91650e260a605F68F2d46F3350f2a1B"
    },
    LooplessFlywheelBooster: {
      address: "0x7ef2A6a6aF8ee090da3a24ea947b3717c4D16D94"
    },
    MasterPriceOracle: {
      address: "0xdD8d4e09Acb39C2B4DE9A84384389B79850f3271"
    },
    MasterPriceOracle_Implementation: {
      address: "0xF9C40b861D7c7a98f13d075baDc540814cf0CAD9"
    },
    MasterPriceOracle_Proxy: {
      address: "0xdD8d4e09Acb39C2B4DE9A84384389B79850f3271"
    },
    MidasFlywheelLensRouter: {
      address: "0x5670E9d607666a8341313DA477B0220E70c98370"
    },
    Quoter: {
      address: "0x2a664207F499496498546aDc9B73d5663171e5D4"
    },
    SimplePriceOracle: {
      address: "0x08690Ef71647C80a96fe98d64Afdf79C347AC1d3"
    },
    SimplePriceOracle_Implementation: {
      address: "0xA5B90C282f7F2220ae0A2115c10BEdC5A07a5980"
    },
    SimplePriceOracle_Proxy: {
      address: "0x08690Ef71647C80a96fe98d64Afdf79C347AC1d3"
    },
    UniswapV3Liquidator: {
      address: "0xB077105Fdf4eb4D1f3360f1CfF8B10398075eE8c"
    },
    UniswapV3LiquidatorFunder: {
      address: "0x01375b03Be806e608b2C7Cb93373d01161Cf18f2"
    },
    UniswapV3PriceOracle: {
      address: "0x71b3eDbD5120Be61F72D9DfCc6AE2EDbA01bee4E"
    },
    UniswapV3PriceOracle_Implementation: {
      address: "0xacA047108bAc3b2cf62cccF53b94F0b1477203DF"
    },
    UniswapV3PriceOracle_Proxy: {
      address: "0x71b3eDbD5120Be61F72D9DfCc6AE2EDbA01bee4E"
    },
    WSTEthPriceOracle: {
      address: "0xf42f6A5cBda6e3601E25e4D19459dBd918661695"
    },
    WSTEthPriceOracle_Implementation: {
      address: "0xeAA782fA732FEf5A5B127EA70d1b656216717353"
    },
    WSTEthPriceOracle_Proxy: {
      address: "0xf42f6A5cBda6e3601E25e4D19459dBd918661695"
    },
    WhitePaperInterestRateModel: {
      address: "0x5a2F2D31484ce01c106C445E5333E54274420135"
    }
  }
};

// src/ethereum/addresses.ts
import { assetSymbols as assetSymbols17, underlying as underlying11 } from "@midas-capital/types";
import { ethers as ethers5 } from "ethers";

// src/ethereum/assets.ts
import { assetSymbols as assetSymbols16, OracleTypes as OracleTypes7, SupportedChains as SupportedChains7 } from "@midas-capital/types";
var WBTC2 = "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599";
var USDC3 = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48";
var WETH2 = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";
var wstETH2 = "0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0";
var DAI3 = "0x6B175474E89094C44Da98b954EedeAC495271d0F";
var BAL2 = "0xba100000625a3754423978a60c9317c58a424e3D";
var PAR = "0x68037790A0229e9Ce6EaA8A99ea92964106C4703";
var USDT3 = "0xdAC17F958D2ee523a2206206994597C13D831ec7";
var eUSD = "0x97de57eC338AB5d51557DA3434828C5DbFaDA371";
var GOHM2 = "0x0ab87046fBb341D058F17CBC4c1133F25a20a52f";
var OHM2 = "0x64aa3364F17a4D01c6f1751Fd97C2BD3D7e7f1D5";
var FRAX3 = "0x853d955aCEf822Db058eb8505911ED77F175b99e";
var MIM = "0x99D8a9C45b2ecA8864373A26D1459e3Dff1e17F3";
var frxETH = "0x5E8422345238F34275888049021821E8E08CAa1f";
var stETH = "0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84";
var swETH = "0xf951E335afb289353dc249e82926178EaC7DEd78";
var cbETH = "0xBe9895146f7AF43049ca1c1AE358B0541Ea49704";
var rETH = "0xae78736Cd615f374D3085123A210448E74Fc6393";
var ANKR2 = "0x8290333ceF9e6D528dD5618Fb97a76f268f3EDD4";
var SWETH_BBA_WETH_BPT = "0x02D928E68D8F10C0358566152677Db51E1e2Dc8C";
var WSTETH_WETH_STABLE_BPT = "0x32296969Ef14EB0c6d29669C550D4a0449130230";
var WSTETH_RETH_FRXETH_STABLE_BPT = "0x5aEe1e99fE86960377DE9f88689616916D5DcaBe";
var WBETH_WSTETH_STABLE_BPT = "0x2E848426AEc6dbF2260535a5bEa048ed94d9FF3D";
var WSTETH_CBETH_STABLE_BPT = "0x9c6d47Ff73e0F5E51BE5FD53236e3F595C5793F2";
var OHM50_DAI50_BPT = "0x76FCf0e8C7Ff37A47a799FA2cd4c13cDe0D981C9";
var OHM50_WETH50_BPT = "0xD1eC5e215E8148D76F4460e4097FD3d5ae0A3558";
var AAVE_BOOSTED_STABLE_BPT = "0xfeBb0bbf162E64fb9D0dfe186E517d84C395f016";
var AAVE_LINEAR_DAI = "0x6667c6fa9f2b3Fc1Cc8D85320b62703d938E4385";
var AAVE_LINEAR_USDT = "0xA1697F9Af0875B63DdC472d6EeBADa8C1fAB8568";
var AAVE_LINEAR_USDC = "0xcbFA4532D8B2ade2C261D3DD5ef2A2284f792692";
var AAVE_LINEAR_WETH = "0x60D604890feaa0b5460B28A424407c24fe89374a";
var realYieldUSD = "0x97e6E0a40a3D02F12d1cEC30ebfbAE04e37C119E";
var realYieldETH = "0xb5b29320d2Dde5BA5BAFA1EbcD270052070483ec";
var ethBtcTrend = "0x6b7f87279982d919Bbf85182DDeAB179B366D8f2";
var assets4 = [
  {
    symbol: assetSymbols16.BAL,
    underlying: BAL2,
    name: "Balancer",
    decimals: 18,
    oracle: OracleTypes7.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://etherscan.io", BAL2)
  },
  {
    symbol: assetSymbols16.DAI,
    underlying: DAI3,
    name: "Dai Stablecoin",
    decimals: 18,
    oracle: OracleTypes7.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://etherscan.io", DAI3)
  },
  {
    symbol: assetSymbols16.FRAX,
    underlying: FRAX3,
    name: "Frax",
    decimals: 18,
    oracle: OracleTypes7.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://etherscan.io", FRAX3)
  },
  {
    symbol: assetSymbols16.MIM,
    underlying: MIM,
    name: "Magic Internet Money",
    decimals: 18,
    oracle: OracleTypes7.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://etherscan.io", MIM)
  },
  {
    symbol: assetSymbols16.GOHM,
    underlying: GOHM2,
    name: "Governance OHM",
    decimals: 18,
    oracle: OracleTypes7.UniswapV3PriceOracle,
    extraDocs: defaultDocs("https://etherscan.io", GOHM2)
  },
  {
    symbol: assetSymbols16.OHM,
    underlying: GOHM2,
    name: "Olympus V2",
    decimals: 18,
    oracle: OracleTypes7.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://etherscan.io", OHM2)
  },
  {
    symbol: assetSymbols16.PAR,
    underlying: PAR,
    name: "PAR Stablecoin",
    decimals: 18,
    oracle: OracleTypes7.UniswapV3PriceOracle,
    extraDocs: defaultDocs("https://etherscan.io", PAR)
  },
  {
    symbol: assetSymbols16.ANKR,
    underlying: ANKR2,
    name: "Ankr Network Token",
    decimals: 18,
    oracle: OracleTypes7.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://etherscan.io", ANKR2)
  },
  {
    symbol: assetSymbols16.USDC,
    underlying: USDC3,
    name: "USD Coin",
    decimals: 6,
    oracle: OracleTypes7.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://etherscan.io", USDC3)
  },
  {
    symbol: assetSymbols16.USDT,
    underlying: USDT3,
    name: "Tether USD",
    decimals: 6,
    oracle: OracleTypes7.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://etherscan.io", USDT3)
  },
  {
    symbol: assetSymbols16.eUSD,
    underlying: eUSD,
    name: "eUSD",
    decimals: 18,
    oracle: OracleTypes7.CurveV2PriceOracle,
    extraDocs: defaultDocs("https://etherscan.io", eUSD)
  },
  {
    symbol: assetSymbols16.WBTC,
    underlying: WBTC2,
    name: "Wrapped BTC",
    decimals: 8,
    oracle: OracleTypes7.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://etherscan.io", WBTC2)
  },
  {
    symbol: assetSymbols16.WETH,
    underlying: WETH2,
    name: "Wrapped Ether",
    decimals: 18,
    oracle: OracleTypes7.FixedNativePriceOracle,
    extraDocs: wrappedAssetDocs(SupportedChains7.ethereum)
  },
  {
    symbol: assetSymbols16.stETH,
    underlying: stETH,
    name: "Lido Staked Ether",
    decimals: 18,
    oracle: OracleTypes7.ChainlinkPriceOracleV2,
    extraDocs: `
    <p><b>How to acquire this token</b><p/><br />
    <p>You can get stETH by staking your ETH </code> on <a href="https://stake.lido.fi/" target="_blank" style="color: #BCAC83; cursor="pointer">Lido on Mainnet</a></p>`
  },
  {
    symbol: assetSymbols16.wstETH,
    underlying: wstETH2,
    name: "Wrapped Staked Ether",
    decimals: 18,
    oracle: OracleTypes7.WSTEthPriceOracle,
    extraDocs: `
    <p><b>How to acquire this token</b><p/><br />
    <p>You can get wstETH by wrapping your stETH </code> on <a href="https://stake.lido.fi/wrap" target="_blank" style="color: #BCAC83; cursor="pointer">Lido on Mainnet</a></p>`
  },
  {
    symbol: assetSymbols16.rETH,
    underlying: rETH,
    name: "Rocket Pool ETH",
    decimals: 18,
    oracle: OracleTypes7.ChainlinkPriceOracleV2,
    extraDocs: `
    <p><b>How to acquire this token</b><p/><br />
    <p>You can get rETH by staking your ETH </code> on <a href="https://stake.rocketpool.net/" target="_blank" style="color: #BCAC83; cursor="pointer">RocketPool on Mainnet</a></p>`
  },
  {
    symbol: assetSymbols16.cbETH,
    underlying: cbETH,
    name: "Coinbase Staked ETH",
    decimals: 18,
    oracle: OracleTypes7.ChainlinkPriceOracleV2,
    extraDocs: `
    <p><b>How to acquire this token</b><p/><br />
    <p>You can get cbETH by staking your ETH </code> on <a href="https://www.coinbase.com/earn" target="_blank" style="color: #BCAC83; cursor="pointer">Coinbase</a></p>`
  },
  {
    symbol: assetSymbols16.swETH,
    underlying: swETH,
    name: "Swell ETH",
    decimals: 18,
    oracle: OracleTypes7.DiaPriceOracle,
    extraDocs: `
    <p><b>How to acquire this token</b><p/><br />
    <p>You can get swETH by staking your ETH </code> on <a href="https://app.swellnetwork.io/" target="_blank" style="color: #BCAC83; cursor="pointer">Swell</a></p>`
  },
  {
    symbol: assetSymbols16.frxETH,
    underlying: frxETH,
    name: "Frax ETH",
    decimals: 18,
    oracle: OracleTypes7.CurveV2PriceOracle,
    extraDocs: `
    <p><b>How to acquire this token</b><p/><br />
    <p>You can get frxETH by minting it with your ETH </code> on <a href="https://app.frax.finance/frxeth/mint" target="_blank" style="color: #BCAC83; cursor="pointer">Frax Finance</a></p>`
  },
  // Sommelier Finance
  {
    symbol: assetSymbols16.realYieldUSD,
    underlying: realYieldUSD,
    name: "Sommelier Finance Real Yield USD",
    decimals: 18,
    oracle: OracleTypes7.ERC4626Oracle,
    extraDocs: sommFinanceMainnetDocs("Real-Yield-USD", realYieldUSD, [
      assetSymbols16.USDC,
      assetSymbols16.USDT,
      assetSymbols16.DAI
    ])
  },
  {
    symbol: assetSymbols16.realYieldETH,
    underlying: realYieldUSD,
    name: "Sommelier Finance Real Yield ETH",
    decimals: 18,
    oracle: OracleTypes7.ERC4626Oracle,
    extraDocs: sommFinanceMainnetDocs("Real-Yield-ETH", realYieldETH, [
      assetSymbols16.cbETH,
      assetSymbols16.rETH,
      assetSymbols16.stETH,
      assetSymbols16.WETH
    ])
  },
  {
    symbol: assetSymbols16.ethBtcTrend,
    underlying: ethBtcTrend,
    name: "Sommelier Finance ETH/BTC Trend",
    decimals: 18,
    oracle: OracleTypes7.ERC4626Oracle,
    extraDocs: sommFinanceMainnetDocs("ETH-BTC-Trend", ethBtcTrend, [assetSymbols16.WETH, assetSymbols16.WBTC])
  },
  {
    symbol: assetSymbols16.SWETH_BBA_WETH_BPT,
    underlying: SWETH_BBA_WETH_BPT,
    name: "SwETH/Boosted Aave V3 WETH",
    decimals: 18,
    oracle: OracleTypes7.BalancerLpStablePoolPriceOracle,
    extraDocs: balancerDocs(
      "ethereum",
      "0x02d928e68d8f10c0358566152677db51e1e2dc8c00000000000000000000051e",
      "SwETH/Boosted Aave V3 WETH",
      SWETH_BBA_WETH_BPT
    )
  },
  {
    symbol: assetSymbols16.WSTETH_WETH_STABLE_BPT,
    underlying: WSTETH_WETH_STABLE_BPT,
    name: "wstETH/WETH Stable LP",
    decimals: 18,
    oracle: OracleTypes7.BalancerLpStablePoolPriceOracle,
    extraDocs: balancerDocs(
      "ethereum",
      "0x32296969ef14eb0c6d29669c550d4a0449130230000200000000000000000080",
      "wstETH/WETH Stable LP",
      WSTETH_WETH_STABLE_BPT
    )
  },
  {
    symbol: assetSymbols16.WSTETH_RETH_FRXETH_STABLE_BPT,
    underlying: WSTETH_RETH_FRXETH_STABLE_BPT,
    name: "wstETH/rETH/frxETH Stable LP",
    decimals: 18,
    oracle: OracleTypes7.BalancerLpStablePoolPriceOracle,
    extraDocs: balancerDocs(
      "ethereum",
      "0x5aee1e99fe86960377de9f88689616916d5dcabe000000000000000000000467",
      "wstETH/rETH/frxETH Stable LP",
      WSTETH_RETH_FRXETH_STABLE_BPT
    )
  },
  {
    symbol: assetSymbols16.WBETH_WSTETH_STABLE_BPT,
    underlying: WBETH_WSTETH_STABLE_BPT,
    name: "wBETH/wstETH Stable LP",
    decimals: 18,
    oracle: OracleTypes7.BalancerLpStablePoolPriceOracle,
    extraDocs: balancerDocs(
      "ethereum",
      "0x2e848426aec6dbf2260535a5bea048ed94d9ff3d000000000000000000000536",
      "wBETH/wstETH Stable LP",
      WBETH_WSTETH_STABLE_BPT
    )
  },
  {
    symbol: assetSymbols16.WSTETH_CBETH_STABLE_BPT,
    underlying: WSTETH_CBETH_STABLE_BPT,
    name: "wstETH/cbETH Stable LP",
    decimals: 18,
    oracle: OracleTypes7.BalancerLpStablePoolPriceOracle,
    extraDocs: balancerDocs(
      "ethereum",
      "0x9c6d47ff73e0f5e51be5fd53236e3f595c5793f200020000000000000000042c",
      "wstETH/cbETH Stable LP",
      WSTETH_CBETH_STABLE_BPT
    )
  },
  {
    symbol: assetSymbols16.OHM50_DAI50_BPT,
    underlying: OHM50_DAI50_BPT,
    name: "OHM50/DAI50 Weighed LP",
    decimals: 18,
    oracle: OracleTypes7.BalancerLpTokenPriceOracle,
    extraDocs: balancerDocs(
      "ethereum",
      "0x76fcf0e8c7ff37a47a799fa2cd4c13cde0d981c90002000000000000000003d2",
      "OHM50/DAI50 Weighed LP",
      OHM50_DAI50_BPT
    )
  },
  {
    symbol: assetSymbols16.OHM50_WETH50_BPT,
    underlying: OHM50_WETH50_BPT,
    name: "OHM50/WETH50 Weighed LP",
    decimals: 18,
    oracle: OracleTypes7.BalancerLpTokenPriceOracle,
    extraDocs: balancerDocs(
      "ethereum",
      "0xd1ec5e215e8148d76f4460e4097fd3d5ae0a35580002000000000000000003d3",
      "OHM50/WETH50 Weighed LP",
      OHM50_WETH50_BPT
    )
  },
  {
    symbol: assetSymbols16.AAVE_LINEAR_WETH,
    underlying: AAVE_LINEAR_WETH,
    name: "bb-a-WETH Linear BLP",
    decimals: 18,
    oracle: OracleTypes7.BalancerLpLinearPoolPriceOracle,
    extraDocs: "",
    disabled: true
  },
  {
    symbol: assetSymbols16.AAVE_LINEAR_USDT,
    underlying: AAVE_LINEAR_USDT,
    name: "bb-a-USDT Linear BLP",
    decimals: 18,
    oracle: OracleTypes7.BalancerLpLinearPoolPriceOracle,
    extraDocs: "",
    disabled: true
  },
  {
    symbol: assetSymbols16.AAVE_LINEAR_USDC,
    underlying: AAVE_LINEAR_USDC,
    name: "bb-a-USDC Linear BLP",
    decimals: 18,
    oracle: OracleTypes7.BalancerLpLinearPoolPriceOracle,
    extraDocs: "",
    disabled: true
  },
  {
    symbol: assetSymbols16.AAVE_LINEAR_DAI,
    underlying: AAVE_LINEAR_DAI,
    name: "bb-a-DAI Linear BLP",
    decimals: 18,
    oracle: OracleTypes7.BalancerLpLinearPoolPriceOracle,
    extraDocs: "",
    disabled: true
  },
  {
    symbol: assetSymbols16.AAVE_BOOSTED_STABLE_BPT,
    underlying: AAVE_BOOSTED_STABLE_BPT,
    name: "AAVE Boosted Stable LP",
    decimals: 18,
    oracle: OracleTypes7.BalancerLpStablePoolPriceOracle,
    extraDocs: balancerDocs(
      "ethereum",
      "0xfebb0bbf162e64fb9d0dfe186e517d84c395f016000000000000000000000502",
      "AAVE Boosted Stable LP",
      AAVE_BOOSTED_STABLE_BPT
    )
  }
];

// src/ethereum/addresses.ts
var chainAddresses4 = {
  W_TOKEN: underlying11(assets4, assetSymbols17.WETH),
  W_TOKEN_USD_CHAINLINK_PRICE_FEED: "0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419",
  UNISWAP_V2_ROUTER: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
  UNISWAP_V2_FACTORY: "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f",
  UNISWAP_V3_ROUTER: "0xE592427A0AEce92De3Edee1F18E0157C05861564",
  PAIR_INIT_HASH: ethers5.utils.hexlify("0x96e8ac4277198ff8b6f785478aa9a39f403cb768dd02cbee326c3e7da348845f"),
  STABLE_TOKEN: underlying11(assets4, assetSymbols17.USDC),
  W_BTC_TOKEN: underlying11(assets4, assetSymbols17.WBTC),
  UNISWAP_V3: UNISWAP_V3_ADDRESSES
};
var addresses_default4 = chainAddresses4;

// src/ethereum/fundingStrategies.ts
var fundingStrategies4 = {};
var fundingStrategies_default4 = fundingStrategies4;

// src/ethereum/irms.ts
import { IrmTypes as IrmTypes4 } from "@midas-capital/types";
var baseIrms4 = [IrmTypes4.WhitePaperInterestRateModel, IrmTypes4.JumpRateModel];
var irms4 = [...baseIrms4];
var irms_default4 = irms4;

// src/ethereum/liquidation.ts
import { assetSymbols as assetSymbols18, LiquidationStrategy as LiquidationStrategy4, underlying as underlying12 } from "@midas-capital/types";
import { BigNumber as BigNumber7, constants as constants4 } from "ethers";
var liquidationDefaults4 = {
  DEFAULT_ROUTER: addresses_default4.UNISWAP_V2_ROUTER,
  ASSET_SPECIFIC_ROUTER: {},
  SUPPORTED_OUTPUT_CURRENCIES: [
    constants4.AddressZero,
    underlying12(assets4, assetSymbols18.WETH),
    underlying12(assets4, assetSymbols18.USDC),
    underlying12(assets4, assetSymbols18.USDT),
    underlying12(assets4, assetSymbols18.DAI)
  ],
  SUPPORTED_INPUT_CURRENCIES: [constants4.AddressZero, WETH2],
  LIQUIDATION_STRATEGY: LiquidationStrategy4.UNISWAP,
  MINIMUM_PROFIT_NATIVE: BigNumber7.from(0),
  LIQUIDATION_INTERVAL_SECONDS: 20,
  jarvisPools: [],
  balancerPools: [
    {
      poolAddress: underlying12(assets4, assetSymbols18.WSTETH_WETH_STABLE_BPT),
      underlyingTokens: [underlying12(assets4, assetSymbols18.WETH), wstETH2]
    },
    {
      poolAddress: underlying12(assets4, assetSymbols18.WSTETH_RETH_FRXETH_STABLE_BPT),
      underlyingTokens: [wstETH2, underlying12(assets4, assetSymbols18.rETH), underlying12(assets4, assetSymbols18.frxETH)]
    },
    {
      poolAddress: underlying12(assets4, assetSymbols18.WSTETH_CBETH_STABLE_BPT),
      underlyingTokens: [wstETH2, underlying12(assets4, assetSymbols18.cbETH)]
    },
    {
      poolAddress: underlying12(assets4, assetSymbols18.WSTETH_CBETH_STABLE_BPT),
      underlyingTokens: [wstETH2, underlying12(assets4, assetSymbols18.cbETH)]
    },
    {
      poolAddress: underlying12(assets4, assetSymbols18.AAVE_BOOSTED_STABLE_BPT),
      underlyingTokens: [
        underlying12(assets4, assetSymbols18.AAVE_LINEAR_DAI),
        underlying12(assets4, assetSymbols18.AAVE_LINEAR_USDC),
        underlying12(assets4, assetSymbols18.AAVE_LINEAR_USDT)
      ]
    },
    {
      poolAddress: underlying12(assets4, assetSymbols18.AAVE_LINEAR_USDC),
      underlyingTokens: [USDC3, underlying12(assets4, assetSymbols18.AAVE_LINEAR_USDC)]
    }
  ]
};
var liquidation_default4 = liquidationDefaults4;

// src/ethereum/oracles.ts
import { OracleTypes as OracleTypes8 } from "@midas-capital/types";
var baseOracles4 = [OracleTypes8.FixedNativePriceOracle, OracleTypes8.MasterPriceOracle, OracleTypes8.SimplePriceOracle];
var oracles4 = [
  ...baseOracles4,
  OracleTypes8.ChainlinkPriceOracleV2,
  OracleTypes8.ERC4626Oracle,
  OracleTypes8.WSTEthPriceOracle
];
var oracles_default4 = oracles4;

// src/ethereum/params.ts
import { assetSymbols as assetSymbols19 } from "@midas-capital/types";
import { BigNumber as BigNumber8 } from "ethers";
var specificParams4 = {
  blocksPerYear: BigNumber8.from((5 * 24 * 365 * 60).toString()),
  cgId: "ethereum",
  metadata: {
    chainIdHex: "0x1",
    name: "Ethereum Mainnet",
    shortName: "Ethereum",
    uniswapV3Fees: {},
    img: "https://d1912tcoux65lj.cloudfront.net/network/ethereum.png",
    blockExplorerUrls: { default: { name: "etherscan", url: "https://etherscan.io" } },
    rpcUrls: {
      default: { http: ["https://rpc.ankr.com/eth"] },
      public: { http: ["https://rpc.ankr.com/eth"] }
    },
    nativeCurrency: {
      symbol: "ETH",
      name: "ETH"
    },
    wrappedNativeCurrency: {
      symbol: assetSymbols19.WETH,
      address: addresses_default4.W_TOKEN,
      name: "WETH",
      decimals: 18,
      color: "#7A88A1",
      overlayTextColor: "#fff",
      logoURL: "https://d1912tcoux65lj.cloudfront.net/network/ethereum.png"
    }
  }
};
var params_default4 = specificParams4;

// src/ethereum/plugins.ts
var deployedPlugins4 = {};
var plugins_default4 = deployedPlugins4;

// src/ethereum/redemptionStrategies.ts
import { assetSymbols as assetSymbols20, RedemptionStrategyContract as RedemptionStrategyContract4, underlying as underlying13 } from "@midas-capital/types";
var redemptionStrategies4 = {
  // SOMM
  [underlying13(assets4, assetSymbols20.realYieldUSD)]: [RedemptionStrategyContract4.ERC4626Liquidator, USDC3],
  [underlying13(assets4, assetSymbols20.realYieldETH)]: [RedemptionStrategyContract4.ERC4626Liquidator, WETH2],
  [underlying13(assets4, assetSymbols20.ethBtcTrend)]: [RedemptionStrategyContract4.ERC4626Liquidator, WETH2],
  // BLPs
  [underlying13(assets4, assetSymbols20.OHM50_DAI50_BPT)]: [RedemptionStrategyContract4.BalancerSwapLiquidator, DAI3],
  [underlying13(assets4, assetSymbols20.OHM50_WETH50_BPT)]: [RedemptionStrategyContract4.BalancerSwapLiquidator, WETH2],
  [underlying13(assets4, assetSymbols20.SWETH_BBA_WETH_BPT)]: [RedemptionStrategyContract4.BalancerSwapLiquidator, WETH2],
  [underlying13(assets4, assetSymbols20.WSTETH_WETH_STABLE_BPT)]: [RedemptionStrategyContract4.BalancerSwapLiquidator, WETH2],
  [underlying13(assets4, assetSymbols20.WSTETH_RETH_FRXETH_STABLE_BPT)]: [
    RedemptionStrategyContract4.BalancerSwapLiquidator,
    wstETH2
  ],
  [underlying13(assets4, assetSymbols20.WBETH_WSTETH_STABLE_BPT)]: [
    RedemptionStrategyContract4.BalancerSwapLiquidator,
    wstETH2
  ],
  [underlying13(assets4, assetSymbols20.WSTETH_CBETH_STABLE_BPT)]: [
    RedemptionStrategyContract4.BalancerSwapLiquidator,
    wstETH2
  ],
  [underlying13(assets4, assetSymbols20.AAVE_BOOSTED_STABLE_BPT)]: [
    RedemptionStrategyContract4.BalancerSwapLiquidator,
    underlying13(assets4, assetSymbols20.AAVE_LINEAR_USDC)
  ],
  [underlying13(assets4, assetSymbols20.AAVE_LINEAR_USDC)]: [
    RedemptionStrategyContract4.BalancerSwapLiquidator,
    underlying13(assets4, assetSymbols20.USDC)
  ],
  // Balancer and Curve Swaps
  [underlying13(assets4, assetSymbols20.eUSD)]: [RedemptionStrategyContract4.CurveSwapLiquidatorFunder, USDC3],
  [underlying13(assets4, assetSymbols20.wstETH)]: [RedemptionStrategyContract4.BalancerSwapLiquidator, WETH2],
  [underlying13(assets4, assetSymbols20.rETH)]: [RedemptionStrategyContract4.BalancerSwapLiquidator, wstETH2],
  [underlying13(assets4, assetSymbols20.cbETH)]: [RedemptionStrategyContract4.BalancerSwapLiquidator, wstETH2]
};
var redemptionStrategies_default4 = redemptionStrategies4;

// src/ethereum/index.ts
var chainConfig4 = {
  chainId: SupportedChains8.ethereum,
  chainAddresses: addresses_default4,
  assets: assets4,
  irms: irms_default4,
  liquidationDefaults: liquidation_default4,
  oracles: oracles_default4,
  specificParams: params_default4,
  deployedPlugins: plugins_default4,
  redemptionStrategies: redemptionStrategies_default4,
  fundingStrategies: fundingStrategies_default4,
  chainDeployments: ethereum_default.contracts
};
var ethereum_default2 = chainConfig4;

// src/evmos/index.ts
import { SupportedChains as SupportedChains9 } from "@midas-capital/types";

// deployments/evmos.json
var evmos_default = {
  name: "evmos",
  chainId: "9001",
  contracts: {
    AddressesProvider: {
      address: "0xe693a13526Eb4cff15EbeC54779Ea640E2F36a9f"
    },
    AddressesProvider_Implementation: {
      address: "0x4C28C6c18481AE068b89e842bACEebB3150e84b8"
    },
    AddressesProvider_Proxy: {
      address: "0xe693a13526Eb4cff15EbeC54779Ea640E2F36a9f"
    },
    AdrastiaPriceOracle: {
      address: "0x16A4CCFE86aD6d6BfF8072eb4e7cA7eb9C6B727E"
    },
    AdrastiaPriceOracle_Implementation: {
      address: "0x905F1abAfA651EFD6E0F513CcD56909FF4170Cb9"
    },
    AdrastiaPriceOracle_Proxy: {
      address: "0x16A4CCFE86aD6d6BfF8072eb4e7cA7eb9C6B727E"
    },
    CErc20Delegate: {
      address: "0x75bBfA5D096B0F5DfC64fB1e10e4A6063089C840"
    },
    CErc20PluginDelegate: {
      address: "0x42ca8cb70B857E77deaD7F62e6765DfAa6ed2082"
    },
    CErc20PluginRewardsDelegate: {
      address: "0xf1dc2f61bAC0253344FBD7D2FC85Dfa2C3D859ea"
    },
    CTokenFirstExtension: {
      address: "0x93709b82Ba091a300CEFdD503FB5EfC0524A3956"
    },
    Comptroller: {
      address: "0x26B63BE8aD948522702E5e3fb8A85116e4023274"
    },
    ComptrollerFirstExtension: {
      address: "0x8123AaA3676eE37461A43006E13549C46fc7784c"
    },
    DefaultProxyAdmin: {
      address: "0xb6C070BFDFbbc081F2da688d3e0527A5B5EfCB0C"
    },
    FixedNativePriceOracle: {
      address: "0xFC43A2A797f731dad53D6BC4Fe9300d68F480203"
    },
    FluxPriceOracle: {
      address: "0xDdD3cf9407a12A6360281d244cb100ABe7fB561f"
    },
    FluxPriceOracle_Implementation: {
      address: "0x60fe505C5332AA95FF87E8892FBCC45e2f91f1D9"
    },
    FluxPriceOracle_Proxy: {
      address: "0xDdD3cf9407a12A6360281d244cb100ABe7fB561f"
    },
    FuseFeeDistributor: {
      address: "0xA21eB35869C4aafeB55a931Db7F56267a59dB620"
    },
    FuseFeeDistributor_Implementation: {
      address: "0xD14E433151c5a567DBcC60aa3c80782898F5cd55"
    },
    FuseFeeDistributor_Proxy: {
      address: "0xA21eB35869C4aafeB55a931Db7F56267a59dB620"
    },
    FuseFlywheelDynamicRewardsPlugin_DIFF: {
      address: "0x4A3499A1e6DCBE2350196901Ec7a45C4C8e93e46"
    },
    FuseFlywheelDynamicRewardsPlugin_Gravity: {
      address: "0x0e16E6D90ADd49FEb293224c530CbB6A8dc97Aee"
    },
    "FuseFlywheelDynamicRewardsPlugin_Wrapped EVMOS": {
      address: "0xaF2Ce14Ca66BbB5884e222d3068afCAE3d450BE1"
    },
    FusePoolDirectory: {
      address: "0x68e8f59eA33FbccA716f56f89F47e53C73d47830"
    },
    FusePoolDirectory_Implementation: {
      address: "0xA443095Fa1B5C9E6B47dB99042097aee24AAc6d3"
    },
    FusePoolDirectory_Proxy: {
      address: "0x68e8f59eA33FbccA716f56f89F47e53C73d47830"
    },
    FusePoolLens: {
      address: "0x20c0D39eFDDf10cccB6CB0b2d4D16cc6B17CB7Fd"
    },
    FusePoolLensSecondary: {
      address: "0x0b7c55eD8f7Bc25c20eA4FdFbfED79d4AeB9aCaE"
    },
    FuseSafeLiquidator: {
      address: "0xf053FC4634B8683a583E962ebb2C7C419B6Eb7Aa"
    },
    FuseSafeLiquidator_Implementation: {
      address: "0x46600C411D16C335D46675C09D578558d42fD1E7"
    },
    FuseSafeLiquidator_Proxy: {
      address: "0xf053FC4634B8683a583E962ebb2C7C419B6Eb7Aa"
    },
    JumpRateModel: {
      address: "0xFF05E8B7a259bC700215ADD5DfE0Dc72067da105"
    },
    LeveredPositionFactory: {
      address: "0x01375b03Be806e608b2C7Cb93373d01161Cf18f2"
    },
    LeveredPositionFactoryExtension: {
      address: "0xf5BCDDDe61EbfBb267061b84fe9eD92f8428B84d"
    },
    LeveredPositionsLens: {
      address: "0x05ac34CF184A6dC188D66921F0A028022a713eee"
    },
    LeveredPositionsLens_Implementation: {
      address: "0x889e1AB929534AB8aa966F6A8Cd9f348343Fa98F"
    },
    LeveredPositionsLens_Proxy: {
      address: "0x05ac34CF184A6dC188D66921F0A028022a713eee"
    },
    LiquidatorsRegistry: {
      address: "0x6939FB2Adc05C75108E2c7767C567884F5FC6920"
    },
    LiquidatorsRegistryExtension: {
      address: "0x7726B17dB8cb945959039fe169e9aa3296D6B686"
    },
    LooplessFlywheelBooster: {
      address: "0x072921efc867a7997A62D89395827a96C201689c"
    },
    MasterPriceOracle: {
      address: "0xd4D0cA503E8befAbE4b75aAC36675Bc1cFA533D1"
    },
    MasterPriceOracle_Implementation: {
      address: "0x559F595fc39C03eB10B7B10308d31B10763f0c6C"
    },
    MasterPriceOracle_Proxy: {
      address: "0xd4D0cA503E8befAbE4b75aAC36675Bc1cFA533D1"
    },
    MidasFlywheelLensRouter: {
      address: "0x92611183A626d1aBEE83cDC656c7500d6053846d"
    },
    MidasFlywheel_DIFF: {
      address: "0x6537110273d79184205395cfd852dcF3DA6861Ed"
    },
    MidasFlywheel_DIFF_Diffusion_pool: {
      address: "0xEb4576920cb861F47a1cfc6c8fdfA40a6c3E49CD"
    },
    MidasFlywheel_DIFF_Diffusion_pool_Implementation: {
      address: "0x1b9E6B79c9f184E8885a5fb36457E4A880376056"
    },
    MidasFlywheel_DIFF_Diffusion_pool_Proxy: {
      address: "0xEb4576920cb861F47a1cfc6c8fdfA40a6c3E49CD"
    },
    MidasFlywheel_DIFF_Implementation: {
      address: "0x225F4bB69B023e0299b4185EdB7D6E809a35661E"
    },
    MidasFlywheel_DIFF_Proxy: {
      address: "0x6537110273d79184205395cfd852dcF3DA6861Ed"
    },
    MidasFlywheel_Gravity: {
      address: "0xEdcefE496FFf12897F6794E22BacCc7DE1D4422F"
    },
    MidasFlywheel_Gravity_Implementation: {
      address: "0x759221d498A8f7a26FFF328770a0a3A5e3A0b468"
    },
    MidasFlywheel_Gravity_Proxy: {
      address: "0xEdcefE496FFf12897F6794E22BacCc7DE1D4422F"
    },
    "MidasFlywheel_Wrapped EVMOS": {
      address: "0x1a8Fef140D955f8BCea6BCe4cFfD2cEA0fd02af6"
    },
    "MidasFlywheel_Wrapped EVMOS_Implementation": {
      address: "0xEa45A552AE008698F9bf5822C1627e53586c78F3"
    },
    "MidasFlywheel_Wrapped EVMOS_Proxy": {
      address: "0x1a8Fef140D955f8BCea6BCe4cFfD2cEA0fd02af6"
    },
    "MiniChefERC4626_WEVMOS-ceUSDC_0xa7492b8aa271c43498ACAa75385631b8b326aed3": {
      address: "0x857248d02b11c7Cbf8a9Ddc026a0A59d396F1D8c"
    },
    "MiniChefERC4626_WEVMOS-ceUSDC_0xa7492b8aa271c43498ACAa75385631b8b326aed3_Implementation": {
      address: "0x351249E8eCf7e9fBc21F59783eA571EBB65f6805"
    },
    "MiniChefERC4626_WEVMOS-ceUSDC_0xa7492b8aa271c43498ACAa75385631b8b326aed3_Proxy": {
      address: "0x857248d02b11c7Cbf8a9Ddc026a0A59d396F1D8c"
    },
    "MiniChefERC4626_WEVMOS-gUSDC_0x8875C45b4813c60C3dC51Aea25d66Dbf2711af9e": {
      address: "0x55E9491C0dbfc01D86D68C2F50C3B3054FbF1b1E"
    },
    "MiniChefERC4626_WEVMOS-gUSDC_0x8875C45b4813c60C3dC51Aea25d66Dbf2711af9e_Implementation": {
      address: "0x454294a59c86e812dd31E4f88A761132F716Eab1"
    },
    "MiniChefERC4626_WEVMOS-gUSDC_0x8875C45b4813c60C3dC51Aea25d66Dbf2711af9e_Proxy": {
      address: "0x55E9491C0dbfc01D86D68C2F50C3B3054FbF1b1E"
    },
    "MiniChefERC4626_WEVMOS-gWETH_0xa1d5A953c43584D0AFD85Ce287Fa8a09f726180F": {
      address: "0xac3a94DA16B99622744D39EABFa8a50787d91cc4"
    },
    "MiniChefERC4626_WEVMOS-gWETH_0xa1d5A953c43584D0AFD85Ce287Fa8a09f726180F_Implementation": {
      address: "0x0ED52F7Cd942555963534e54aC9656B120f0C147"
    },
    "MiniChefERC4626_WEVMOS-gWETH_0xa1d5A953c43584D0AFD85Ce287Fa8a09f726180F_Proxy": {
      address: "0xac3a94DA16B99622744D39EABFa8a50787d91cc4"
    },
    "MiniChefERC4626_ceUSDC-ceUSDT_0x580216B72d2f70953d3Be4E885863aC295670EE5": {
      address: "0x74f5280443c0Ab2A42eaa1ED0aEa6F4c45599b68"
    },
    "MiniChefERC4626_ceUSDC-ceUSDT_0x580216B72d2f70953d3Be4E885863aC295670EE5_Implementation": {
      address: "0xfB84FDfC617e40BBdC5af043ecc48D852533C86b"
    },
    "MiniChefERC4626_ceUSDC-ceUSDT_0x580216B72d2f70953d3Be4E885863aC295670EE5_Proxy": {
      address: "0x74f5280443c0Ab2A42eaa1ED0aEa6F4c45599b68"
    },
    NativeUSDPriceOracle: {
      address: "0xC6cB1FeA5F391B6998D074Ad31EB1DCFbDEF7601"
    },
    NativeUSDPriceOracle_Implementation: {
      address: "0x99d485eF4425862D90C8301725DF670c4b8B71EC"
    },
    NativeUSDPriceOracle_Proxy: {
      address: "0xC6cB1FeA5F391B6998D074Ad31EB1DCFbDEF7601"
    },
    OptimizedVaultsRegistry: {
      address: "0x3189B452870eEe42a5ccfD338c550812F34740f1"
    },
    OptimizedVaultsRegistry_Implementation: {
      address: "0x0ad440C8cbAE4428E530530C5273469843455587"
    },
    OptimizedVaultsRegistry_Proxy: {
      address: "0x3189B452870eEe42a5ccfD338c550812F34740f1"
    },
    ReplacingFlywheelDynamicRewards_DIFF: {
      address: "0x0FbFc75E7FAcEb8453f8F0F6938c4898C9Fcdcbd"
    },
    ReplacingFlywheelDynamicRewards_Gravity: {
      address: "0x90721EfE6b155052b9f9E99043A43fDAB521aeC1"
    },
    "ReplacingFlywheelDynamicRewards_Wrapped EVMOS": {
      address: "0xdE58CF12595e92ebB07D664eE59A642e360bea58"
    },
    SaddleLpPriceOracle: {
      address: "0x81503F4810A668a6D4e8233842655B554B95D7E4"
    },
    SaddleLpPriceOracle_Implementation: {
      address: "0x94Df0eBbc7e6264F9970A961DA81A6dBEa28a904"
    },
    SaddleLpPriceOracle_Proxy: {
      address: "0x81503F4810A668a6D4e8233842655B554B95D7E4"
    },
    SaddleLpTokenLiquidator: {
      address: "0x968188FD4Bc26D120368Cec1410677B035848301"
    },
    SimplePriceOracle: {
      address: "0x407c87717D8EcbBe2d9a9930E4c0fcd6ab10Ec4A"
    },
    SimplePriceOracle_Implementation: {
      address: "0xFe5aF5765A7cCD1538E4ee4B501BC7fe93ec8EBa"
    },
    SimplePriceOracle_Proxy: {
      address: "0x407c87717D8EcbBe2d9a9930E4c0fcd6ab10Ec4A"
    },
    UniswapLpTokenPriceOracle: {
      address: "0x45C4AD08b050E3c8CeCfC1e77B88DC372b4Cf94c"
    },
    UniswapTwapPriceOracleV2: {
      address: "0x4EdC25aA50A20184Cd90280D781acC8b59ab6C91"
    },
    UniswapTwapPriceOracleV2Factory: {
      address: "0x66BBfA7d24f77A990B0E360Ce0c2D141cbcE39bB"
    },
    UniswapTwapPriceOracleV2Resolver: {
      address: "0x5d7d2AF814918782355b14BFEe2fb58C5e7ae3ea"
    },
    UniswapTwapPriceOracleV2Root: {
      address: "0xdE9f0900e135614036422EaAfAe95883f2F8A2e0"
    },
    WhitePaperInterestRateModel: {
      address: "0xC1B6152d3977E994F5a4E0dead9d0a11a0D229Ef"
    }
  }
};

// src/evmos/addresses.ts
import { assetSymbols as assetSymbols22, underlying as underlying14 } from "@midas-capital/types";
import { ethers as ethers6 } from "ethers";

// src/evmos/assets.ts
import { assetSymbols as assetSymbols21, OracleTypes as OracleTypes9 } from "@midas-capital/types";
var assets5 = [
  {
    symbol: assetSymbols21.ATOM,
    underlying: "0xC5e00D3b04563950941f7137B5AfA3a534F0D6d6",
    name: "Cosmos Hub",
    decimals: 6,
    oracle: OracleTypes9.FluxPriceOracle,
    disabled: true
  },
  {
    symbol: assetSymbols21.WEVMOS,
    underlying: "0xD4949664cD82660AaE99bEdc034a0deA8A0bd517",
    name: "Wrapped EVMOS",
    decimals: 18,
    oracle: OracleTypes9.FixedNativePriceOracle
  },
  {
    symbol: assetSymbols21.gUSDT,
    underlying: "0xecEEEfCEE421D8062EF8d6b4D814efe4dc898265",
    name: "Gravity Bridged USDT",
    decimals: 6,
    oracle: OracleTypes9.AdrastiaPriceOracle
  },
  {
    symbol: assetSymbols21.ceUSDT,
    underlying: "0xb72A7567847abA28A2819B855D7fE679D4f59846",
    name: "Celer Bridged USDT",
    decimals: 6,
    oracle: OracleTypes9.FluxPriceOracle
  },
  {
    symbol: assetSymbols21.axlUSDT,
    underlying: "0xe01C6D4987Fc8dCE22988DADa92d56dA701d0Fe0",
    name: "Axelar Bridged USDT",
    decimals: 6,
    oracle: OracleTypes9.FluxPriceOracle
  },
  {
    symbol: assetSymbols21.gUSDC,
    underlying: "0x5FD55A1B9FC24967C4dB09C513C3BA0DFa7FF687",
    name: "Gravity Bridged USDC",
    decimals: 6,
    oracle: OracleTypes9.AdrastiaPriceOracle
  },
  {
    symbol: assetSymbols21.ceUSDC,
    underlying: "0xe46910336479F254723710D57e7b683F3315b22B",
    name: "Celer Bridged USDC",
    decimals: 6,
    oracle: OracleTypes9.AdrastiaPriceOracle
  },
  {
    symbol: assetSymbols21.axlUSDC,
    underlying: "0x15C3Eb3B621d1Bff62CbA1c9536B7c1AE9149b57",
    name: "Axelar Bridged USD Coin",
    decimals: 6,
    oracle: OracleTypes9.AdrastiaPriceOracle
  },
  {
    symbol: assetSymbols21.axlWETH,
    underlying: "0x50dE24B3f0B3136C50FA8A3B8ebc8BD80a269ce5",
    name: "Axelar Bridged WETH",
    decimals: 18,
    oracle: OracleTypes9.AdrastiaPriceOracle
  },
  {
    symbol: assetSymbols21.ceWETH,
    underlying: "0x153A59d48AcEAbedbDCf7a13F67Ae52b434B810B",
    name: "Celer Bridged WETH",
    decimals: 18,
    oracle: OracleTypes9.AdrastiaPriceOracle
  },
  {
    symbol: assetSymbols21.gWETH,
    underlying: "0xc03345448969Dd8C00e9E4A85d2d9722d093aF8E",
    name: "Gravity Bridged WETH",
    decimals: 18,
    oracle: OracleTypes9.AdrastiaPriceOracle
  },
  {
    symbol: assetSymbols21.FRAX,
    underlying: "0xE03494D0033687543a80c9B1ca7D6237F2EA8BD8",
    name: "Frax",
    decimals: 18,
    oracle: OracleTypes9.FluxPriceOracle
  },
  {
    symbol: assetSymbols21.gDAI,
    underlying: "0xd567B3d7B8FE3C79a1AD8dA978812cfC4Fa05e75",
    name: "Dai Stablecoin",
    decimals: 18,
    oracle: OracleTypes9.AdrastiaPriceOracle
  },
  {
    symbol: assetSymbols21.gWBTC,
    underlying: "0x1D54EcB8583Ca25895c512A8308389fFD581F9c9",
    name: "Gravity Bridged Wrapped BTC",
    decimals: 8,
    oracle: OracleTypes9.FluxPriceOracle
  },
  {
    symbol: assetSymbols21.axlWBTC,
    underlying: "0xF5b24c0093b65408ACE53df7ce86a02448d53b25",
    name: "Axelar Bridged Wrapped BTC",
    decimals: 8,
    oracle: OracleTypes9.AdrastiaPriceOracle
  },
  {
    symbol: assetSymbols21.OSMO,
    underlying: "0xFA3C22C069B9556A4B2f7EcE1Ee3B467909f4864",
    name: "Osmosis",
    decimals: 6,
    oracle: OracleTypes9.AdrastiaPriceOracle
  },
  {
    symbol: assetSymbols21.JUNO,
    underlying: "0x3452e23F9c4cC62c70B7ADAd699B264AF3549C19",
    name: "Juno",
    decimals: 6,
    oracle: OracleTypes9.AdrastiaPriceOracle
  },
  {
    symbol: assetSymbols21.DIFF,
    underlying: "0x3f75ceabCDfed1aCa03257Dc6Bdc0408E2b4b026",
    name: "Diffusion",
    decimals: 18,
    disabled: true
  },
  {
    symbol: assetSymbols21.GRAV,
    underlying: "0x80b5a32E4F032B2a058b4F29EC95EEfEEB87aDcd",
    name: "Graviton",
    decimals: 6,
    disabled: true
  },
  {
    symbol: assetSymbols21["WEVMOS-JUNO"],
    underlying: "0x4Aa9c250874C2d14D0d686833e7b3C5c1837c36c",
    name: "WEVMOS-JUNO",
    decimals: 18,
    oracle: OracleTypes9.UniswapLpTokenPriceOracle
  },
  {
    symbol: assetSymbols21["WEVMOS-gUSDC"],
    underlying: "0x089B913E5Ba7B32d44A69B09f600b87c70Df57eb",
    name: "WEVMOS-gUSDC",
    decimals: 18,
    oracle: OracleTypes9.UniswapLpTokenPriceOracle
  },
  {
    symbol: assetSymbols21["WEVMOS-gWETH"],
    underlying: "0xD7bfB11ED8fd924E77487480d13542328601e5a3",
    name: "WEVMOS-gWETH",
    decimals: 18,
    oracle: OracleTypes9.UniswapLpTokenPriceOracle
  },
  {
    symbol: assetSymbols21["WEVMOS-ceUSDC"],
    underlying: "0x2fD681DB6b1e4775a79c9fF728B86a6EfE460be9",
    name: "WEVMOS-ceUSDC",
    decimals: 18,
    oracle: OracleTypes9.UniswapLpTokenPriceOracle
  },
  {
    symbol: assetSymbols21["ceUSDC-ceUSDT"],
    underlying: "0xeD5d692caF043489058d8ae9B9C31CA5e468E60f",
    name: "ceUSDC-ceUSDT",
    decimals: 18,
    oracle: OracleTypes9.UniswapLpTokenPriceOracle
  },
  {
    symbol: assetSymbols21.kinesisUSDC,
    underlying: "0xfD2fd675176a8Ed1CF643886ee557929FDEcBBfD",
    name: "Kinesis USDC Pool",
    decimals: 18,
    oracle: OracleTypes9.SaddleLpPriceOracle
  },
  {
    symbol: assetSymbols21.kinesisUSDT,
    underlying: "0x78549EF94dB08E8bf2e528F0aE97F186Fc51185E",
    name: "Kinesis USDT Pool",
    decimals: 18,
    oracle: OracleTypes9.SaddleLpPriceOracle
  }
];
var assets_default4 = assets5;

// src/evmos/addresses.ts
var chainAddresses5 = {
  W_TOKEN: underlying14(assets5, assetSymbols22.WEVMOS),
  W_TOKEN_USD_CHAINLINK_PRICE_FEED: "",
  UNISWAP_V2_ROUTER: "0xFCd2Ce20ef8ed3D43Ab4f8C2dA13bbF1C6d9512F",
  UNISWAP_V2_FACTORY: "0x6aBdDa34Fb225be4610a2d153845e09429523Cd2",
  PAIR_INIT_HASH: ethers6.utils.hexlify("0xa192c894487128ec7b68781ed7bd7e3141d1718df9e4e051e0124b7671d9a6ef"),
  STABLE_TOKEN: underlying14(assets5, assetSymbols22.gUSDC),
  W_BTC_TOKEN: underlying14(assets5, assetSymbols22.gWBTC)
};
var addresses_default5 = chainAddresses5;

// src/evmos/fundingStrategies.ts
var fundingStrategies5 = {};
var fundingStrategies_default5 = fundingStrategies5;

// src/evmos/irms.ts
import { IrmTypes as IrmTypes5 } from "@midas-capital/types";
var baseIrms5 = [IrmTypes5.WhitePaperInterestRateModel, IrmTypes5.JumpRateModel];
var irms5 = [...baseIrms5];
var irms_default5 = irms5;

// src/evmos/liquidation.ts
import { assetSymbols as assetSymbols23, LiquidationStrategy as LiquidationStrategy5, underlying as underlying15 } from "@midas-capital/types";
import { BigNumber as BigNumber9, constants as constants5 } from "ethers";
var liquidationDefaults5 = {
  DEFAULT_ROUTER: addresses_default5.UNISWAP_V2_ROUTER,
  ASSET_SPECIFIC_ROUTER: {},
  SUPPORTED_OUTPUT_CURRENCIES: [constants5.AddressZero, underlying15(assets5, assetSymbols23.WEVMOS)],
  SUPPORTED_INPUT_CURRENCIES: [constants5.AddressZero, underlying15(assets5, assetSymbols23.WEVMOS)],
  LIQUIDATION_STRATEGY: LiquidationStrategy5.UNISWAP,
  MINIMUM_PROFIT_NATIVE: BigNumber9.from(0),
  LIQUIDATION_INTERVAL_SECONDS: 30,
  jarvisPools: [],
  balancerPools: []
};
var liquidation_default5 = liquidationDefaults5;

// src/evmos/oracles.ts
import { OracleTypes as OracleTypes10 } from "@midas-capital/types";
var baseOracles5 = [OracleTypes10.FixedNativePriceOracle, OracleTypes10.MasterPriceOracle, OracleTypes10.SimplePriceOracle];
var oracles5 = [
  ...baseOracles5,
  OracleTypes10.UniswapLpTokenPriceOracle,
  OracleTypes10.UniswapTwapPriceOracleV2,
  OracleTypes10.AdrastiaPriceOracle,
  OracleTypes10.FluxPriceOracle,
  OracleTypes10.NativeUSDPriceOracle,
  OracleTypes10.SaddleLpPriceOracle
];
var oracles_default5 = oracles5;

// src/evmos/params.ts
import { assetSymbols as assetSymbols24 } from "@midas-capital/types";
import { BigNumber as BigNumber10 } from "ethers";
var specificParams5 = {
  // 1.8 seconds block time
  blocksPerYear: BigNumber10.from((33 * 24 * 365 * 60).toString()),
  cgId: "evmos",
  metadata: {
    chainIdHex: "0x2329",
    name: "Evmos Mainnet",
    shortName: "EVMOS",
    img: "https://d1912tcoux65lj.cloudfront.net/network/evmos.png",
    rpcUrls: {
      default: { http: ["https://evmos.api.onfinality.io/public"] },
      public: { http: ["https://evmos.api.onfinality.io/public"] }
    },
    blockExplorerUrls: { default: { name: "Evmos", url: "https://escan.live" } },
    nativeCurrency: {
      symbol: "EVMOS",
      name: "EVMOS"
    },
    wrappedNativeCurrency: {
      name: "Wrapped EVMOS",
      symbol: assetSymbols24.WEVMOS,
      address: addresses_default5.W_TOKEN,
      decimals: 18,
      color: "#000",
      overlayTextColor: "#fff",
      logoURL: "https://d1912tcoux65lj.cloudfront.net/network/evmos.png"
    },
    testnet: false
  }
};
var params_default5 = specificParams5;

// src/evmos/plugins.ts
import { assetSymbols as assetSymbols25, Strategy as Strategy2, underlying as underlying16 } from "@midas-capital/types";
var deployedPlugins5 = {
  // MiniChefERC4626_WEVMOS-gUSDC_0x8875C45b4813c60C3dC51Aea25d66Dbf2711af9e.json
  "0x55E9491C0dbfc01D86D68C2F50C3B3054FbF1b1E": {
    market: "0x8875C45b4813c60C3dC51Aea25d66Dbf2711af9e",
    name: "Diffusion MiniChefV2 WEVMOS-gUSDC",
    strategy: Strategy2.MiniChefV2,
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/evmos/diffusion",
    underlying: underlying16(assets_default4, assetSymbols25["WEVMOS-gUSDC"]),
    otherParams: ["6", "0x067eC87844fBD73eDa4a1059F30039584586e09d"],
    // poolId, minchef address
    flywheels: ["0x6537110273d79184205395cfd852dcF3DA6861Ed", "0xEdcefE496FFf12897F6794E22BacCc7DE1D4422F"],
    // DIFF, GRAV flywheels
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/diffusion.png"
  },
  // MiniChefERC4626_WEVMOS-gWETH_0xa1d5A953c43584D0AFD85Ce287Fa8a09f726180F.json
  "0xac3a94DA16B99622744D39EABFa8a50787d91cc4": {
    market: "0xa1d5A953c43584D0AFD85Ce287Fa8a09f726180F",
    name: "Diffusion MiniChefV2 WEVMOS-gWETH",
    strategy: Strategy2.MiniChefV2,
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/evmos/diffusion",
    underlying: underlying16(assets_default4, assetSymbols25["WEVMOS-gWETH"]),
    otherParams: ["7", "0x067eC87844fBD73eDa4a1059F30039584586e09d"],
    // poolId, minchef address
    flywheels: ["0x6537110273d79184205395cfd852dcF3DA6861Ed", "0xEdcefE496FFf12897F6794E22BacCc7DE1D4422F"],
    // DIFF, GRAV flywheels
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/diffusion.png"
  },
  // MiniChefERC4626_WEVMOS-ceUSDC_0xa7492b8aa271c43498ACAa75385631b8b326aed3.json
  "0x857248d02b11c7Cbf8a9Ddc026a0A59d396F1D8c": {
    market: "0xa7492b8aa271c43498ACAa75385631b8b326aed3",
    name: "Diffusion MiniChefV2 WEVMOS-ceUSDC",
    strategy: Strategy2.MiniChefV2,
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/evmos/diffusion",
    underlying: underlying16(assets_default4, assetSymbols25["WEVMOS-ceUSDC"]),
    otherParams: ["8", "0x067eC87844fBD73eDa4a1059F30039584586e09d"],
    // poolId, minchef address
    flywheels: ["0x6537110273d79184205395cfd852dcF3DA6861Ed", "0x1a8Fef140D955f8BCea6BCe4cFfD2cEA0fd02af6"],
    // DIFF, EVMOS flywheels
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/diffusion.png"
  },
  // MiniChefERC4626_ceUSDC-ceUSDT_0x580216B72d2f70953d3Be4E885863aC295670EE5.json
  "0x74f5280443c0Ab2A42eaa1ED0aEa6F4c45599b68": {
    market: "0x580216B72d2f70953d3Be4E885863aC295670EE5",
    name: "Diffusion MiniChefV2 ceUSDC-ceUSDT",
    strategy: Strategy2.MiniChefV2,
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/evmos/diffusion",
    underlying: underlying16(assets_default4, assetSymbols25["ceUSDC-ceUSDT"]),
    otherParams: ["9", "0x067eC87844fBD73eDa4a1059F30039584586e09d"],
    // poolId, minchef address
    flywheels: ["0x6537110273d79184205395cfd852dcF3DA6861Ed"],
    // DIFF flywheel
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/diffusion.png"
  }
};
var plugins_default5 = deployedPlugins5;

// src/evmos/redemptionStrategies.ts
var redemptionStrategies5 = {};
var redemptionStrategies_default5 = redemptionStrategies5;

// src/evmos/index.ts
var chainConfig5 = {
  chainId: SupportedChains9.evmos,
  chainAddresses: addresses_default5,
  assets: assets_default4,
  irms: irms_default5,
  liquidationDefaults: liquidation_default5,
  oracles: oracles_default5,
  specificParams: params_default5,
  deployedPlugins: plugins_default5,
  redemptionStrategies: redemptionStrategies_default5,
  fundingStrategies: fundingStrategies_default5,
  chainDeployments: evmos_default.contracts
};
var evmos_default2 = chainConfig5;

// src/fantom/index.ts
import { SupportedChains as SupportedChains11 } from "@midas-capital/types";

// deployments/fantom.json
var fantom_default = {
  name: "fantom",
  chainId: "250",
  contracts: {
    AddressesProvider: {
      address: "0xC1B6152d3977E994F5a4E0dead9d0a11a0D229Ef"
    },
    AddressesProvider_Implementation: {
      address: "0x9F5DaC5Aa35a55bCb84A7AdFcdA71e7b4bc0DaF7"
    },
    AddressesProvider_Proxy: {
      address: "0xC1B6152d3977E994F5a4E0dead9d0a11a0D229Ef"
    },
    AnkrCertificateTokenPriceOracle: {
      address: "0x66ceEB3AA6fC5d67f478b56B475fF930bacf7E03"
    },
    AnkrCertificateTokenPriceOracle_Implementation: {
      address: "0x2fe516D093715aD7702f1b21b37c0936dd04F5C8"
    },
    AnkrCertificateTokenPriceOracle_Proxy: {
      address: "0x66ceEB3AA6fC5d67f478b56B475fF930bacf7E03"
    },
    AnkrFTMInterestRateModel: {
      address: "0x6c66030a8431C7e2973D63340F5fF95889dce5f9"
    },
    BalancerLpTokenLiquidator: {
      address: "0x8085C0880b8DcaD595CC8092A590778b99fA4151"
    },
    BalancerLpTokenPriceOracle: {
      address: "0x70B6f26755e2594a714a931f21C3d29bcb424757"
    },
    BalancerLpTokenPriceOracle_Implementation: {
      address: "0x6811d328aa5599FEC9f7Fe65fE0C9E8529f20A65"
    },
    BalancerLpTokenPriceOracle_Proxy: {
      address: "0x70B6f26755e2594a714a931f21C3d29bcb424757"
    },
    CErc20Delegate: {
      address: "0xe3a5BDb287D9f6da0453A0025B8Df550D518A86c"
    },
    CErc20PluginDelegate: {
      address: "0x089A362BFFcAeed0674C6C1211843CA346E5FB72"
    },
    CErc20PluginRewardsDelegate: {
      address: "0x8700bd39A9Fd7cC8050bf0447DfdAEb579D7D50B"
    },
    CTokenFirstExtension: {
      address: "0x93709b82Ba091a300CEFdD503FB5EfC0524A3956"
    },
    ChainlinkPriceOracleV2: {
      address: "0x55C0f57cf6c1CAe4CC57D5F6334408f63C0d5bbC"
    },
    Comptroller: {
      address: "0x79eA192e51e56D639AafB24993b6CEd772407d13"
    },
    ComptrollerFirstExtension: {
      address: "0xd32d5AEcCFc4183B6472a15D94D421B2241317d7"
    },
    CurveLpTokenLiquidatorNoRegistry: {
      address: "0xcC00d1D50D4E12A5Ec9De133Cf5431718EA7958C"
    },
    CurveLpTokenPriceOracleNoRegistry: {
      address: "0xbF60bDf4bc95d84fB9a6b40792C5465D491B2023"
    },
    CurveLpTokenPriceOracleNoRegistry_Implementation: {
      address: "0xe2530177FB770c1A69e77882bC6bfAF7D086017C"
    },
    CurveLpTokenPriceOracleNoRegistry_Proxy: {
      address: "0xbF60bDf4bc95d84fB9a6b40792C5465D491B2023"
    },
    CurveSwapLiquidator: {
      address: "0x7d5bCe803bBd8511875122267cD0Cf89d058196F"
    },
    CurveV2LpTokenPriceOracleNoRegistry: {
      address: "0x506E21A470ff22dF5DDAb03C0149b3234b4B0d4A"
    },
    CurveV2LpTokenPriceOracleNoRegistry_Implementation: {
      address: "0xb51c785933715530eDAE9EAfbBd51D4a7fc7F3b6"
    },
    CurveV2LpTokenPriceOracleNoRegistry_Proxy: {
      address: "0x506E21A470ff22dF5DDAb03C0149b3234b4B0d4A"
    },
    DefaultProxyAdmin: {
      address: "0xA21eB35869C4aafeB55a931Db7F56267a59dB620"
    },
    DiaPriceOracle: {
      address: "0xed5886Fcf8B390210B387432431159dCfA5D8763"
    },
    FixedNativePriceOracle: {
      address: "0xd4D0cA503E8befAbE4b75aAC36675Bc1cFA533D1"
    },
    FuseFeeDistributor: {
      address: "0xA2938d28D26F5D0aA86ffdC9BED815F184A7b1bD"
    },
    FuseFeeDistributor_Implementation: {
      address: "0x364E069F0B84B669f8132c107C0410ceb6eDD923"
    },
    FuseFeeDistributor_Proxy: {
      address: "0xA2938d28D26F5D0aA86ffdC9BED815F184A7b1bD"
    },
    FusePoolDirectory: {
      address: "0xE622c2967E2885ED04436075889C88696328aBE8"
    },
    FusePoolDirectory_Implementation: {
      address: "0x3eDBE40Ef393070404cB056B10bF597a1C7B0e3b"
    },
    FusePoolDirectory_Proxy: {
      address: "0xE622c2967E2885ED04436075889C88696328aBE8"
    },
    FusePoolLens: {
      address: "0x79AAb023F3cdCf5a8314E88bfb9EE88ecd3e12e7"
    },
    FusePoolLensSecondary: {
      address: "0xcd85F8403B7B303a1b97dFbfAf5b161C1Ad9A8aA"
    },
    FuseSafeLiquidator: {
      address: "0x1557ccC2cc2B0CC6029330B86FC39CCEA007339b"
    },
    FuseSafeLiquidator_Implementation: {
      address: "0xe39F5730A845c14558d9e52504d36f634d5AC20C"
    },
    FuseSafeLiquidator_Proxy: {
      address: "0x1557ccC2cc2B0CC6029330B86FC39CCEA007339b"
    },
    JumpRateModel: {
      address: "0xDcC10801935A8565f717cc42dC8c2c5cB0C50374"
    },
    LeveredPositionFactory: {
      address: "0x1f0847B260D736Ed16bCDacFa76b04f0EC6427ba"
    },
    LeveredPositionFactoryExtension: {
      address: "0xc7107037D5871084Ece6010f648e3BE552c1582b"
    },
    LeveredPositionsLens: {
      address: "0xAFF9219c8a561a6022AfEae163e333BB446B5A7E"
    },
    LeveredPositionsLens_Implementation: {
      address: "0xBe99e316BdCF1091450E4300202e3FCB5da55756"
    },
    LeveredPositionsLens_Proxy: {
      address: "0xAFF9219c8a561a6022AfEae163e333BB446B5A7E"
    },
    LiquidatorsRegistry: {
      address: "0xDdA85440F80319e3B3591C13fDF9e2708D996c9c"
    },
    LiquidatorsRegistryExtension: {
      address: "0x7E62b1eCF80cE90374F7E90F1AF5c7877cDFb52B"
    },
    LooplessFlywheelBooster: {
      address: "0x604805B587C939042120D2e22398f299547A130c"
    },
    MasterPriceOracle: {
      address: "0xe693a13526Eb4cff15EbeC54779Ea640E2F36a9f"
    },
    MasterPriceOracle_Implementation: {
      address: "0x9733de87628a5D9Ef216682313d8143cE3b0ebD8"
    },
    MasterPriceOracle_Proxy: {
      address: "0xe693a13526Eb4cff15EbeC54779Ea640E2F36a9f"
    },
    MidasFlywheelLensRouter: {
      address: "0xFe5aF5765A7cCD1538E4ee4B501BC7fe93ec8EBa"
    },
    OptimizedVaultsRegistry: {
      address: "0xBB9A827f23c1352f33Be91160c36b2eE05a13C9a"
    },
    OptimizedVaultsRegistry_Implementation: {
      address: "0x789eA0BDC85D385CD728e9ff261aF5738667d6e0"
    },
    OptimizedVaultsRegistry_Proxy: {
      address: "0xBB9A827f23c1352f33Be91160c36b2eE05a13C9a"
    },
    SimplePriceOracle: {
      address: "0x863D4F7FA3618fb1D4A94F28B5b74a30c337b9C7"
    },
    SimplePriceOracle_Implementation: {
      address: "0x00C0681a5F335bE0077cf9b959d025C6b5b0A264"
    },
    SimplePriceOracle_Proxy: {
      address: "0x863D4F7FA3618fb1D4A94F28B5b74a30c337b9C7"
    },
    WhitePaperInterestRateModel: {
      address: "0xf053FC4634B8683a583E962ebb2C7C419B6Eb7Aa"
    }
  }
};

// src/fantom/addresses.ts
import { assetSymbols as assetSymbols27, underlying as underlying17 } from "@midas-capital/types";
import { ethers as ethers7 } from "ethers";

// src/fantom/assets.ts
import { assetSymbols as assetSymbols26, OracleTypes as OracleTypes11, SupportedChains as SupportedChains10 } from "@midas-capital/types";
var aFTMc = "0xCfC785741Dc0e98ad4c9F6394Bb9d43Cd1eF5179";
var BNB3 = "0xD67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454";
var multiBTC = "0x321162Cd933E2Be498Cd2267a90534A804051b11";
var multiETH = "0x74b23882a30290451A17c44f4F05243b6b58C76d";
var MIMO = "0x1D1764F04DE29da6b90ffBef372D1A45596C4855";
var USDC4 = "0x04068DA6C83AFCFA0e13ba15A6696662335D5B75";
var fUSDT = "0x049d68029688eAbF473097a2fC38ef61633A3C7A";
var DAI4 = "0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E";
var PAR2 = "0x13082681E8CE9bd0aF505912d306403592490Fc7";
var WFTM = "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83";
var MAI2 = "0xfB98B335551a418cD0737375a2ea0ded62Ea213b";
var PAR_USDC_CURVE = "0x43363B5b640284da0BB8e2c545e43C1583277022";
var MIMO_PAR_75_25 = "0x851553FD9BCd28Befe450d3cfbB3f86F13832a1d";
var TRI_CRYPTO = "0x58e57cA18B7A47112b877E31929798Cd3D703b0f";
var TWO_POOL = "0x27E611FD27b276ACbd5Ffd632E5eAEBEC9761E40";
var assets6 = [
  {
    symbol: assetSymbols26.aFTMc,
    underlying: aFTMc,
    name: "Ankr FTM Reward Bearing Certificate",
    decimals: 18,
    oracle: OracleTypes11.AnkrCertificateTokenPriceOracle,
    extraDocs: ankrCertificateDocs("aFTMc", "FTM")
  },
  {
    symbol: assetSymbols26.BNB,
    underlying: BNB3,
    name: "Binance Network",
    decimals: 18,
    oracle: OracleTypes11.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://ftmscan.com", BNB3)
  },
  {
    symbol: assetSymbols26.multiBTC,
    underlying: multiBTC,
    name: "multichain BTC",
    decimals: 8,
    oracle: OracleTypes11.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://ftmscan.com", multiBTC)
  },
  {
    symbol: assetSymbols26.multiETH,
    underlying: multiETH,
    name: "Multichain Ether",
    decimals: 18,
    oracle: OracleTypes11.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://ftmscan.com", multiETH)
  },
  {
    symbol: assetSymbols26.WFTM,
    underlying: WFTM,
    name: "Wrapped Fantom",
    decimals: 18,
    oracle: OracleTypes11.ChainlinkPriceOracleV2,
    extraDocs: wrappedAssetDocs(SupportedChains10.fantom)
  },
  {
    symbol: assetSymbols26.MAI,
    underlying: MAI2,
    name: "miMATIC",
    decimals: 18,
    oracle: OracleTypes11.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://ftmscan.com", MAI2)
  },
  {
    symbol: assetSymbols26.MIMO,
    underlying: MIMO,
    name: "MIMO Parallel Governance Token",
    decimals: 18,
    oracle: OracleTypes11.DiaPriceOracle,
    extraDocs: defaultDocs("https://ftmscan.com", MIMO)
  },
  {
    symbol: assetSymbols26.DAI,
    underlying: DAI4,
    name: "Dai Stablecoin",
    decimals: 18,
    oracle: OracleTypes11.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://ftmscan.com", DAI4)
  },
  {
    symbol: assetSymbols26.USDC,
    underlying: USDC4,
    name: "USD Coin",
    decimals: 6,
    oracle: OracleTypes11.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://ftmscan.com", USDC4)
  },
  {
    symbol: assetSymbols26.fUSDT,
    underlying: fUSDT,
    name: "Frapped USDT",
    decimals: 6,
    oracle: OracleTypes11.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://ftmscan.com", fUSDT)
  },
  {
    symbol: assetSymbols26.PAR,
    underlying: PAR2,
    name: "PAR Stablecoin",
    decimals: 18,
    oracle: OracleTypes11.DiaPriceOracle,
    extraDocs: defaultDocs("https://ftmscan.com", USDC4)
  },
  {
    symbol: assetSymbols26.PAR_USDC_CURVE,
    underlying: PAR_USDC_CURVE,
    name: "PAR/USDC Curve LP Token",
    decimals: 18,
    oracle: OracleTypes11.CurveV2LpTokenPriceOracleNoRegistry,
    extraDocs: curveFinanceFantomDocs("https://ftmscan.com", "factory-crypto-7")
  },
  {
    symbol: assetSymbols26.triCrypto,
    underlying: TRI_CRYPTO,
    name: "ETH/WBTC/USDT Curve LP Token",
    decimals: 18,
    oracle: OracleTypes11.CurveV2LpTokenPriceOracleNoRegistry,
    extraDocs: curveFinanceFantomDocs("https://ftmscan.com", "tricrypto")
  },
  {
    symbol: assetSymbols26["2pool"],
    underlying: TWO_POOL,
    name: "DAI/USDC Curve LP Token",
    decimals: 18,
    oracle: OracleTypes11.CurveLpTokenPriceOracleNoRegistry,
    extraDocs: curveFinanceFantomDocs("https://ftmscan.com", "tricrypto")
  },
  {
    symbol: assetSymbols26.MIMO_PAR_75_25,
    underlying: MIMO_PAR_75_25,
    name: "75MIMO-25PAR",
    decimals: 18,
    oracle: OracleTypes11.BalancerLpTokenPriceOracle,
    extraDocs: beethovenXDocs(
      "0x851553fd9bcd28befe450d3cfbb3f86f13832a1d000200000000000000000211",
      "PAR25 - MIMO75",
      MIMO_PAR_75_25
    )
  }
];
var assets_default5 = assets6;

// src/fantom/addresses.ts
var chainAddresses6 = {
  W_TOKEN: underlying17(assets6, assetSymbols27.WFTM),
  W_TOKEN_USD_CHAINLINK_PRICE_FEED: "0xf4766552D15AE4d256Ad41B6cf2933482B0680dc",
  UNISWAP_V2_ROUTER: "0xF491e7B69E4244ad4002BC14e878a34207E38c29",
  UNISWAP_V2_FACTORY: "0x152eE697f2E276fA89E96742e9bB9aB1F2E61bE3",
  PAIR_INIT_HASH: ethers7.utils.hexlify("0xcdf2deca40a0bd56de8e3ce5c7df6727e5b1bf2ac96f283fa9c4b3e6b42ea9d2"),
  STABLE_TOKEN: underlying17(assets6, assetSymbols27.USDC),
  W_BTC_TOKEN: underlying17(assets6, assetSymbols27.multiBTC)
};
var addresses_default6 = chainAddresses6;

// src/fantom/fundingStrategies.ts
var fundingStrategies6 = {};
var fundingStrategies_default6 = fundingStrategies6;

// src/fantom/irms.ts
import { IrmTypes as IrmTypes6 } from "@midas-capital/types";
var baseIrms6 = [IrmTypes6.WhitePaperInterestRateModel, IrmTypes6.JumpRateModel];
var irms6 = [...baseIrms6, IrmTypes6.AnkrFTMInterestRateModel];
var irms_default6 = irms6;

// src/fantom/liquidation.ts
import { assetSymbols as assetSymbols28, LiquidationStrategy as LiquidationStrategy6, underlying as underlying18 } from "@midas-capital/types";
import { BigNumber as BigNumber11, constants as constants6 } from "ethers";
var liquidationDefaults6 = {
  DEFAULT_ROUTER: addresses_default6.UNISWAP_V2_ROUTER,
  ASSET_SPECIFIC_ROUTER: {},
  SUPPORTED_OUTPUT_CURRENCIES: [constants6.AddressZero, underlying18(assets6, assetSymbols28.WFTM)],
  SUPPORTED_INPUT_CURRENCIES: [constants6.AddressZero, underlying18(assets6, assetSymbols28.WFTM)],
  LIQUIDATION_STRATEGY: LiquidationStrategy6.UNISWAP,
  MINIMUM_PROFIT_NATIVE: BigNumber11.from(0),
  LIQUIDATION_INTERVAL_SECONDS: 20,
  jarvisPools: [],
  balancerPools: []
};
var liquidation_default6 = liquidationDefaults6;

// src/fantom/oracles.ts
import { OracleTypes as OracleTypes12 } from "@midas-capital/types";
var baseOracles6 = [OracleTypes12.FixedNativePriceOracle, OracleTypes12.MasterPriceOracle, OracleTypes12.SimplePriceOracle];
var oracles6 = [...baseOracles6, OracleTypes12.ChainlinkPriceOracleV2];
var oracles_default6 = oracles6;

// src/fantom/params.ts
import { assetSymbols as assetSymbols29 } from "@midas-capital/types";
import { BigNumber as BigNumber12 } from "ethers";
var specificParams6 = {
  // ~ 1.5 seconds per block: https://ftmscan.com/chart/blocktime
  blocksPerYear: BigNumber12.from((40 * 24 * 365 * 60).toString()),
  cgId: "fantom",
  metadata: {
    chainIdHex: "0xFA",
    name: "Fantom Opera",
    shortName: "Fantom",
    img: "https://d1912tcoux65lj.cloudfront.net/network/fantom.png",
    blockExplorerUrls: { default: { name: "ftmscan", url: "https://ftmscan.com" } },
    rpcUrls: {
      default: { http: ["https://rpcapi.fantom.network"] },
      public: { http: ["https://rpcapi.fantom.network"] }
    },
    nativeCurrency: {
      symbol: "FTM",
      name: "FTM"
    },
    wrappedNativeCurrency: {
      symbol: assetSymbols29.WFTM,
      address: addresses_default6.W_TOKEN,
      name: "WFTM",
      decimals: 18,
      color: "#7A88A1",
      overlayTextColor: "#fff",
      logoURL: "https://d1912tcoux65lj.cloudfront.net/network/fantom.png"
    }
  }
};
var params_default6 = specificParams6;

// src/fantom/plugins.ts
var deployedPlugins6 = {};
var plugins_default6 = deployedPlugins6;

// src/fantom/redemptionStrategies.ts
import { assetSymbols as assetSymbols30, RedemptionStrategyContract as RedemptionStrategyContract5, underlying as underlying19 } from "@midas-capital/types";
var redemptionStrategies6 = {
  [underlying19(assets6, assetSymbols30.MIMO_PAR_75_25)]: [
    RedemptionStrategyContract5.BalancerLpTokenLiquidator,
    underlying19(assets6, assetSymbols30.PAR)
  ],
  [underlying19(assets6, assetSymbols30.PAR)]: [RedemptionStrategyContract5.CurveSwapLiquidator, USDC4],
  [underlying19(assets6, assetSymbols30["PAR_USDC_CURVE"])]: [
    RedemptionStrategyContract5.CurveLpTokenLiquidatorNoRegistry,
    USDC4
  ]
};
var redemptionStrategies_default6 = redemptionStrategies6;

// src/fantom/index.ts
var chainConfig6 = {
  chainId: SupportedChains11.fantom,
  chainAddresses: addresses_default6,
  assets: assets_default5,
  irms: irms_default6,
  liquidationDefaults: liquidation_default6,
  oracles: oracles_default6,
  specificParams: params_default6,
  deployedPlugins: plugins_default6,
  redemptionStrategies: redemptionStrategies_default6,
  fundingStrategies: fundingStrategies_default6,
  chainDeployments: fantom_default.contracts
};
var fantom_default2 = chainConfig6;

// src/ganache/index.ts
import { SupportedChains as SupportedChains12 } from "@midas-capital/types";

// deployments/ganache.json
var ganache_default = {
  name: "localhost",
  chainId: "1337",
  contracts: {
    AddressesProvider: {
      address: "0x5B3639BaDD3A08da48cBfb8F8451ff0035d9a4c8"
    },
    AddressesProvider_Implementation: {
      address: "0xb9e1c2B011f252B9931BBA7fcee418b95b6Bdc31"
    },
    AddressesProvider_Proxy: {
      address: "0x5B3639BaDD3A08da48cBfb8F8451ff0035d9a4c8"
    },
    CErc20Delegate: {
      address: "0x6475789448864C5FA6fA2714331E4352b4187289"
    },
    CErc20PluginDelegate: {
      address: "0x0689f3F569fdEff58967d0F4e41A690aa759596e"
    },
    CErc20PluginRewardsDelegate: {
      address: "0xE622C3c867FC0Dbc264E4b9D4c92586f1C8D80cf"
    },
    CEtherDelegate: {
      address: "0x0942Fb84dF77A1Ae96Af3903A5a245995e7fAEf1"
    },
    Comptroller: {
      address: "0x65305b46116cc5182eDf3CE2E784a659f14F8Fa1"
    },
    DefaultProxyAdmin: {
      address: "0x9b30a238A94c5a456a02ceC01e41f1c91d54e915"
    },
    FixedNativePriceOracle: {
      address: "0xb707Ad431E9eeBB88697309663dF95aA9e6f79a2"
    },
    FuseFeeDistributor: {
      address: "0xf656D243a23A0987329ac6522292f4104A7388e1"
    },
    FuseFeeDistributor_Implementation: {
      address: "0x806586bC8f8ACd0Fc7B3b736124246fF981E210B"
    },
    FuseFeeDistributor_Proxy: {
      address: "0xf656D243a23A0987329ac6522292f4104A7388e1"
    },
    FuseFlywheelCore_TOUCH: {
      address: "0x04E45982FCAdB95abaF9A6Aa9502532832A3cF22"
    },
    FuseFlywheelDynamicRewardsPlugin_TOUCH: {
      address: "0x56385f347e18452C00801c9E5029E7658B017EB5"
    },
    FuseFlywheelLensRouter: {
      address: "0x35f526B02080255BEC7bA9CD041ECba9f8Ca3A69"
    },
    FusePoolDirectory: {
      address: "0xb61b03968B3b92C7f1824c2329A7bd83c06d7C40"
    },
    FusePoolDirectory_Implementation: {
      address: "0x1c539dE11de3A99aA9d3FFB08a18095C34F49840"
    },
    FusePoolDirectory_Proxy: {
      address: "0xb61b03968B3b92C7f1824c2329A7bd83c06d7C40"
    },
    FusePoolLens: {
      address: "0x690f45D646D54AaFc2Ca826Af456A07D9FfF5950"
    },
    FusePoolLensSecondary: {
      address: "0x370D9B40Ed935485D645851953dc3bD3c2a51131"
    },
    FuseSafeLiquidator: {
      address: "0x6F747d2A8900A04247F491d894D7765FdEc0D97a"
    },
    FuseSafeLiquidator_Implementation: {
      address: "0xf93c846c5FB6097900e60785886935eea6dd2C5F"
    },
    FuseSafeLiquidator_Proxy: {
      address: "0x6F747d2A8900A04247F491d894D7765FdEc0D97a"
    },
    InitializableClones: {
      address: "0xcdaf7CBA50d9511e6FCdeD625c9039576DeF7932"
    },
    JumpRateModel: {
      address: "0x2fCa24E19C67070467927DDB85810fF766423e8e"
    },
    MasterPriceOracle: {
      address: "0xcC6Aa628516BB46391B05B16E5058c877461cc76"
    },
    MasterPriceOracle_Implementation: {
      address: "0x14C15B9ec83ED79f23BF71D51741f58b69ff1494"
    },
    MasterPriceOracle_Proxy: {
      address: "0xcC6Aa628516BB46391B05B16E5058c877461cc76"
    },
    RewardsDistributorDelegate: {
      address: "0xAe12c1932CA2A1711A16EdaB464b72d44c4c6767"
    },
    SimplePriceOracle: {
      address: "0x935a1E31515175269aD16F9d53783F31450B6e82"
    },
    TOUCHToken: {
      address: "0xD54Ae101D6980dB5a8Aa60124b2e5D4B7f02f12C"
    },
    TRIBEToken: {
      address: "0xeD4764ad14Bb60DC698372B92e51CEC62688DC52"
    },
    WETH: {
      address: "0x37b3890B9b3a5e158EAFDA243d4640c5349aFC15"
    },
    WhitePaperInterestRateModel: {
      address: "0x193E8ffD909c919E406776906F3De68CA202BE61"
    }
  }
};

// src/ganache/addresses.ts
import { assetSymbols as assetSymbols32, underlying as underlying20 } from "@midas-capital/types";
import { ethers as ethers8 } from "ethers";

// src/ganache/assets.ts
import { assetSymbols as assetSymbols31, OracleTypes as OracleTypes13 } from "@midas-capital/types";
var assets7 = [
  {
    symbol: assetSymbols31.WETH,
    underlying: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    name: "Wrapped Ether",
    decimals: 18,
    oracle: OracleTypes13.FixedNativePriceOracle
  },
  {
    symbol: assetSymbols31.TOUCH,
    underlying: "0x02Ec29Fd9f0bB212eD2C4926ACe1aeab732ed620",
    name: "Touch Token",
    decimals: 18,
    oracle: OracleTypes13.SimplePriceOracle
  },
  {
    symbol: assetSymbols31.TRIBE,
    underlying: "0xf9a089C918ad9c484201E7d328C0d29019997117",
    name: "Tribe Token",
    decimals: 18,
    oracle: OracleTypes13.SimplePriceOracle
  }
];
var assets_default6 = assets7;

// src/ganache/addresses.ts
var chainAddresses7 = {
  W_TOKEN: underlying20(assets7, assetSymbols32.WETH),
  W_TOKEN_USD_CHAINLINK_PRICE_FEED: "0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419",
  UNISWAP_V2_ROUTER: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
  UNISWAP_V2_FACTORY: "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f",
  PAIR_INIT_HASH: ethers8.utils.hexlify("0x96e8ac4277198ff8b6f785478aa9a39f403cb768dd02cbee326c3e7da348845f"),
  STABLE_TOKEN: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  W_BTC_TOKEN: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599"
};
var addresses_default7 = chainAddresses7;

// src/ganache/fundingStrategies.ts
var fundingStrategies7 = {};
var fundingStrategies_default7 = fundingStrategies7;

// src/ganache/irms.ts
import { IrmTypes as IrmTypes7 } from "@midas-capital/types";
var baseIrms7 = [IrmTypes7.WhitePaperInterestRateModel, IrmTypes7.JumpRateModel];
var irms7 = [...baseIrms7];
var irms_default7 = irms7;

// src/ganache/liquidation.ts
import {
  assetSymbols as assetSymbols33,
  LiquidationStrategy as LiquidationStrategy7,
  underlying as underlying21
} from "@midas-capital/types";
import { BigNumber as BigNumber13, constants as constants7 } from "ethers";
var liquidationDefaults7 = {
  DEFAULT_ROUTER: addresses_default7.UNISWAP_V2_ROUTER,
  ASSET_SPECIFIC_ROUTER: {},
  SUPPORTED_OUTPUT_CURRENCIES: [constants7.AddressZero, underlying21(assets7, assetSymbols33.WETH)],
  SUPPORTED_INPUT_CURRENCIES: [constants7.AddressZero, underlying21(assets7, assetSymbols33.WETH)],
  LIQUIDATION_STRATEGY: LiquidationStrategy7.DEFAULT,
  MINIMUM_PROFIT_NATIVE: BigNumber13.from(0),
  LIQUIDATION_INTERVAL_SECONDS: 2,
  jarvisPools: [],
  balancerPools: []
};
var liquidation_default7 = liquidationDefaults7;

// src/ganache/oracles.ts
import { OracleTypes as OracleTypes14 } from "@midas-capital/types";
var baseOracles7 = [OracleTypes14.FixedNativePriceOracle, OracleTypes14.MasterPriceOracle, OracleTypes14.SimplePriceOracle];
var oracles7 = [...baseOracles7];
var oracles_default7 = oracles7;

// src/ganache/params.ts
import { assetSymbols as assetSymbols34 } from "@midas-capital/types";
import { BigNumber as BigNumber14 } from "ethers";
var specificParams7 = {
  blocksPerYear: BigNumber14.from((5 * 24 * 365 * 60).toString()),
  cgId: "ethereum",
  metadata: {
    chainIdHex: "0x539",
    name: "Ganache",
    shortName: "Ganache",
    img: "https://d1912tcoux65lj.cloudfront.net/network/hardhat.png",
    rpcUrls: { default: { http: ["http://localhost:8545"] }, public: { http: ["http://localhost:8545"] } },
    blockExplorerUrls: { default: { name: "Etherscan", url: "http://localhost:3000" } },
    nativeCurrency: {
      symbol: "ETH",
      name: "Ganache"
    },
    wrappedNativeCurrency: {
      symbol: assetSymbols34.WETH,
      address: addresses_default7.W_TOKEN,
      name: "Ganache",
      decimals: 18,
      color: "#627EEA",
      overlayTextColor: "#fff",
      logoURL: "https://d1912tcoux65lj.cloudfront.net/network/hardhat.png"
    },
    testnet: true
  }
};
var params_default7 = specificParams7;

// src/ganache/plugins.ts
var deployedPlugins7 = {};
var plugins_default7 = deployedPlugins7;

// src/ganache/redemptionStrategies.ts
var redemptionStrategies7 = {};
var redemptionStrategies_default7 = redemptionStrategies7;

// src/ganache/index.ts
var chainConfig7 = {
  chainId: SupportedChains12.ganache,
  chainAddresses: addresses_default7,
  assets: assets_default6,
  irms: irms_default7,
  liquidationDefaults: liquidation_default7,
  oracles: oracles_default7,
  specificParams: params_default7,
  deployedPlugins: plugins_default7,
  redemptionStrategies: redemptionStrategies_default7,
  fundingStrategies: fundingStrategies_default7,
  chainDeployments: ganache_default.contracts
};
var ganache_default2 = chainConfig7;

// src/lineagoerli/index.ts
import { SupportedChains as SupportedChains14 } from "@midas-capital/types";

// deployments/lineagoerli.json
var lineagoerli_default = {
  name: "lineagoerli",
  chainId: "59140",
  contracts: {
    AddressesProvider: {
      address: "0x9368f3a490f0f97e308C7d5CC040aeF3987dD5a5"
    },
    AddressesProvider_Implementation: {
      address: "0x7ff5F79258B6aEE77D665D2EBdb60ec21B0Bc88b"
    },
    AddressesProvider_Proxy: {
      address: "0x9368f3a490f0f97e308C7d5CC040aeF3987dD5a5"
    },
    CErc20Delegate: {
      address: "0xA587B957a191E85ee02DD198A56e79d263Bbc64c"
    },
    CErc20PluginDelegate: {
      address: "0xD51A8642218A421A9dA6aA00f87d9C5bEEB24EF4"
    },
    CErc20PluginRewardsDelegate: {
      address: "0xed5886Fcf8B390210B387432431159dCfA5D8763"
    },
    CTokenFirstExtension: {
      address: "0x55C0f57cf6c1CAe4CC57D5F6334408f63C0d5bbC"
    },
    Comptroller: {
      address: "0x219f7a75925D6d0312d1B62cEE6a4eE6F1A85217"
    },
    ComptrollerFirstExtension: {
      address: "0x5f2ed9Ef856e640A857af361222A6B856f3B55F9"
    },
    DefaultProxyAdmin: {
      address: "0xC86CD4882a5019004675099CEEa90923BbF5cA72"
    },
    FixedNativePriceOracle: {
      address: "0x4Fb711fEB2d8D8544379665E552434C086Fe14e3"
    },
    FuseFeeDistributor: {
      address: "0xd36568FEcBCe1b5784db43C2F988Df358E04bBA0"
    },
    FuseFeeDistributor_Implementation: {
      address: "0x2a8541A102dE64aFf94f53558ABecfA9407a3086"
    },
    FuseFeeDistributor_Proxy: {
      address: "0xd36568FEcBCe1b5784db43C2F988Df358E04bBA0"
    },
    FusePoolDirectory: {
      address: "0x9B4910cEAfb03056DddF9A1CB284C58f1d85d8A4"
    },
    FusePoolDirectory_Implementation: {
      address: "0xBdd763290371E63507910D51F6812717fC2A8A5c"
    },
    FusePoolDirectory_Proxy: {
      address: "0x9B4910cEAfb03056DddF9A1CB284C58f1d85d8A4"
    },
    FusePoolLens: {
      address: "0x23DB6596145883475C0B8425802FA63Add5CF7b8"
    },
    FusePoolLensSecondary: {
      address: "0x63b484896Bd2A381f1E308088A65bf9E292FBc7a"
    },
    FuseSafeLiquidator: {
      address: "0x57F4dA24e95DCb26A58b45CecaCeaE858215e1C2"
    },
    FuseSafeLiquidator_Implementation: {
      address: "0x0b7c55eD8f7Bc25c20eA4FdFbfED79d4AeB9aCaE"
    },
    FuseSafeLiquidator_Proxy: {
      address: "0x57F4dA24e95DCb26A58b45CecaCeaE858215e1C2"
    },
    JumpRateModel: {
      address: "0x20c0D39eFDDf10cccB6CB0b2d4D16cc6B17CB7Fd"
    },
    LeveredPositionFactory: {
      address: "0x9b64b5336aCFc63bFa1E4992C42a8797fFcC1B83"
    },
    LeveredPositionFactoryExtension: {
      address: "0x05ac34CF184A6dC188D66921F0A028022a713eee"
    },
    LeveredPositionsLens: {
      address: "0x4682b914C351830e9e289C02ec312e453f017eE2"
    },
    LeveredPositionsLens_Implementation: {
      address: "0x705dd6236C0Eb8c000bac6FB2B5FDAe1D588ACaa"
    },
    LeveredPositionsLens_Proxy: {
      address: "0x4682b914C351830e9e289C02ec312e453f017eE2"
    },
    LiquidatorsRegistry: {
      address: "0x01375b03Be806e608b2C7Cb93373d01161Cf18f2"
    },
    LiquidatorsRegistryExtension: {
      address: "0xf5BCDDDe61EbfBb267061b84fe9eD92f8428B84d"
    },
    LooplessFlywheelBooster: {
      address: "0x09FC7b0459963b6A7Fe7197b43c062f025a4c3b9"
    },
    MasterPriceOracle: {
      address: "0xd1321414EFe60bcB381C40Ee3beebE3e0d00375F"
    },
    MasterPriceOracle_Implementation: {
      address: "0xe8589287762914eef5e08De9f17d4e46E1628d05"
    },
    MasterPriceOracle_Proxy: {
      address: "0xd1321414EFe60bcB381C40Ee3beebE3e0d00375F"
    },
    MidasFlywheelLensRouter: {
      address: "0x772b5bf3A94fb6B6Fcbd3Ef528D6AA145164D86E"
    },
    OptimizedVaultsRegistry: {
      address: "0xB077105Fdf4eb4D1f3360f1CfF8B10398075eE8c"
    },
    OptimizedVaultsRegistry_Implementation: {
      address: "0x7726B17dB8cb945959039fe169e9aa3296D6B686"
    },
    OptimizedVaultsRegistry_Proxy: {
      address: "0xB077105Fdf4eb4D1f3360f1CfF8B10398075eE8c"
    },
    SimplePriceOracle: {
      address: "0xBeE20e560E23632b5BAE176cDF5f12851118d587"
    },
    SimplePriceOracle_Implementation: {
      address: "0x166853e3cCBcc406798E86B7eC9e2C7EF3bab28a"
    },
    SimplePriceOracle_Proxy: {
      address: "0xBeE20e560E23632b5BAE176cDF5f12851118d587"
    },
    UmbrellaPriceOracle: {
      address: "0xFeD26a4807f0af0F4FdFD59C8940a0c06FcCD8a2"
    },
    UmbrellaPriceOracle_Implementation: {
      address: "0x16A4CCFE86aD6d6BfF8072eb4e7cA7eb9C6B727E"
    },
    UmbrellaPriceOracle_Proxy: {
      address: "0xFeD26a4807f0af0F4FdFD59C8940a0c06FcCD8a2"
    },
    WhitePaperInterestRateModel: {
      address: "0xFfcD5BeC57EbF1B9AC513D1340c13a3A9d2a053e"
    }
  }
};

// src/lineagoerli/addresses.ts
import { assetSymbols as assetSymbols36, underlying as underlying22 } from "@midas-capital/types";
import { ethers as ethers9 } from "ethers";

// src/lineagoerli/assets.ts
import { assetSymbols as assetSymbols35, OracleTypes as OracleTypes15, SupportedChains as SupportedChains13 } from "@midas-capital/types";
var WBTC3 = "0xDbcd5BafBAA8c1B326f14EC0c8B125DB57A5cC4c";
var USDC5 = "0xf56dc6695cF1f5c364eDEbC7Dc7077ac9B586068";
var WETH3 = "0x2C1b868d6596a18e32E61B901E4060C872647b6C";
var DAI5 = "0x8741Ba6225A6BF91f9D73531A98A89807857a2B3";
var USDT4 = "0x1990BC6dfe2ef605Bfc08f5A23564dB75642Ad73";
var assets8 = [
  {
    symbol: assetSymbols35.USDC,
    underlying: USDC5,
    name: "USD Coin",
    decimals: 6,
    oracle: OracleTypes15.UmbrellaPriceOracle,
    extraDocs: defaultDocs("https://explorer.goerli.linea.build", USDC5)
  },
  {
    symbol: assetSymbols35.WBTC,
    underlying: WBTC3,
    name: "Wrapped BTC",
    decimals: 18,
    oracle: OracleTypes15.UmbrellaPriceOracle,
    extraDocs: defaultDocs("https://explorer.goerli.linea.build", WBTC3)
  },
  {
    symbol: assetSymbols35.WETH,
    underlying: WETH3,
    name: "Wrapped Ether",
    decimals: 18,
    oracle: OracleTypes15.FixedNativePriceOracle,
    extraDocs: wrappedAssetDocs(SupportedChains13.ethereum)
  },
  {
    symbol: assetSymbols35.DAI,
    underlying: DAI5,
    name: "DAI Token",
    decimals: 18,
    oracle: OracleTypes15.UmbrellaPriceOracle,
    extraDocs: defaultDocs("https://explorer.goerli.linea.build", DAI5)
  },
  {
    symbol: assetSymbols35.USDT,
    underlying: USDT4,
    name: "USD Tether",
    decimals: 6,
    oracle: OracleTypes15.UmbrellaPriceOracle,
    extraDocs: defaultDocs("https://explorer.goerli.linea.build", USDT4)
  }
];

// src/lineagoerli/addresses.ts
var chainAddresses8 = {
  W_TOKEN: underlying22(assets8, assetSymbols36.WETH),
  W_TOKEN_USD_CHAINLINK_PRICE_FEED: ethers9.constants.AddressZero,
  UNISWAP_V2_ROUTER: ethers9.constants.AddressZero,
  UNISWAP_V2_FACTORY: ethers9.constants.AddressZero,
  UNISWAP_V3_ROUTER: ethers9.constants.AddressZero,
  PAIR_INIT_HASH: ethers9.utils.hexlify("0x96e8ac4277198ff8b6f785478aa9a39f403cb768dd02cbee326c3e7da348845f"),
  STABLE_TOKEN: underlying22(assets8, assetSymbols36.USDC),
  W_BTC_TOKEN: underlying22(assets8, assetSymbols36.WBTC),
  UNISWAP_V3: UNISWAP_V3_ADDRESSES
};
var addresses_default8 = chainAddresses8;

// src/lineagoerli/fundingStrategies.ts
var fundingStrategies8 = {};
var fundingStrategies_default8 = fundingStrategies8;

// src/lineagoerli/irms.ts
import { IrmTypes as IrmTypes8 } from "@midas-capital/types";
var baseIrms8 = [IrmTypes8.WhitePaperInterestRateModel, IrmTypes8.JumpRateModel];
var irms8 = [...baseIrms8];
var irms_default8 = irms8;

// src/lineagoerli/liquidation.ts
import { LiquidationStrategy as LiquidationStrategy8 } from "@midas-capital/types";
import { BigNumber as BigNumber15, constants as constants8 } from "ethers";
var liquidationDefaults8 = {
  DEFAULT_ROUTER: addresses_default8.UNISWAP_V2_ROUTER,
  ASSET_SPECIFIC_ROUTER: {},
  SUPPORTED_OUTPUT_CURRENCIES: [constants8.AddressZero, WETH3, USDC5],
  SUPPORTED_INPUT_CURRENCIES: [constants8.AddressZero, WETH3],
  LIQUIDATION_STRATEGY: LiquidationStrategy8.UNISWAP,
  MINIMUM_PROFIT_NATIVE: BigNumber15.from(0),
  LIQUIDATION_INTERVAL_SECONDS: 20,
  jarvisPools: [],
  balancerPools: []
};
var liquidation_default8 = liquidationDefaults8;

// src/lineagoerli/oracles.ts
import { OracleTypes as OracleTypes16 } from "@midas-capital/types";
var baseOracles8 = [OracleTypes16.FixedNativePriceOracle, OracleTypes16.MasterPriceOracle, OracleTypes16.SimplePriceOracle];
var oracles8 = [...baseOracles8, OracleTypes16.UmbrellaPriceOracle];
var oracles_default8 = oracles8;

// src/lineagoerli/params.ts
import { assetSymbols as assetSymbols38 } from "@midas-capital/types";
import { BigNumber as BigNumber16 } from "ethers";
var specificParams8 = {
  blocksPerYear: BigNumber16.from((5 * 24 * 365 * 60).toString()),
  cgId: "ethereum",
  metadata: {
    chainIdHex: "0xE704",
    name: "Linea Goerli Testnet",
    shortName: "Linea Goerli",
    uniswapV3Fees: {},
    img: "https://d1912tcoux65lj.cloudfront.net/network/lineagoerli.png",
    blockExplorerUrls: { default: { name: "lineascan", url: "https://goerli.lineascan.build/" } },
    rpcUrls: {
      default: { http: ["https://rpc.goerli.linea.build"] },
      public: { http: ["https://rpc.goerli.linea.build"] }
    },
    nativeCurrency: {
      symbol: "ETH",
      name: "ETH"
    },
    wrappedNativeCurrency: {
      symbol: assetSymbols38.WETH,
      address: addresses_default8.W_TOKEN,
      name: "WETH",
      decimals: 18,
      color: "#7A88A1",
      overlayTextColor: "#fff",
      logoURL: "https://d1912tcoux65lj.cloudfront.net/network/ethereum.png"
    }
  }
};
var params_default8 = specificParams8;

// src/lineagoerli/plugins.ts
var deployedPlugins8 = {};
var plugins_default8 = deployedPlugins8;

// src/lineagoerli/redemptionStrategies.ts
var redemptionStrategies8 = {};
var redemptionStrategies_default8 = redemptionStrategies8;

// src/lineagoerli/index.ts
var chainConfig8 = {
  chainId: SupportedChains14.lineagoerli,
  chainAddresses: addresses_default8,
  assets: assets8,
  irms: irms_default8,
  liquidationDefaults: liquidation_default8,
  oracles: oracles_default8,
  specificParams: params_default8,
  deployedPlugins: plugins_default8,
  redemptionStrategies: redemptionStrategies_default8,
  fundingStrategies: fundingStrategies_default8,
  chainDeployments: lineagoerli_default.contracts
};
var lineagoerli_default2 = chainConfig8;

// src/moonbeam/index.ts
import { SupportedChains as SupportedChains16 } from "@midas-capital/types";

// deployments/moonbeam.json
var moonbeam_default = {
  name: "moonbeam",
  chainId: "1284",
  contracts: {
    AddressesProvider: {
      address: "0x771ee5a72A57f3540E5b9A6A8C226C2a24A70Fae"
    },
    AddressesProvider_Implementation: {
      address: "0xe39F5730A845c14558d9e52504d36f634d5AC20C"
    },
    AddressesProvider_Proxy: {
      address: "0x771ee5a72A57f3540E5b9A6A8C226C2a24A70Fae"
    },
    AdjustableJumpRateModel_MIXBYTES_USDC: {
      address: "0xd114655edc91ED00841d1A69dd8c2C02B54996bb"
    },
    AdjustableJumpRateModel_MIXBYTES_XCDOT: {
      address: "0x27Db2518FB95C30B75094317349e1C5BCC241cf9"
    },
    CErc20Delegate: {
      address: "0x889e1AB929534AB8aa966F6A8Cd9f348343Fa98F"
    },
    CErc20PluginDelegate: {
      address: "0xc4F946fCaD90555CC25412D0933FD6c7B3E3d579"
    },
    CErc20PluginRewardsDelegate: {
      address: "0x8439Bbf90D0C290B762209ef0fE8C5F16a3A66DA"
    },
    CEtherDelegate: {
      address: "0x7698198376DAAB5097c9A327250BD07062118626"
    },
    CTokenFirstExtension: {
      address: "0xA7B683c9445277534577973A51E0B052934562b9"
    },
    ChainlinkPriceOracleV2: {
      address: "0xA443095Fa1B5C9E6B47dB99042097aee24AAc6d3"
    },
    Comptroller: {
      address: "0x9bb5B30BF73E82A3b720eb7396407F43407ac537"
    },
    ComptrollerFirstExtension: {
      address: "0xBe99e316BdCF1091450E4300202e3FCB5da55756"
    },
    "CurveGaugeERC4626_xcDOT-stDOT_0xe4C6Bd326a1715cEbFeD3647A963a308Ae7F8A98": {
      address: "0xE9c4274341ab4Be0857476e84963b3c36787568D"
    },
    "CurveGaugeERC4626_xcDOT-stDOT_0xe4C6Bd326a1715cEbFeD3647A963a308Ae7F8A98_Implementation": {
      address: "0x9cC2C617a9F12A4D978037A709e12886b277eD9F"
    },
    "CurveGaugeERC4626_xcDOT-stDOT_0xe4C6Bd326a1715cEbFeD3647A963a308Ae7F8A98_Proxy": {
      address: "0xE9c4274341ab4Be0857476e84963b3c36787568D"
    },
    CurveLpTokenLiquidatorNoRegistry: {
      address: "0x3aaaEbf49749F6643De91C45Cd47cAD4099063E7"
    },
    CurveLpTokenPriceOracleNoRegistry: {
      address: "0x315b23e85E1ad004A466f3C89544794Ef3392179"
    },
    CurveLpTokenPriceOracleNoRegistry_Implementation: {
      address: "0x260F73299DA85922D8A4c6A7EcaECAF2777C091A"
    },
    CurveLpTokenPriceOracleNoRegistry_Proxy: {
      address: "0x315b23e85E1ad004A466f3C89544794Ef3392179"
    },
    CurveSwapLiquidator: {
      address: "0x6cEE87F4555D90F6aC7A20c146C5DF96481285D6"
    },
    DefaultProxyAdmin: {
      address: "0x806586bC8f8ACd0Fc7B3b736124246fF981E210B"
    },
    DiaPriceOracle: {
      address: "0x21e3Fe865721797440cDb8c2cAFAC098Cc1a9004"
    },
    DiaStDotPriceOracle: {
      address: "0xB0ffCf0ba7e2F6fF303e3CC5100333a811A363C1"
    },
    DiaStDotPriceOracle_Implementation: {
      address: "0xFE9B92522853F1d0c85f6fe6bD76d56c57DD6405"
    },
    DiaStDotPriceOracle_Proxy: {
      address: "0xB0ffCf0ba7e2F6fF303e3CC5100333a811A363C1"
    },
    FixedNativePriceOracle: {
      address: "0xbccAaB8953C8a16FC39CfE9Ff3351E07262dBa92"
    },
    FuseFeeDistributor: {
      address: "0x06cA643287642acbb008E5a050668c8e26bA0814"
    },
    FuseFeeDistributor_Implementation: {
      address: "0x7bAa4A79E0a143Fa893858ac435AEa8cfA68a96B"
    },
    FuseFeeDistributor_Proxy: {
      address: "0x06cA643287642acbb008E5a050668c8e26bA0814"
    },
    FuseFlywheelDynamicRewardsPlugin_LDO: {
      address: "0xAe81EA3567e1257d9862421db181A735694c3809"
    },
    FuseFlywheelDynamicRewardsPlugin_STELLA: {
      address: "0xF28BbF7483be1Ed508159486dDe3cFd5bE93A529"
    },
    FuseFlywheelDynamicRewardsPlugin_WGLMR: {
      address: "0xB6E9eA70E5C1E5Fa92ef8c154437AE875A25feF3"
    },
    FuseFlywheelLensRouter: {
      address: "0xb36badd5a3040fC2E950E087bf2B9BCfdb45aC1e"
    },
    FusePoolDirectory: {
      address: "0x64d9a81F1412c7691e7731C66A290aeE8FA21f10"
    },
    FusePoolDirectory_Implementation: {
      address: "0x5dCE4d771Cb30C36dEdBA61eEF776140f5C277Bb"
    },
    FusePoolDirectory_Proxy: {
      address: "0x64d9a81F1412c7691e7731C66A290aeE8FA21f10"
    },
    FusePoolLens: {
      address: "0x52643620755Bda9d6E0f10Fa796375f1fc0F2Ca3"
    },
    FusePoolLensSecondary: {
      address: "0xf12F6aB676263e73a9D5e4456B931FEF4F028cdf"
    },
    FuseSafeLiquidator: {
      address: "0xb4A9ebf1A0dcf58e0EF936cF1ca49067Ba49228B"
    },
    FuseSafeLiquidator_Implementation: {
      address: "0xEBc3697BA85b403c1eb79a8ea1B90629ABe21a7E"
    },
    FuseSafeLiquidator_Proxy: {
      address: "0xb4A9ebf1A0dcf58e0EF936cF1ca49067Ba49228B"
    },
    JumpRateModel: {
      address: "0xFbf5124ed4700564a17E0b9E43880B39177c54eF"
    },
    LeveredPositionFactory: {
      address: "0xa1E8f53Af27263a15E20C58476D261e2c27C6985"
    },
    LeveredPositionFactoryExtension: {
      address: "0x7d310205dC214a305904a2a13bb6A87ba0C0Ce94"
    },
    LeveredPositionsLens: {
      address: "0x8a571d80CaB8bf2101dbA71F4FeaC2216b507403"
    },
    LeveredPositionsLens_Implementation: {
      address: "0x21fAe78928806E5CB02700Ce9c7d6C1a9E9C0093"
    },
    LeveredPositionsLens_Proxy: {
      address: "0x8a571d80CaB8bf2101dbA71F4FeaC2216b507403"
    },
    LiquidatorsRegistry: {
      address: "0xBC442ca628753f6e70620B32f9B88E6980B78219"
    },
    LiquidatorsRegistryExtension: {
      address: "0xf06e7DDD892905323299B808792777a52ab377d6"
    },
    LooplessFlywheelBooster: {
      address: "0x0D71831F4f819057A606656D5eF2BEe0bF491f29"
    },
    MasterPriceOracle: {
      address: "0x14C15B9ec83ED79f23BF71D51741f58b69ff1494"
    },
    MasterPriceOracle_Implementation: {
      address: "0xDd4b4965516c5aadE56c794Cb0e30555f2da306f"
    },
    MasterPriceOracle_Proxy: {
      address: "0x14C15B9ec83ED79f23BF71D51741f58b69ff1494"
    },
    MidasFlywheelLensRouter: {
      address: "0x968188FD4Bc26D120368Cec1410677B035848301"
    },
    MidasFlywheel_LDO: {
      address: "0xb13aAD1F212FdE08a5ab51249cf6434574434A4e"
    },
    MidasFlywheel_LDO_Implementation: {
      address: "0xd29696d4A5295d6987150B07F47444391b20D539"
    },
    MidasFlywheel_LDO_Proxy: {
      address: "0xb13aAD1F212FdE08a5ab51249cf6434574434A4e"
    },
    MidasFlywheel_LDO_stDOT_pool: {
      address: "0x65b7a52423515451fFa7E1B4774CA6671C4d4aBF"
    },
    MidasFlywheel_LDO_stDOT_pool_Implementation: {
      address: "0x7c58a779Ba75FBc93441f931996Cdbfc04feF314"
    },
    MidasFlywheel_LDO_stDOT_pool_Proxy: {
      address: "0x65b7a52423515451fFa7E1B4774CA6671C4d4aBF"
    },
    MidasFlywheel_STELLA: {
      address: "0x601ac0Aa2aFdfC9Bd080A41E7BeF95D0b1aff7f9"
    },
    MidasFlywheel_STELLA_Implementation: {
      address: "0x59a638381AD65B6DC285Dd47a227B219222d1694"
    },
    MidasFlywheel_STELLA_Proxy: {
      address: "0x601ac0Aa2aFdfC9Bd080A41E7BeF95D0b1aff7f9"
    },
    MidasFlywheel_STELLA_StellaSwap_pool: {
      address: "0xCD9C8bFC3a2C17021038Fc0B6297fc21b01Daab5"
    },
    MidasFlywheel_STELLA_StellaSwap_pool_Implementation: {
      address: "0xe17e8f27EE542E646e0BE82f7c110C157477F9F5"
    },
    MidasFlywheel_STELLA_StellaSwap_pool_Proxy: {
      address: "0xCD9C8bFC3a2C17021038Fc0B6297fc21b01Daab5"
    },
    MidasFlywheel_USDC_stDOR_pool: {
      address: "0xe279668ACD0a6825221fda27aa158DF2b9Ee5C43"
    },
    MidasFlywheel_USDC_stDOR_pool_Implementation: {
      address: "0xCAA7462B4c3E0fa71F1fbD8e902dac70cA096357"
    },
    MidasFlywheel_USDC_stDOR_pool_Proxy: {
      address: "0xe279668ACD0a6825221fda27aa158DF2b9Ee5C43"
    },
    MidasFlywheel_WGLMR: {
      address: "0x22F1B04104f994D27B89cDB1d7f98102cA5F30cB"
    },
    MidasFlywheel_WGLMR_Implementation: {
      address: "0x655c7e8a2F92898301586C6c5c34eD0a47Ad352b"
    },
    MidasFlywheel_WGLMR_Proxy: {
      address: "0x22F1B04104f994D27B89cDB1d7f98102cA5F30cB"
    },
    OptimizedVaultsRegistry: {
      address: "0xC7E9eBBE065FA096264dB1dbDc8A16c66000Ff17"
    },
    OptimizedVaultsRegistry_Implementation: {
      address: "0x738f38751f967bBb61c576A946F9110035013108"
    },
    OptimizedVaultsRegistry_Proxy: {
      address: "0xC7E9eBBE065FA096264dB1dbDc8A16c66000Ff17"
    },
    ReplacingFlywheelDynamicRewards_ATOM: {
      address: "0x47246c2e75409284b6409534d410245Ee48002c7"
    },
    ReplacingFlywheelDynamicRewards_GLINT: {
      address: "0x99d485eF4425862D90C8301725DF670c4b8B71EC"
    },
    ReplacingFlywheelDynamicRewards_LDO: {
      address: "0x225F4bB69B023e0299b4185EdB7D6E809a35661E"
    },
    ReplacingFlywheelDynamicRewards_STELLA: {
      address: "0x8C2248FB5A5b8b35530A5A715791CaA4e4503FD7"
    },
    ReplacingFlywheelDynamicRewards_WGLMR: {
      address: "0x94Df0eBbc7e6264F9970A961DA81A6dBEa28a904"
    },
    SaddleLpPriceOracle: {
      address: "0x2D2AA009b467Cd6c34D991D6434fB3365D179679"
    },
    SaddleLpPriceOracle_Implementation: {
      address: "0xC075244c4A0bE7BC76F68dA32E41f7E9Ad8786cf"
    },
    SaddleLpPriceOracle_Proxy: {
      address: "0x2D2AA009b467Cd6c34D991D6434fB3365D179679"
    },
    SaddleLpTokenLiquidator: {
      address: "0xF7FBB2b467fc3F80c50298dbA2D81E0B546c3411"
    },
    SimplePriceOracle: {
      address: "0xE2740f47C26f43e56bE3EeCe7c01a5187C766FB9"
    },
    SimplePriceOracle_Implementation: {
      address: "0x48e83349f0D5C6093AEfa2D3EEE3e9e3DE77cE58"
    },
    SimplePriceOracle_Proxy: {
      address: "0xE2740f47C26f43e56bE3EeCe7c01a5187C766FB9"
    },
    "StellaLpERC4626_DOT.xc-GLMR_0xe2b3FC05C0c90c38BCa6E149D186571D413F5f5A": {
      address: "0xe32f52f8172E3c75dacB3606e254275ab774d88f"
    },
    "StellaLpERC4626_DOT.xc-GLMR_0xe2b3FC05C0c90c38BCa6E149D186571D413F5f5A_Implementation": {
      address: "0xC6edFE2a437899A65230DcEc50b9576A12Fe356c"
    },
    "StellaLpERC4626_DOT.xc-GLMR_0xe2b3FC05C0c90c38BCa6E149D186571D413F5f5A_Proxy": {
      address: "0xe32f52f8172E3c75dacB3606e254275ab774d88f"
    },
    "StellaLpERC4626_USDC.wh-GLMR_0xeB7b975C105f05bFb02757fB9bb3361D77AAe84A": {
      address: "0x7EbB783fA23b731c76017bB0656530337Ed31577"
    },
    "StellaLpERC4626_USDC.wh-GLMR_0xeB7b975C105f05bFb02757fB9bb3361D77AAe84A_Implementation": {
      address: "0xd8eBB2C9B8634d38E41AB855bba1DD66428f5b61"
    },
    "StellaLpERC4626_USDC.wh-GLMR_0xeB7b975C105f05bFb02757fB9bb3361D77AAe84A_Proxy": {
      address: "0x7EbB783fA23b731c76017bB0656530337Ed31577"
    },
    "StellaLpERC4626_WBTC.wh-GLMR_0xD7ee88664C269Ec834a471cf47C576B6203cdC45": {
      address: "0x5BF5B16130B90fB636A3b8a136da3944BAACaCAC"
    },
    "StellaLpERC4626_WBTC.wh-GLMR_0xD7ee88664C269Ec834a471cf47C576B6203cdC45_Implementation": {
      address: "0x78816bECA06D9249BCAA268c622E62be13AfD880"
    },
    "StellaLpERC4626_WBTC.wh-GLMR_0xD7ee88664C269Ec834a471cf47C576B6203cdC45_Proxy": {
      address: "0x5BF5B16130B90fB636A3b8a136da3944BAACaCAC"
    },
    "StellaLpERC4626_WETH.wh-GLMR_0x95b1eFbd91E35FdAE0720d1BeDA3d4d1fbfefa1C": {
      address: "0x2a7A88544270bbD181E147B1A1d88d0A78186dA6"
    },
    "StellaLpERC4626_WETH.wh-GLMR_0x95b1eFbd91E35FdAE0720d1BeDA3d4d1fbfefa1C_Implementation": {
      address: "0xF331AaA6EA301fd52c54Ffb674a4865F90BF692e"
    },
    "StellaLpERC4626_WETH.wh-GLMR_0x95b1eFbd91E35FdAE0720d1BeDA3d4d1fbfefa1C_Proxy": {
      address: "0x2a7A88544270bbD181E147B1A1d88d0A78186dA6"
    },
    UniswapLpTokenLiquidator: {
      address: "0x6447E0427bB1Aa39b7E497843fbFEc59400C42f5"
    },
    UniswapLpTokenPriceOracle: {
      address: "0x40f3704C5b04f015BDE6c2b0222b7e884Ee5b0F5"
    },
    UniswapTwapPriceOracleV2: {
      address: "0xd0c3fC3A81F009C82cc029e27ADE9acf95271BC0"
    },
    UniswapTwapPriceOracleV2Factory: {
      address: "0x34899E27FF66300f29fC687ff47C1A0C9E2274ea"
    },
    UniswapTwapPriceOracleV2Resolver: {
      address: "0x1cCb35F33096a116D498f2745aef1303B5450363"
    },
    UniswapTwapPriceOracleV2Root: {
      address: "0x58d7840319ab12fFE1f4A43741F6ad30288DD3Ad"
    },
    UniswapV2LiquidatorFunder: {
      address: "0xB8423EE8aa0476a909786D079dF5C0766cB09142"
    },
    WhitePaperInterestRateModel: {
      address: "0x5169DfAA975CEbC3E26D0c7DFd08fEF7e0A5A759"
    }
  }
};

// src/moonbeam/addresses.ts
import { assetSymbols as assetSymbols40, underlying as underlying24 } from "@midas-capital/types";
import { ethers as ethers10 } from "ethers";

// src/moonbeam/assets.ts
import { assetSymbols as assetSymbols39, OracleTypes as OracleTypes17, SupportedChains as SupportedChains15 } from "@midas-capital/types";
var ATOM = "0x27292cf0016E5dF1d8b37306B2A98588aCbD6fCA";
var xcDOT = "0xFfFFfFff1FcaCBd218EDc0EbA20Fc2308C778080";
var multiWBTC = "0x922D641a426DcFFaeF11680e5358F34d97d112E1";
var stDOT = "0xFA36Fe1dA08C89eC72Ea1F0143a35bFd5DAea108";
var wstDOT = "0x191cf2602Ca2e534c5Ccae7BCBF4C46a704bb949";
var ETH2 = "0xfA9343C3897324496A05fC75abeD6bAC29f8A40f";
var whWETH = "0xab3f0245B83feB11d15AAffeFD7AD465a59817eD";
var whWBTC = "0xE57eBd2d67B462E9926e04a8e33f01cD0D64346D";
var BNB4 = "0xc9BAA8cfdDe8E328787E29b4B078abf2DaDc2055";
var multiDAI = "0x765277EebeCA2e31912C9946eAe1021199B39C61";
var multiUSDC = "0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b";
var multiUSDT = "0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73";
var USDT_xc = "0xFFFFFFfFea09FB06d082fd1275CD48b191cbCD1d";
var BUSD_wh = "0x692C57641fc054c2Ad6551Ccc6566EbA599de1BA";
var whUSDC = "0x931715FEE2d06333043d11F658C8CE934aC61D0c";
var FRAX4 = "0x322E86852e492a7Ee17f28a78c663da38FB33bfb";
var WGLMR = "0xAcc15dC74880C9944775448304B263D191c6077F";
var GLINT = "0xcd3B51D98478D53F4515A306bE565c6EebeF1D58";
var FTM = "0xC19281F22A075E0F10351cd5D6Ea9f0AC63d4327";
var STELLA = "0x0E358838ce72d5e61E0018a2ffaC4bEC5F4c88d2";
var CELR = "0x3795C36e7D12A8c252A20C5a7B455f7c57b60283";
var LDO = "0x9Fda7cEeC4c18008096C2fE2B85F05dc300F94d0";
var GLMR_USDC = "0x555B74dAFC4Ef3A5A1640041e3244460Dc7610d1";
var GLMR_GLINT = "0x99588867e817023162F4d4829995299054a5fC57";
var USDC_ETH2 = "0x0Aa48bF937ee8F41f1a52D225EF5A6F6961e39FA";
var WGLMR_xcDOT = "0xd8FbdeF502770832E90a6352b275f20F38269b74";
var GLMR_ATOM = "0xf4C10263f2A4B1f75b8a5FD5328fb61605321639";
var STELLA_GLMR = "0x7F5Ac0FC127bcf1eAf54E3cd01b00300a0861a62";
var CELR_GLMR = "0xd47BeC28365a82C0C006f3afd617012B02b129D6";
var LDO_GLMR = "0x00870B0e6994fFb142a91173a882d2F6a9a8Ac4a";
var GLMR_whUSDC = "0x8CCBbcAF58f5422F6efD4034d8E8a3c9120ADf79";
var GLMR_xcDOT = "0xa927E1e1E044CA1D9fe1854585003477331fE2Af";
var GLMR_whWETH = "0x8577273FB3B72306F3A59E26ab77116f5D428DAa";
var GLMR_whWBTC = "0xf8f5E8B9Ee84664695B14862212D8092E16331F6";
var wstDOT_xcDOT = "0x61BF1b38930e37850D459f3CB926Cd197F5F88c0";
var base4pool = "0xB326b5189AA42Acaa3C649B120f084Ed8F4dCaA6";
var xcDOT_stDOT = "0xc6e37086D09ec2048F151D11CdB9F9BbbdB7d685";
var assets10 = [
  {
    symbol: assetSymbols39.ATOM,
    underlying: ATOM,
    name: "Axelar ATOM",
    decimals: 18,
    oracle: OracleTypes17.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://moonbeam.moonscan.io", ATOM),
    disabled: true
  },
  {
    symbol: assetSymbols39.multiWBTC,
    underlying: multiWBTC,
    name: "Multichain Wrapped BTC",
    decimals: 8,
    oracle: OracleTypes17.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://moonbeam.moonscan.io", multiWBTC),
    disabled: true
  },
  {
    symbol: assetSymbols39.WBTC_wh,
    underlying: whWBTC,
    name: "Wormhole Wrapped BTC",
    decimals: 8,
    oracle: OracleTypes17.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://moonbeam.moonscan.io", whWBTC)
  },
  {
    symbol: assetSymbols39.xcDOT,
    underlying: xcDOT,
    name: "ERC20 DOT",
    decimals: 10,
    oracle: OracleTypes17.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://moonbeam.moonscan.io", xcDOT)
  },
  {
    symbol: assetSymbols39.stDOT,
    underlying: stDOT,
    name: "Staked ERC20 DOT",
    decimals: 10,
    oracle: OracleTypes17.DiaStDotPriceOracle,
    extraDocs: lidoFinanceDocs("polkadot", "xcDOT", "stDOT")
  },
  {
    symbol: assetSymbols39.wstDOT,
    underlying: wstDOT,
    name: "Wrapped Liquid Staked ERC20 DOT",
    decimals: 10,
    oracle: OracleTypes17.DiaStDotPriceOracle,
    extraDocs: `<p><b>How to acquire this token</b><p/><br />
    <p>You can get <code>stDOT</code> by staking your xcDOT on <a href="https://polkadot.lido.fi/" target="_blank" style="color: #BCAC83; cursor="pointer">Lido on Polkadot</a>.</p><br />
    <p>And wrap <code>stDOT</code> to get <code>wstDOT</code> <a href="https://polkadot.lido.fi/wrap" target="_blank" style="color: #BCAC83; cursor="pointer">at Lido here</a>.</p>`
  },
  {
    symbol: assetSymbols39.ETH,
    underlying: ETH2,
    name: "Multichain ETH",
    decimals: 18,
    oracle: OracleTypes17.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://moonbeam.moonscan.io", ETH2),
    disabled: true
  },
  {
    symbol: assetSymbols39.WETH_wh,
    underlying: whWETH,
    name: "Wormhole Wrapped ETH",
    decimals: 18,
    oracle: OracleTypes17.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://moonbeam.moonscan.io", whWETH)
  },
  {
    symbol: assetSymbols39.BNB,
    underlying: BNB4,
    name: "Multichain BNB",
    decimals: 18,
    oracle: OracleTypes17.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://moonbeam.moonscan.io", BNB4),
    disabled: true
  },
  {
    symbol: assetSymbols39.multiDAI,
    underlying: multiDAI,
    name: "Multichain DAI Stablecoin",
    decimals: 18,
    oracle: OracleTypes17.DiaPriceOracle,
    extraDocs: defaultDocs("https://moonbeam.moonscan.io", multiDAI)
  },
  {
    symbol: assetSymbols39.multiUSDC,
    underlying: multiUSDC,
    name: "Multichain USDC",
    decimals: 6,
    oracle: OracleTypes17.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://moonbeam.moonscan.io", multiUSDC)
  },
  {
    symbol: assetSymbols39.USDC_wh,
    underlying: whUSDC,
    name: "USD Coin",
    decimals: 6,
    oracle: OracleTypes17.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://moonbeam.moonscan.io", whUSDC)
  },
  {
    symbol: assetSymbols39.multiUSDT,
    underlying: multiUSDT,
    name: "Multichain USDT",
    decimals: 6,
    oracle: OracleTypes17.DiaPriceOracle,
    extraDocs: defaultDocs("https://moonbeam.moonscan.io", multiUSDT)
  },
  {
    symbol: assetSymbols39.USDT_xc,
    underlying: USDT_xc,
    name: "XCM USDT",
    decimals: 6,
    oracle: OracleTypes17.DiaPriceOracle,
    extraDocs: defaultDocs("https://moonbeam.moonscan.io", USDT_xc)
  },
  {
    symbol: assetSymbols39.BUSD_wh,
    underlying: BUSD_wh,
    name: "Wormhole BUSD",
    decimals: 18,
    oracle: OracleTypes17.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://moonbeam.moonscan.io", BUSD_wh)
  },
  {
    symbol: assetSymbols39.FRAX,
    underlying: FRAX4,
    name: "Frax",
    decimals: 18,
    oracle: OracleTypes17.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://moonbeam.moonscan.io", FRAX4)
  },
  {
    symbol: assetSymbols39.WGLMR,
    underlying: WGLMR,
    name: "Wrapped GLMR",
    decimals: 18,
    oracle: OracleTypes17.FixedNativePriceOracle,
    extraDocs: wrappedAssetDocs(SupportedChains15.moonbeam)
  },
  {
    symbol: assetSymbols39.GLINT,
    underlying: GLINT,
    name: "Beamswap Token",
    decimals: 18,
    oracle: OracleTypes17.UniswapTwapPriceOracleV2,
    extraDocs: defaultDocs("https://moonbeam.moonscan.io", GLINT),
    disabled: true
  },
  {
    symbol: assetSymbols39.FTM,
    underlying: FTM,
    name: "Mulitchain Fantom",
    decimals: 18,
    oracle: OracleTypes17.DiaPriceOracle,
    extraDocs: defaultDocs("https://moonbeam.moonscan.io", FTM),
    disabled: true
  },
  {
    symbol: assetSymbols39["WGLMR-xcDOT"],
    underlying: WGLMR_xcDOT,
    name: "BeamSwap WGLMR-xcDOT LP Token",
    decimals: 18,
    oracle: OracleTypes17.UniswapLpTokenPriceOracle,
    extraDocs: beamSwapDocs(WGLMR, xcDOT, "WGLMR-xcDOT", WGLMR_xcDOT),
    disabled: true
  },
  {
    symbol: assetSymbols39["GLMR-USDC"],
    underlying: GLMR_USDC,
    name: "BeamSwap GLMR-USDC LP Token",
    decimals: 18,
    oracle: OracleTypes17.UniswapLpTokenPriceOracle,
    extraDocs: beamSwapDocs(WGLMR, multiUSDC, "GLMR-USDC", GLMR_USDC),
    disabled: true
  },
  {
    symbol: assetSymbols39["GLMR-GLINT"],
    underlying: GLMR_GLINT,
    name: "BeamSwap GLMR-GLINT LP Token",
    decimals: 18,
    oracle: OracleTypes17.UniswapLpTokenPriceOracle,
    extraDocs: beamSwapDocs(WGLMR, GLINT, "GLMR-GLINT", GLMR_GLINT),
    disabled: true
  },
  {
    symbol: assetSymbols39["USDC-ETH"],
    underlying: USDC_ETH2,
    name: "BeamSwap ETH-USDC LP Token",
    decimals: 18,
    oracle: OracleTypes17.UniswapLpTokenPriceOracle,
    extraDocs: beamSwapDocs(multiUSDC, ETH2, "USDC-ETH", USDC_ETH2),
    disabled: true
  },
  {
    symbol: assetSymbols39["xcDOT-stDOT"],
    underlying: xcDOT_stDOT,
    name: "Curve.fi xcDOT-stDOT LP Token",
    decimals: 18,
    oracle: OracleTypes17.CurveLpTokenPriceOracleNoRegistry,
    extraDocs: curveFinanceMoonbeamDocs(14, "xcDOT-stDOT", xcDOT_stDOT, true)
  },
  {
    symbol: assetSymbols39["STELLA-GLMR"],
    underlying: STELLA_GLMR,
    name: "Stella Swap STELLA/GLMR LP Token",
    decimals: 18,
    oracle: OracleTypes17.UniswapLpTokenPriceOracle,
    extraDocs: stellaSwapDocs("ETH", STELLA, "STELLA-GLMR", STELLA_GLMR)
  },
  {
    symbol: assetSymbols39["USDC.wh-GLMR"],
    underlying: GLMR_whUSDC,
    name: "Stella Swap WGLMR/USDC.wh LP Token",
    decimals: 18,
    oracle: OracleTypes17.UniswapLpTokenPriceOracle,
    extraDocs: stellaSwapDocs("ETH", whUSDC, "WGLMR-USDC.wh", GLMR_whUSDC)
  },
  {
    symbol: assetSymbols39["DOT.xc-GLMR"],
    underlying: GLMR_xcDOT,
    name: "Stella Swap WGLMR/DOT.xc LP Token",
    decimals: 18,
    oracle: OracleTypes17.UniswapLpTokenPriceOracle,
    extraDocs: stellaSwapDocs("ETH", xcDOT, "WGLMR-DOT.xc", GLMR_xcDOT)
  },
  {
    symbol: assetSymbols39["WETH.wh-GLMR"],
    underlying: GLMR_whWETH,
    name: "Stella Swap WGLMR/WETH.wh LP Token",
    decimals: 18,
    oracle: OracleTypes17.UniswapLpTokenPriceOracle,
    extraDocs: stellaSwapDocs("ETH", whWETH, "WGLMR-WETH.wh", GLMR_whWETH)
  },
  {
    symbol: assetSymbols39["WBTC.wh-GLMR"],
    underlying: GLMR_whWBTC,
    name: "Stella Swap WGLMR/WBTC.wh LP Token",
    decimals: 18,
    oracle: OracleTypes17.UniswapLpTokenPriceOracle,
    extraDocs: stellaSwapDocs("ETH", whWBTC, "WGLMR-WBTC.wh", GLMR_whWBTC)
  },
  {
    symbol: assetSymbols39["CELR-GLMR"],
    underlying: CELR_GLMR,
    name: "Stella Swap CELR/GLMR LP Token",
    decimals: 18,
    oracle: OracleTypes17.UniswapLpTokenPriceOracle,
    extraDocs: stellaSwapDocs("ETH", CELR, "CELR-GLMR", CELR_GLMR),
    disabled: true
  },
  {
    symbol: assetSymbols39["ATOM-GLMR"],
    underlying: GLMR_ATOM,
    name: "Stella Swap ATOM/GLMR LP Token",
    decimals: 18,
    oracle: OracleTypes17.UniswapLpTokenPriceOracle,
    extraDocs: stellaSwapDocs("ETH", ATOM, "ATOM-GLMR", GLMR_ATOM),
    disabled: true
  },
  {
    symbol: assetSymbols39["LDO-GLMR"],
    underlying: LDO_GLMR,
    name: "Stella Swap LDO/GLMR LP Token",
    decimals: 18,
    oracle: OracleTypes17.UniswapLpTokenPriceOracle,
    extraDocs: stellaSwapDocs("ETH", LDO, "LDO-GLMR", LDO_GLMR),
    disabled: true
  },
  {
    symbol: assetSymbols39["wstDOT-DOT.xc"],
    underlying: wstDOT_xcDOT,
    name: "Stella Swap wstDOT/xcDOT LP Token",
    decimals: 18,
    oracle: OracleTypes17.UniswapLpTokenPriceOracle,
    extraDocs: stellaSwapDocs(wstDOT, xcDOT, "wstDOT-xcDOT", wstDOT_xcDOT)
  },
  {
    symbol: assetSymbols39.base4pool,
    underlying: base4pool,
    name: "Stella Swap USDC.wh/USDT.xc/BUSD.wh/FRAX LP Token",
    decimals: 18,
    oracle: OracleTypes17.SaddleLpPriceOracle,
    extraDocs: stellaSwapStableDocs("0xB1BC9f56103175193519Ae1540A0A4572b1566F6", "base4pool", base4pool)
  },
  {
    symbol: assetSymbols39.STELLA,
    underlying: STELLA,
    name: "Stellaswap Token",
    decimals: 18,
    oracle: OracleTypes17.UniswapTwapPriceOracleV2,
    extraDocs: defaultDocs("https://moonbeam.moonscan.io", STELLA)
  },
  {
    symbol: assetSymbols39.CELR,
    underlying: CELR,
    name: "CELR Token",
    decimals: 18,
    oracle: OracleTypes17.UniswapTwapPriceOracleV2,
    extraDocs: defaultDocs("https://moonbeam.moonscan.io", CELR),
    disabled: true
  },
  {
    symbol: assetSymbols39.LDO,
    underlying: LDO,
    name: "LDO Token",
    decimals: 18,
    oracle: OracleTypes17.UniswapTwapPriceOracleV2,
    extraDocs: defaultDocs("https://moonbeam.moonscan.io", LDO),
    disabled: true
  }
];
var assets_default7 = assets10;

// src/moonbeam/addresses.ts
var chainAddresses9 = {
  W_TOKEN: underlying24(assets10, assetSymbols40.WGLMR),
  W_TOKEN_USD_CHAINLINK_PRICE_FEED: "0x4497B606be93e773bbA5eaCFCb2ac5E2214220Eb",
  UNISWAP_V2_ROUTER: "0x70085a09D30D6f8C4ecF6eE10120d1847383BB57",
  UNISWAP_V2_FACTORY: "0x68A384D826D3678f78BB9FB1533c7E9577dACc0E",
  PAIR_INIT_HASH: ethers10.utils.hexlify("0x48a6ca3d52d0d0a6c53a83cc3c8688dd46ea4cb786b169ee959b95ad30f61643"),
  STABLE_TOKEN: underlying24(assets10, assetSymbols40.multiUSDC),
  W_BTC_TOKEN: underlying24(assets10, assetSymbols40.multiWBTC)
};
var addresses_default9 = chainAddresses9;

// src/moonbeam/fundingStrategies.ts
var fundingStrategies9 = {};
var fundingStrategies_default9 = fundingStrategies9;

// src/moonbeam/irms.ts
import { IrmTypes as IrmTypes9 } from "@midas-capital/types";
var baseIrms9 = [IrmTypes9.WhitePaperInterestRateModel, IrmTypes9.JumpRateModel];
var irms9 = [
  ...baseIrms9,
  IrmTypes9.AdjustableJumpRateModel_MIXBYTES_XCDOT,
  IrmTypes9.AdjustableJumpRateModel_MIXBYTES_USDC
];
var irms_default9 = irms9;

// src/moonbeam/liquidation.ts
import { assetSymbols as assetSymbols41, LiquidationStrategy as LiquidationStrategy9, underlying as underlying25 } from "@midas-capital/types";
import { BigNumber as BigNumber17, constants as constants9 } from "ethers";
var liquidationDefaults9 = {
  DEFAULT_ROUTER: addresses_default9.UNISWAP_V2_ROUTER,
  ASSET_SPECIFIC_ROUTER: {},
  SUPPORTED_OUTPUT_CURRENCIES: [constants9.AddressZero, underlying25(assets10, assetSymbols41.WGLMR)],
  SUPPORTED_INPUT_CURRENCIES: [constants9.AddressZero, underlying25(assets10, assetSymbols41.WGLMR)],
  LIQUIDATION_STRATEGY: LiquidationStrategy9.UNISWAP,
  MINIMUM_PROFIT_NATIVE: BigNumber17.from(0),
  LIQUIDATION_INTERVAL_SECONDS: 60,
  jarvisPools: [],
  balancerPools: []
};
var liquidation_default9 = liquidationDefaults9;

// src/moonbeam/oracles.ts
import { OracleTypes as OracleTypes18 } from "@midas-capital/types";
var baseOracles9 = [OracleTypes18.FixedNativePriceOracle, OracleTypes18.MasterPriceOracle, OracleTypes18.SimplePriceOracle];
var oracles9 = [
  ...baseOracles9,
  OracleTypes18.DiaPriceOracle,
  OracleTypes18.CurveLpTokenPriceOracleNoRegistry,
  OracleTypes18.UniswapLpTokenPriceOracle,
  OracleTypes18.UniswapTwapPriceOracleV2
];
var oracles_default9 = oracles9;

// src/moonbeam/params.ts
import { assetSymbols as assetSymbols42 } from "@midas-capital/types";
import { BigNumber as BigNumber18 } from "ethers";
var specificParams9 = {
  blocksPerYear: BigNumber18.from((5 * 24 * 365 * 60).toString()),
  cgId: "moonbeam",
  metadata: {
    chainIdHex: "0x504",
    name: "Moonbeam",
    shortName: "Moonbeam",
    img: "https://d1912tcoux65lj.cloudfront.net/network/moonbeam.jpg",
    blockExplorerUrls: { default: { name: "Moonbeam", url: "https://moonscan.io" } },
    rpcUrls: {
      default: { http: ["https://rpc.api.moonbeam.network"] },
      public: { http: ["https://rpc.api.moonbeam.network"] }
    },
    nativeCurrency: {
      symbol: "GLMR",
      name: "Moonbeam"
    },
    wrappedNativeCurrency: {
      symbol: assetSymbols42.WGLMR,
      address: addresses_default9.W_TOKEN,
      name: "Moonbeam",
      decimals: 18,
      color: "#627EEA",
      overlayTextColor: "#fff",
      logoURL: "https://d1912tcoux65lj.cloudfront.net/network/moonbeam.jpg"
    }
  }
};
var params_default9 = specificParams9;

// src/moonbeam/plugins.ts
import { assetSymbols as assetSymbols43, Strategy as Strategy3, underlying as underlying26 } from "@midas-capital/types";
var deployedPlugins9 = {
  // no plugin deployment file stored
  "0x0DaFF7aaaE63F1Fc30c1C40816257513D052b649": {
    market: "0x85Ff07b5F3454143531F36Bd6bEd92654d0681eD",
    name: "GLMR-ATOM",
    strategy: Strategy3.Stella,
    apyDocsUrl: "https://app.stellaswap.com/farm",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/moonbeam/stellaswap",
    underlying: underlying26(assets_default7, assetSymbols43["ATOM-GLMR"]),
    otherParams: [
      "0xF3a5454496E26ac57da879bf3285Fa85DEBF0388",
      "5",
      "0x85Ff07b5F3454143531F36Bd6bEd92654d0681eD",
      [underlying26(assets_default7, assetSymbols43.STELLA), underlying26(assets_default7, assetSymbols43.WGLMR)]
    ],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/stella.png"
  },
  // CurveGaugeERC4626_xcDOT-stDOT_0xe4C6Bd326a1715cEbFeD3647A963a308Ae7F8A98.json
  "0xE9c4274341ab4Be0857476e84963b3c36787568D": {
    market: "0xe4C6Bd326a1715cEbFeD3647A963a308Ae7F8A98",
    name: "Curve xcDOT-stDOT Gauge",
    strategy: Strategy3.CurveGauge,
    apyDocsUrl: "https://moonbeam.curve.fi/",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/moonbeam/xcdot",
    underlying: underlying26(assets_default7, assetSymbols43["xcDOT-stDOT"]),
    otherParams: ["0xC106C836771B0B4f4a0612Bd68163Ca93be1D340", [underlying26(assets_default7, assetSymbols43["xcDOT-stDOT"])]],
    flywheel: "0xb13aAD1F212FdE08a5ab51249cf6434574434A4e",
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/curve.png"
  },
  // StellaLpERC4626_USDC.wh-GLMR_0xeB7b975C105f05bFb02757fB9bb3361D77AAe84A.json
  "0x7EbB783fA23b731c76017bB0656530337Ed31577": {
    market: "0xeB7b975C105f05bFb02757fB9bb3361D77AAe84A",
    name: "StellaSwap USDC.wh-GLMR Pool",
    strategy: Strategy3.Stella,
    apyDocsUrl: "https://app.stellaswap.com/farm",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/moonbeam/stellaswap",
    underlying: underlying26(assets_default7, assetSymbols43["USDC.wh-GLMR"]),
    otherParams: ["0xF3a5454496E26ac57da879bf3285Fa85DEBF0388", "28"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/stella.png"
  },
  // StellaLpERC4626_WETH.wh-GLMR_0x95b1eFbd91E35FdAE0720d1BeDA3d4d1fbfefa1C.json
  "0x2a7A88544270bbD181E147B1A1d88d0A78186dA6": {
    market: "0x95b1eFbd91E35FdAE0720d1BeDA3d4d1fbfefa1C",
    name: "StellaSwap WETH.wh-GLMR Pool",
    strategy: Strategy3.Stella,
    apyDocsUrl: "https://app.stellaswap.com/farm",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/moonbeam/stellaswap",
    underlying: underlying26(assets_default7, assetSymbols43["WETH.wh-GLMR"]),
    otherParams: ["0xF3a5454496E26ac57da879bf3285Fa85DEBF0388", "28"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/stella.png"
  },
  // StellaLpERC4626_WBTC.wh-GLMR_0xD7ee88664C269Ec834a471cf47C576B6203cdC45.json
  "0x5BF5B16130B90fB636A3b8a136da3944BAACaCAC": {
    market: "0xD7ee88664C269Ec834a471cf47C576B6203cdC45",
    name: "StellaSwap WBTC.wh-GLMR Pool",
    strategy: Strategy3.Stella,
    apyDocsUrl: "https://app.stellaswap.com/farm",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/moonbeam/stellaswap",
    underlying: underlying26(assets_default7, assetSymbols43["WBTC.wh-GLMR"]),
    otherParams: ["0xF3a5454496E26ac57da879bf3285Fa85DEBF0388", "30"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/stella.png"
  },
  // StellaLpERC4626_DOT.xc-GLMR_0xe2b3FC05C0c90c38BCa6E149D186571D413F5f5A.json
  "0xe32f52f8172E3c75dacB3606e254275ab774d88f": {
    market: "0xe2b3FC05C0c90c38BCa6E149D186571D413F5f5A",
    name: "StellaSwap DOT.xc-GLMR Pool",
    strategy: Strategy3.Stella,
    apyDocsUrl: "https://app.stellaswap.com/farm",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/moonbeam/stellaswap",
    underlying: underlying26(assets_default7, assetSymbols43["DOT.xc-GLMR"]),
    otherParams: ["0xF3a5454496E26ac57da879bf3285Fa85DEBF0388", "10"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/stella.png"
  },
  // BeefyERC4626_wstDOT-DOT.xc_0x90430a6B861B40589c4148984F77d0d181cE17bf.json
  "0x2b5387D1B552d50a08d1df2e8C1e77De6d681970": {
    market: "0x90430a6B861B40589c4148984F77d0d181cE17bf",
    name: "Beefy wstDOT-DOT.xc Vault",
    strategy: Strategy3.Beefy,
    apyDocsUrl: "https://app.beefy.finance/vault/stellaswap-wstdot-xcdot",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/moonbeam/stellaswap",
    underlying: underlying26(assets_default7, assetSymbols43["wstDOT-DOT.xc"]),
    otherParams: ["0x6EA8ad7228eBcA6C686096269d60bb1C72D13fa8", "0"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/beefy.png"
  },
  // BeefyERC4626_base4pool_0x3a789A7C5DA5A74253B5AfCF3b5194De1bcF7Aa7.json
  "0x26511F5D5b91ADd0E36aAbd581E7E3fbaAF0008C": {
    market: "0x3a789A7C5DA5A74253B5AfCF3b5194De1bcF7Aa7",
    name: "Beefy base4pool Vault",
    strategy: Strategy3.Beefy,
    apyDocsUrl: "https://app.beefy.finance/vault/stellaswap-4pool",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/moonbeam/stellaswap",
    underlying: underlying26(assets_default7, assetSymbols43.base4pool),
    otherParams: ["0xA576b4429d190f8EcD0f82c0C1f492cEA8c7DE9B", "0"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/beefy.png"
  },
  // BeefyERC4626_STELLA-GLMR_0x94Cb2144e74998265926d928b449A0aF7D35e463.json
  "0x650B448D4Edb13D7355B9E79cB8DC6Cefdf28BEc": {
    market: "0x94Cb2144e74998265926d928b449A0aF7D35e463",
    name: "Beefy STELLA-WGLMR Vault",
    strategy: Strategy3.Beefy,
    apyDocsUrl: "https://app.beefy.finance/vault/stellaswap-stella-wglmr",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/moonbeam/stellaswap",
    underlying: underlying26(assets_default7, assetSymbols43["STELLA-GLMR"]),
    otherParams: ["0x515989A9Add6Bb3Ab78b59a2AE30E398E740886F", "0"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/beefy.png"
  }
};
var plugins_default9 = deployedPlugins9;

// src/moonbeam/redemptionStrategies.ts
import { assetSymbols as assetSymbols44, RedemptionStrategyContract as RedemptionStrategyContract6, underlying as underlying27 } from "@midas-capital/types";
var redemptionStrategies9 = {
  [underlying27(assets10, assetSymbols44["GLMR-USDC"])]: [
    RedemptionStrategyContract6.UniswapLpTokenLiquidator,
    underlying27(assets10, assetSymbols44.WGLMR)
  ],
  [underlying27(assets10, assetSymbols44["GLMR-GLINT"])]: [
    RedemptionStrategyContract6.UniswapLpTokenLiquidator,
    underlying27(assets10, assetSymbols44.WGLMR)
  ],
  [underlying27(assets10, assetSymbols44.xcDOT)]: [
    RedemptionStrategyContract6.CurveSwapLiquidator,
    underlying27(assets10, assetSymbols44.stDOT)
  ],
  [underlying27(assets10, assetSymbols44["xcDOT-stDOT"])]: [
    RedemptionStrategyContract6.CurveLpTokenLiquidatorNoRegistry,
    underlying27(assets10, assetSymbols44.stDOT)
  ],
  [underlying27(assets10, assetSymbols44["USDC.wh-GLMR"])]: [
    RedemptionStrategyContract6.UniswapLpTokenLiquidator,
    underlying27(assets10, assetSymbols44.WGLMR)
  ],
  [underlying27(assets10, assetSymbols44["WBTC.wh-GLMR"])]: [
    RedemptionStrategyContract6.UniswapLpTokenLiquidator,
    underlying27(assets10, assetSymbols44.WGLMR)
  ],
  [underlying27(assets10, assetSymbols44["WETH.wh-GLMR"])]: [
    RedemptionStrategyContract6.UniswapLpTokenLiquidator,
    underlying27(assets10, assetSymbols44.WGLMR)
  ],
  [underlying27(assets10, assetSymbols44["DOT.xc-GLMR"])]: [
    RedemptionStrategyContract6.UniswapLpTokenLiquidator,
    underlying27(assets10, assetSymbols44.WGLMR)
  ],
  [underlying27(assets10, assetSymbols44["wstDOT-DOT.xc"])]: [
    RedemptionStrategyContract6.SaddleLpTokenLiquidator,
    underlying27(assets10, assetSymbols44.wstDOT)
  ],
  [underlying27(assets10, assetSymbols44.base4pool)]: [
    RedemptionStrategyContract6.SaddleLpTokenLiquidator,
    underlying27(assets10, assetSymbols44.USDC_wh)
  ]
};
var redemptionStrategies_default9 = redemptionStrategies9;

// src/moonbeam/index.ts
var chainConfig9 = {
  chainId: SupportedChains16.moonbeam,
  chainAddresses: addresses_default9,
  assets: assets_default7,
  irms: irms_default9,
  liquidationDefaults: liquidation_default9,
  oracles: oracles_default9,
  specificParams: params_default9,
  deployedPlugins: plugins_default9,
  redemptionStrategies: redemptionStrategies_default9,
  fundingStrategies: fundingStrategies_default9,
  chainDeployments: moonbeam_default.contracts
};
var moonbeam_default2 = chainConfig9;

// src/neondevnet/index.ts
import { SupportedChains as SupportedChains17 } from "@midas-capital/types";

// deployments/neondevnet.json
var neondevnet_default = {
  name: "neondevnet",
  chainId: "245022926",
  contracts: {
    AddressesProvider: {
      address: "0x3F56f8571988D03Cdc7E51fdaB19ADb032CCbe21"
    },
    AddressesProvider_Implementation: {
      address: "0xA443095Fa1B5C9E6B47dB99042097aee24AAc6d3"
    },
    AddressesProvider_Proxy: {
      address: "0x3F56f8571988D03Cdc7E51fdaB19ADb032CCbe21"
    },
    CErc20Delegate: {
      address: "0xBe99e316BdCF1091450E4300202e3FCB5da55756"
    },
    CErc20PluginDelegate: {
      address: "0xE2e734642F432d47b9f09bfA3bc5d65F9e949db4"
    },
    CErc20PluginRewardsDelegate: {
      address: "0x889e1AB929534AB8aa966F6A8Cd9f348343Fa98F"
    },
    CTokenFirstExtension: {
      address: "0x9bb5B30BF73E82A3b720eb7396407F43407ac537"
    },
    Comptroller: {
      address: "0x1f0847B260D736Ed16bCDacFa76b04f0EC6427ba"
    },
    ComptrollerFirstExtension: {
      address: "0xc7107037D5871084Ece6010f648e3BE552c1582b"
    },
    DefaultProxyAdmin: {
      address: "0x47246c2e75409284b6409534d410245Ee48002c7"
    },
    FixedNativePriceOracle: {
      address: "0xd994ece732aC32a92a575268f4FaBF2025C83F1a"
    },
    FuseFeeDistributor: {
      address: "0x45C4AD08b050E3c8CeCfC1e77B88DC372b4Cf94c"
    },
    FuseFeeDistributor_Implementation: {
      address: "0x2dda49e54e9eccae1a1b7e493b787310483dbb71"
    },
    FuseFeeDistributor_Proxy: {
      address: "0x45C4AD08b050E3c8CeCfC1e77B88DC372b4Cf94c"
    },
    FusePoolDirectory: {
      address: "0x5D391D2f2AA77060D72a0E9C454F7ea6D3287b50"
    },
    FusePoolDirectory_Implementation: {
      address: "0x6eD496CfaB49079EDd7f53c782B41D6ecFF91617"
    },
    FusePoolDirectory_Proxy: {
      address: "0x5D391D2f2AA77060D72a0E9C454F7ea6D3287b50"
    },
    FusePoolLens: {
      address: "0xD14E433151c5a567DBcC60aa3c80782898F5cd55"
    },
    FusePoolLensSecondary: {
      address: "0xc1B0E555b88abd17211e1590f315abab3f2Aa187"
    },
    FuseSafeLiquidator: {
      address: "0x6bd52c2f212335749dc6e9bAeBb971DD4De532B4"
    },
    FuseSafeLiquidator_Implementation: {
      address: "0x166853e3cCBcc406798E86B7eC9e2C7EF3bab28a"
    },
    FuseSafeLiquidator_Proxy: {
      address: "0x6bd52c2f212335749dc6e9bAeBb971DD4De532B4"
    },
    JumpRateModel: {
      address: "0x51Fc4413D3d32129a7C56c72fC8d273Cfe8306e6"
    },
    LeveredPositionFactory: {
      address: "0x2a3720945D9416f6583770E402f2129c77479878"
    },
    LeveredPositionFactoryExtension: {
      address: "0x085a8522D81e1cab60547613b2626db6A94A64c4"
    },
    LeveredPositionsLens: {
      address: "0x5670E9d607666a8341313DA477B0220E70c98370"
    },
    LeveredPositionsLens_Implementation: {
      address: "0x9565b6a207f4cd7e617A17f3A21B95dC855Df2dd"
    },
    LeveredPositionsLens_Proxy: {
      address: "0x5670E9d607666a8341313DA477B0220E70c98370"
    },
    LiquidatorsRegistry: {
      address: "0xDBCa7caD01cc7B9Ec67B4B29D610590a77E11720"
    },
    LiquidatorsRegistryExtension: {
      address: "0x3aD81a1fe57189C613a711f4dF32D28ADEF7a0E6"
    },
    LooplessFlywheelBooster: {
      address: "0x63b484896Bd2A381f1E308088A65bf9E292FBc7a"
    },
    MasterPriceOracle: {
      address: "0x5403C2E4D471f16dc01F343075A6e905a7594A35"
    },
    MasterPriceOracle_Implementation: {
      address: "0x99D0d93b5a057270Da61D4971C2CDdC5bF0E07B8"
    },
    MasterPriceOracle_Proxy: {
      address: "0x5403C2E4D471f16dc01F343075A6e905a7594A35"
    },
    MidasFlywheelLensRouter: {
      address: "0x831036Ee05c5B47f964c9E2aD961E99EBb8D1e4e"
    },
    OptimizedVaultsRegistry: {
      address: "0x17De74d3E0763D3F2463d2aEB0c74C899BA57800"
    },
    OptimizedVaultsRegistry_Implementation: {
      address: "0xe05BA06bA30bdFB7992cEf9911931E045Dbf66AE"
    },
    OptimizedVaultsRegistry_Proxy: {
      address: "0x17De74d3E0763D3F2463d2aEB0c74C899BA57800"
    },
    Pyth: {
      address: "0xd1321414EFe60bcB381C40Ee3beebE3e0d00375F"
    },
    SimplePriceOracle: {
      address: "0x0b7c55eD8f7Bc25c20eA4FdFbfED79d4AeB9aCaE"
    },
    SimplePriceOracle_Implementation: {
      address: "0xFfcD5BeC57EbF1B9AC513D1340c13a3A9d2a053e"
    },
    SimplePriceOracle_Proxy: {
      address: "0x0b7c55eD8f7Bc25c20eA4FdFbfED79d4AeB9aCaE"
    },
    WhitePaperInterestRateModel: {
      address: "0xf2355b3A009d316A369fD411D297F2e017D69337"
    }
  }
};

// src/neondevnet/addresses.ts
import { assetSymbols as assetSymbols46, underlying as underlying28 } from "@midas-capital/types";
import { ethers as ethers11 } from "ethers";

// src/neondevnet/assets.ts
import { assetSymbols as assetSymbols45, OracleTypes as OracleTypes19 } from "@midas-capital/types";
var WNEON = "0xf1041596da0499c3438e3B1Eb7b95354C6Aed1f5";
var MORA = "0x6dcDD1620Ce77B595E6490701416f6Dbf20D2f67";
var USDC6 = "0x4F6B3c357c439E15FB61c1187cc5E28eC72bBc55";
var assets11 = [
  {
    symbol: assetSymbols45.WNEON,
    underlying: WNEON,
    name: "Wrapped NEON ",
    decimals: 18,
    oracle: OracleTypes19.FixedNativePriceOracle
  },
  {
    symbol: assetSymbols45.MORA,
    underlying: MORA,
    name: "Moraswap Token",
    decimals: 18,
    oracle: OracleTypes19.SimplePriceOracle
  },
  {
    symbol: assetSymbols45.USDC,
    underlying: USDC6,
    name: "USD Coin",
    decimals: 6,
    oracle: OracleTypes19.SimplePriceOracle
  }
];
var assets_default8 = assets11;

// src/neondevnet/addresses.ts
var chainAddresses10 = {
  W_TOKEN: underlying28(assets11, assetSymbols46.WNEON),
  W_TOKEN_USD_CHAINLINK_PRICE_FEED: "",
  UNISWAP_V2_ROUTER: "0x491FFC6eE42FEfB4Edab9BA7D5F3e639959E081B",
  UNISWAP_V2_FACTORY: "0x6dcDD1620Ce77B595E6490701416f6Dbf20D2f67",
  PAIR_INIT_HASH: ethers11.utils.hexlify("0x1f475d88284b09799561ca05d87dc757c1ff4a9f48983cdb84d1dd6e209d3ae2"),
  STABLE_TOKEN: underlying28(assets11, assetSymbols46.USDC),
  W_BTC_TOKEN: ""
};
var addresses_default10 = chainAddresses10;

// src/neondevnet/fundingStrategies.ts
var fundingStrategies10 = {};
var fundingStrategies_default10 = fundingStrategies10;

// src/neondevnet/irms.ts
import { IrmTypes as IrmTypes10 } from "@midas-capital/types";
var baseIrms10 = [IrmTypes10.WhitePaperInterestRateModel, IrmTypes10.JumpRateModel];
var irms10 = [...baseIrms10];
var irms_default10 = irms10;

// src/neondevnet/liquidation.ts
import { assetSymbols as assetSymbols47, LiquidationStrategy as LiquidationStrategy10, underlying as underlying29 } from "@midas-capital/types";
import { BigNumber as BigNumber19, constants as constants10 } from "ethers";
var liquidationDefaults10 = {
  DEFAULT_ROUTER: addresses_default10.UNISWAP_V2_ROUTER,
  ASSET_SPECIFIC_ROUTER: {},
  SUPPORTED_OUTPUT_CURRENCIES: [constants10.AddressZero, underlying29(assets11, assetSymbols47.WNEON)],
  SUPPORTED_INPUT_CURRENCIES: [constants10.AddressZero, underlying29(assets11, assetSymbols47.WNEON)],
  LIQUIDATION_STRATEGY: LiquidationStrategy10.UNISWAP,
  MINIMUM_PROFIT_NATIVE: BigNumber19.from(0),
  LIQUIDATION_INTERVAL_SECONDS: 20,
  jarvisPools: [],
  balancerPools: []
};
var liquidation_default10 = liquidationDefaults10;

// src/neondevnet/oracles.ts
import { OracleTypes as OracleTypes20 } from "@midas-capital/types";
var baseOracles10 = [OracleTypes20.FixedNativePriceOracle, OracleTypes20.MasterPriceOracle, OracleTypes20.SimplePriceOracle];
var oracles10 = [...baseOracles10];
var oracles_default10 = oracles10;

// src/neondevnet/params.ts
import { assetSymbols as assetSymbols48 } from "@midas-capital/types";
import { BigNumber as BigNumber20 } from "ethers";
var specificParams10 = {
  blocksPerYear: BigNumber20.from((5 * 24 * 365 * 60).toString()),
  cgId: "neon",
  metadata: {
    chainIdHex: "0xE9AC0CE",
    name: "Neon Devnet",
    shortName: "Neon Devnet",
    img: "https://d1912tcoux65lj.cloudfront.net/network/neon.jpg",
    rpcUrls: {
      default: { http: ["https://devnet.neonevm.org"] },
      public: { http: ["https://devnet.neonevm.org"] }
    },
    blockExplorerUrls: {
      default: { name: "NeonScan", url: "https://neonscan.org" },
      public: { name: "NeonScan", url: "https://neonscan.org" }
    },
    nativeCurrency: {
      symbol: "NEON",
      name: "Neon Devnet"
    },
    wrappedNativeCurrency: {
      symbol: assetSymbols48.WNEON,
      address: "",
      name: "Neon Devnet",
      decimals: 18,
      color: "#627EEA",
      overlayTextColor: "#fff",
      logoURL: "https://d1912tcoux65lj.cloudfront.net/network/neon.jpg"
    },
    testnet: true
  }
};
var params_default10 = specificParams10;

// src/neondevnet/plugins.ts
var deployedPlugins10 = {};
var plugins_default10 = deployedPlugins10;

// src/neondevnet/redemptionStrategies.ts
var redemptionStrategies10 = {};
var redemptionStrategies_default10 = redemptionStrategies10;

// src/neondevnet/index.ts
var chainConfig10 = {
  chainId: SupportedChains17.neon_devnet,
  chainAddresses: addresses_default10,
  assets: assets_default8,
  irms: irms_default10,
  liquidationDefaults: liquidation_default10,
  oracles: oracles_default10,
  specificParams: params_default10,
  deployedPlugins: plugins_default10,
  redemptionStrategies: redemptionStrategies_default10,
  fundingStrategies: fundingStrategies_default10,
  chainDeployments: neondevnet_default.contracts
};
var neondevnet_default2 = chainConfig10;

// src/polygon/index.ts
import { SupportedChains as SupportedChains19 } from "@midas-capital/types";

// deployments/polygon.json
var polygon_default = {
  name: "polygon",
  chainId: "137",
  contracts: {
    AaveTokenLiquidator: {
      address: "0x1AB1884Db84759BabD63970D3617BFB12329c8C7"
    },
    AddressesProvider: {
      address: "0x2fCa24E19C67070467927DDB85810fF766423e8e"
    },
    AddressesProvider_Implementation: {
      address: "0xC91a6979620e4946E87e8DFF87f6776819d5380e"
    },
    AddressesProvider_Proxy: {
      address: "0x2fCa24E19C67070467927DDB85810fF766423e8e"
    },
    AdjustableJumpRateModel_JARVIS_jEUR: {
      address: "0x1802F7FBc8CBE9D23E106CA5BfFaDb1c95ab0BBD"
    },
    AlgebraPriceOracle: {
      address: "0x7f3597b369610cCFf34C181c0A19d4480aCe1eEa"
    },
    AlgebraPriceOracle_Implementation: {
      address: "0x0DF63896A016098e72F1a3d947C25d686860C861"
    },
    AlgebraPriceOracle_Proxy: {
      address: "0x7f3597b369610cCFf34C181c0A19d4480aCe1eEa"
    },
    AlgebraSwapLiquidator: {
      address: "0xE706134f898320dc1b1772362566bA6646C569c1"
    },
    AnkrCertificateTokenPriceOracle: {
      address: "0x4Be947BaA3C038aac1097EDF845080c4b2C1130e"
    },
    AnkrCertificateTokenPriceOracle_Implementation: {
      address: "0x25dE54887A43a33f25A418792978Ab2FbcbeDe64"
    },
    AnkrCertificateTokenPriceOracle_Proxy: {
      address: "0x4Be947BaA3C038aac1097EDF845080c4b2C1130e"
    },
    BalancerLpLinearPoolPriceOracle: {
      address: "0x966C8998962A0c5472b9DB8e865dcba3c100Ac40"
    },
    BalancerLpLinearPoolPriceOracle_Implementation: {
      address: "0x2C4957cFeAa2137DB078f5A4c8549F0afb0369F2"
    },
    BalancerLpLinearPoolPriceOracle_Proxy: {
      address: "0x966C8998962A0c5472b9DB8e865dcba3c100Ac40"
    },
    BalancerLpStablePoolPriceOracle: {
      address: "0x73472dD551E2991574a1132D29438033123A64D4"
    },
    BalancerLpStablePoolPriceOracle_Implementation: {
      address: "0x599Ce00221030620A65d7bBDAeC447fb37A0C05E"
    },
    BalancerLpStablePoolPriceOracle_Proxy: {
      address: "0x73472dD551E2991574a1132D29438033123A64D4"
    },
    BalancerLpTokenLiquidator: {
      address: "0xB954290c884d96C39EC10d87F8B2a5a9A407F93A"
    },
    BalancerLpTokenPriceOracle: {
      address: "0x7b7fa6D93f8ca69724d98E0d563e868Ce342eB51"
    },
    BalancerLpTokenPriceOracle_Implementation: {
      address: "0x3968f4BAD0cc1a8AE5A11619c8c33d3a7aab1F30"
    },
    BalancerLpTokenPriceOracle_Proxy: {
      address: "0x7b7fa6D93f8ca69724d98E0d563e868Ce342eB51"
    },
    BalancerRateProviderOracle: {
      address: "0x0C3397c084F373C11B9f39341f03316d9109DCE7"
    },
    BalancerRateProviderOracle_Implementation: {
      address: "0x5Bc1A20C44e42b7e0E17C7e6cDACd6f4c6061786"
    },
    BalancerRateProviderOracle_Proxy: {
      address: "0x0C3397c084F373C11B9f39341f03316d9109DCE7"
    },
    BalancerSwapLiquidator: {
      address: "0xAe0CDC5b15E4F916DE52F8c0f3289556fe24a1a6"
    },
    CErc20Delegate: {
      address: "0xE55a7dd52D4e0577B1a8bbfeA7246d83b85A5B56"
    },
    CErc20PluginDelegate: {
      address: "0x99F7040BE7FeFb1f514949BcaB6A79f8351B7657"
    },
    CErc20PluginRewardsDelegate: {
      address: "0x555AC469373C6d215D4D00E334a19C43e6A1085F"
    },
    CEtherDelegate: {
      address: "0xA6BDC4e098b6b14f4fCA2906B0a51e2798BA5777"
    },
    CTokenFirstExtension: {
      address: "0x76724Bcd9E270d4d27aed9f7BD77Dd0CB7B7264b"
    },
    ChainlinkPriceOracleV2: {
      address: "0x12f455CA7cf0b90aDAF31f58dA6BeeD7DFe28bBf"
    },
    Comptroller: {
      address: "0x273060359eeDe3AB058bcc52d26C603531c2f778"
    },
    ComptrollerFirstExtension: {
      address: "0x4A5bCc111102E9b0d86E473EacFB7C530048a960"
    },
    CurveLpTokenLiquidatorNoRegistry: {
      address: "0xb7d3cf34fA83994B2eC92d918a693d2a4aCEA9Fa"
    },
    CurveLpTokenPriceOracleNoRegistry: {
      address: "0xaCF3E1C6f2D6Ff12B8aEE44413D6834774B3f7A3"
    },
    CurveLpTokenPriceOracleNoRegistry_Implementation: {
      address: "0x90470456afd930f4B296D27dE035160a7b452F72"
    },
    CurveLpTokenPriceOracleNoRegistry_Proxy: {
      address: "0xaCF3E1C6f2D6Ff12B8aEE44413D6834774B3f7A3"
    },
    CurveLpTokenWrapper: {
      address: "0xd0C4A279ac136D4ccBDD985f8eC86c39D52f7b34"
    },
    CurveSwapLiquidator: {
      address: "0x368c5E0655566DFE96c469EAB68969B0c6F4d3bc"
    },
    CurveSwapLiquidatorFunder: {
      address: "0x76f7252274F2FFD3F6f298D25C257fDB5c569cDa"
    },
    DefaultProxyAdmin: {
      address: "0x9b30a238A94c5a456a02ceC01e41f1c91d54e915"
    },
    DiaPriceOracle: {
      address: "0x5670E9d607666a8341313DA477B0220E70c98370"
    },
    FixedNativePriceOracle: {
      address: "0x14C15B9ec83ED79f23BF71D51741f58b69ff1494"
    },
    FuseFeeDistributor: {
      address: "0xf656D243a23A0987329ac6522292f4104A7388e1"
    },
    FuseFeeDistributor_Implementation: {
      address: "0x7F5CA76be462F0e504749Cb7C6f934A482108b05"
    },
    FuseFeeDistributor_Proxy: {
      address: "0xf656D243a23A0987329ac6522292f4104A7388e1"
    },
    FuseFlywheelCore_JRT_MIMO_SEP22: {
      address: "0x6c44d119536CE433dC8bed943B7A1BC7EFCD56F4"
    },
    FuseFlywheelCore_MIMO: {
      address: "0x7D28F081711f43Ad98ba0cB7C65af6268f27fdA7"
    },
    FuseFlywheelDynamicRewardsPlugin_JRT_MIMO_SEP22: {
      address: "0xbA7223958c5790dD00a1B89E92355fE7a0df2801"
    },
    FuseFlywheelDynamicRewardsPlugin_MIMO: {
      address: "0x9c44eD0210a082CFA1378cd88BcE30dbA08daCb3"
    },
    FuseFlywheelLensRouter: {
      address: "0xACE76A60D4bF76BCa8ccd274Ae6D081904bBbAe3"
    },
    FusePoolDirectory: {
      address: "0x9A161e68EC0d5364f4d09A6080920DAFF6FFf250"
    },
    FusePoolDirectory_Implementation: {
      address: "0xB50aAd266Fa23ec0f435330242F1a0AA8D207758"
    },
    FusePoolDirectory_Proxy: {
      address: "0x9A161e68EC0d5364f4d09A6080920DAFF6FFf250"
    },
    FusePoolLens: {
      address: "0x16d8C0Ee982d5219285B1042D3675a6D9E247f9D"
    },
    FusePoolLensSecondary: {
      address: "0xB6a5b29FA1e7a7b3733c5E361920a27c87BbA286"
    },
    FuseSafeLiquidator: {
      address: "0x37b3890B9b3a5e158EAFDA243d4640c5349aFC15"
    },
    FuseSafeLiquidator_Implementation: {
      address: "0xfF365Fd45d906F542e3A9eB5eDf5aA095c4Eab53"
    },
    FuseSafeLiquidator_Proxy: {
      address: "0x37b3890B9b3a5e158EAFDA243d4640c5349aFC15"
    },
    GammaLpTokenLiquidator: {
      address: "0x35EAeEb9a300aaB2cC47101250024e30c1233567"
    },
    GelatoGUniLiquidator: {
      address: "0x1ef47980f73A8b82a96C093F07C0ddB166fD485F"
    },
    GelatoGUniPriceOracle: {
      address: "0xb36badd5a3040fC2E950E087bf2B9BCfdb45aC1e"
    },
    JarvisLiquidatorFunder: {
      address: "0x6886f3F3b4457f33FF5bbfa2Ff3c58bc017E0920"
    },
    JarvisSafeLiquidator: {
      address: "0x6dA2d84d390F12a6C49Afe7B677a6a2B8E0D961a"
    },
    JarvisSafeLiquidator_Implementation: {
      address: "0xf78A8fbBD89Bb01bA273565eb80f6D8425F63f1d"
    },
    JarvisSafeLiquidator_Proxy: {
      address: "0x6dA2d84d390F12a6C49Afe7B677a6a2B8E0D961a"
    },
    JumpRateModel: {
      address: "0x79Ebf6D2F3724254f290862A5a0F57044885Cb50"
    },
    JumpRateModel_JARVIS_002_004_4_08: {
      address: "0x33EdD722DEfF1b7c7180812D079AA2FC04ADae02"
    },
    JumpRateModel_MIMO_002_004_4_08: {
      address: "0xAe93A10190393c90593db3E931295d6D27e51383"
    },
    LeveredPositionFactory: {
      address: "0x2f54C95b9A76699C7Cd77d3bCe096B36ccbda402"
    },
    LeveredPositionFactoryExtension: {
      address: "0xDE399BFA80Cf1c07FCeF8DBC07CB7E2165447A3a"
    },
    LeveredPositionsLens: {
      address: "0xc7C522615d468c8F91d8B8B5837f0E71fcefEe32"
    },
    LeveredPositionsLens_Implementation: {
      address: "0x48e9E2b08461aD468A8feFB48d67b33249656524"
    },
    LeveredPositionsLens_Proxy: {
      address: "0xc7C522615d468c8F91d8B8B5837f0E71fcefEe32"
    },
    LiquidatorsRegistry: {
      address: "0x61B6d5962d3855f6EcA4Ef646ABF79c8C8beD8bA"
    },
    LiquidatorsRegistryExtension: {
      address: "0x295A9Ac02bfe914ffa2EeADB4F304D051168be12"
    },
    LooplessFlywheelBooster: {
      address: "0x04ce4F5F699A2F52152Ea6F567EA852870fFcE73"
    },
    MasterPriceOracle: {
      address: "0xb9e1c2B011f252B9931BBA7fcee418b95b6Bdc31"
    },
    MasterPriceOracle_Implementation: {
      address: "0xcC6Aa628516BB46391B05B16E5058c877461cc76"
    },
    MasterPriceOracle_Proxy: {
      address: "0xb9e1c2B011f252B9931BBA7fcee418b95b6Bdc31"
    },
    MidasFlywheelCore_JRT_MIMO_SEP22: {
      address: "0xC6cB1FeA5F391B6998D074Ad31EB1DCFbDEF7601"
    },
    MidasFlywheelCore_JRT_MIMO_SEP22_Implementation: {
      address: "0x60Fb098C48159064a71e161dEA951815f1ceB00f"
    },
    MidasFlywheelCore_JRT_MIMO_SEP22_Proxy: {
      address: "0xC6cB1FeA5F391B6998D074Ad31EB1DCFbDEF7601"
    },
    MidasFlywheelCore_MIMO: {
      address: "0x1557ccC2cc2B0CC6029330B86FC39CCEA007339b"
    },
    MidasFlywheelCore_MIMO_Implementation: {
      address: "0x22A4D1bf93e84E107795c82f0c718a083dD1c215"
    },
    MidasFlywheelCore_MIMO_Proxy: {
      address: "0x1557ccC2cc2B0CC6029330B86FC39CCEA007339b"
    },
    MidasFlywheelLensRouter: {
      address: "0x79eA192e51e56D639AafB24993b6CEd772407d13"
    },
    MidasFlywheel_JRT_Jarvis: {
      address: "0x5943487ca06D49Bb62b378ad7D1e9ff0848cCF86"
    },
    MidasFlywheel_JRT_Jarvis_Implementation: {
      address: "0x6E94e8704fa9f3958B7c19f5ff2fD7753FAaDFb2"
    },
    MidasFlywheel_JRT_Jarvis_Proxy: {
      address: "0x5943487ca06D49Bb62b378ad7D1e9ff0848cCF86"
    },
    MidasFlywheel_MIMO: {
      address: "0x5fF63E442AC4724EC342f4a3d26924233832EcBB"
    },
    MidasFlywheel_MIMO_Implementation: {
      address: "0x95807e954F78B8a934848C089fE5E25cEdAb3e9A"
    },
    MidasFlywheel_MIMO_Proxy: {
      address: "0x5fF63E442AC4724EC342f4a3d26924233832EcBB"
    },
    MidasSafeLiquidator: {
      address: "0x401B9E407896d5CAad75b057EF32Fa005c07252d"
    },
    MidasSafeLiquidator_Implementation: {
      address: "0x5449bc2cc9d989649BD40ee815a93279c9112B95"
    },
    MidasSafeLiquidator_Proxy: {
      address: "0x401B9E407896d5CAad75b057EF32Fa005c07252d"
    },
    OptimizedVaultsRegistry: {
      address: "0xcAff220EAd1484Cc9c6Ebab6B07f7Da086e77601"
    },
    OptimizedVaultsRegistry_Implementation: {
      address: "0x4F0913D476e4CbC9661de416d85994be9a04beE7"
    },
    OptimizedVaultsRegistry_Proxy: {
      address: "0xcAff220EAd1484Cc9c6Ebab6B07f7Da086e77601"
    },
    Quoter: {
      address: "0x265A84C369c9dd12DbAAe57b81463A3c6C7eD6eC"
    },
    ReplacingFlywheelDynamicRewards_MIMO: {
      address: "0x8A54287A702556197a38014f010938E86dcDFCF9"
    },
    SimplePriceOracle: {
      address: "0x7770BdDdA35F7e13dB7e112Cc77b0445922DA660"
    },
    SimplePriceOracle_Implementation: {
      address: "0x44298Df796b12310a0c100bf559D817A989FAE21"
    },
    SimplePriceOracle_Proxy: {
      address: "0x7770BdDdA35F7e13dB7e112Cc77b0445922DA660"
    },
    SolidlyLpTokenLiquidator: {
      address: "0xa5aD81634B005e18Ab663b789dC127faA79a1150"
    },
    SolidlyLpTokenWrapper: {
      address: "0x6765D8b85e88DB96261Dee542A2FE56c90b3CDCF"
    },
    SolidlyPriceOracle: {
      address: "0xe3a5BDb287D9f6da0453A0025B8Df550D518A86c"
    },
    SolidlyPriceOracle_Implementation: {
      address: "0xFcF2cf061B27783E650c79DcD90682Ee7eaC7900"
    },
    SolidlyPriceOracle_Proxy: {
      address: "0xe3a5BDb287D9f6da0453A0025B8Df550D518A86c"
    },
    SolidlySwapLiquidator: {
      address: "0x03Cbdfaf6e3D6108a462691759392Aef814d0dB2"
    },
    UniswapLpTokenLiquidator: {
      address: "0x67b3AF7C93903A21818C5f8Ef7129832165CB792"
    },
    UniswapLpTokenPriceOracle: {
      address: "0x7D846942d87374Cf7b4761c0a5C5d70c6FEed669"
    },
    UniswapV2Liquidator: {
      address: "0xd0CE13FD52b4bE9e375EAEf5B2d4F6dB207c0E90"
    },
    UniswapV2LiquidatorFunder: {
      address: "0x5bEb4BD2164D286CdE9c672910c548dF643a2Caa"
    },
    UniswapV3LiquidatorFunder: {
      address: "0xC3627C03FC11EFE4A031d5769E5335B058724BD2"
    },
    UniswapV3PriceOracle: {
      address: "0xF36e137F9aA5fB74e9aEb0EDc767238872d6fd3d"
    },
    UniswapV3PriceOracle_Implementation: {
      address: "0x8BCfb6afE288A26aB69644e18Ed9913B4C2EdDac"
    },
    UniswapV3PriceOracle_Proxy: {
      address: "0xF36e137F9aA5fB74e9aEb0EDc767238872d6fd3d"
    },
    WhitePaperInterestRateModel: {
      address: "0x1c5DCC70eD61301Ebfd847Ad3573290da223E085"
    }
  }
};

// src/polygon/addresses.ts
import { assetSymbols as assetSymbols50, underlying as underlying30 } from "@midas-capital/types";
import { ethers as ethers12 } from "ethers";

// src/polygon/assets.ts
import { assetOriginalSymbols as assetOriginalSymbols2, assetSymbols as assetSymbols49, OracleTypes as OracleTypes21, SupportedChains as SupportedChains18 } from "@midas-capital/types";
var WBTC4 = "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6";
var WMATIC = "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270";
var USDC7 = "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174";
var AAVE = "0xD6DF932A45C0f255f85145f286eA0b292B21C90B";
var ALCX = "0x95c300e7740D2A88a44124B424bFC1cB2F9c3b89";
var BAL3 = "0x9a71012B13CA4d3D0Cdc72A177DF3ef03b0E76A3";
var oBNB = "0x7e9928aFe96FefB820b85B4CE6597B8F660Fe4F4";
var BUSD2 = "0xdAb529f40E671A1D4bF91361c21bf9f0C9712ab7";
var CRV2 = "0x172370d5Cd63279eFa6d502DAB29171933a610AF";
var CVX = "0x4257EA7637c355F81616050CbB6a9b709fd72683";
var DAI6 = "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063";
var WETH4 = "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619";
var FRAX5 = "0x45c32fA6DF82ead1e2EF74d17b76547EDdFaFF89";
var FTM2 = "0xC9c1c1c20B3658F8787CC2FD702267791f224Ce1";
var FXS2 = "0x1a3acf6D19267E2d3e7f898f42803e90C9219062";
var GHST = "0x385Eeac5cB85A38A9a07A70c73e0a3271CfB54A7";
var GRT = "0x5fe2B58c013d7601147DcdD68C143A77499f5531";
var LINK2 = "0xb0897686c545045aFc77CF20eC7A532E3120E0F1";
var MAI3 = "0xa3Fa99A148fA48D14Ed51d610c367C61876997F1";
var MKR = "0x6f7C932e7684666C9fd1d44527765433e01fF61d";
var RAI = "0x00e5646f60AC6Fb446f621d146B6E1886f002905";
var SNX = "0x50B728D8D964fd00C2d0AAD81718b71311feF68a";
var SOL = "0xd93f7E271cB87c23AaA73edC008A79646d1F9912";
var SUSHI2 = "0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a";
var YFI = "0xDA537104D6A5edd53c6fBba9A898708E465260b6";
var USDT5 = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F";
var MIMO2 = "0xADAC33f543267c4D59a8c299cF804c303BC3e4aC";
var JRT = "0x596eBE76e2DB4470966ea395B0d063aC6197A8C5";
var IXT = "0xE06Bd4F5aAc8D0aA337D13eC88dB6defC6eAEefE";
var GNS = "0xE5417Af564e4bFDA1c483642db72007871397896";
var SD2 = "0x1d734A02eF1e1f5886e66b0673b71Af5B53ffA94";
var USDR = "0xb5DFABd7fF7F83BAB83995E72A52B97ABb7bcf63";
var WUSDR = "0xAF0D9D65fC54de245cdA37af3d18cbEc860A4D4b";
var TNGBL = "0x49e6A20f1BBdfEeC2a8222E052000BbB14EE6007";
var MATICx = "0xfa68FB4628DFF1028CFEc22b4162FCcd0d45efb6";
var stMATIC = "0x3A58a54C066FdC0f2D55FC9C89F0415C92eBf3C4";
var aMATICc = "0x0E9b89007eEE9c958c0EDA24eF70723C2C93dD58";
var csMATIC = "0xFcBB00dF1d663eeE58123946A30AB2138bF9eb2A";
var MIMO_PAR_80_20 = "0x82d7f08026e21c7713CfAd1071df7C8271B17Eae";
var BRZ_JBRL_STABLE_BLP = "0xE22483774bd8611bE2Ad2F4194078DaC9159F4bA";
var JEUR_PAR_STABLE_BLP = "0x513CdEE00251F39DE280d9E5f771A6eaFebCc88E";
var WMATIC_STMATIC_STABLE_BLP = "0x8159462d255C1D24915CB51ec361F700174cD994";
var WMATIC_CSMATIC_STABLE_BLP = "0x02d2e2D7a89D6c5CB3681cfCb6F7dAC02A55eDA4";
var WMATIC_MATICX_STABLE_BLP = "0xb20fC01D21A50d2C734C4a1262B4404d41fA7BF0";
var MATICX_AAVE_WMATIC_STABLE_BLP = "0xE78b25c06dB117fdF8F98583CDaaa6c92B79E917";
var stMATIC_AAVE_WMATIC_STABLE_BLP = "0x216690738Aac4aa0C4770253CA26a28f0115c595";
var TETU_BOOSTED_STABLE_BLP = "0xb3d658d5b95BF04E2932370DD1FF976fe18dd66A";
var TETU_LINEAR_USDT = "0x7c82A23B4C48D796dee36A9cA215b641C6a8709d";
var TETU_LINEAR_USDC = "0xae646817e458C0bE890b81e8d880206710E3c44e";
var TETU_LINEAR_DAI = "0xDa1CD1711743e57Dd57102E9e61b75f3587703da";
var AAVE_LINEAR_WMATIC = "0xE4885Ed2818Cc9E840A25f94F9b2A28169D1AEA7";
var amUSDC = "0x1a13F4Ca1d028320A707D99520AbFefca3998b7F";
var am3CRV = "0xE7a24EF0C5e95Ffb0f6684b813A78F2a3AD7D171";
var USDR3CRV = "0xa138341185a9D0429B0021A11FB717B225e13e1F";
var WMATIC_USDC = "0x6e7a5FAFcec6BB1e78bAE2A1F0B612012BF14827";
var WMATIC_USDT = "0x604229c960e5CACF2aaEAc8Be68Ac07BA9dF81c3";
var WMATIC_ETH = "0xadbF1854e5883eB8aa7BAf50705338739e558E5b";
var WMATIC_MATICx = "0xb0e69f24982791dd49e316313fD3A791020B8bF7";
var WETH_WBTC = "0xdC9232E2Df177d7a12FdFf6EcBAb114E2231198D";
var AGEUR_JEUR = "0x2fFbCE9099cBed86984286A54e5932414aF4B717";
var JEUR_PAR = "0x0f110c55EfE62c16D553A3d3464B77e1853d0e97";
var JJPY_JPYC = "0xaA91CDD7abb47F821Cf07a2d38Cc8668DEAf1bdc";
var JCAD_CADC = "0xA69b0D5c0C401BBA2d5162138613B5E38584F63F";
var JSGD_XSGD = "0xeF75E9C7097842AcC5D0869E1dB4e5fDdf4BFDDA";
var JNZD_NZDS = "0x976A750168801F58E8AEdbCfF9328138D544cc09";
var JEUR_EURT = "0x2C3cc8e698890271c8141be9F6fD6243d56B39f1";
var EURE_JEUR = "0x2F3E9CA3bFf85B91D9fe6a9f3e8F9B1A6a4c3cF4";
var MAI_USDC = "0x160532D2536175d65C03B97b0630A9802c274daD";
var DAI_GNS = "0x6E53cB6942e518376E9e763554dB1A45DDCd25c4";
var IXT_USDT = "0x304e57c752E854E9A233Ae82fcC42F7568b81180";
var AGEUR = "0xE0B52e49357Fd4DAf2c15e02058DCE6BC0057db4";
var JEUR = "0x4e3Decbb3645551B8A19f0eA1678079FCB33fB4c";
var EURE = "0x18ec0A6E18E5bc3784fDd3a3634b31245ab704F6";
var EURT = "0x7BDF330f423Ea880FF95fC41A280fD5eCFD3D09f";
var PAR3 = "0xE2Aa7db6dA1dAE97C5f5C6914d285fBfCC32A128";
var JJPY = "0x8343091F2499FD4b6174A46D067A920a3b851FF9";
var JPYC = "0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB";
var JCAD = "0x8ca194A3b22077359b5732DE53373D4afC11DeE3";
var CADC = "0x5d146d8B1dACb1EBBA5cb005ae1059DA8a1FbF57";
var JSGD = "0xa926db7a4CC0cb1736D5ac60495ca8Eb7214B503";
var JCHF = "0xbD1463F02f61676d53fd183C2B19282BFF93D099";
var JMXN = "0xBD1fe73e1f12bD2bc237De9b626F056f21f86427";
var JGBP = "0x767058F11800FBA6A682E73A6e79ec5eB74Fac8c";
var JCNY = "0x84526c812D8f6c4fD6C1a5B68713AFF50733E772";
var JAUD = "0xCB7F1Ef7246D1497b985f7FC45A1A31F04346133";
var JNZD = "0x6b526Daf03B4C47AF2bcc5860B12151823Ff70E0";
var JPLN = "0x08E6d1F0c4877Ef2993Ad733Fc6F1D022d0E9DBf";
var JSEK = "0x197E5d6CcfF265AC3E303a34Db360ee1429f5d1A";
var JKRW = "0xa22f6bc96f13bcC84dF36109c973d3c0505a067E";
var JPHP = "0x486880FB16408b47f928F472f57beC55AC6089d1";
var NZDS = "0xeaFE31Cd9e8E01C8f0073A2C974f728Fb80e9DcE";
var BRZ2 = "0x491a4eB4f1FC3BfF8E1d2FC856a6A46663aD556f";
var jBRL2 = "0xf2f77FE7b8e66571E0fca7104c4d670BF1C8d722";
var XSGD = "0x769434dcA303597C8fc4997Bf3DAB233e961Eda2";
var arrakis_USDC_WETH_005 = "0xA173340f1E942c2845bcBCe8EBD411022E18EB13";
var arrakis_WBTC_WETH_005 = "0x590217ef04BcB96FF6Da991AB070958b8F9E77f0";
var arrakis_USDC_PAR_005 = "0xC1DF4E2fd282e39346422e40C403139CD633Aacd";
var arrakis_WMATIC_USDC_005 = "0x4520c823E3a84ddFd3F99CDd01b2f8Bf5372A82a";
var arrakis_USDC_agEUR_001 = "0x1644de0A8E54626b54AC77463900FcFFD8B94542";
var arrakis_WMATIC_WETH_005 = "0xDC0eCA1D69ab51C2B2171C870A1506499081dA5B";
var arrakis_WMATIC_AAVE_03 = "0x3Cc255339a27eFa6c38bEe880F4061AB9b231732";
var arrakis_USDC_MAI_005 = "0x4Fe4d754d1B2feaAd266332CfE3d3fcaa632c953";
var arrakis_USDC_USDT_001 = "0x2817E729178471DBAC8b1FC190b4fd8e6F3984e3";
var arrakis_USDC_USDT_005 = "0x869A75D6F7ae09810c9083684cf22e9A618c8B05";
var arrakis_USDC_DAI_005 = "0x2aF769150510Ad9eb37D2e63e1E483114d995cBA";
var arrakis_WETH_DAI_03 = "0x21F65eA5bf55c48A19b195d5d8CB0f708018Ab6c";
var solidlyStableAMM_USDR_USDC = "0xf6A72Bd46F53Cd5103812ea1f4B5CF38099aB797";
var solidlyVolatileAMM_stMATIC_USDR = "0x733eEEf37De013283da29cE9EB4758dC59CaFc87";
var solidlyVolatileAMM_wUSDR_USDR = "0x10E1b58B3C93890D04D539b5f39Aa4Df27A362b2";
var assets12 = [
  {
    symbol: assetSymbols49.AAVE,
    underlying: AAVE,
    name: "AAVE Token (PoS)",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://polygonscan.com", AAVE),
    disabled: true
  },
  {
    symbol: assetSymbols49.ALCX,
    underlying: ALCX,
    name: "Alchemix Token (PoS)",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://polygonscan.com", ALCX),
    disabled: true
  },
  {
    symbol: assetSymbols49.BAL,
    underlying: BAL3,
    name: "Balancer (PoS)",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://polygonscan.com", BAL3),
    disabled: true
  },
  {
    symbol: assetSymbols49.oBNB,
    underlying: oBNB,
    name: "Orbit Bridge BNB",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://polygonscan.com", oBNB),
    disabled: true
  },
  {
    symbol: assetSymbols49.BUSD,
    underlying: BUSD2,
    name: "Binance USD (PoS)",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://polygonscan.com", BUSD2),
    disabled: true
  },
  {
    symbol: assetSymbols49.CRV,
    underlying: CRV2,
    name: "CRV (PoS)",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://polygonscan.com", CRV2),
    disabled: true
  },
  {
    symbol: assetSymbols49.CVX,
    underlying: CVX,
    name: "CVX (PoS)",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://polygonscan.com", CVX),
    disabled: true
  },
  {
    symbol: assetSymbols49.DAI,
    underlying: DAI6,
    name: "Dai Stablecoin (PoS)",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: oneInchDocs("https://app.1inch.io/#/137/unified/swap/MATIC/DAI")
  },
  {
    symbol: assetSymbols49.WETH,
    underlying: WETH4,
    name: "Wrapped Ether",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://polygonscan.com", WETH4)
  },
  {
    symbol: assetSymbols49.FRAX,
    underlying: FRAX5,
    name: "Frax",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: oneInchDocs("https://app.1inch.io/#/137/unified/swap/MATIC/FRAX")
  },
  {
    symbol: assetSymbols49.FTM,
    underlying: FTM2,
    name: "Fantom",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://polygonscan.com", FTM2),
    disabled: true
  },
  {
    symbol: assetSymbols49.FXS,
    underlying: FXS2,
    name: "Frax Share",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://polygonscan.com", FXS2),
    disabled: true
  },
  {
    symbol: assetSymbols49.GHST,
    underlying: GHST,
    name: "Aavegotchi GHST Token (PoS)",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://polygonscan.com", GHST),
    disabled: true
  },
  {
    symbol: assetSymbols49.GRT,
    underlying: GRT,
    name: "Graph Token",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://polygonscan.com", GRT),
    disabled: true
  },
  {
    symbol: assetSymbols49.GNS,
    underlying: GNS,
    name: "Gains Network Token",
    decimals: 18,
    oracle: OracleTypes21.UniswapV3PriceOracle,
    extraDocs: defaultDocs("https://polygonscan.com", GNS)
  },
  {
    symbol: assetSymbols49.IXT,
    underlying: IXT,
    name: "PlanetIX",
    decimals: 18,
    oracle: OracleTypes21.AlgebraPriceOracle,
    extraDocs: defaultDocs("https://polygonscan.com", IXT)
  },
  {
    symbol: assetSymbols49.LINK,
    underlying: LINK2,
    name: "ChainLink Token",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://polygonscan.com", LINK2),
    disabled: true
  },
  {
    symbol: assetSymbols49.MAI,
    underlying: MAI3,
    name: "miMATIC",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://polygonscan.com", MAI3)
  },
  {
    symbol: assetSymbols49.MKR,
    underlying: MKR,
    name: "Maker DAO",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://polygonscan.com", MKR),
    disabled: true
  },
  {
    symbol: assetSymbols49.RAI,
    underlying: RAI,
    name: "Rai Reflex Index (PoS)",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://polygonscan.com", RAI),
    disabled: true
  },
  {
    symbol: assetSymbols49.SNX,
    underlying: SNX,
    name: "Synthetix Network Token (PoS)",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://polygonscan.com", SNX),
    disabled: true
  },
  {
    symbol: assetSymbols49.SD,
    underlying: SD2,
    name: "Stader Labs (PoS)",
    decimals: 18,
    oracle: OracleTypes21.AlgebraPriceOracle,
    extraDocs: defaultDocs("https://polygonscan.com", SD2),
    disabled: true
  },
  {
    symbol: assetSymbols49.SOL,
    underlying: SOL,
    name: "Wrapped SOL (Wormhole)",
    decimals: 9,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://polygonscan.com", SOL),
    disabled: true
  },
  {
    symbol: assetSymbols49.SUSHI,
    underlying: SUSHI2,
    name: "SushiToken (PoS)",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: oneInchDocs("https://app.1inch.io/#/137/unified/swap/MATIC/SUSHI"),
    disabled: true
  },
  {
    symbol: assetSymbols49.YFI,
    underlying: YFI,
    name: "yearn.finance (PoS)",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: defaultDocs("https://polygonscan.com", YFI),
    disabled: true
  },
  {
    symbol: assetSymbols49.USDC,
    underlying: USDC7,
    name: "USD Coin (PoS)",
    decimals: 6,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: oneInchDocs("https://app.1inch.io/#/137/unified/swap/MATIC/USDC")
  },
  {
    symbol: assetSymbols49.USDT,
    underlying: USDT5,
    name: "Tether USD (PoS)",
    decimals: 6,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: oneInchDocs("https://app.1inch.io/#/137/unified/swap/MATIC/USDT")
  },
  {
    symbol: assetSymbols49.WBTC,
    underlying: WBTC4,
    name: "Wrapped BTC (PoS)",
    decimals: 8,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: oneInchDocs("https://app.1inch.io/#/137/unified/swap/MATIC/WBTC")
  },
  {
    symbol: assetSymbols49.WMATIC,
    underlying: WMATIC,
    name: "Wrapped Matic",
    decimals: 18,
    oracle: OracleTypes21.FixedNativePriceOracle,
    extraDocs: wrappedAssetDocs(SupportedChains18.polygon)
  },
  {
    symbol: assetSymbols49.MIMO,
    underlying: MIMO2,
    name: "MIMO Parallel Governance Token (PoS) ",
    decimals: 18,
    oracle: OracleTypes21.DiaPriceOracle,
    extraDocs: oneInchDocs("https://app.1inch.io/#/137/unified/swap/MATIC/MIMO")
  },
  {
    symbol: assetSymbols49.MATICx,
    underlying: MATICx,
    name: "Liquid Staking Matic (PoS)",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: StaderXDocs("polygon", "MATICx")
  },
  {
    symbol: assetSymbols49.stMATIC,
    underlying: stMATIC,
    name: "Staked MATIC (PoS)",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: lidoFinanceDocs("polygon", "MATIC", "stMATIC")
  },
  {
    symbol: assetSymbols49.aMATICc,
    underlying: aMATICc,
    name: "Ankr MATIC Reward Bearing Certificate",
    decimals: 18,
    oracle: OracleTypes21.AnkrCertificateTokenPriceOracle,
    extraDocs: ankrCertificateDocs("aMATICc", "MATIC")
  },
  {
    symbol: assetSymbols49.csMATIC,
    underlying: csMATIC,
    name: "ClayStack Staked MATIC",
    decimals: 18,
    oracle: OracleTypes21.BalancerRateProviderOracle,
    extraDocs: clayStackDocs("MATIC", "polygon")
  },
  // QuickSwap LPs
  {
    symbol: assetSymbols49["WMATIC-USDC"],
    underlying: WMATIC_USDC,
    name: "WMATIC-USDC LP Token",
    decimals: 18,
    oracle: OracleTypes21.UniswapLpTokenPriceOracle,
    extraDocs: quickSwapDocs(WMATIC, USDC7, "WMATIC-USDC", WMATIC_USDC),
    disabled: true
  },
  {
    symbol: assetSymbols49["WMATIC-USDT"],
    underlying: WMATIC_USDT,
    name: "WMATIC-USDT LP Token",
    decimals: 18,
    oracle: OracleTypes21.UniswapLpTokenPriceOracle,
    extraDocs: quickSwapDocs(WMATIC, USDT5, "WMATIC-USDT", WMATIC_USDT),
    disabled: true
  },
  {
    symbol: assetSymbols49["WMATIC-ETH"],
    underlying: WMATIC_ETH,
    name: "WMATIC-ETH LP Token",
    decimals: 18,
    oracle: OracleTypes21.UniswapLpTokenPriceOracle,
    extraDocs: quickSwapDocs(WMATIC, WETH4, "WMATIC-ETH", WMATIC_ETH),
    disabled: true
  },
  {
    symbol: assetSymbols49["WMATIC-MATICx"],
    underlying: WMATIC_MATICx,
    name: "WMATIC-MATICx LP Token",
    decimals: 18,
    oracle: OracleTypes21.UniswapLpTokenPriceOracle,
    extraDocs: quickSwapDocs(WMATIC, MATICx, "WMATIC-MATICx", WMATIC_MATICx)
  },
  {
    symbol: assetSymbols49["WETH-WBTC"],
    underlying: WETH_WBTC,
    name: "WETH-WBTC LP Token",
    decimals: 18,
    oracle: OracleTypes21.UniswapLpTokenPriceOracle,
    extraDocs: quickSwapDocs(WETH4, WBTC4, "WETH-WBTC", WETH_WBTC),
    disabled: true
  },
  {
    symbol: assetSymbols49["MAI-USDC"],
    underlying: MAI_USDC,
    name: "MAI-USDC LP Token",
    decimals: 18,
    oracle: OracleTypes21.UniswapLpTokenPriceOracle,
    extraDocs: quickSwapDocs(MAI3, USDC7, "MAI-USDC", MAI_USDC)
  },
  {
    symbol: assetSymbols49["DAI-GNS"],
    underlying: DAI_GNS,
    name: "DAI-GNS LP Token",
    decimals: 18,
    oracle: OracleTypes21.UniswapLpTokenPriceOracle,
    extraDocs: quickSwapDocs(DAI6, GNS, "DAI-GNS", DAI_GNS)
  },
  {
    symbol: assetSymbols49["IXT-USDT"],
    underlying: IXT_USDT,
    name: "IXT-USDT LP Token",
    decimals: 18,
    oracle: OracleTypes21.UniswapLpTokenPriceOracle,
    extraDocs: quickSwapDocs(IXT, USDT5, "IXT-USDT", IXT_USDT)
  },
  // curve.fi LP tokens
  {
    symbol: assetSymbols49["AGEUR-JEUR"],
    underlying: AGEUR_JEUR,
    name: "Jarvis agEUR-jEUR LP Token",
    decimals: 18,
    oracle: OracleTypes21.CurveLpTokenPriceOracleNoRegistry,
    extraDocs: curveFinancePolygonDocs(209, "AGEUR-JEUR", AGEUR_JEUR, true)
  },
  {
    symbol: assetSymbols49["JEUR-PAR"],
    underlying: JEUR_PAR,
    name: "Jarvis jEUR-PAR LP Token",
    decimals: 18,
    oracle: OracleTypes21.CurveLpTokenPriceOracleNoRegistry,
    extraDocs: curveFinancePolygonDocs(285, "JEUR-PAR", JEUR_PAR, true)
  },
  {
    symbol: assetSymbols49["JJPY-JPYC"],
    underlying: JJPY_JPYC,
    name: "Jarvis jJPY-JPYC LP Token",
    decimals: 18,
    oracle: OracleTypes21.CurveLpTokenPriceOracleNoRegistry,
    extraDocs: curveFinancePolygonDocs(255, "JJPY-JPYC", JJPY_JPYC, true)
  },
  {
    symbol: assetSymbols49["JCAD-CADC"],
    underlying: JCAD_CADC,
    name: "Jarvis jCAD-CADC LP Token",
    decimals: 18,
    oracle: OracleTypes21.CurveLpTokenPriceOracleNoRegistry,
    extraDocs: curveFinancePolygonDocs(23, "JCAD-CADC", JCAD_CADC, true)
  },
  {
    symbol: assetSymbols49["JSGD-XSGD"],
    underlying: JSGD_XSGD,
    name: "Jarvis jSGD-XSGD LP Token",
    decimals: 18,
    oracle: OracleTypes21.CurveLpTokenPriceOracleNoRegistry,
    extraDocs: curveFinancePolygonDocs(22, "JSGD-XSGD", JSGD_XSGD, true)
  },
  {
    symbol: assetSymbols49["JNZD-NZDS"],
    underlying: JNZD_NZDS,
    name: "Jarvis JNZD-NZDS LP Token",
    decimals: 18,
    oracle: OracleTypes21.CurveLpTokenPriceOracleNoRegistry,
    extraDocs: curveFinancePolygonDocs(228, "JNZD-NZDS", JNZD_NZDS, true)
  },
  {
    symbol: assetSymbols49["JEUR-EURT"],
    underlying: JEUR_EURT,
    name: "Jarvis JEUR-EURT LP Token",
    decimals: 18,
    oracle: OracleTypes21.CurveLpTokenPriceOracleNoRegistry,
    extraDocs: curveFinancePolygonDocs(286, "JEUR-EURT", JEUR_EURT, true)
  },
  {
    symbol: assetSymbols49["EURE-JEUR"],
    underlying: EURE_JEUR,
    name: "Jarvis EURE-JEUR LP Token",
    decimals: 18,
    oracle: OracleTypes21.CurveLpTokenPriceOracleNoRegistry,
    extraDocs: curveFinancePolygonDocs(304, "EURE-JEUR", EURE_JEUR, true)
  },
  {
    symbol: assetSymbols49.amUSDC,
    underlying: amUSDC,
    name: "AAVE Usdc Token",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: `https://app.aave.com/reserve-overview/?underlyingAsset=${USDC7}&marketName=proto_polygon_v3`,
    disabled: true
  },
  {
    symbol: assetSymbols49.am3CRV,
    underlying: am3CRV,
    name: "Curve.fi amDAI/amUSDC/amUSDT",
    decimals: 18,
    oracle: OracleTypes21.CurveLpTokenPriceOracleNoRegistry,
    extraDocs: curveFinancePolygonDocs("aave", "am3CRV", am3CRV, false)
  },
  {
    symbol: assetSymbols49.USDR3CRV,
    underlying: USDR3CRV,
    name: "Curve.fi USDR/DAI/USDC/USDT",
    decimals: 18,
    oracle: OracleTypes21.CurveLpTokenPriceOracleNoRegistry,
    extraDocs: curveFinancePolygonDocs(339, "USDR3CRV", USDR3CRV, true)
  },
  // Balancer
  {
    symbol: assetSymbols49.MIMO_PAR_80_20,
    underlying: MIMO_PAR_80_20,
    name: "80MIMO-20PAR",
    decimals: 18,
    oracle: OracleTypes21.BalancerLpTokenPriceOracle,
    extraDocs: balancerDocs(
      "polygon",
      "0x82d7f08026e21c7713cfad1071df7c8271b17eae0002000000000000000004b6",
      "80MIMO-20PAR",
      MIMO_PAR_80_20
    )
  },
  {
    symbol: assetSymbols49.WMATIC_MATICX_STABLE_BLP,
    underlying: WMATIC_MATICX_STABLE_BLP,
    name: "WMATIC-MATICX Stable BLP",
    decimals: 18,
    oracle: OracleTypes21.BalancerLpStablePoolPriceOracle,
    extraDocs: balancerDocs(
      "polygon",
      "0xb20fc01d21a50d2c734c4a1262b4404d41fa7bf000000000000000000000075c",
      "WMATIC-MATICx BLP",
      WMATIC_MATICX_STABLE_BLP
    ),
    originalSymbol: assetOriginalSymbols2.WMATIC_MATICX_STABLE_BLP
  },
  {
    symbol: assetSymbols49.WMATIC_CSMATIC_STABLE_BLP,
    underlying: WMATIC_CSMATIC_STABLE_BLP,
    name: "WMATIC-csMATIC Stable BLP",
    decimals: 18,
    oracle: OracleTypes21.BalancerLpStablePoolPriceOracle,
    extraDocs: balancerDocs(
      "polygon",
      "0x02d2e2d7a89d6c5cb3681cfcb6f7dac02a55eda400000000000000000000088f",
      "WMATIC-csMATIC BLP",
      WMATIC_CSMATIC_STABLE_BLP
    )
  },
  {
    symbol: assetSymbols49.WMATIC_STMATIC_STABLE_BLP,
    underlying: WMATIC_STMATIC_STABLE_BLP,
    name: "WMATIC-stMATIC Stable BLP",
    decimals: 18,
    oracle: OracleTypes21.BalancerLpStablePoolPriceOracle,
    extraDocs: balancerDocs(
      "polygon",
      "0x8159462d255c1d24915cb51ec361f700174cd99400000000000000000000075d",
      "WMATIC-stMATIC Stable BLP",
      WMATIC_STMATIC_STABLE_BLP
    )
  },
  {
    symbol: assetSymbols49.BRZ_JBRL_STABLE_BLP,
    underlying: BRZ_JBRL_STABLE_BLP,
    name: "BRZ-jBRL Stable BLP",
    decimals: 18,
    oracle: OracleTypes21.BalancerLpStablePoolPriceOracle,
    extraDocs: balancerDocs(
      "polygon",
      "0xe22483774bd8611be2ad2f4194078dac9159f4ba0000000000000000000008f0",
      "BRZ-jBRL Stable BLP",
      BRZ_JBRL_STABLE_BLP
    )
  },
  {
    symbol: assetSymbols49.TETU_BOOSTED_STABLE_BLP,
    underlying: TETU_BOOSTED_STABLE_BLP,
    name: "DAI-USDT-USDC Boosted Stable BLP",
    decimals: 18,
    oracle: OracleTypes21.BalancerLpStablePoolPriceOracle,
    extraDocs: balancerDocs(
      "polygon",
      "0xb3d658d5b95bf04e2932370dd1ff976fe18dd66a000000000000000000000ace",
      "Tetu Boosted Stable BLP",
      TETU_BOOSTED_STABLE_BLP
    ),
    disabled: true
  },
  {
    symbol: assetSymbols49.JEUR_PAR_STABLE_BLP,
    underlying: JEUR_PAR_STABLE_BLP,
    name: "jEUR-PAR Stable BLP",
    decimals: 18,
    oracle: OracleTypes21.BalancerLpStablePoolPriceOracle,
    extraDocs: balancerDocs(
      "polygon",
      "0x513cdee00251f39de280d9e5f771a6eafebcc88e000000000000000000000a6b",
      "jEUR-PAR Stable BLP",
      JEUR_PAR_STABLE_BLP
    )
  },
  {
    symbol: assetSymbols49.MaticX_bbaWMATIC,
    underlying: MATICX_AAVE_WMATIC_STABLE_BLP,
    name: "MaticX-bbaWMATIC Stable BLP",
    decimals: 18,
    oracle: OracleTypes21.BalancerLpStablePoolPriceOracle,
    extraDocs: balancerDocs(
      "polygon",
      "0xe78b25c06db117fdf8f98583cdaaa6c92b79e917000000000000000000000b2b",
      "MaticX-bbaWMATIC Stable BLP",
      MATICX_AAVE_WMATIC_STABLE_BLP
    )
  },
  {
    symbol: assetSymbols49.StMatic_bbaWMATIC,
    underlying: stMATIC_AAVE_WMATIC_STABLE_BLP,
    name: "MaticX-bbaWMATIC Stable BLP",
    decimals: 18,
    oracle: OracleTypes21.BalancerLpStablePoolPriceOracle,
    extraDocs: balancerDocs(
      "polygon",
      "0x216690738aac4aa0c4770253ca26a28f0115c595000000000000000000000b2c",
      "StMatic-bbaWMATIC Stable BLP",
      stMATIC_AAVE_WMATIC_STABLE_BLP
    )
  },
  {
    symbol: assetSymbols49.TETU_LINEAR_USDT,
    underlying: TETU_LINEAR_USDT,
    name: "bb-t-USDT Linear BLP",
    decimals: 18,
    oracle: OracleTypes21.BalancerLpLinearPoolPriceOracle,
    extraDocs: "https://app.tetu.io/vault/0xf2fB1979C4bed7E71E6ac829801E0A8a4eFa8513",
    disabled: true
  },
  {
    symbol: assetSymbols49.TETU_LINEAR_USDC,
    underlying: TETU_LINEAR_USDC,
    name: "bb-t-USDC Linear BLP",
    decimals: 18,
    oracle: OracleTypes21.BalancerLpLinearPoolPriceOracle,
    extraDocs: "https://app.tetu.io/vault/0xf2fB1979C4bed7E71E6ac829801E0A8a4eFa8513",
    disabled: true
  },
  {
    symbol: assetSymbols49.TETU_LINEAR_DAI,
    underlying: TETU_LINEAR_DAI,
    name: "bb-t-DAI Linear BLP",
    decimals: 18,
    oracle: OracleTypes21.BalancerLpLinearPoolPriceOracle,
    extraDocs: "https://app.tetu.io/vault/0xf2fB1979C4bed7E71E6ac829801E0A8a4eFa8513",
    disabled: true
  },
  {
    symbol: assetSymbols49.AAVE_LINEAR_WMATIC,
    underlying: AAVE_LINEAR_WMATIC,
    name: "bb-a-WMATIC Linear BLP",
    decimals: 18,
    oracle: OracleTypes21.BalancerLpLinearPoolPriceOracle,
    extraDocs: "https://app.balancer.fi/#/polygon/pool/0xe78b25c06db117fdf8f98583cdaaa6c92b79e917000000000000000000000b2b",
    disabled: true
  },
  // stable forex
  {
    symbol: assetSymbols49.AGEUR,
    underlying: AGEUR,
    name: "agEUR Token",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: oneInchDocs("https://app.1inch.io/#/137/unified/swap/MATIC/agEUR")
  },
  {
    symbol: assetSymbols49.JEUR,
    underlying: JEUR,
    name: "Jarvis JEUR Token",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: jarvisDocs("v2")
  },
  {
    symbol: assetSymbols49.PAR,
    underlying: PAR3,
    name: "PAR Stablecoin (PoS)",
    decimals: 18,
    oracle: OracleTypes21.DiaPriceOracle,
    extraDocs: oneInchDocs("https://app.1inch.io/#/137/unified/swap/MATIC/PAR")
  },
  {
    symbol: assetSymbols49.EURT,
    underlying: EURT,
    name: "Euro Tether (PoS)",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2
  },
  {
    symbol: assetSymbols49.EURE,
    underlying: EURE,
    name: "Monerium EUR emoney",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2
  },
  {
    symbol: assetSymbols49.JJPY,
    underlying: JJPY,
    name: "Jarvis JJPY Token",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: jarvisDocs("v2")
  },
  {
    symbol: assetSymbols49.JPYC,
    underlying: JPYC,
    name: "JPY Coin",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2
  },
  {
    symbol: assetSymbols49.JCAD,
    underlying: JCAD,
    name: "Jarvis JCAD Token",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: jarvisDocs("v2"),
    disabled: true
  },
  {
    symbol: assetSymbols49.CADC,
    underlying: CADC,
    name: "CAD Coin (PoS)",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    disabled: true
  },
  {
    symbol: assetSymbols49.JSGD,
    underlying: JSGD,
    name: "Jarvis JSGD Token",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: jarvisDocs("v2")
  },
  {
    symbol: assetSymbols49.JCHF,
    underlying: JCHF,
    name: "Jarvis JCHF Token",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: jarvisDocs("v2")
  },
  {
    symbol: assetSymbols49.JMXN,
    underlying: JMXN,
    name: "Jarvis JMXN Token",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: jarvisDocs("v2")
  },
  {
    symbol: assetSymbols49.JGBP,
    underlying: JGBP,
    name: "Jarvis JGPB Token",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: jarvisDocs("v2")
  },
  {
    symbol: assetSymbols49.JCNY,
    underlying: JCNY,
    name: "Jarvis JCNY Token",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: jarvisDocs("v2")
  },
  {
    symbol: assetSymbols49.JAUD,
    underlying: JAUD,
    name: "Jarvis JAUD Token",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: jarvisDocs("v2")
  },
  {
    symbol: assetSymbols49.JNZD,
    underlying: JNZD,
    name: "Jarvis JNZD Token",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: jarvisDocs("v2")
  },
  {
    symbol: assetSymbols49.JPLN,
    underlying: JPLN,
    name: "Jarvis JPLN Token",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: jarvisDocs("v2")
  },
  {
    symbol: assetSymbols49.JSEK,
    underlying: JSEK,
    name: "Jarvis JSEK Token",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: jarvisDocs("v2")
  },
  {
    symbol: assetSymbols49.JKRW,
    underlying: JKRW,
    name: "Jarvis JKRW Token",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: jarvisDocs("v2")
  },
  {
    symbol: assetSymbols49.JPHP,
    underlying: JPHP,
    name: "Jarvis JPHP Token",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: jarvisDocs("v2")
  },
  {
    symbol: assetSymbols49.NZDS,
    underlying: NZDS,
    name: "NZD Stablecoin (PoS)",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: `<p><b>How to acquire this token</b><p/><br />
    <p>Learn more on how to acquire NZDS at <a href="https://www.techemynt.com/#buy_nzds" target="_blank" style="color: #BCAC83; cursor="pointer">Techemynt</a></p>`
  },
  {
    symbol: assetSymbols49.XSGD,
    underlying: XSGD,
    name: "StratisX Singapore Dollar (PoS)",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: `<p><b>How to acquire this token</b><p/><br />
    <p>Learn more on how to acquire NZDS at <a href="https://www.straitsx.com/sg/xsgd" target="_blank" style="color: #BCAC83; cursor="pointer">Stratis X</a></p>`
  },
  {
    symbol: assetSymbols49.BRZ,
    underlying: BRZ2,
    name: "BRZ Token",
    decimals: 4,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: `<p><b>How to acquire this token</b><p/><br />
    <p>You can acquire BRZ tokens at <a href="https://www.brztoken.io" target="_blank" style="color: #BCAC83; cursor="pointer">https://www.brztoken.io</> or other centralised exchanges</p>`
  },
  {
    symbol: assetSymbols49.jBRL,
    underlying: jBRL2,
    name: "Jarvis JBRL Token",
    decimals: 18,
    oracle: OracleTypes21.ChainlinkPriceOracleV2,
    extraDocs: jarvisDocs("v2")
  },
  {
    symbol: assetSymbols49.USDR,
    underlying: USDR,
    name: "Real USD ",
    decimals: 9,
    oracle: OracleTypes21.DiaPriceOracle,
    extraDocs: tangibleDocsUsdr("Mint")
  },
  {
    symbol: assetSymbols49.WUSDR,
    underlying: WUSDR,
    name: "Wrappd Real USD ",
    decimals: 9,
    oracle: OracleTypes21.DiaPriceOracle,
    extraDocs: tangibleDocsUsdr("Wrap")
  },
  {
    symbol: assetSymbols49.TNGBL,
    underlying: TNGBL,
    name: "Tangible ",
    decimals: 18,
    oracle: OracleTypes21.UniswapV3PriceOracle,
    extraDocs: defaultDocs("https://polygonscan.com", TNGBL)
  },
  // Arrakis Vaults
  {
    symbol: assetSymbols49["arrakis_USDC_WETH_005"],
    underlying: arrakis_USDC_WETH_005,
    name: "Arrakis Vault V1 USDC/WETH 0.05",
    decimals: 18,
    oracle: OracleTypes21.GelatoGUniPriceOracle,
    extraDocs: arrakisDocs("Polygon", 137, arrakis_USDC_WETH_005)
  },
  {
    symbol: assetSymbols49["arrakis_WBTC_WETH_005"],
    underlying: arrakis_WBTC_WETH_005,
    name: "Arrakis Vault V1 WBTC/WETH 0.05",
    decimals: 18,
    oracle: OracleTypes21.GelatoGUniPriceOracle,
    extraDocs: arrakisDocs("Polygon", 137, arrakis_WBTC_WETH_005)
  },
  {
    symbol: assetSymbols49["arrakis_USDC_PAR_005"],
    underlying: arrakis_USDC_PAR_005,
    name: "Arrakis Vault V1 USDC/PAR 0.05",
    decimals: 18,
    oracle: OracleTypes21.GelatoGUniPriceOracle,
    extraDocs: arrakisDocs("Polygon", 137, arrakis_USDC_PAR_005)
  },
  {
    symbol: assetSymbols49["arrakis_WMATIC_USDC_005"],
    underlying: arrakis_WMATIC_USDC_005,
    name: "Arrakis Vault V1 WMATIC/USDC 0.05",
    decimals: 18,
    oracle: OracleTypes21.GelatoGUniPriceOracle,
    extraDocs: arrakisDocs("Polygon", 137, arrakis_WMATIC_USDC_005)
  },
  {
    symbol: assetSymbols49["arrakis_USDC_agEUR_001"],
    underlying: arrakis_USDC_agEUR_001,
    name: "Arrakis Vault V1 USDC/agEUR 0.01",
    decimals: 18,
    oracle: OracleTypes21.GelatoGUniPriceOracle,
    extraDocs: arrakisDocs("Polygon", 137, arrakis_USDC_agEUR_001)
  },
  {
    symbol: assetSymbols49["arrakis_WMATIC_WETH_005"],
    underlying: arrakis_WMATIC_WETH_005,
    name: "Arrakis Vault V1 WMATIC/WETH 0.05",
    decimals: 18,
    oracle: OracleTypes21.GelatoGUniPriceOracle,
    extraDocs: arrakisDocs("Polygon", 137, arrakis_WMATIC_WETH_005)
  },
  {
    symbol: assetSymbols49["arrakis_WMATIC_AAVE_03"],
    underlying: arrakis_WMATIC_AAVE_03,
    name: "Arrakis Vault V1 WMATIC/AAVE 0.3",
    decimals: 18,
    oracle: OracleTypes21.GelatoGUniPriceOracle,
    extraDocs: arrakisDocs("Polygon", 137, arrakis_WMATIC_AAVE_03)
  },
  {
    symbol: assetSymbols49["arrakis_USDC_MAI_005"],
    underlying: arrakis_USDC_MAI_005,
    name: "Arrakis Vault V1 USDC/MAI 0.05",
    decimals: 18,
    oracle: OracleTypes21.GelatoGUniPriceOracle,
    extraDocs: arrakisDocs("Polygon", 137, arrakis_USDC_MAI_005)
  },
  {
    symbol: assetSymbols49["arrakis_USDC_USDT_001"],
    underlying: arrakis_USDC_USDT_001,
    name: "Arrakis Vault V1 USDC/USDT 0.01",
    decimals: 18,
    oracle: OracleTypes21.GelatoGUniPriceOracle,
    extraDocs: arrakisDocs("Polygon", 137, arrakis_USDC_USDT_001)
  },
  {
    symbol: assetSymbols49["arrakis_USDC_USDT_005"],
    underlying: arrakis_USDC_USDT_005,
    name: "Arrakis Vault V1 USDC/USDT 0.05",
    decimals: 18,
    oracle: OracleTypes21.GelatoGUniPriceOracle,
    extraDocs: arrakisDocs("Polygon", 137, arrakis_USDC_USDT_005)
  },
  {
    symbol: assetSymbols49["arrakis_USDC_DAI_005"],
    underlying: arrakis_USDC_DAI_005,
    name: "Arrakis Vault V1 USDC/DAI 0.05",
    decimals: 18,
    oracle: OracleTypes21.GelatoGUniPriceOracle,
    extraDocs: arrakisDocs("Polygon", 137, arrakis_USDC_DAI_005)
  },
  {
    symbol: assetSymbols49["arrakis_WETH_DAI_03"],
    underlying: arrakis_WETH_DAI_03,
    name: "Arrakis Vault V1 WETH/DAI 0.3",
    decimals: 18,
    oracle: OracleTypes21.GelatoGUniPriceOracle,
    extraDocs: arrakisDocs("Polygon", 137, arrakis_WETH_DAI_03)
  },
  {
    symbol: assetSymbols49.JRT,
    underlying: JRT,
    name: "Jarivs Reward Token",
    decimals: 18,
    oracle: OracleTypes21.UniswapTwapPriceOracleV2,
    extraDocs: defaultDocs("https://polygonscan.com", JRT),
    disabled: true
  },
  {
    symbol: assetSymbols49["sAMM-USDC/USDR"],
    underlying: solidlyStableAMM_USDR_USDC,
    name: "Stable AMM - USDC/USDR",
    decimals: 18,
    oracle: OracleTypes21.SolidlyLpTokenPriceOracle,
    extraDocs: pearlDocs(solidlyStableAMM_USDR_USDC),
    originalSymbol: assetOriginalSymbols2["sAMM-USDC/USDR"]
  },
  {
    symbol: assetSymbols49["vAMM-wUSDR/USDR"],
    underlying: solidlyVolatileAMM_wUSDR_USDR,
    name: "Volatile AMM - wUSDR/USDR",
    decimals: 18,
    oracle: OracleTypes21.SolidlyLpTokenPriceOracle,
    extraDocs: pearlDocs(solidlyVolatileAMM_wUSDR_USDR),
    originalSymbol: assetOriginalSymbols2["vAMM-wUSDR/USDR"]
  },
  {
    symbol: assetSymbols49["vAMM-stMATIC/USDR"],
    underlying: solidlyVolatileAMM_stMATIC_USDR,
    name: "Stable V1 AMM - stMATIC/USDR",
    decimals: 18,
    oracle: OracleTypes21.SolidlyLpTokenPriceOracle,
    extraDocs: pearlDocs(solidlyVolatileAMM_stMATIC_USDR),
    originalSymbol: assetOriginalSymbols2["vAMM-stMATIC/USDR"]
  }
];
var assets_default9 = assets12;

// src/polygon/addresses.ts
var chainAddresses11 = {
  W_TOKEN: underlying30(assets12, assetSymbols50.WMATIC),
  W_TOKEN_USD_CHAINLINK_PRICE_FEED: "0xAB594600376Ec9fD91F8e885dADF0CE036862dE0",
  UNISWAP_V2_ROUTER: "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff",
  UNISWAP_V2_FACTORY: "0x5757371414417b8C6CAad45bAeF941aBc7d3Ab32",
  UNISWAP_V3_ROUTER: "0xE592427A0AEce92De3Edee1F18E0157C05861564",
  PAIR_INIT_HASH: ethers12.utils.hexlify("0x96e8ac4277198ff8b6f785478aa9a39f403cb768dd02cbee326c3e7da348845f"),
  STABLE_TOKEN: underlying30(assets12, assetSymbols50.USDC),
  W_BTC_TOKEN: underlying30(assets12, assetSymbols50.WBTC),
  ALGEBRA_SWAP_ROUTER: "0xf5b509bB0909a69B1c207E495f687a596C168E12",
  SOLIDLY_SWAP_ROUTER: "0xda822340F5E8216C277DBF66627648Ff5D57b527"
};
var addresses_default11 = chainAddresses11;

// src/polygon/fundingStrategies.ts
import { assetSymbols as assetSymbols51, FundingStrategyContract as FundingStrategyContract2, underlying as underlying31 } from "@midas-capital/types";
var fundingStrategies11 = {
  // TODO: group by input token or configure as predefined paths
  [underlying31(assets12, assetSymbols51.JAUD)]: [
    FundingStrategyContract2.JarvisLiquidatorFunder,
    underlying31(assets12, assetSymbols51.USDC)
  ],
  [underlying31(assets12, assetSymbols51.JGBP)]: [
    FundingStrategyContract2.JarvisLiquidatorFunder,
    underlying31(assets12, assetSymbols51.USDC)
  ],
  [underlying31(assets12, assetSymbols51.JCAD)]: [
    FundingStrategyContract2.JarvisLiquidatorFunder,
    underlying31(assets12, assetSymbols51.USDC)
  ],
  [underlying31(assets12, assetSymbols51.JCHF)]: [
    FundingStrategyContract2.JarvisLiquidatorFunder,
    underlying31(assets12, assetSymbols51.USDC)
  ],
  [underlying31(assets12, assetSymbols51.JCNY)]: [
    FundingStrategyContract2.JarvisLiquidatorFunder,
    underlying31(assets12, assetSymbols51.USDC)
  ],
  [underlying31(assets12, assetSymbols51.JEUR)]: [
    FundingStrategyContract2.JarvisLiquidatorFunder,
    underlying31(assets12, assetSymbols51.USDC)
  ],
  [underlying31(assets12, assetSymbols51.JJPY)]: [
    FundingStrategyContract2.JarvisLiquidatorFunder,
    underlying31(assets12, assetSymbols51.USDC)
  ],
  [underlying31(assets12, assetSymbols51.JKRW)]: [
    FundingStrategyContract2.JarvisLiquidatorFunder,
    underlying31(assets12, assetSymbols51.USDC)
  ],
  [underlying31(assets12, assetSymbols51.JMXN)]: [
    FundingStrategyContract2.JarvisLiquidatorFunder,
    underlying31(assets12, assetSymbols51.USDC)
  ],
  [underlying31(assets12, assetSymbols51.JNZD)]: [
    FundingStrategyContract2.JarvisLiquidatorFunder,
    underlying31(assets12, assetSymbols51.USDC)
  ],
  [underlying31(assets12, assetSymbols51.JPHP)]: [
    FundingStrategyContract2.JarvisLiquidatorFunder,
    underlying31(assets12, assetSymbols51.USDC)
  ],
  [underlying31(assets12, assetSymbols51.JPLN)]: [
    FundingStrategyContract2.JarvisLiquidatorFunder,
    underlying31(assets12, assetSymbols51.USDC)
  ],
  [underlying31(assets12, assetSymbols51.JSEK)]: [
    FundingStrategyContract2.JarvisLiquidatorFunder,
    underlying31(assets12, assetSymbols51.USDC)
  ],
  [underlying31(assets12, assetSymbols51.JSGD)]: [
    FundingStrategyContract2.JarvisLiquidatorFunder,
    underlying31(assets12, assetSymbols51.USDC)
  ],
  [underlying31(assets12, assetSymbols51.PAR)]: [
    FundingStrategyContract2.UniswapV3LiquidatorFunder,
    // or CurveSwapLiquidatorFunder
    underlying31(assets12, assetSymbols51.USDC)
  ],
  [underlying31(assets12, assetSymbols51["JEUR-EURT"])]: [
    FundingStrategyContract2.CurveSwapLiquidatorFunder,
    underlying31(assets12, assetSymbols51.JEUR)
  ]
};
var fundingStrategies_default11 = fundingStrategies11;

// src/polygon/irms.ts
import { IrmTypes as IrmTypes11 } from "@midas-capital/types";
var baseIrms11 = [IrmTypes11.WhitePaperInterestRateModel, IrmTypes11.JumpRateModel];
var irms11 = [...baseIrms11, IrmTypes11.AdjustableJumpRateModel_JARVIS_jEUR];
var irms_default11 = irms11;

// src/polygon/liquidation.ts
import { assetSymbols as assetSymbols52, LiquidationStrategy as LiquidationStrategy11, underlying as underlying32 } from "@midas-capital/types";
import { BigNumber as BigNumber21, constants as constants11 } from "ethers";
var liquidationDefaults11 = {
  DEFAULT_ROUTER: addresses_default11.UNISWAP_V2_ROUTER,
  ASSET_SPECIFIC_ROUTER: {},
  SUPPORTED_OUTPUT_CURRENCIES: [
    constants11.AddressZero,
    underlying32(assets12, assetSymbols52.WMATIC),
    underlying32(assets12, assetSymbols52.USDC),
    underlying32(assets12, assetSymbols52.USDT)
  ],
  SUPPORTED_INPUT_CURRENCIES: [constants11.AddressZero, underlying32(assets12, assetSymbols52.WMATIC)],
  LIQUIDATION_STRATEGY: LiquidationStrategy11.UNISWAP,
  MINIMUM_PROFIT_NATIVE: BigNumber21.from(0),
  LIQUIDATION_INTERVAL_SECONDS: 60,
  jarvisPools: [
    // jAUD <-> USDC
    {
      expirationTime: 40 * 60,
      liquidityPoolAddress: "0x36572797Cc569A74731E0738Ef56e3b8ce3F309c",
      syntheticToken: underlying32(assets12, assetSymbols52.JAUD),
      collateralToken: underlying32(assets12, assetSymbols52.USDC)
    },
    //  jGBP <-> USDC
    {
      expirationTime: 40 * 60,
      liquidityPoolAddress: "0x36d6D1d6249fbC6EBd0fC28fd46C846fB69b9074",
      syntheticToken: underlying32(assets12, assetSymbols52.JGBP),
      collateralToken: underlying32(assets12, assetSymbols52.USDC)
    },
    //  jCAD <-> USDC
    {
      expirationTime: 40 * 60,
      liquidityPoolAddress: "0x06440a2DA257233790B5355322dAD82C10F0389A",
      syntheticToken: underlying32(assets12, assetSymbols52.JCAD),
      collateralToken: underlying32(assets12, assetSymbols52.USDC)
    },
    //  jCHF <-> USDC
    {
      expirationTime: 40 * 60,
      liquidityPoolAddress: "0x8734CF40A402D4191BD4D7a64bEeF12E4c452DeF",
      syntheticToken: underlying32(assets12, assetSymbols52.JCHF),
      collateralToken: underlying32(assets12, assetSymbols52.USDC)
    },
    //  jCNY <-> USDC
    {
      expirationTime: 40 * 60,
      liquidityPoolAddress: "0x72E7Da7C0dD3C082Dfe8f22343D6AD70286e07bd",
      syntheticToken: underlying32(assets12, assetSymbols52.JCNY),
      collateralToken: underlying32(assets12, assetSymbols52.USDC)
    },
    // //  jCOP <-> USDC
    // {
    //   expirationTime: 40 * 60,
    //   liquidityPoolAddress: "0x1493607042C5725cEf277A83CFC94caA4fc6278F",
    //   syntheticToken: underlying(assets, assetSymbols.JCOP),
    //   collateralToken: underlying(assets, assetSymbols.USDC),
    // },
    //  jEUR <-> USDC
    {
      expirationTime: 40 * 60,
      liquidityPoolAddress: "0x65a7b4Ff684C2d08c115D55a4B089bf4E92F5003",
      syntheticToken: underlying32(assets12, assetSymbols52.JEUR),
      collateralToken: underlying32(assets12, assetSymbols52.USDC)
    },
    //  jJPY <-> USDC
    {
      expirationTime: 40 * 60,
      liquidityPoolAddress: "0xAEc757BF73cc1f4609a1459205835Dd40b4e3F29",
      syntheticToken: underlying32(assets12, assetSymbols52.JJPY),
      collateralToken: underlying32(assets12, assetSymbols52.USDC)
    },
    //  jKRW <-> USDC
    {
      expirationTime: 40 * 60,
      liquidityPoolAddress: "0x7aC6515f4772fcB6EEeF978f60D996B21C56089D",
      syntheticToken: underlying32(assets12, assetSymbols52.JKRW),
      collateralToken: underlying32(assets12, assetSymbols52.USDC)
    },
    //  jMXN <-> USDC
    {
      expirationTime: 40 * 60,
      liquidityPoolAddress: "0x25E9F976f5020F6BF2d417b231e5f414b7700E31",
      syntheticToken: underlying32(assets12, assetSymbols52.JMXN),
      collateralToken: underlying32(assets12, assetSymbols52.USDC)
    },
    // //  jNGN <-> USDC
    // {
    //   expirationTime: 40 * 60,
    //   liquidityPoolAddress: "0x63B5891895A57C31d5Ec2a8A5521b6EE67700f9F",
    //   syntheticToken: underlying(assets, assetSymbols.JNGN),
    //   collateralToken: underlying(assets, assetSymbols.USDC),
    // },
    //  jNZD <-> USDC
    {
      expirationTime: 40 * 60,
      liquidityPoolAddress: "0x4FDA1B4b16f5F2535482b91314018aE5A2fda602",
      syntheticToken: underlying32(assets12, assetSymbols52.JNZD),
      collateralToken: underlying32(assets12, assetSymbols52.USDC)
    },
    //  jPHP <-> USDC
    {
      expirationTime: 40 * 60,
      liquidityPoolAddress: "0x8aE34663B4622336818e334dC42f92C41eFbfa35",
      syntheticToken: underlying32(assets12, assetSymbols52.JPHP),
      collateralToken: underlying32(assets12, assetSymbols52.USDC)
    },
    //  jPLN <-> USDC
    {
      expirationTime: 40 * 60,
      liquidityPoolAddress: "0x166e4B3Ec3F81F32f0863B9cD63621181d6bFED5",
      syntheticToken: underlying32(assets12, assetSymbols52.JPLN),
      collateralToken: underlying32(assets12, assetSymbols52.USDC)
    },
    //  jSEK <-> USDC
    {
      expirationTime: 40 * 60,
      liquidityPoolAddress: "0xc8442072CF1E131506eaC7df33eA8910e1d5cFDd",
      syntheticToken: underlying32(assets12, assetSymbols52.JSEK),
      collateralToken: underlying32(assets12, assetSymbols52.USDC)
    },
    //  jSGD <-> USDC
    {
      expirationTime: 40 * 60,
      liquidityPoolAddress: "0xBE813590e1B191120f5df3343368f8a2F579514C",
      syntheticToken: underlying32(assets12, assetSymbols52.JSGD),
      collateralToken: underlying32(assets12, assetSymbols52.USDC)
    }
  ],
  balancerPools: [
    {
      poolAddress: underlying32(assets12, assetSymbols52.MIMO_PAR_80_20),
      underlyingTokens: [underlying32(assets12, assetSymbols52.MIMO), underlying32(assets12, assetSymbols52.PAR)]
    },
    {
      poolAddress: underlying32(assets12, assetSymbols52.BRZ_JBRL_STABLE_BLP),
      underlyingTokens: [underlying32(assets12, assetSymbols52.jBRL), underlying32(assets12, assetSymbols52.BRZ)]
    },
    {
      poolAddress: underlying32(assets12, assetSymbols52.JEUR_PAR_STABLE_BLP),
      underlyingTokens: [underlying32(assets12, assetSymbols52.JEUR), underlying32(assets12, assetSymbols52.PAR)]
    },
    {
      poolAddress: underlying32(assets12, assetSymbols52.WMATIC_CSMATIC_STABLE_BLP),
      underlyingTokens: [underlying32(assets12, assetSymbols52.WMATIC), underlying32(assets12, assetSymbols52.csMATIC)]
    },
    {
      poolAddress: underlying32(assets12, assetSymbols52.WMATIC_MATICX_STABLE_BLP),
      underlyingTokens: [underlying32(assets12, assetSymbols52.WMATIC), underlying32(assets12, assetSymbols52.MATICx)]
    },
    {
      poolAddress: underlying32(assets12, assetSymbols52.TETU_BOOSTED_STABLE_BLP),
      underlyingTokens: [
        underlying32(assets12, assetSymbols52.TETU_LINEAR_DAI),
        underlying32(assets12, assetSymbols52.TETU_LINEAR_USDC),
        underlying32(assets12, assetSymbols52.TETU_LINEAR_USDT)
      ]
    },
    {
      poolAddress: underlying32(assets12, assetSymbols52.TETU_LINEAR_USDC),
      underlyingTokens: [underlying32(assets12, assetSymbols52.USDC)]
    },
    {
      poolAddress: underlying32(assets12, assetSymbols52.MaticX_bbaWMATIC),
      underlyingTokens: [underlying32(assets12, assetSymbols52.MATICx), underlying32(assets12, assetSymbols52.AAVE_LINEAR_WMATIC)]
    },
    {
      poolAddress: underlying32(assets12, assetSymbols52.StMatic_bbaWMATIC),
      underlyingTokens: [underlying32(assets12, assetSymbols52.stMATIC), underlying32(assets12, assetSymbols52.AAVE_LINEAR_WMATIC)]
    },
    {
      poolAddress: underlying32(assets12, assetSymbols52.StMatic_bbaWMATIC),
      underlyingTokens: [underlying32(assets12, assetSymbols52.stMATIC), underlying32(assets12, assetSymbols52.AAVE_LINEAR_WMATIC)]
    },
    {
      poolAddress: underlying32(assets12, assetSymbols52.AAVE_LINEAR_WMATIC),
      underlyingTokens: [underlying32(assets12, assetSymbols52.WMATIC), underlying32(assets12, assetSymbols52.AAVE_LINEAR_WMATIC)]
    }
  ]
};
var liquidation_default11 = liquidationDefaults11;

// src/polygon/oracles.ts
import { OracleTypes as OracleTypes22 } from "@midas-capital/types";
var baseOracles11 = [OracleTypes22.FixedNativePriceOracle, OracleTypes22.MasterPriceOracle, OracleTypes22.SimplePriceOracle];
var oracles11 = [
  ...baseOracles11,
  OracleTypes22.ChainlinkPriceOracleV2,
  OracleTypes22.DiaPriceOracle,
  OracleTypes22.CurveLpTokenPriceOracleNoRegistry,
  OracleTypes22.UniswapLpTokenPriceOracle,
  OracleTypes22.UniswapTwapPriceOracleV2,
  OracleTypes22.GelatoGUniPriceOracle,
  OracleTypes22.BalancerLpTokenPriceOracle,
  OracleTypes22.BalancerLpStablePoolPriceOracle,
  OracleTypes22.BalancerRateProviderOracle,
  OracleTypes22.AlgebraPriceOracle
];
var oracles_default11 = oracles11;

// src/polygon/params.ts
import { assetSymbols as assetSymbols53, underlying as underlying33 } from "@midas-capital/types";
import { BigNumber as BigNumber22 } from "ethers";
var specificParams11 = {
  blocksPerYear: BigNumber22.from((26 * 24 * 365 * 60).toString()),
  cgId: "matic-network",
  metadata: {
    chainIdHex: "0x89",
    name: "Polygon Mainnet",
    shortName: "Polygon",
    uniswapV3Fees: {
      [underlying33(assets_default9, assetSymbols53.USDC)]: {
        [underlying33(assets_default9, assetSymbols53.PAR)]: 500
      }
    },
    img: "https://d1912tcoux65lj.cloudfront.net/network/polygon.jpg",
    blockExplorerUrls: { default: { name: "polygonscan", url: "https://polygonscan.com" } },
    rpcUrls: {
      default: { http: ["https://polygon.llamarpc.com"] },
      public: { http: ["https://polygon.llamarpc.com"] }
    },
    nativeCurrency: {
      symbol: "MATIC",
      name: "MATIC"
    },
    wrappedNativeCurrency: {
      symbol: assetSymbols53.WMATIC,
      address: addresses_default11.W_TOKEN,
      name: "WMATIC",
      decimals: 18,
      color: "#627EEA",
      overlayTextColor: "#fff",
      logoURL: "https://d1912tcoux65lj.cloudfront.net/network/polygon.jpg"
    }
  }
};
var params_default11 = specificParams11;

// src/polygon/plugins.ts
import { assetSymbols as assetSymbols54, Strategy as Strategy4, underlying as underlying34 } from "@midas-capital/types";
var deployedPlugins11 = {
  // BeefyERC4626_EURE-jEUR_0x6dDF9A3b2DE1300bB2B99277716e4E574DB3a871.json
  "0xB6a8f36746BcCC1025Ec54eb2c6DCEF8EeE8df2f": {
    // still the same, failing to change to new 0x43fa05d9D56c44d7a697Ac458CC16707A545183B
    market: "0x6dDF9A3b2DE1300bB2B99277716e4E574DB3a871",
    name: "Beefy EURE-jEUR Vault",
    strategy: Strategy4.Beefy,
    apyDocsUrl: "https://app.beefy.finance/vault/jarvis-2eure",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/polygon/jarvis-jfiat-pool",
    underlying: underlying34(assets_default9, assetSymbols54["EURE-JEUR"]),
    otherParams: ["0x58a3e6d5501180fb9fcE7cFC2368F9Dc5e186A6f", "0"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/beefy.png"
  },
  // BeefyERC4626_agEUR-jEUR_0x9b5D86F4e7A45f4b458A2B673B4A3b43D15428A7.json
  "0x509d5070088d1F789cD6BeAA88055ac93fF9bCeB": {
    market: "0x9b5D86F4e7A45f4b458A2B673B4A3b43D15428A7",
    name: "Beefy agEUR-jEUR Vault",
    strategy: Strategy4.Beefy,
    apyDocsUrl: "https://app.beefy.finance/vault/jarvis-2eur",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/polygon/jarvis-jfiat-pool",
    underlying: underlying34(assets_default9, assetSymbols54["AGEUR-JEUR"]),
    otherParams: ["0x5F1b5714f30bAaC4Cb1ee95E1d0cF6d5694c2204", "0"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/beefy.png"
  },
  // BeefyERC4626_jEUR-PAR_0xCC7eab2605972128752396241e46C281e0405a27.json
  "0x9F82D802FB4940743C543041b86220A9096A7522": {
    market: "0xCC7eab2605972128752396241e46C281e0405a27",
    name: "Beefy jEUR-PAR Vault",
    strategy: Strategy4.Beefy,
    apyDocsUrl: "https://app.beefy.finance/vault/jarvis-2eurp",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/polygon/jarvis-jfiat-pool",
    underlying: underlying34(assets_default9, assetSymbols54["JEUR-PAR"]),
    otherParams: ["0xfE1779834EaDD60660a7F3f576448D6010f5e3Fc", "0"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/beefy.png"
  },
  // BeefyERC4626_jJPY-JPYC_0x1792046890b99ae36756Fd00f135dc5F80D41dfA.json
  "0xcDb7D4f4Dbe0DDd09F1De16aaA2eEcA6a590F725": {
    market: "0x1792046890b99ae36756Fd00f135dc5F80D41dfA",
    name: "Beefy jJPY-JPYC Vault",
    strategy: Strategy4.Beefy,
    apyDocsUrl: "https://app.beefy.finance/vault/jarvis-2jpy2",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/polygon/jarvis-jfiat-pool",
    underlying: underlying34(assets_default9, assetSymbols54["JJPY-JPYC"]),
    otherParams: ["0x122E09FdD2FF73C8CEa51D432c45A474BAa1518a", "0"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/beefy.png"
  },
  // BeefyERC4626_jCAD-CADC_0x17A6922ADE40e8aE783b0f6b8931Faeca4a5A264.json
  "0x0FbFc75E7FAcEb8453f8F0F6938c4898C9Fcdcbd": {
    market: "0x17A6922ADE40e8aE783b0f6b8931Faeca4a5A264",
    name: "Beefy jCAD-CADC Vault",
    strategy: Strategy4.Beefy,
    apyDocsUrl: "https://app.beefy.finance/vault/jarvis-2cad",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/polygon/jarvis-jfiat-pool",
    underlying: underlying34(assets_default9, assetSymbols54["JCAD-CADC"]),
    otherParams: ["0xcf9Dd1de1D02158B3d422779bd5184032674A6D1", "0"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/beefy.png"
  },
  // BeefyERC4626_jSGD-XSGD_0x41EDdba1e19fe301A067b2726DF5a3332DD02D6A
  "0x8cA5151058aD6F5684287ca523194Faa79827B99": {
    market: "0x41EDdba1e19fe301A067b2726DF5a3332DD02D6A",
    name: "Beefy jSGD-XSGD Vault",
    strategy: Strategy4.Beefy,
    apyDocsUrl: "https://app.beefy.finance/vault/jarvis-2sgd",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/polygon/jarvis-jfiat-pool",
    underlying: underlying34(assets_default9, assetSymbols54["JSGD-XSGD"]),
    otherParams: ["0x18DAdac6d0AAF37BaAAC811F6338427B46815a81", "0"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/beefy.png"
  },
  // BeefyERC4626_jEUR-EURT_0xB3eAb218a7e3A68Dc5020fC1c0F7f0e3214a8bAE.json
  "0x90721EfE6b155052b9f9E99043A43fDAB521aeC1": {
    market: "0xB3eAb218a7e3A68Dc5020fC1c0F7f0e3214a8bAE",
    name: "Beefy jEUR-EURt Vault",
    strategy: Strategy4.Beefy,
    apyDocsUrl: "https://app.beefy.finance/vault/jarvis-2eurt",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/polygon/jarvis-jfiat-pool",
    underlying: underlying34(assets_default9, assetSymbols54["JEUR-EURT"]),
    otherParams: ["0x26B7d2fe697e932907175A3920B5dC2C2e2440A4", "0"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/beefy.png"
  },
  // ArrakisERC4626_G-UNI USDC-PAR Vault (0.05)_0xa5A14c3814d358230a56e8f011B8fc97A508E890.json
  "0xdE58CF12595e92ebB07D664eE59A642e360bea58": {
    market: "0xa5A14c3814d358230a56e8f011B8fc97A508E890",
    name: "Arrakis PAR-USDC Vault",
    strategy: Strategy4.Arrakis,
    // TODO should be called Mimo
    apyDocsUrl: "https://app.mimo.capital/mining",
    underlying: underlying34(assets_default9, assetSymbols54["arrakis_USDC_PAR_005"]),
    otherParams: [
      "0x5fF63E442AC4724EC342f4a3d26924233832EcBB",
      // _flywheel
      "0x528330fF7c358FE1bAe348D23849CCed8edA5917",
      // IGuniPool _pool
      "0xa5A14c3814d358230a56e8f011B8fc97A508E890",
      // _rewardsDestination
      [underlying34(assets_default9, assetSymbols54.MIMO)]
      // _rewardTokens
    ],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/mimo.png"
  },
  // ArrakisERC4626_MIMO80-PAR20 BLP_0x82d7f08026e21c7713CfAd1071df7C8271B17Eae.json
  "0xd682451F627d54cfdA74a80972aDaeF133cdc15e": {
    market: "0xcb67Bd2aE0597eDb2426802CdF34bb4085d9483A",
    name: "Balancer LP MIMO80-PAR20",
    strategy: Strategy4.Arrakis,
    // TODO should be called Mimo
    apyDocsUrl: "https://app.mimo.capital/mining",
    underlying: underlying34(assets_default9, assetSymbols54.MIMO_PAR_80_20),
    otherParams: [
      "0x5fF63E442AC4724EC342f4a3d26924233832EcBB",
      // _flywheel
      "0xBA2D426DCb186d670eD54a759098947fad395C95",
      // IGuniPool _pool
      "0xcb67Bd2aE0597eDb2426802CdF34bb4085d9483A",
      // _rewardsDestination
      [underlying34(assets_default9, assetSymbols54.MIMO)]
      // _rewardTokens
    ],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/mimo.png"
  },
  // BeefyERC4626_jEUR-PAR_0x30b32BbfcA3A81922F88809F53E625b5EE5286f6.json
  "0xc8E8B4A7E0F854Cf516A75fE742FC791dBec9F86": {
    market: "0x30b32BbfcA3A81922F88809F53E625b5EE5286f6",
    name: "Beefy jEUR-PAR Vault",
    strategy: Strategy4.Beefy,
    apyDocsUrl: "https://app.beefy.finance/vault/jarvis-2eurp",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/polygon/mimo-pool",
    underlying: underlying34(assets_default9, assetSymbols54["JEUR-PAR"]),
    otherParams: ["0xfE1779834EaDD60660a7F3f576448D6010f5e3Fc", "0"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/beefy.png"
  },
  // ArrakisERC4626_G-UNI USDC-PAR Vault (0.05)_0x4DED2939A2A8912E9Cc9eaEFAbECC43CC9864723.json
  "0xdF23f2E94a322685DD4E967dE6165242cf00B85B": {
    market: "0x4DED2939A2A8912E9Cc9eaEFAbECC43CC9864723",
    name: "Arrakis PAR-USDC Vault",
    strategy: Strategy4.Arrakis,
    underlying: underlying34(assets_default9, assetSymbols54["arrakis_USDC_PAR_005"]),
    otherParams: [
      "0x5fF63E442AC4724EC342f4a3d26924233832EcBB",
      // _flywheel
      "0x528330fF7c358FE1bAe348D23849CCed8edA5917",
      // IGuniPool _pool
      "0x4DED2939A2A8912E9Cc9eaEFAbECC43CC9864723",
      // _rewardsDestination
      [underlying34(assets_default9, assetSymbols54.MIMO)]
      // _rewardTokens
    ],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/mimo.png"
  },
  // BeefyERC4626_am3CRV_0xd412eff9163742f6D3A4AD56B5C83D8864E8Ec3F.json
  "0xAe8a8253cd70A8Ad4749aAd850A99e2f30552f10": {
    market: "0xd412eff9163742f6D3A4AD56B5C83D8864E8Ec3F",
    name: "Beefy am3CRV Vault",
    strategy: Strategy4.Beefy,
    apyDocsUrl: "https://app.beefy.finance/vault/curve-am3crv",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/polygon/jarvis-jfiat-pool",
    underlying: underlying34(assets_default9, assetSymbols54.am3CRV),
    otherParams: ["0xAA7C2879DaF8034722A0977f13c343aF0883E92e", "0"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/beefy.png"
  },
  // BeefyERC4626_MAI-USDC_0x9df035a2DC13ab63FcF05bb27B3722E68c7AE6b9.json
  "0x11EE50FB7DB66d044C3342546C27457f958e1ceD": {
    market: "0x9df035a2DC13ab63FcF05bb27B3722E68c7AE6b9",
    name: "Beefy am3CRV Vault",
    strategy: Strategy4.Beefy,
    apyDocsUrl: "https://app.beefy.finance/vault/mai-usdc-mimatic",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/polygon/jarvis-jfiat-pool",
    underlying: underlying34(assets_default9, assetSymbols54["MAI-USDC"]),
    otherParams: ["0xebe0c8d842AA5A57D7BEf8e524dEabA676F91cD1", "50"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/beefy.png"
  },
  // BeefyERC4626_JEUR_PAR STABLE BLP_0x1944FA4a490f85Ed99e2c6fF9234F94DE16fdbde.json
  "0x36A297A86Eaed6aE0BaEb541F766A1675960B390": {
    market: "0x1944FA4a490f85Ed99e2c6fF9234F94DE16fdbde",
    name: "Beefy JEUR-PAR Vault",
    strategy: Strategy4.Beefy,
    apyDocsUrl: "https://app.beefy.finance/vault/balancer-jeur-par-v2",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/polygon/mimo-pool",
    underlying: underlying34(assets_default9, assetSymbols54.JEUR_PAR_STABLE_BLP),
    otherParams: ["0xB476B7A027da3D9fB5A5c9CB6078A34f7289B476", "0"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/beefy.png"
  },
  // BeefyERC4626_MaticX-bba-WMATIC_0x13e763D25D78c3Fd6FEA534231BdaEBE7Fa52945.json
  "0x27569756A02Ee590306F1409821b5496A8e4B2B3": {
    market: "0x13e763D25D78c3Fd6FEA534231BdaEBE7Fa52945",
    name: "Beefy MaticX-bba-WMATIC Vault",
    strategy: Strategy4.Beefy,
    apyDocsUrl: "https://app.beefy.finance/vault/balancer-maticx-bbawmatic",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/polygon/mimo-pool",
    underlying: underlying34(assets_default9, assetSymbols54.MaticX_bbaWMATIC),
    otherParams: ["0x4C98CB046c3eb7e3ae7Eb49a33D6f3386Ec2b9D9", "0"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/beefy.png"
  },
  // BeefyERC4626_StMatic-bba-WMATIC_0x6107D9186D710cA8BaC2B9b2b8b26a045D9f994b.json
  "0x6732E47b15919EaA4a876bC1007bdCa19020d4ED": {
    market: "0x6107D9186D710cA8BaC2B9b2b8b26a045D9f994b",
    name: "Dyson StMatic-bba-WMATIC Vault",
    strategy: Strategy4.Dyson4626,
    apyDocsUrl: "https://app.dyson.money/#/pools?id=balancer-stmatic-bbawmatic",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/polygon/mimo-pool",
    underlying: underlying34(assets_default9, assetSymbols54.StMatic_bbaWMATIC),
    otherParams: ["0x18661C2527220aBE021F5b52351d5c4210E0E2c6", "10"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/dyson.png"
  },
  // BeefyERC4626_USDR3CRV_0x24691492A0B99404F21B1509B1f41Ea3927c8Da9.json
  "0xb341c397dEe4098c53428bF4f8BC90F79BFDf4bA": {
    market: "0x24691492A0B99404F21B1509B1f41Ea3927c8Da9",
    name: "Beefy Curve.fi USDR/DAI/USDC/USDT Vault",
    strategy: Strategy4.Beefy,
    apyDocsUrl: "https://app.beefy.finance/vault/curve-poly-usdr",
    strategyDocsUrl: "https://docs.midascapital.xyz/guides/assets-and-strategies-addresses/polygon/mimo-pool",
    underlying: underlying34(assets_default9, assetSymbols54.USDR3CRV),
    otherParams: ["0x2520D50bfD793D3C757900D81229422F70171969", "0"],
    icon: "https://d1912tcoux65lj.cloudfront.net/plugin/beefy.png"
  }
};
var plugins_default11 = deployedPlugins11;

// src/polygon/redemptionStrategies.ts
import { assetSymbols as assetSymbols55, RedemptionStrategyContract as RedemptionStrategyContract7, underlying as underlying35 } from "@midas-capital/types";
var redemptionStrategies11 = {
  [underlying35(assets12, assetSymbols55.jBRL)]: [
    RedemptionStrategyContract7.JarvisLiquidatorFunder,
    underlying35(assets12, assetSymbols55.USDC)
  ],
  [underlying35(assets12, assetSymbols55.JAUD)]: [
    RedemptionStrategyContract7.JarvisLiquidatorFunder,
    underlying35(assets12, assetSymbols55.USDC)
  ],
  [underlying35(assets12, assetSymbols55.JCAD)]: [
    RedemptionStrategyContract7.JarvisLiquidatorFunder,
    underlying35(assets12, assetSymbols55.USDC)
  ],
  [underlying35(assets12, assetSymbols55.JCHF)]: [
    RedemptionStrategyContract7.JarvisLiquidatorFunder,
    underlying35(assets12, assetSymbols55.USDC)
  ],
  [underlying35(assets12, assetSymbols55.JCNY)]: [
    RedemptionStrategyContract7.JarvisLiquidatorFunder,
    underlying35(assets12, assetSymbols55.USDC)
  ],
  [underlying35(assets12, assetSymbols55.JEUR)]: [
    RedemptionStrategyContract7.JarvisLiquidatorFunder,
    underlying35(assets12, assetSymbols55.USDC)
  ],
  [underlying35(assets12, assetSymbols55.JCAD)]: [
    RedemptionStrategyContract7.JarvisLiquidatorFunder,
    underlying35(assets12, assetSymbols55.USDC)
  ],
  [underlying35(assets12, assetSymbols55.JGBP)]: [
    RedemptionStrategyContract7.JarvisLiquidatorFunder,
    underlying35(assets12, assetSymbols55.USDC)
  ],
  [underlying35(assets12, assetSymbols55.JJPY)]: [
    RedemptionStrategyContract7.JarvisLiquidatorFunder,
    underlying35(assets12, assetSymbols55.USDC)
  ],
  [underlying35(assets12, assetSymbols55.JKRW)]: [
    RedemptionStrategyContract7.JarvisLiquidatorFunder,
    underlying35(assets12, assetSymbols55.USDC)
  ],
  [underlying35(assets12, assetSymbols55.JSGD)]: [
    RedemptionStrategyContract7.JarvisLiquidatorFunder,
    underlying35(assets12, assetSymbols55.USDC)
  ],
  [underlying35(assets12, assetSymbols55.JMXN)]: [
    RedemptionStrategyContract7.JarvisLiquidatorFunder,
    underlying35(assets12, assetSymbols55.USDC)
  ],
  [underlying35(assets12, assetSymbols55.JNZD)]: [
    RedemptionStrategyContract7.JarvisLiquidatorFunder,
    underlying35(assets12, assetSymbols55.USDC)
  ],
  [underlying35(assets12, assetSymbols55.JPLN)]: [
    RedemptionStrategyContract7.JarvisLiquidatorFunder,
    underlying35(assets12, assetSymbols55.USDC)
  ],
  [underlying35(assets12, assetSymbols55.JSEK)]: [
    RedemptionStrategyContract7.JarvisLiquidatorFunder,
    underlying35(assets12, assetSymbols55.USDC)
  ],
  [underlying35(assets12, assetSymbols55.JPHP)]: [
    RedemptionStrategyContract7.JarvisLiquidatorFunder,
    underlying35(assets12, assetSymbols55.USDC)
  ],
  [underlying35(assets12, assetSymbols55.arrakis_USDC_WETH_005)]: [
    RedemptionStrategyContract7.GelatoGUniLiquidator,
    underlying35(assets12, assetSymbols55.USDC)
  ],
  [underlying35(assets12, assetSymbols55.arrakis_USDC_DAI_005)]: [
    RedemptionStrategyContract7.GelatoGUniLiquidator,
    underlying35(assets12, assetSymbols55.USDC)
  ],
  [underlying35(assets12, assetSymbols55.arrakis_USDC_MAI_005)]: [
    RedemptionStrategyContract7.GelatoGUniLiquidator,
    underlying35(assets12, assetSymbols55.USDC)
  ],
  [underlying35(assets12, assetSymbols55.arrakis_USDC_PAR_005)]: [
    RedemptionStrategyContract7.GelatoGUniLiquidator,
    underlying35(assets12, assetSymbols55.USDC)
  ],
  [underlying35(assets12, assetSymbols55.arrakis_USDC_USDT_001)]: [
    RedemptionStrategyContract7.GelatoGUniLiquidator,
    underlying35(assets12, assetSymbols55.USDC)
  ],
  [underlying35(assets12, assetSymbols55.arrakis_USDC_USDT_005)]: [
    RedemptionStrategyContract7.GelatoGUniLiquidator,
    underlying35(assets12, assetSymbols55.USDC)
  ],
  [underlying35(assets12, assetSymbols55.arrakis_USDC_WETH_005)]: [
    RedemptionStrategyContract7.GelatoGUniLiquidator,
    underlying35(assets12, assetSymbols55.USDC)
  ],
  [underlying35(assets12, assetSymbols55.arrakis_USDC_agEUR_001)]: [
    RedemptionStrategyContract7.GelatoGUniLiquidator,
    underlying35(assets12, assetSymbols55.USDC)
  ],
  [underlying35(assets12, assetSymbols55.arrakis_WBTC_WETH_005)]: [
    RedemptionStrategyContract7.GelatoGUniLiquidator,
    underlying35(assets12, assetSymbols55.WBTC)
  ],
  [underlying35(assets12, assetSymbols55.arrakis_WETH_DAI_03)]: [
    RedemptionStrategyContract7.GelatoGUniLiquidator,
    underlying35(assets12, assetSymbols55.WETH)
  ],
  [underlying35(assets12, assetSymbols55.arrakis_WMATIC_AAVE_03)]: [
    RedemptionStrategyContract7.GelatoGUniLiquidator,
    underlying35(assets12, assetSymbols55.WMATIC)
  ],
  [underlying35(assets12, assetSymbols55.arrakis_WMATIC_USDC_005)]: [
    RedemptionStrategyContract7.GelatoGUniLiquidator,
    underlying35(assets12, assetSymbols55.WMATIC)
  ],
  [underlying35(assets12, assetSymbols55.arrakis_WMATIC_WETH_005)]: [
    RedemptionStrategyContract7.GelatoGUniLiquidator,
    underlying35(assets12, assetSymbols55.WMATIC)
  ],
  [underlying35(assets12, assetSymbols55["AGEUR-JEUR"])]: [
    RedemptionStrategyContract7.CurveLpTokenLiquidatorNoRegistry,
    underlying35(assets12, assetSymbols55.JEUR)
  ],
  [underlying35(assets12, assetSymbols55["JEUR-PAR"])]: [
    RedemptionStrategyContract7.CurveLpTokenLiquidatorNoRegistry,
    underlying35(assets12, assetSymbols55.JEUR)
  ],
  [underlying35(assets12, assetSymbols55["JJPY-JPYC"])]: [
    RedemptionStrategyContract7.CurveLpTokenLiquidatorNoRegistry,
    underlying35(assets12, assetSymbols55.JJPY)
  ],
  [underlying35(assets12, assetSymbols55["JCAD-CADC"])]: [
    RedemptionStrategyContract7.CurveLpTokenLiquidatorNoRegistry,
    underlying35(assets12, assetSymbols55.JCAD)
  ],
  [underlying35(assets12, assetSymbols55["JSGD-XSGD"])]: [
    RedemptionStrategyContract7.CurveLpTokenLiquidatorNoRegistry,
    underlying35(assets12, assetSymbols55.JSGD)
  ],
  [underlying35(assets12, assetSymbols55["JNZD-NZDS"])]: [
    RedemptionStrategyContract7.CurveLpTokenLiquidatorNoRegistry,
    underlying35(assets12, assetSymbols55.JNZD)
  ],
  [underlying35(assets12, assetSymbols55["JEUR-EURT"])]: [
    RedemptionStrategyContract7.CurveLpTokenLiquidatorNoRegistry,
    underlying35(assets12, assetSymbols55.JEUR)
  ],
  [underlying35(assets12, assetSymbols55["EURE-JEUR"])]: [
    RedemptionStrategyContract7.CurveLpTokenLiquidatorNoRegistry,
    underlying35(assets12, assetSymbols55.JEUR)
  ],
  [underlying35(assets12, assetSymbols55.am3CRV)]: [
    RedemptionStrategyContract7.CurveLpTokenLiquidatorNoRegistry,
    underlying35(assets12, assetSymbols55.amUSDC)
  ],
  [underlying35(assets12, assetSymbols55.amUSDC)]: [
    RedemptionStrategyContract7.AaveTokenLiquidator,
    underlying35(assets12, assetSymbols55.USDC)
  ],
  [underlying35(assets12, assetSymbols55.USDR3CRV)]: [
    RedemptionStrategyContract7.CurveLpTokenLiquidatorNoRegistry,
    underlying35(assets12, assetSymbols55.USDR)
  ],
  [underlying35(assets12, assetSymbols55["MAI-USDC"])]: [RedemptionStrategyContract7.UniswapLpTokenLiquidator, USDC7],
  [underlying35(assets12, assetSymbols55["WMATIC-MATICx"])]: [RedemptionStrategyContract7.UniswapLpTokenLiquidator, WMATIC],
  [underlying35(assets12, assetSymbols55.MIMO_PAR_80_20)]: [
    RedemptionStrategyContract7.BalancerLpTokenLiquidator,
    underlying35(assets12, assetSymbols55.PAR)
  ],
  [underlying35(assets12, assetSymbols55.PAR)]: [
    RedemptionStrategyContract7.CurveSwapLiquidator,
    underlying35(assets12, assetSymbols55.JEUR)
  ],
  [underlying35(assets12, assetSymbols55.MAI)]: [
    RedemptionStrategyContract7.UniswapV2LiquidatorFunder,
    underlying35(assets12, assetSymbols55.USDC)
  ],
  [underlying35(assets12, assetSymbols55.JEUR_PAR_STABLE_BLP)]: [
    RedemptionStrategyContract7.BalancerLpTokenLiquidator,
    underlying35(assets12, assetSymbols55.JEUR)
  ],
  [underlying35(assets12, assetSymbols55.BRZ_JBRL_STABLE_BLP)]: [
    RedemptionStrategyContract7.BalancerLpTokenLiquidator,
    underlying35(assets12, assetSymbols55.jBRL)
  ],
  [underlying35(assets12, assetSymbols55.WMATIC_MATICX_STABLE_BLP)]: [
    RedemptionStrategyContract7.BalancerLpTokenLiquidator,
    underlying35(assets12, assetSymbols55.WMATIC)
  ],
  [underlying35(assets12, assetSymbols55.WMATIC_CSMATIC_STABLE_BLP)]: [
    RedemptionStrategyContract7.BalancerLpTokenLiquidator,
    underlying35(assets12, assetSymbols55.WMATIC)
  ],
  [underlying35(assets12, assetSymbols55.TETU_BOOSTED_STABLE_BLP)]: [
    RedemptionStrategyContract7.BalancerLpTokenLiquidator,
    underlying35(assets12, assetSymbols55.TETU_LINEAR_USDC)
  ],
  [underlying35(assets12, assetSymbols55.TETU_LINEAR_USDC)]: [
    RedemptionStrategyContract7.BalancerSwapLiquidator,
    underlying35(assets12, assetSymbols55.USDC)
  ],
  [underlying35(assets12, assetSymbols55.MaticX_bbaWMATIC)]: [
    RedemptionStrategyContract7.BalancerLpTokenLiquidator,
    underlying35(assets12, assetSymbols55.AAVE_LINEAR_WMATIC)
  ],
  [underlying35(assets12, assetSymbols55.StMatic_bbaWMATIC)]: [
    RedemptionStrategyContract7.BalancerLpTokenLiquidator,
    underlying35(assets12, assetSymbols55.AAVE_LINEAR_WMATIC)
  ],
  [underlying35(assets12, assetSymbols55.AAVE_LINEAR_WMATIC)]: [
    RedemptionStrategyContract7.BalancerSwapLiquidator,
    underlying35(assets12, assetSymbols55.WMATIC)
  ],
  [underlying35(assets12, assetSymbols55.USDR)]: [
    RedemptionStrategyContract7.SolidlySwapLiquidator,
    underlying35(assets12, assetSymbols55.USDC)
  ],
  [underlying35(assets12, assetSymbols55["sAMM-USDC/USDR"])]: [
    RedemptionStrategyContract7.SolidlyLpTokenLiquidator,
    underlying35(assets12, assetSymbols55.USDC)
  ],
  [underlying35(assets12, assetSymbols55["vAMM-wUSDR/USDR"])]: [
    RedemptionStrategyContract7.SolidlyLpTokenLiquidator,
    underlying35(assets12, assetSymbols55.USDR)
  ],
  [underlying35(assets12, assetSymbols55["vAMM-stMATIC/USDR"])]: [
    RedemptionStrategyContract7.SolidlyLpTokenLiquidator,
    underlying35(assets12, assetSymbols55.USDR)
  ],
  // Zappers
  [underlying35(assets12, assetSymbols55.USDC)]: [
    RedemptionStrategyContract7.SolidlyLpTokenWrapper,
    underlying35(assets12, assetSymbols55["sAMM-USDC/USDR"])
  ],
  [underlying35(assets12, assetSymbols55.USDR)]: [
    RedemptionStrategyContract7.SolidlyLpTokenWrapper,
    underlying35(assets12, assetSymbols55["sAMM-USDC/USDR"])
  ],
  [underlying35(assets12, assetSymbols55.USDR)]: [
    RedemptionStrategyContract7.CurveLpTokenWrapper,
    underlying35(assets12, assetSymbols55.USDR3CRV)
  ],
  [underlying35(assets12, assetSymbols55.am3CRV)]: [
    RedemptionStrategyContract7.CurveLpTokenWrapper,
    underlying35(assets12, assetSymbols55.USDR3CRV)
  ],
  [underlying35(assets12, assetSymbols55.USDR)]: [
    RedemptionStrategyContract7.SolidlyLpTokenWrapper,
    underlying35(assets12, assetSymbols55["vAMM-stMATIC/USDR"])
  ],
  [underlying35(assets12, assetSymbols55.USDR)]: [
    RedemptionStrategyContract7.SolidlyLpTokenWrapper,
    underlying35(assets12, assetSymbols55["vAMM-stMATIC/USDR"])
  ],
  [underlying35(assets12, assetSymbols55.stMATIC)]: [
    RedemptionStrategyContract7.SolidlyLpTokenWrapper,
    underlying35(assets12, assetSymbols55["vAMM-stMATIC/USDR"])
  ]
};
var redemptionStrategies_default11 = redemptionStrategies11;

// src/polygon/index.ts
var chainConfig11 = {
  chainId: SupportedChains19.polygon,
  chainAddresses: addresses_default11,
  assets: assets_default9,
  irms: irms_default11,
  liquidationDefaults: liquidation_default11,
  oracles: oracles_default11,
  specificParams: params_default11,
  deployedPlugins: plugins_default11,
  redemptionStrategies: redemptionStrategies_default11,
  fundingStrategies: fundingStrategies_default11,
  chainDeployments: polygon_default.contracts
};
var polygon_default2 = chainConfig11;

// src/index.ts
var chainIdToConfig = {
  [bsc_default2.chainId]: bsc_default2,
  [polygon_default2.chainId]: polygon_default2,
  [moonbeam_default2.chainId]: moonbeam_default2,
  [arbitrum_default2.chainId]: arbitrum_default2,
  [evmos_default2.chainId]: evmos_default2,
  [chapel_default2.chainId]: chapel_default2,
  [ganache_default2.chainId]: ganache_default2,
  [neondevnet_default2.chainId]: neondevnet_default2,
  [fantom_default2.chainId]: fantom_default2,
  [lineagoerli_default2.chainId]: lineagoerli_default2,
  [ethereum_default2.chainId]: ethereum_default2
};
export {
  arbitrum_default2 as arbitrum,
  bsc_default2 as bsc,
  chainIdToConfig,
  chapel_default2 as chapel,
  ethereum_default2 as ethereum,
  evmos_default2 as evmos,
  fantom_default2 as fantom,
  ganache_default2 as ganache,
  lineagoerli_default2 as lineagoerli,
  moonbeam_default2 as moonbeam,
  neondevnet_default2 as neondevnet,
  polygon_default2 as polygon
};
